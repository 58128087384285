import React, { useState, useEffect } from "react";
import axios from "axios";
import { Alert } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { FaTrashAlt } from "react-icons/fa";
import { Header } from "./Header";
import { Footer } from "./Footer";

export const Clientinfo = () => {
  const currentYear = new Date().getFullYear();
  const [emData, setData] = useState([]);
  const [activeIndex, setActiveIndex] = useState(null);
  const [activesIndex, setActivesIndex] = useState(null);
  const [gdata, getData] = useState("");
  const [valuegetUser, setValuegetuser] = useState([]);
  const [getallroster, setValuegetallroster] = useState([]);
  const [isClicked, setIsClicked] = useState(false);
  const [minisClicked, setMinClicked] = useState(false);
  const [locationID, setLocationId] = useState("");
  const [clientID, setClientId] = useState("");
  const [clientinfo, setclientInfo] = useState("");
  const [locatid, setlocatid] = useState("");
  const [isClickedloc, setIsClickedloc] = useState(false);
  const [clientreq, setclientreq] = useState(false);
  const [locatreq, setlocatreq] = useState(false);
  const [rosterreq, setrosterreq] = useState(false);
  const [located, setlocated] = useState("");
  const [rosters, setroster] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const apiurl = "https://jlmining.app/uploads/";
  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    let month = today.getMonth() + 1;
    let day = today.getDate();

    // Add leading zero if month/day is a single digit
    month = month < 10 ? "0" + month : month;
    day = day < 10 ? "0" + day : day;

    return `${year}-${month}-${day}`;
  };
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };
  const handleLogout = () => {
    localStorage.removeItem("users");
    window.location.href = "/login";
  };
  const handleSubmit = () => {};
  const handlechooseRoster = (type) => {
    setroster(type);
  };
  const checkcondition = () => {
    if (clientID === "") {
      setclientreq(true);
      return false;
    }
    if (locationID === "") {
      setlocatreq(true);
      return false;
    }
    if (selectedOption === "" || selectedOption === undefined) {
      setrosterreq(true);
      return false;
    }
    setrosterreq(false);
    setclientreq(false);
    setlocatreq(false);
  };
  const handlechooseRostersubmit = (event) => {
    if (clientID === "") {
      setclientreq(true);
      return false;
    }
    const userStr = JSON.parse(localStorage.getItem("users"));
    if (locationID === "") {
      setclientreq(true);
      return false;
    }
    if (selectedOption === "" || selectedOption === undefined) {
      setrosterreq(true);
      return false;
    }
    setclientreq(false);
    setlocatreq(false);
    var vl = event.target;
    console.log(vl.date.value);

    let formdata = {
      type: selectedOption,
      date: vl.date.value,
      locationId: locationID,
      clientID: clientID,
      user_id: userStr[0].id,
    };
    console.log(formdata);

    setrosterreq(false);
    setclientreq(false);
    setlocatreq(false);
    let formdsata = {
      user_id: userStr[0].id,
    };
    axios
      .post("https://jlmining.app/admin/setRoster", formdata, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log(res.data);
        if (res.data.status == "1") {
          setShowAlert(true);
          setShowAlert({ type: "error" });
          setTimeout(() => {
            setShowAlert(false);
          }, 4500);
        }
        if (res.data.status == "2") {
          setShowAlert(true);
          setShowAlert({ type: "success" });
          axios
            .post("https://jlmining.app/admin/getallrosters", formdsata, {
              headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "POST,PUT",
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            })
            .then((res) => {
              var alldata = res.data.results;

              setValuegetallroster(alldata);
              setTimeout(() => {
                setShowAlert(false);
                window.location.reload();
              }, 4500);
            })
            .catch((err) => {});
        }
        if (res.data.status == "3") {
          setShowAlert(true);
          setShowAlert({ type: "errorroster" });
          setTimeout(() => {
            setShowAlert(false);
          }, 5500);
        }
        axios
          .post("https://jlmining.app/admin/getallrosters", formdata, {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods": "POST,PUT",
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          })
          .then((res) => {
            var alldata = res.data.results;

            setValuegetallroster(alldata);
          })
          .catch((err) => {});
      })
      .catch((err) => {
        //console.log(err.response.data);
      });
  };
  const localizer = momentLocalizer(moment);
  const navigate = useNavigate();
  const handlegetmnesites = (clientId, index) => {
    setActiveIndex(index);
    setActivesIndex("");
    setIsClickedloc(false);
    setClientId(clientId);
    let formdata = {
      clientId: clientId,
    };
    axios
      .post("https://jlmining.app/admin/getminesites", formdata, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log(res.data.results);
        getData(res.data.results);
        setIsClicked(true);
        setMinClicked(false);
      })
      .catch((err) => {});
    axios
      .post("https://jlmining.app/admin/getclientinfo", formdata, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log(res.data.row);
        if (res.data.row != "") {
          setclientInfo(res.data.row[0]);
        }
      })
      .catch((err) => {});
  };
  const deleterotser = (id) => {
    var id = id;

    let roster = {
      id: id,
    };
    const userStr = JSON.parse(localStorage.getItem("users"));
    if (!userStr) {
      navigate("/login");
    }
    let formdata = {
      user_id: userStr[0].id,
    };
    const del = window.confirm("Are you sure you want to delete this roster?");
    if (del) {
      axios
        .post("https://jlmining.app/admin/deleteroster", roster, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "POST,PUT",
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          axios
            .post("https://jlmining.app/admin/getallrosters", formdata, {
              headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "POST,PUT",
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            })
            .then((res) => {
              var alldata = res.data.results;

              setShowAlert(true);
              setShowAlert({ type: "errordelete" });
              setTimeout(() => {
                setShowAlert(false);
              }, 4500);

              setValuegetallroster(alldata);
            })
            .catch((err) => {});
        })
        .catch((err) => {});
    }
  };
  const handlegetmoinesites = (locationId, index) => {
    var locationId = locationId;
    console.log(locationId);
    setActivesIndex(index);
    setlocatid(locationId);
    setMinClicked(true);
    setLocationId(locationId);
    setIsClickedloc(true);
    let location_Id = {
      locationId: locationId,
    };
    axios
      .post("https://jlmining.app/admin/getlocationr", location_Id, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log(res.data.ss);
        if (res.data.ss != "") {
          setlocated(res.data.ss);
        }
      })
      .catch((err) => {});
  };
  const changeFavicon = (url) => {
    const favicon = document.querySelector('link[rel="icon"]');
    if (favicon) {
      favicon.href = url;
    }
  };
  useEffect(() => {
    changeFavicon(require("./assets/admin/images/f-icon.png"));
    document.title = "ClientInformation Page";
    const userStr = JSON.parse(localStorage.getItem("users"));
    if (!userStr) {
      navigate("/login");
    }
    let formdata = {
      user_id: userStr[0].id,
    };
    axios
      .post("https://jlmining.app/admin/getuserdetails", formdata, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        var alldata = res.data.row[0];

        setValuegetuser(alldata);
      })
      .catch((err) => {});
    axios
      .post("https://jlmining.app/admin/getallrosters", formdata, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        var alldata = res.data.results;

        setValuegetallroster(alldata);
      })
      .catch((err) => {});
    axios
      .get("https://jlmining.app/admin/getclient", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log("ss");
        console.log(res.data.results);
        setData(res.data.results);
      })
      .catch((err) => {});
  }, []);
  const [values, setValues] = useState({
    name: "",
    email: "",
    password: "",
    contact: "",
    address: "",
  });
  const [showAlert, setShowAlert] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [val, setVal] = useState("");
  const handleInput = (event) => {
    setVal((v) => (event.target.validity.valid ? event.target.value : v));
    setValues((prev) => ({
      ...prev,
      [event.target.name]: [event.target.value],
    }));
  };

  const events = [
    {
      title: "Event 1",
      start: new Date(2023, 6, 11),
      end: new Date(2023, 6, 12),
    },
    // Add more events as needed
  ];

  var imageUrl = `${apiurl}${valuegetUser.image}`;
  if (valuegetUser.image == null) {
    var im = "male-upload.png";
    var imageUrl = `${apiurl}${im}`;
  } else {
    var imageUrl = `${apiurl}${valuegetUser.image}`;
  }
  console.log("s");
  console.log(imageUrl);
  const fname = valuegetUser.first_name;
  const mname = valuegetUser.middle_name;
  const lname = valuegetUser.last_name;
  const fLetter = fname ? fname.charAt(0) : "";
  const mLetter = mname ? mname.charAt(0) : "";
  const lLetter = lname ? lname.charAt(0) : "";
  return (
    <>
      <Header />
      <section className="innerbanner text-center py-5 d-block">
        <div className="container-lg">
          <h3 className="fs-30 fw-800 text-white">
            Client/Location Information
          </h3>
        </div>
      </section>
      <section className="profile-box text-center d-block pb-5 ">
        <div className="container-lg">
          <div className="row gy-4">
            <div className="col-md-2">
              <div className="d-flex flex-column">
                <div className="d-flex flex-column gap-2 text-center pic-emp">
                  <div className="emplo-box-pic ofit mx-auto">
                    <img src={imageUrl} alt="image" />
                  </div>
                  <div className="d-flex flex-column">
                    <h3 className="fs-26 text-black fw-800">
                      {valuegetUser.first_name} {valuegetUser.middle_name}{" "}
                      {valuegetUser.last_name}
                    </h3>
                    <h4 className="fs-18 text-black">{valuegetUser.role}</h4>
                    {/* <p className="fs-12 text-black">{valueUserDetail.firstname}</p> */}
                  </div>
                </div>
                <div className="d-block red-heading bg-white shadow py-4 mt-auto">
                  {clientreq && (
                    <Alert
                      type="error"
                      description="This field is required"
                      closable
                    />
                  )}
                  <h3 className="mb-3">Select Client</h3>
                  <div className="over-empls">
                    <div className="d-flex flex-column h-100 clientselectmain">
                      {Array.isArray(emData) ? (
                        emData.map((item, index) => (
                          <Link
                            to="javascript:void(0)"
                            key={index}
                            className={`item ${
                              activeIndex === index ? "active" : ""
                            }`}
                            onClick={() => handlegetmnesites(item.id, index)}
                          >
                            {item.name}
                          </Link>
                        ))
                      ) : (
                        <Link>No data available</Link>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-2">
              <div className="d-flex flex-column mt-4">
                <div className="d-flex flex-column gap-2 text-center pic-emp">
                  <div className="emplo-box-pic ofit mx-auto"></div>
                  <div className="d-flex flex-column"></div>
                </div>
                <div className="d-block red-heading bg-white shadow py-4 mt-auto">
                  {locatreq && (
                    <Alert
                      type="error"
                      description="This field is required"
                      closable
                    />
                  )}
                  <h3 className="mb-3">Mine Sites</h3>
                  <div className="over-empls">
                    <div className="d-flex flex-column h-100 minestone">
                      {Array.isArray(gdata) ? (
                        gdata.map((item, index) => (
                          <Link
                            to="javascript:void(0)"
                            key={index}
                            className={`item ${
                              activesIndex === index ? "active" : ""
                            }`}
                            onClick={() => handlegetmoinesites(item.id, index)}
                          >
                            {item.location_name}
                          </Link>
                        ))
                      ) : (
                        <Link>No data available</Link>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {isClicked && (
              <div className="col-md-4">
                <div className="d-flex flex-column gap-2 text-center pic-emp">
                  <div className="emplo-box-pic ofit mx-auto"></div>
                  <div className="d-flex flex-column"></div>
                </div>
                <div className="d-flex flex-column mt-4">
                  <div className="d-block red-heading bg-white shadow py-4 mt-auto ">
                    <h3 className="mb-3">Client Information</h3>
                    <div className="over-empls">
                      <div className="row gap-2">
                        <div className="col-12 col-lg-8">
                          <div className="d-flex justify-content-between p-2">
                            <label for="" className="fs-14">
                              Client Name:-
                            </label>
                            <span className="fs-14">{clientinfo.name}</span>
                          </div>

                          <div className="d-flex justify-content-between p-2">
                            <label for="" className="fs-14">
                              Position:-
                            </label>
                            <span className="fs-14">{clientinfo.position}</span>
                          </div>
                          <div className="d-flex justify-content-between p-2">
                            <label for="" className="fs-14">
                              Department:-
                            </label>
                            <span className="fs-14">
                              {clientinfo.department}
                            </span>
                          </div>
                          <div className="d-flex justify-content-between p-2">
                            <label for="" className="fs-14">
                              Work Phone Number:-
                            </label>
                            <span className="fs-14">
                              {clientinfo.phone_number}
                            </span>
                          </div>
                          <div className="d-flex justify-content-between p-2">
                            <label for="" className="fs-14 text-start">
                              Mobile Phone Number:-
                            </label>
                            <span className="fs-14">
                              {clientinfo.mobile_number}
                            </span>
                          </div>
                          <div className="d-flex justify-content-between p-2">
                            <label for="" className="fs-14">
                              Home Phone Number:-
                            </label>
                            <span className="fs-14">
                              {clientinfo.home_phone_number}
                            </span>
                          </div>
                          <div className="d-flex justify-content-between p-2">
                            <label for="" className="fs-14">
                              Fax Number:-
                            </label>
                            <span className="fs-14">
                              {clientinfo.fax_number}
                            </span>
                          </div>
                          <div className="d-flex justify-content-between p-2">
                            <label for="" className="fs-14">
                              Email-Id:-
                            </label>
                            <span className="fs-14">{clientinfo.email}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {isClickedloc && (
              <div className="col-md-4">
                <div className="d-flex flex-column gap-2 text-center pic-emp">
                  <div className="emplo-box-pic ofit mx-auto"></div>
                  <div className="d-flex flex-column"></div>
                </div>
                <div className="d-flex flex-column mt-4">
                  <div className="d-block red-heading bg-white shadow py-4 mt-auto">
                    <h3 className="mb-3">Mine Information</h3>
                    <div className="over-empls">
                      <div className="row gap-2">
                        <div className="col-12 col-lg-8">
                          <div className="d-flex justify-content-between p-2">
                            <label for="" className="fs-14">
                              Location Name:-
                            </label>
                            <span className="fs-14">
                              {located.location_name}
                            </span>
                          </div>

                          <div className="d-flex justify-content-between p-2">
                            <label for="" className="fs-14">
                              Nearest Town:-
                            </label>
                            <span className="fs-14">
                              {located.nearest_town}
                            </span>
                          </div>
                          <div className="d-flex justify-content-between p-2">
                            <label for="" className="fs-14">
                              Commodity:-
                            </label>
                            <span className="fs-14">{located.commodity}</span>
                          </div>
                          <div className="d-flex justify-content-between p-2">
                            <label for="" className="fs-14">
                              Contract Type:-
                            </label>
                            <span className="fs-14">
                              {located.contract_type}
                            </span>
                          </div>
                          <div className="d-flex justify-content-between p-2">
                            <label for="" className="fs-14">
                              Duration Start:-
                            </label>
                            <span className="fs-14">
                              {located.duration_start}
                            </span>
                          </div>
                          <div className="d-flex justify-content-between p-2">
                            <label for="" className="fs-14">
                              Duration End:-
                            </label>
                            <span className="fs-14">
                              {located.duration_end}
                            </span>
                          </div>
                          <div className="d-flex justify-content-between p-2">
                            <label for="" className="fs-14">
                              Scope:-
                            </label>
                            <span className="fs-14">{located.scope}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};
