import React, { useRef, useState, useEffect } from "react";
import axios from "axios";
import { message, Form, Input, Button, Alert } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { Header } from "./Header";
import { Footer } from "./Footer";
import Modal from "react-modal";
import Select from "react-select";
import Slider from "react-slick";
import "./assets/ohs/newstyle.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
export const Usermannual = () => {
  const inputFileRef = useRef(null);
  const currentYear = new Date().getFullYear();
  const navigate = useNavigate();
  const [issetgetalldocuments, setgetalldocuments] = useState([]);
  const [issetgetLocations, setgetLocations] = useState([]);
  const [docscategory, setdocscategory] = useState([]);
  const changeFavicon = (url) => {
    const favicon = document.querySelector('link[rel="icon"]');
    if (favicon) {
      favicon.href = url;
    }
  };

  useEffect(() => {
    changeFavicon(require("./assets/admin/images/f-icon.png"));
    const userStr = JSON.parse(localStorage.getItem("users"));
    if (userStr === null) {
      window.location.href = "/login";
    }
    document.title = "User Manual Page";
    let formdata = {
      user_id: userStr[0].id,
    };

    axios
      .post("https://jlmining.app/getalldocumentusermannual", formdata, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setgetalldocuments(res.data.results);
      })
      .catch((err) => {});
    axios
      .post("https://jlmining.app/getlocationohs", formdata, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log("locations");
        console.log(res.data.results);
        if (res.data.results.length > 0) {
          const options = res.data.results.map((item) => ({
            value: item.location_name,
            label: item.location_name,
          }));
          setgetLocations(options);
        }
      })
      .catch((err) => {});

    const options_category = [
      { value: "CHECKLIST", label: "Checklist" },
      { value: "FIRE_SAFETY", label: "Fire Safety" },
      { value: "FIRST_AID", label: "First Aid" },
      { value: "INCIDENT_RECORD", label: "Incident Record" },
      { value: "METHOD_STATEMENT", label: "Method Statement" },
      { value: "MISC", label: "Misc" },
      { value: "PHOTOGRAPH", label: "Photograph" },
      { value: "PROCEDURE", label: "Procedure" },
      { value: "REPORT", label: "Report" },
      { value: "RESPONSIBILITY", label: "Responsibility" },
      { value: "RISK_ASSESSMENT", label: "Risk Assessment" },
      { value: "SAFETY_DATA_SHEET", label: "Safety Data Sheet" },
      { value: "SAFETY_RECORD", label: "Safety Record" },
      { value: "SAFE_WORK_PROCEDURE", label: "Safe Work Procedure" },
      { value: "TOOLBOX_TALK", label: "Tool-box Talk" },
    ];
    setdocscategory(options_category);
  }, []);

  function getdattime(dateString) {
    const options = {
      weekday: "short",
      day: "2-digit",
      month: "short",
      year: "numeric",
    };
    const formattedDate = new Date(dateString).toLocaleString("en-US", options);
    return formattedDate;
  }
  function formatDate_notes(dateString) {
    const options = {
      month: "long",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", options);
  }

  const handleDownload = (m, file) => {
    const link = document.createElement("a");
    link.href = file;
    link.download = m;
    link.click();
  };
  const searcSite = (event) => {
    const search = event.target.value;
    let formdata = {
      search: search,
      table: "documents",
      type: "User Mannual",
    };
    axios
      .post("https://jlmining.app/searchQuery", formdata, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setgetalldocuments(res.data.results);
      })
      .catch((err) => {});
  };
  return (
    <>
      <Header />
      <section className="d-block pb-5 mt-4">
        <div className="container-lg">
          <div className="d-flex flex-column gap-3">
            <div className="d-flex flex-column gap-1 notif-content">
              <h3>User Manual</h3>
            </div>
          </div>
        </div>
      </section>
      <section className="d-block pb-5">
        <div className="container-lg">
          <div className="row">
            <div className="col-12">
              <ul className="nav nav-tabs inci-tab" id="myTab" role="tablist">
                {/* <li className="nav-item" role="presentation">
                  <button
                    className={`nav-link ${
                      activeTab === "home-tab" ? "active" : ""
                    }`}
                    id="home-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#home-tab-pane"
                    type="button"
                    role="tab"
                    aria-controls="home-tab-pane"
                    aria-selected="true"
                  >
                    Incidents
                  </button>
                </li> */}
                {/* <li className="nav-item" role="presentation">
                  <button
                    className={`nav-link ${
                      activeTab === "profile-tab" ? "active" : ""
                    }`}
                    id="profile-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#profile-tab-pane"
                    type="button"
                    role="tab"
                    aria-controls="profile-tab-pane"
                    aria-selected="false"
                  >
                    Near Misses
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className={`nav-link ${
                      activeTab === "report-tab" ? "active" : ""
                    }`}
                    id="report-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#report-tab-pane"
                    type="button"
                    role="tab"
                    aria-controls="report-tab-pane"
                    aria-selected="false"
                  >
                    Report a hazard
                  </button>
                </li> */}
              </ul>
              <div className="tab-content" id="myTabContent">
                <div
                  className="tab-pane show active fade p-3 border border-secondary border-opacity-25"
                  id="document-tab-pane"
                  role="tabpanel"
                  aria-labelledby="document-tab"
                  tabindex="0"
                >
                  <div className="d-flex flex-column gap-4">
                    <div className="d-flex flex-column flex-md-row gap-2 justify-content-between">
                      <div className="d-flex gap-2 align-items-center number-siz-title">
                        {/* <div className="flex-shrink-0">
                          <div className="num-six">
                            <div className="circle-small">
                              <div className="text">
                                {getincidentDataLength}
                                <br />
                              </div>
                            </div>
                          </div>
                        </div> */}
                        <div className="flex-grow-1"></div>
                      </div>
                      {/* <div>
                        <Link
                          to="javascript:void(0)"
                          onClick={uploaddoc}
                          className="cbtn"
                        >
                          Upload Document <i className="fal fa-plus"></i>
                        </Link>
                      </div> */}
                    </div>
                    <div className="d-flex flex-column gap-2 align-items-start number-siz-title">
                      <div className="mb-2">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search..."
                          onInput={searcSite}
                        />
                      </div>
                      <div className="table-w w-100 d-block">
                        <table className="table tableprimary doctable">
                          <thead>
                            <th>Title</th>
                            <th>Category</th>
                            <th>Sites</th>
                            <th>Date Created</th>
                          </thead>
                          <tbody className="trbody">
                            {Array.isArray(issetgetalldocuments) ? (
                              issetgetalldocuments.map((item, index) => (
                                <tr className="even">
                                  <td>
                                    <Link
                                      to="javascript:void(0)"
                                      onClick={() =>
                                        handleDownload(
                                          item.file,
                                          `https://jlmining.app/uploads/documents/${item.file}`
                                        )
                                      }
                                    >
                                      {item.file}
                                    </Link>
                                  </td>
                                  <td>{item.category}</td>
                                  <td>{item.site}</td>
                                  <td>{formatDate_notes(item.created_at)}</td>
                                </tr>
                              ))
                            ) : (
                              <div> No data available </div>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};
