import React, { useState, useEffect } from "react";
import axios from "axios";
import { Alert } from "antd";
import { useNavigate, Link } from "react-router-dom";
import TextArea from "antd/es/input/TextArea";
import { Header } from "./Header";
import { Footer } from "./Footer";
export const Contact = () => {
  const currentYear = new Date().getFullYear();
  const navigate = useNavigate();
  const [items, setItems] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [val, setVal] = useState("");
  const changeFavicon = (url) => {
    const favicon = document.querySelector('link[rel="icon"]');
    if (favicon) {
      favicon.href = url;
    }
  };
  useEffect(() => {
    changeFavicon(require("./assets/admin/images/f-icon.png"));
    const userStr = JSON.parse(sessionStorage.getItem("users"));
    console.log(userStr);
    console.log("chk");
    document.title = "Contact Page";
  }, []);
  const handleSubmit = (event) => {
    var vll = event.target;
    let formdata = {
      email: vll.email.value,
      address: vll.address.value,
      phone: vll.phone.value,
    };
    axios
      .post("https://jlmining.app/contactsupport", formdata, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res.data.status === "1") {
          console.log(res.data);
          setShowAlert(true);

          event.target.reset();
          event.target.text();
          setTimeout(() => {
            setShowAlert(false);
          }, 4500);
        }
      })
      .catch((err) => {});
  };

  return (
    <>
      <Header />
      <section className="innerbanner text-center py-5 d-block">
        <div className="container-lg">
          <h3 className="fs-30 fw-800 text-white">Contact Information</h3>
        </div>
      </section>
      <section className="profile-box d-block">
        <div className="container-lg">
          <div className="row gy-4 align-items-center">
            <div className="col-md-12 py-2 px-2">
              <div className="d-flex flex-column gap-2">
                <h5 className="py-2 px-2 text-secondary lh-base">
                  If you have any questions, concerns, or requests related to
                  your personal data, the Terms and Conditions, or the Privacy
                  Policy for the Roster and Time-Sheet Website, please feel free
                  to contact us using the following contact information:
                </h5>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="d-block py-5">
        <div className="container-lg">
          <div className="row gy-4">
            <div className="col-md-6">
              <div div class="new-con-block d-block p-3">
                <form
                  action="javascript:void(0)"
                  onSubmit={handleSubmit}
                  method="post"
                >
                  <div class="d-flex flex-column gap-4">
                    <div className="d-flex flex-column gap-2">
                      <label className="mb-1 text-black">
                        Email <span className="text-danger">*</span>
                      </label>
                      <div className="form-box rel">
                        <i className="fal fa-envelope top-icon"></i>
                        <input
                          type="text"
                          name="email"
                          required
                          placeholder="Enter email address"
                        />
                      </div>
                    </div>
                    <div className="d-flex flex-column gap-2">
                      <label className="mb-1 text-black">Phone Number</label>
                      <div className="form-box rel">
                        <i className="fal fa-phone top-icon"></i>
                        <input
                          type="tel"
                          name="phone"
                          pattern="[0-9]*"
                          minlength="10"
                          required
                          placeholder="Enter phone number"
                        />
                      </div>
                    </div>
                    <div className="d-flex flex-column gap-2">
                      <label className="mb-1 text-black">Mailing Address</label>
                      <div className="form-box rel">
                        <i className="fal fa-address-card top-icon"></i>
                        <textarea
                          type="text"
                          name="address"
                          required
                          placeholder="Enter address"
                        ></textarea>
                      </div>
                    </div>
                    {showAlert && (
                      <span className="text-success fs-16 fw-800">
                        Email successfully sent,Please wait some time!
                      </span>
                    )}
                    <div class="d-flex justify-content-end">
                      <button type="submit" className="subbtn rounded-2">
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-md-6">
              <div className="d-flex flex-column gap-2">
                <h5 className="text-secondary lh-base">
                  Website, you can reach our support team through the "Support"
                  or "Contact Us" section on the Website. Please ensure that
                  your inquiries or requests are clear and include relevant
                  details to help us assist you effectively. We strive to
                  respond to all inquiries in a timely manner and provide the
                  necessary support to address your concerns. Thank you for
                  using the Roster and Time-Sheet Website.
                </h5>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};
