import React, { useState, useEffect } from "react";
import axios from "axios";
import { Alert } from "antd";
import { useNavigate, Link } from "react-router-dom";
import TextArea from "antd/es/input/TextArea";
import { Header } from "./Header";
import { Footer } from "./Footer";
export const Privacypolicy = () => {
  const currentYear = new Date().getFullYear();
  const navigate = useNavigate();
  const [items, setItems] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [val, setVal] = useState("");
  const changeFavicon = (url) => {
    const favicon = document.querySelector('link[rel="icon"]');
    if (favicon) {
      favicon.href = url;
    }
  };
  useEffect(() => {
    changeFavicon(require("./assets/admin/images/f-icon.png"));
    const userStr = JSON.parse(sessionStorage.getItem("users"));

    document.title = "Privacy Policy Page";
  }, []);

  return (
    <>
      <Header />
      <section className="innerbanner text-center py-5 d-block">
        <div className="container-lg">
          <h3 className="fs-30 fw-800 text-white">Privacy Policy</h3>
        </div>
      </section>
      <section className="profile-box d-block">
        <div className="container-lg">
          <div className="row gy-4 align-items-center">
            <div className="col-md-12 py-2 px-2">
              <div className="d-flex flex-column gap-2 mt-2">
                <h3 className="fs-25 fw-800 text-black">
                  Privacy Policy for Roster and Time-Sheet Website
                </h3>
                <h5 className="py-2 px-2 text-secondary lh-base">
                  This Privacy Policy ("Policy") outlines how the Roster and
                  Time-Sheet website (the "Website") collects, uses, and
                  protects your personal information. By accessing or using the
                  Website, you consent to the practices described in this
                  Policy.
                </h5>
              </div>
            </div>
            <div className="col-md-12 py-2 px-2">
              <div className="d-flex flex-column">
                <h3 className="fs-25 fw-800 text-black">
                  1. Information Collection
                </h3>
                <h3 className="fs-20 fw-800 text-black">
                  1.1. User Information:
                </h3>
                <h5 className="py-2 px-2 text-secondary lh-base">
                  To use the Website, you may be required to provide personal
                  information during the registration process, such as your
                  name, contact details, and employee identification
                </h5>{" "}
                <h3 className="fs-20 fw-800 text-black">
                  1.2. Roster and Time-Sheet Data:
                </h3>
                <h5 className="py-2 px-2 text-secondary lh-base">
                  The Website collects data related to employee rosters,
                  attendance, and time-sheets, including but not limited to
                  client names, location details, roster types, hours worked,
                  leave information, travel data, and any uploaded receipts.
                </h5>{" "}
                <h3 className="fs-25 fw-800 text-black">
                  2. Use of Information
                </h3>
                <h3 className="fs-20 fw-800 text-black">
                  2.1. Employee Roster Management:
                </h3>
                <h5 className="py-2 px-2 text-secondary lh-base">
                  The information collected is used to facilitate employee
                  roster management and time-sheet generation.
                </h5>{" "}
                <h3 className="fs-20 fw-800 text-black">
                  2.2. Payroll Administration:
                </h3>
                <h5 className="py-2 px-2 text-secondary lh-base">
                  Collected data is used for payroll calculations and
                  administration.
                </h5>{" "}
                <h3 className="fs-20 fw-800 text-black">
                  2.3. Signature Collection:
                </h3>
                <h5 className="py-2 px-2 text-secondary lh-base">
                  If you choose to collect client signatures, the Website will
                  use the provided information for verification and
                  record-keeping purposes.
                </h5>{" "}
                <h3 className="fs-25 fw-800 text-black">3. Data Security</h3>
                <h5 className="py-2 px-2 text-secondary lh-base">
                  <b>3.1. </b>
                  The Website employs industry-standard security measures to
                  protect your personal information and data from unauthorized
                  access, disclosure, or alteration.
                </h5>{" "}
                <h5 className="py-2 px-2 text-secondary lh-base">
                  <b>3.2. </b>
                  Access to your account is protected by your unique login
                  credentials, and you are responsible for maintaining their
                  confidentiality.
                </h5>{" "}
                <h3 className="fs-25 fw-800 text-black">4. Data Sharing</h3>
                <h5 className="py-2 px-2 text-secondary lh-base">
                  <b>4.1. </b>
                  Your roster and time-sheet data may be shared with authorized
                  administrators or employers for the purpose of managing
                  employee attendance and payroll.
                </h5>{" "}
                <h5 className="py-2 px-2 text-secondary lh-base">
                  <b>4.2. </b>
                  Personal information and data will not be sold, rented, or
                  disclosed to third parties for marketing purposes without your
                  explicit consent.
                </h5>{" "}
                <h3 className="fs-25 fw-800 text-black">
                  5. Retention of Data
                </h3>
                <h5 className="py-2 px-2 text-secondary lh-base">
                  <b>5.1. </b>
                  Your data will be retained for as long as necessary to fulfill
                  the purposes outlined in this Policy or as required by law.
                </h5>{" "}
                <h3 className="fs-25 fw-800 text-black">
                  6. Cookies and Tracking
                </h3>
                <h5 className="py-2 px-2 text-secondary lh-base">
                  <b>6.1. </b>
                  The Website may use cookies or similar tracking technologies
                  to enhance user experience and gather usage statistics. You
                  can manage cookie preferences through your browser settings.
                </h5>{" "}
                <h3 className="fs-25 fw-800 text-black">
                  7. Third-Party Links
                </h3>
                <h5 className="py-2 px-2 text-secondary lh-base">
                  <b>7.1. </b>
                  The Website may contain links to third-party websites or
                  services. This Policy does not apply to the privacy practices
                  of those third parties, and you are encouraged to review their
                  privacy policies.
                </h5>{" "}
                <h3 className="fs-25 fw-800 text-black">
                  8. Changes to the Policy
                </h3>
                <h5 className="py-2 px-2 text-secondary lh-base">
                  <b>8.1. </b>
                  The Website administrators may update or modify this Privacy
                  Policy at any time. Any changes will be posted on the Website,
                  and your continued use of the Website constitutes acceptance
                  of the revised Policy.
                </h5>{" "}
                <h3 className="fs-25 fw-800 text-black">
                  9. Contact Information
                </h3>
                <h5 className="py-2 px-2 text-secondary lh-base">
                  <b>9.1. </b>
                  For questions, concerns, or requests related to your personal
                  data or this Privacy Policy, you can contact the Website
                  administrators using the provided contact information. By
                  using the Roster and Time-Sheet Website, you consent to the
                  collection and use of your personal information as described
                  in this Privacy Policy. It is your responsibility to review
                  this Policy periodically for any updates or changes.
                </h5>{" "}
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};
