import React, { useState, useEffect, useRef } from "react";
import PSPDFKit from "pspdfkit";

export const Checkpdf = () => {
  const [documentPath, setDocumentPath] = useState("TFN Declaration Form.pdf");
  const containerRef = useRef(null);
  const pspdfKitInstanceRef = useRef(null);

  useEffect(() => {
    const loadPSPDFKit = async () => {
      try {
        // Unload previous instance if it exists
        if (pspdfKitInstanceRef.current) {
          await pspdfKitInstanceRef.current.unload();
        }

        // Load PSPDFKit instance
        const container = containerRef.current;
        pspdfKitInstanceRef.current = await PSPDFKit.load({
          container,
          document: documentPath,
          baseUrl: `${window.location.protocol}//${window.location.host}/${process.env.PUBLIC_URL}`,
        });
      } catch (error) {
        console.error("Error loading PSPDFKit instance:", error);
      }
    };

    loadPSPDFKit();

    // Cleanup function
    return () => {
      // Unload PSPDFKit instance when the component unmounts
      if (pspdfKitInstanceRef.current) {
        pspdfKitInstanceRef.current.destroy();
        pspdfKitInstanceRef.current = null;
      }
    };
  }, [documentPath]);

  return (
    <>
      <div ref={containerRef} style={{ width: "100%", height: "100vh" }} />
    </>
  );
};
