import React, { useRef, useState, useEffect } from "react";
import axios from "axios";
import { message, Form, Input, Button, Alert } from "antd";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Header } from "../Header";
import { Footer } from "../Footer";
import Modal from "react-modal";

import moment from "moment-timezone";
import Select from "react-select";
import "../assets/ohs/newstyle.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
export const Reporth = () => {
  const inputFileRef = useRef(null);
  const [issetgetLocations, setgetLocations] = useState([]);
  const [issetSelectedsite, setSelectedsite] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isalldocs, setalldocs] = useState([]);
  const [isalldocsl, setalldocsl] = useState(0);
  const [isusernameall, setusernameall] = useState("");
  const [fileshow, setfilename] = useState([]);
  const [hazardreport, sethazardreport] = useState([]);
  const [hazardreportvl, sethazardreportvl] = useState("");
  const [isModalOpenDoc, setIsModalOpenDoc] = useState(false);
  const [isetuniq_code, setisetuniq_code] = useState("");
  const [issethazatdData, sethazatdData] = useState("");
  const [ishazard_id, setishazard_id] = useState("");
  const [issetSelectedhazard, setSelectedhazard] = useState("");
  const currentYear = new Date().getFullYear();
  const navigate = useNavigate();

  const changeFavicon = (url) => {
    const favicon = document.querySelector('link[rel="icon"]');
    if (favicon) {
      favicon.href = url;
    }
  };
  const { id } = useParams();

  useEffect(() => {
    console.log(id);
    const userStr = JSON.parse(localStorage.getItem("users"));
    document.title = "Report Hazard Page";
    if (userStr === null) {
      window.location.href = "/login";
    }
    let formdata = {
      user_id: userStr[0].id,
    };
    setisetuniq_code(id);
    if (id != "") {
      let hz = {
        code: id,
        user_id: userStr[0].id,
      };
      axios
        .post("https://jlmining.app/gethazarddetail", hz, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "POST,PUT",
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          if (res.data.row.length > 0) {
            var indata = res.data.row[0];
            sethazatdData(indata);
            setSelectedhazard({
              value: indata.hazard_site,
              label: indata.hazard_site,
            });
            sethazardreportvl({
              value: indata.reporter,
              label: indata.reporter,
            });
            setishazard_id(indata.id);
          }
        })
        .catch((err) => {});
      axios
        .post("https://jlmining.app/gethazardDocs", hz, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "POST,PUT",
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          setalldocsl(res.data.results.length);
          setalldocs(res.data.results);
        })
        .catch((err) => {});
    }
    axios
      .post("https://jlmining.app/getlocationohs", formdata, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log("locations");
        console.log(res.data.results);
        if (res.data.results.length > 0) {
          const options = res.data.results.map((item) => ({
            value: item.location_name,
            label: item.location_name,
          }));
          setgetLocations(options);
        }
      })
      .catch((err) => {});
    axios
      .post("https://jlmining.app/getalluser", formdata, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res.data.results.length > 0) {
          const options = res.data.results.map((item) => ({
            value:
              item.first_name + " " + item.middle_name + " " + item.last_name,
            label:
              item.first_name + " " + item.middle_name + " " + item.last_name,
          }));
          sethazardreport(options);
        }
      })
      .catch((err) => {});
  }, []);
  const stepsite = (event) => {
    console.log(event.value);
    if (event !== "") {
      setSelectedhazard({
        value: event.value,
        label: event.value,
      });
    }
  };
  const hazardrep = (event) => {
    console.log(event.value);
    if (event !== "") {
      sethazardreportvl({
        value: event.value,
        label: event.value,
      });
    }
  };
  const handsubmit = (event) => {
    const userStr = JSON.parse(localStorage.getItem("users"));
    var vll = event.target;
    const formData = new FormData();
    if (isalldocs.length > 0) {
      for (let i = 0; i < isalldocs.length; i++) {
        formData.append("file", isalldocs[i]);
      }
    }

    formData.append("user_id", userStr[0].id);
    formData.append("name", vll.name.value);
    formData.append("hazard_site", vll.hazard_site.value);
    formData.append("location", vll.location.value);
    formData.append("reporter", vll.reporter.value);
    formData.append("details", vll.details.value);
    formData.append("hazard_id", vll.hazard_id.value);

    axios
      .post("https://jlmining.app/reporthazard", formData, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        event.target.reset();
        console.log(res.data.row.length);
        if (res.data.row.length > 0) {
          if (vll.hazard_id.value == "") {
            const newUrl = "/ohs";

            // Use history.push to navigate to the new URL
            navigate(newUrl);
          } else {
            var indata = res.data.row[0];
            sethazatdData(indata);
            setSelectedhazard({
              value: indata.hazard_site,
              label: indata.hazard_site,
            });
            sethazardreportvl({
              value: indata.reporter,
              label: indata.reporter,
            });
            setishazard_id(indata.id);
            setIsModalOpen(true);
          }
        }
      })
      .catch((err) => {});
  };
  const docclose = () => {
    setIsModalOpen(false);
  };
  const closeDoc = () => {
    // setfilename([]);
    // setalldocsl(0);
    // setalldocs([]);
    const userStr = JSON.parse(localStorage.getItem("users"));
    if (inputFileRef.current) {
      inputFileRef.current.value = "";
    }
    let hz = {
      code: id,
      user_id: userStr[0].id,
    };
    axios
      .post("https://jlmining.app/gethazardDocs", hz, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setalldocsl(res.data.results.length);
        setalldocs(res.data.results);
      })
      .catch((err) => {});

    setIsModalOpenDoc(false);
  };
  const uploaddoc = () => {
    setIsModalOpenDoc(true);
  };
  const docfile = (event) => {
    const file = event.target.files; // Get the first file from the input

    setfilename([...file]);
    setalldocs([...file]);
  };
  const handleRemoveFile = (index) => {
    setfilename((prevFiles) => prevFiles.filter((_, i) => i !== index)); // Remove the file at the specified index
    if (isalldocs.length == 0) {
      if (inputFileRef.current) {
        inputFileRef.current.value = "";
      }
    }
  };
  function formatDate(dateString) {
    const options = { year: "numeric", month: "short", day: "numeric" };
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", options);
  }
  const handsubmitfile = (event) => {
    var fdata = event.target.hazard_update_id.value;
    const userStr = JSON.parse(localStorage.getItem("users"));
    const formData = new FormData();
    if (fdata != "") {
      console.log(isalldocs);
      if (isalldocs.length > 0) {
        for (let i = 0; i < isalldocs.length; i++) {
          formData.append("file", isalldocs[i]);
        }
      }

      formData.append("user_id", userStr[0].id);
      formData.append("code", isetuniq_code);
      axios
        .post("https://jlmining.app/reporthazardUpdateFile", formData, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "POST,PUT",
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          setalldocs(res.data.results);
          setalldocsl(res.data.results.length);
          setIsModalOpenDoc(false);
        })
        .catch((err) => {});
    } else {
      setalldocs([...fileshow]);
      setalldocsl(fileshow.length);
      setIsModalOpenDoc(false);
    }
  };
  const removefile = (index, id) => {
    const userStr = JSON.parse(localStorage.getItem("users"));
    setalldocsl((prevLength) => prevLength - 1);
    setalldocs((prevFiles) => prevFiles.filter((_, i) => i !== index)); // Remove the file at the specified index

    if (ishazard_id != "") {
      let rem = {
        id: id,
        user_id: userStr[0].id,
        code: isetuniq_code,
      };
      console.log(rem);
      axios
        .post("https://jlmining.app/reporthazardRemoveFile", rem, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "POST,PUT",
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          setalldocsl(res.data.results.length);
          setalldocs(res.data.results);
        })
        .catch((err) => {});
    }
  };
  return (
    <>
      <Header />
      <section className="d-block py-5">
        <div className="container-lg">
          <div className="row gy-4">
            <div className="col-md-8">
              <form
                action="javascript:void(0)"
                method="post"
                onSubmit={handsubmit}
              >
                <div className="d-flex flex-column flex-md-row gap-3 justify-content-between align-items-center hazard-title mb-3">
                  <div>
                    <h3>New hazard report</h3>
                  </div>
                  <div>
                    <Link to="#" onClick={uploaddoc} className="attach-doc">
                      {" "}
                      Attach documents{" "}
                    </Link>
                  </div>
                </div>

                <input
                  type="hidden"
                  value={issethazatdData.id}
                  name="hazard_id"
                />

                <div className="row">
                  <div className="col-md-5 hazard-form-block">
                    <div className="d-flex flex-column gap-2">
                      <div className="d-flex flex-column gap-2">
                        <label for="">
                          Hazard name <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          name="name"
                          required
                          defaultValue={issethazatdData.name}
                        />
                      </div>
                      <div className="d-flex flex-column gap-2 rel search-icon">
                        <label for="">
                          Hazard site <span className="text-danger">*</span>
                        </label>
                        <Select
                          required
                          options={issetgetLocations}
                          value={issetSelectedhazard}
                          onChange={stepsite}
                          name="hazard_site"
                        />
                      </div>
                      <div className="d-flex flex-column gap-2">
                        <label for="">
                          Hazard location <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          defaultValue={issethazatdData.location}
                          name="location"
                          required
                        />
                      </div>
                      <div className="d-flex flex-column gap-2">
                        <label for="">
                          Hazard reporter <span className="text-danger">*</span>
                        </label>

                        <Select
                          required
                          options={hazardreport}
                          value={hazardreportvl}
                          onChange={hazardrep}
                          name="reporter"
                        />
                      </div>
                      <div className="d-flex flex-column gap-2">
                        <label for="">
                          Hazard details <span className="text-danger">*</span>
                        </label>
                        <textarea
                          rows="4"
                          name="details"
                          defaultValue={issethazatdData.details}
                          required
                        >
                          {issethazatdData.details}
                        </textarea>
                      </div>
                      <div className="d-flex gap-2 align-items-center buttons-two">
                        <button type="reset" className="cel-btn">
                          Cancel
                        </button>
                        <button type="submit" className="rep-btn">
                          Report this hazard
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <Modal
              isOpen={isModalOpenDoc}
              onRequestClose={() => setIsModalOpenDoc(false)}
              contentLabel="Add Text Modal"
              className="docs d-flex flex-column  bg-light mt-5 p-3 align-item-center justify-content-center"
            >
              <div style={{ width: "500px" }}>
                <div className="d-flex flex-column file-pop">
                  <div className="d-flex justify-content-between align-items-center top-head">
                    <div className="flex-grow-1">
                      <h2>Upload</h2>
                    </div>
                    <div className="flex-shirnk-0">
                      <button
                        type="button"
                        onClick={() => closeDoc()}
                        className="close-btn"
                      >
                        <i className="fal fa-times" aria-hidden="true"></i>
                      </button>
                    </div>
                  </div>
                  <div className="d-flex flex-column px-1 py-2">
                    <h3 className="text-black choose-text">
                      Choose the document you would like to upload.
                    </h3>
                    {fileshow.map((file, index) => (
                      <div
                        key={index}
                        className="sceenshot-box my-1 d-flex align-items-center border-secondary border border-opacity-25 bg-secondary bg-opacity-25 py-1 px-3"
                      >
                        <div className="flex-grow-1">
                          <h4>{file.name}</h4> {/* Display the file name */}
                        </div>
                        <div className="flex-shrink-0">
                          <button
                            type="button"
                            className="scsbtn"
                            onClick={() => handleRemoveFile(index)}
                          >
                            <i className="fal fa-trash-alt"></i>{" "}
                            {/* Button to remove the file */}
                          </button>
                        </div>
                      </div>
                    ))}

                    <form
                      action="javascript:void(0)"
                      method="post"
                      onSubmit={handsubmitfile}
                    >
                      <input
                        type="hidden"
                        value={issethazatdData.id}
                        name="hazard_update_id"
                      />
                      <div className="d-flex flex-column text-center gap-3 rel p-4 drop-box-pop mx-auto ">
                        <i className="fal fa-arrow-alt-up"></i>
                        <h1>Drag and drop your files here to upload</h1>
                        <h6>Browse files...</h6>
                        <input
                          type="file"
                          multiple
                          onChange={docfile}
                          ref={inputFileRef}
                          className="file-name-block"
                        />
                      </div>
                      <div className="d-flex flex-column gap-3 drag-box">
                        <div className="d-flex gap-2 check-box-list">
                          <div className="d-flex gap-2 align-items-center justify-content-end btns-box mt-4">
                            <div className="flex-shrink-0">
                              <button type="button">Cancel</button>
                            </div>
                            <div className="flex-shrink-0">
                              <button type="submit">Upload</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </Modal>
            <Modal
              isOpen={isModalOpen}
              onRequestClose={() => setIsModalOpen(false)}
              contentLabel="Add Text Modal"
              className="docs d-flex flex-column  bg-light mt-5 p-3 align-item-center justify-content-center"
            >
              <div style={{ width: "500px" }}>
                <div className="d-flex flex-column file-pop">
                  <div className="d-flex justify-content-between align-items-center top-head">
                    <div className="flex-grow-1">
                      <h2>Success</h2>
                    </div>
                    <div className="flex-shirnk-0">
                      <button
                        type="button"
                        onClick={() => docclose()}
                        className="close-btn"
                      >
                        <i className="fal fa-times" aria-hidden="true"></i>
                      </button>
                    </div>
                  </div>
                  <div className="d-flex flex-column px-1 py-2">
                    <p>
                      Hazard has been reported, a notification will be sent to
                      the appropriate person(s).
                    </p>
                  </div>
                  <div className="d-flex gap-2 align-items-center buttons-two">
                    <button
                      type="button"
                      onClick={() => docclose()}
                      className="rep-btn"
                    >
                      Done
                    </button>
                  </div>
                </div>
              </div>
            </Modal>
            <div className="col-md-4">
              <div className="d-flex flex-column gap-3">
                <div className="d-flex flex-column need-block border border-secondary border-opacity-25 rounded-2 overflow-hidden">
                  <div className="d-block fulw">
                    <img
                      src={require("../assets/ohs/images/need.png")}
                      alt="image"
                    />
                  </div>
                  <div className="d-block px-3 pb-3">
                    <p>
                      Need help with the hazard report process? Call the Health
                      & Safety Advice Line on
                      <Link to="tel:1300 029 198">
                        0431 619 015,0406 400 994
                      </Link>
                    </p>
                  </div>
                </div>
                <div className="d-flex flex-column doco-block border border-secondary border-opacity-25 rounded-2 overflow-hidden">
                  <div className="d-flex align-items-center justify-content-between border-bottom border-secondary border-opacity-25 p-2">
                    <div className="flex-grow-1">
                      <h3 className="doco-title">
                        Documents <span>({isalldocsl})</span>
                      </h3>
                    </div>
                    <div className="flex-shrink-0">
                      <button
                        type="button"
                        className="plus-btn"
                        data-bs-toggle="modal"
                        data-bs-target="#fileplus"
                        onClick={uploaddoc}
                      >
                        <i className="fal fa-plus"></i>
                      </button>
                    </div>
                  </div>
                  <div className="g-center text-center icon-empty">
                    {Array.isArray(isalldocs) ? (
                      isalldocs.map((item, index) => (
                        <div className="list-view-report w-100 gap-3 d-flex border-opacity-50  border-bottom border-secondary align-items-center p-2">
                          <div className="flex-shrink-0">
                            <i className="far fa-file-edit"></i>
                          </div>
                          <div className="flex-grow-1">
                            <div className="d-flex flex-column text-start">
                              <Link to="{item.name}" className="file-name">
                                {item.name}
                              </Link>
                              <p className="file-date">{isusernameall}</p>
                            </div>
                          </div>
                          <div className="flex-shrink-0">
                            <button
                              type="button"
                              onClick={() => {
                                if (item.id) {
                                  removefile(index, item.id);
                                } else {
                                  removefile(index, "");
                                }
                              }}
                              className="delete-btn"
                            >
                              <i className="fal fa-trash-alt"></i>
                            </button>
                          </div>
                        </div>
                      ))
                    ) : (
                      <Link> No data available </Link>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};
