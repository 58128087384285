import React, { useRef, useState, useEffect } from "react";
import axios from "axios";
import { message, Form, Input, Button, Alert } from "antd";
import { useNavigate, Link } from "react-router-dom";
import TextArea from "antd/es/input/TextArea";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import { FaTrash } from "react-icons/fa";
import Slider from "react-slick";
import AdminHeader from "../AdminHeader";
import AdminFooter from "../AdminFooter";

export const Elearningcourse = () => {
  const inputFileRef = useRef(null);
  const navigate = useNavigate();
  const changeFavicon = (url) => {
    const favicon = document.querySelector('link[rel="icon"]');
    if (favicon) {
      favicon.href = url;
    }
  };
  const [showAlert_r, setShowAlert_r] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);
  const [values, setValues] = useState({});
  const [val, setVal] = useState("");
  const [getincidentData, setgetincidentData] = useState([]);
  const [getincidentDataLength, setgetincidentDataLength] = useState([]);
  const [ispinnerset, spinnerset] = useState(false);
  const [filemain, setfilemain] = useState("");
  const [isallcourse, setallcourse] = useState([]);
  useEffect(() => {
    document.title = "E-Learning Page";
    const userStr = JSON.parse(localStorage.getItem("admin"));
    console.log(userStr);
    if (userStr == null) {
      navigate("/admin/login");
    }
    let formdata = {
      table: "elearningCourse",
    };
    axios
      .post("https://jlmining.app/getselectCommonForhazard", formdata, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setallcourse(res.data.results);
      })
      .catch((err) => {});
  }, []);

  //Get User Time Sheet
  const handsubmit = (event) => {
    let formdata = {
      title: event.target.title.value,
      description: event.target.description.value,
      file: filemain,
    };
    axios
      .post("https://jlmining.app/admin/elearningsave", formdata, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        event.target.reset();
        if (inputFileRef.current) {
          inputFileRef.current.value = "";
        }
        setfilemain("");
        setallcourse(res.data.results);
        if (res.data.results.length > 0) {
          setShowAlert(true);
          setShowAlert({ type: "success" });
          setTimeout(() => {
            setShowAlert(false);
          }, 4500);
        }
      })
      .catch((err) => {});
  };
  const docfile = (event) => {
    const file = event.target.files[0]; // Get the first file from the input
    const file_Name = file.name; // Get the file name
    if (file_Name != "") {
      setfilemain(file);
    } else {
      setfilemain("");
    }
  };
  const showhide = (e, index) => {
    setActiveIndex(index);
  };
  const removeskills = (e, index) => {
    let formdata = {
      id: e,
    };
    axios
      .post("https://jlmining.app/admin/removecourse", formdata, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setallcourse(res.data.results);
        setShowAlert_r(true);
        setShowAlert_r({ type: "success" });
        setTimeout(() => {
          setShowAlert(false);
          setShowAlert_r(false);
        }, 4500);
      })
      .catch((err) => {});
  };
  return (
    <>
      <div>
        <AdminHeader />

        <div className="main-right-body">
          <div className="block-full">
            <div className="row gy-4">
              <div className="col-md-4">
                {showAlert_r?.type === "success" && (
                  <Alert
                    type="success"
                    description="Successfully deleted"
                    closable
                  />
                )}
                <div className="d-block red-heading bg-white shadow p-2">
                  <h3 className="mb-3">E-Learning Course</h3>

                  <div className="over-empl">
                    <div className="d-flex flex-column h-100 clientselectmain">
                      {Array.isArray(isallcourse) ? (
                        isallcourse.map((item, index) => (
                          <React.Fragment key={index}>
                            <div className="d-flex justify-content-between">
                              <Link
                                onClick={() => showhide(item.title, index)}
                                to="javascript:void(0)"
                              >
                                {item.title}
                              </Link>
                              {/* {isClickedbtn && ( */}
                              <button
                                type="button"
                                title="Delete"
                                onClick={() => removeskills(item.id, index)}
                                className={`removeskill text-danger ${
                                  activeIndex === index ? "" : "rem"
                                }`}
                              >
                                <FaTrash />
                              </button>
                              {/* )} */}
                            </div>
                          </React.Fragment>
                        ))
                      ) : (
                        <Link> No data available </Link>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-8">
                <div className="right-form-box bg-white shadow p-2">
                  <form
                    action="javascript:void(0)"
                    onSubmit={handsubmit}
                    method="post"
                  >
                    <div className="row gy-2">
                      {showAlert?.type === "success" && (
                        <Alert
                          type="success"
                          description="E-learning course has been successfully created!"
                          closable
                        />
                      )}
                      <div className="col-12 col-lg-6">
                        <div className="d-flex flex-column gap-2">
                          <label for="">
                            Image <span className="text-danger">*</span>{" "}
                          </label>
                          <input
                            type="file"
                            onChange={docfile}
                            required
                            ref={inputFileRef}
                            name="file"
                          />
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="d-flex flex-column gap-2">
                          <label for="">
                            Title <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            required
                            placeholder="Enter here..."
                            name="title"
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="d-flex flex-column gap-2">
                          <label for="">
                            Description <span className="text-danger">*</span>
                          </label>
                          <textarea
                            id=""
                            cols="30"
                            required
                            rows="4"
                            placeholder="Enter here..."
                            name="description"
                          ></textarea>
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="d-flex align-items-center gap-3 justify-content-end">
                          <button className="sub-btn">add</button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <AdminFooter />
      </div>
    </>
  );
};
