import React, { useRef, useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";

export const JlCertificate = () => {
  //useKeyboardShortcutBlocker();
  const [resumedata, setresumedata] = useState("");
  const [licenseArray, setlicenseArray] = useState([]);
  const [educationsArray, seteducations] = useState([]);
  const [skillsArray, setskillsArray] = useState([]);
  const [licfile, setlicfile] = useState([]);
  const [edfile, setedfile] = useState([]);
  const [vocationalArray, setvocationalArray] = useState([]);

  const [tradetextArray, settradetextArray] = useState([]);
  const [equipmentWorked, setEquipmentWorked] = useState([]);
  const [tradefile, setedtradefile] = useState([]);
  const [certm, setedcertif_file] = useState([]);
  const [machinetextArray, setmachinetextArray] = useState([]);
  const [machinefileArray, semachinefile] = useState([]);

  const [refreArray, setrefreArray] = useState([]);
  const [prevworkArray, setprevworkArray] = useState([]);
  const [historyArray, sethostoryArray] = useState([]);
  const currentYear = new Date().getFullYear();
  const navigate = useNavigate();
  const changeFavicon = (url) => {
    const favicon = document.querySelector('link[rel="icon"]');
    if (favicon) {
      favicon.href = url;
    }
  };
  const apiurl = "https://jlmining.app/uploads/";

  const empid = useParams();

  useEffect(() => {
    changeFavicon(require("../assets/admin/images/f-icon.png"));
    const userStr = JSON.parse(localStorage.getItem("admin"));
    if (userStr == null) {
      navigate("/admin/login");
    }
    if (empid === null) {
      navigate("/admin/employee");
    }

    let formdata = {
      userId: empid.id,
    };
    axios
      .post("https://jlmining.app/admin/getuser", formdata, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log(res.data.row);
        if (res.data.row.length !== 0) {
          setresumedata(res.data.row[0]);

          if (res.data.row[0].licence != null) {
            const resultArrays = JSON.parse(res.data.row[0].licence);
            setlicenseArray(resultArrays);
          }
          //Lic file
          if (res.data.row[0].licence_file != null) {
            const lf = JSON.parse(res.data.row[0].licence_file);
            setlicfile(lf);
          }

          //edu
          if (res.data.row[0].certificate_file != null) {
            const edd = JSON.parse(res.data.row[0].certificate_file);
            console.log(edd);
            setedfile(edd);
          }
          //Education
          if (res.data.row[0].education != null) {
            const educations = JSON.parse(res.data.row[0].education);
            seteducations(educations);
          }

          //Skills
          if (res.data.row[0].skills != null) {
            const ss = JSON.parse(res.data.row[0].skills);
            setskillsArray(ss);
          }
          // console.log(skillsArray);

          //vocational
          if (res.data.row[0].vocational_training != null) {
            const sssv = JSON.parse(res.data.row[0].vocational_training);
            setvocationalArray(sssv);
          }

          //History

          const h = JSON.parse(res.data.row[0].employmentHistorySections);
          if (h != null) {
            sethostoryArray(h);
          }

          //refre
          const refreh = JSON.parse(res.data.row[0].references);
          if (res.data.row[0].previous_work !== null) {
            const prevw = JSON.parse(res.data.row[0].previous_work);
            setprevworkArray(prevw);
          }
          //console.log(refreh);
          if (res.data.row[0].certificate != null) {
            const sssvtc = JSON.parse(res.data.row[0].certificate);
            setedcertif_file(sssvtc);
          }

          //Trade Text certificate
          if (res.data.row[0].trade != null) {
            const sssvt = JSON.parse(res.data.row[0].trade);
            setedtradefile(sssvt);
          }
          if (res.data.row[0].trade_file != null) {
            const eddt = JSON.parse(res.data.row[0].trade_file);
            console.log(eddt);
            settradetextArray(eddt);
          }
          //Trade Text Certificate

          //Machinary
          if (res.data.row[0].machinery != null) {
            const sssvm = JSON.parse(res.data.row[0].machinery);
            semachinefile(sssvm);
          }
          if (res.data.row[0].machinery_file != null) {
            const eddm = JSON.parse(res.data.row[0].machinery_file);
            setmachinetextArray(eddm);
          }

          //Machinary
          if (res.data.row[0].equipment_work != null) {
            const eqp = JSON.parse(res.data.row[0].equipment_work);
            setEquipmentWorked(eqp);
          }
          //Eq Worked
          if (refreh != null) {
            setrefreArray(refreh);
          }
        } else {
          navigate("/admin/employee");
        }
      })
      .catch((err) => {
        //console.log(err.response.data);
      });
  }, []);
  function getYearFromDate(dateString) {
    const dateObject = new Date(dateString);
    return dateObject.getFullYear();
  }
  const dynamicImagePath = require("../assets/images/background/bg.jpg");
  const tableStyle1 = {
    border: "none",
    borderSpacing: 0,
    width: "100%",
    padding: "1cm",
    height: "297mm",
  };

  const containerStyle = {
    background: `url(${dynamicImagePath}) no-repeat center top`,
    backgroundSize: "cover",
    textAlign: "center",
    height: "100vh",
  };

  const tableStyle = {
    border: "none",
    borderSpacing: 0,
    textAlign: "center",
    width: "100%",
    marginTop: "100px",
  };

  const textStyle = {
    textAlign: "center",
    color: "#fff",
    fontSize: "18px",
    textTransform: "capitalize",
    fontFamily: "'Times New Roman', Times, serif",
  };
  const mainPageStyles = {
    width: "210mm",
    minHeight: "297mm",
    background: "white",
    margin: "10mm auto",
    boxShadow: "0 0 0.5cm rgba(0, 0, 0, 0.5)",
  };

  const printStyles = {
    "@media print": {
      html: {
        width: "210mm",
        height: "297mm",
      },
      body: {
        width: "210mm",
        height: "297mm",
        WebkitPrintColorAdjust: "exact",
        position: "relative",
        margin: 0,
      },
      ".main-page": {
        margin: "0",
        border: "initial",
        borderRadius: "initial",
        width: "initial",
        minHeight: "initial",
        boxShadow: "initial",
        background: "initial",
        pageBreakAfter: "always",
      },
    },
  };
  const mergedStyles = {
    ...containerStyle,
    ...printStyles,
  };
  return (
    <div>
      <table
        style={{
          width: "794px",
          margin: "0 auto",
          backgroundImage: `url(${require("../assets/images/certificate_bg.jpg")})`,
          backgroundSize: "cover",
          height: "1158px",
        }}
      >
        <tr
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            gap: "30px",
          }}
        >
          <td
            style={{
              textAlign: "center",
              color: "rgb(255, 255, 255)",
              fontSize: "30px",
              textTransform: "capitalize",
              fontFamily: '"Jost", sans-serif',
              fontWeight: "500",
              borderBottom: "2px solid #fff",
            }}
          >
            Application Form
          </td>
          <td
            style={{
              border: "2px solid #fff",
              borderRadius: "12px",
              padding: "10px 30px",
              fontSize: "28px",
              fontWeight: "400",
              color: "#fff",
              fontFamily: '"Jost", sans-serif;',
              textAlign: "center",
            }}
          >
            Confidential Profile of: <br />
            ANDRE POTGIETER
          </td>
        </tr>
      </table>
      <table
        style={{
          width: "794px",
          margin: "0 auto",
          backgroundImage: `url(${require("../assets/images/certificate_bg.jpg")})`,
          backgroundSize: "cover",
          height: "1158px",
        }}
      >
        <tr
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            height: "100%",
            gap: "30px",
          }}
        >
          <td
            style={{
              textAlign: "center",
              color: "rgb(255, 255, 255)",
              fontSize: "30px",
              textTransform: "capitalize",
              fontFamily: '"Jost", sans-serif',
              fontWeight: "500",
              borderBottom: "2px solid #fff",
            }}
          >
            Basic Detail
          </td>
          <tr style={{ padding: "0px 50px" }}>
            <td
              style={{ display: "flex", flexDirection: "column", gap: "5px" }}
            >
              <tr style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                <td
                  style={{
                    fontSize: "20px",
                    color: "#fff",
                    fontWeight: "400",
                    fontFamily: '"Jost", sans-serif',
                  }}
                >
                  Name :
                </td>
                <td
                  style={{
                    fontSize: "20px",
                    color: "#fff",
                    fontWeight: "400",
                    fontFamily: '"Jost", sans-serif',
                  }}
                >
                  {resumedata.first_name} {resumedata.middle_name}{" "}
                  {resumedata.last_name}
                </td>
              </tr>
              <tr style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                <td
                  style={{
                    fontSize: "20px",
                    color: "#fff",
                    fontWeight: "400",
                    fontFamily: '"Jost", sans-serif',
                  }}
                >
                  Mobile Number :
                </td>
                <td
                  style={{
                    fontSize: "20px",
                    color: "#fff",
                    fontWeight: "400",
                    fontFamily: '"Jost", sans-serif',
                  }}
                >
                  {resumedata.contact}
                </td>
              </tr>
              <tr style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                <td
                  style={{
                    fontSize: "20px",
                    color: "#fff",
                    fontWeight: "400",
                    fontFamily: '"Jost", sans-serif',
                  }}
                >
                  Gender :
                </td>
                <td
                  style={{
                    fontSize: "20px",
                    color: "#fff",
                    fontWeight: "400",
                    fontFamily: '"Jost", sans-serif',
                  }}
                >
                  {resumedata.step2_gender}
                </td>
              </tr>
              <tr style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                <td
                  style={{
                    fontSize: "20px",
                    color: "#fff",
                    fontWeight: "400",
                    fontFamily: '"Jost", sans-serif',
                  }}
                >
                  Are you of Aboriginal or Torres Strait Island origin? :
                </td>
                <td
                  style={{
                    fontSize: "20px",
                    color: "#fff",
                    fontWeight: "400",
                    fontFamily: '"Jost", sans-serif',
                  }}
                >
                  {resumedata.step2_origin}
                </td>
              </tr>
              <tr style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                <td
                  style={{
                    fontSize: "20px",
                    color: "#fff",
                    fontWeight: "400",
                    fontFamily: '"Jost", sans-serif',
                  }}
                >
                  Email :
                </td>
                <td
                  style={{
                    fontSize: "20px",
                    color: "#fff",
                    fontWeight: "400",
                    fontFamily: '"Jost", sans-serif',
                  }}
                >
                  {resumedata.email}
                </td>
              </tr>

              <tr style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                <td
                  style={{
                    fontSize: "20px",
                    color: "#fff",
                    fontWeight: "400",
                    fontFamily: '"Jost", sans-serif',
                  }}
                >
                  Home Address :
                </td>
                <td
                  style={{
                    fontSize: "20px",
                    color: "#fff",
                    fontWeight: "400",
                    fontFamily: '"Jost", sans-serif',
                  }}
                >
                  {resumedata.step2_residential_address}{" "}
                </td>
              </tr>
              <tr style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                <td
                  style={{
                    fontSize: "20px",
                    color: "#fff",
                    fontWeight: "400",
                    fontFamily: '"Jost", sans-serif',
                  }}
                >
                  Street Address Line 2 :
                </td>
                <td
                  style={{
                    fontSize: "20px",
                    color: "#fff",
                    fontWeight: "400",
                    fontFamily: '"Jost", sans-serif',
                  }}
                >
                  {" "}
                  {resumedata.step2_postal_address2}{" "}
                  {resumedata.step2_postal_city}
                  {resumedata.step2_postal_state}
                  {resumedata.step2_postal_code}
                  {resumedata.step2_postal_country}
                </td>
              </tr>
              <tr style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                <td
                  style={{
                    fontSize: "20px",
                    color: "#fff",
                    fontWeight: "400",
                    fontFamily: '"Jost", sans-serif',
                  }}
                >
                  City :
                </td>
                <td
                  style={{
                    fontSize: "20px",
                    color: "#fff",
                    fontWeight: "400",
                    fontFamily: '"Jost", sans-serif',
                  }}
                >
                  {resumedata.step2_postal_city}
                </td>
              </tr>
              <tr style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                <td
                  style={{
                    fontSize: "20px",
                    color: "#fff",
                    fontWeight: "400",
                    fontFamily: '"Jost", sans-serif',
                  }}
                >
                  State :
                </td>
                <td
                  style={{
                    fontSize: "20px",
                    color: "#fff",
                    fontWeight: "400",
                    fontFamily: '"Jost", sans-serif',
                  }}
                >
                  {resumedata.step2_state}
                </td>
              </tr>
              <tr style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                <td
                  style={{
                    fontSize: "20px",
                    color: "#fff",
                    fontWeight: "400",
                    fontFamily: '"Jost", sans-serif',
                  }}
                >
                  Postal Code :
                </td>
                <td
                  style={{
                    fontSize: "20px",
                    color: "#fff",
                    fontWeight: "400",
                    fontFamily: '"Jost", sans-serif',
                  }}
                >
                  {resumedata.step2_postal_code}
                </td>
              </tr>
              <tr style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                <td
                  style={{
                    fontSize: "20px",
                    color: "#fff",
                    fontWeight: "400",
                    fontFamily: '"Jost", sans-serif',
                  }}
                >
                  Country :
                </td>
                <td
                  style={{
                    fontSize: "20px",
                    color: "#fff",
                    fontWeight: "400",
                    fontFamily: '"Jost", sans-serif',
                  }}
                >
                  {resumedata.step2_postal_country}
                </td>
              </tr>
              <tr style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                <td
                  style={{
                    fontSize: "20px",
                    color: "#fff",
                    fontWeight: "400",
                    fontFamily: '"Jost", sans-serif',
                  }}
                >
                  Date of Birth :
                </td>
                <td
                  style={{
                    fontSize: "20px",
                    color: "#fff",
                    fontWeight: "400",
                    fontFamily: '"Jost", sans-serif',
                  }}
                >
                  {resumedata.step2_dob}
                </td>
              </tr>
              <tr style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                <td
                  style={{
                    fontSize: "20px",
                    color: "#fff",
                    fontWeight: "400",
                    fontFamily: '"Jost", sans-serif',
                  }}
                >
                  Country of Birth :
                </td>
                <td
                  style={{
                    fontSize: "20px",
                    color: "#fff",
                    fontWeight: "400",
                    fontFamily: '"Jost", sans-serif',
                  }}
                >
                  {resumedata.step2_country_birth}
                </td>
              </tr>
              <tr style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                <td
                  style={{
                    fontSize: "20px",
                    color: "#fff",
                    fontWeight: "400",
                    fontFamily: '"Jost", sans-serif',
                  }}
                >
                  Are you a permanent Resident/Citizen of Australia? :
                </td>
                <td
                  style={{
                    fontSize: "20px",
                    color: "#fff",
                    fontWeight: "400",
                    fontFamily: '"Jost", sans-serif',
                  }}
                >
                  Yes
                </td>
              </tr>
              <tr style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                <td
                  style={{
                    fontSize: "20px",
                    color: "#fff",
                    fontWeight: "400",
                    fontFamily: '"Jost", sans-serif',
                  }}
                >
                  Do you have the legal right to live and work in Australia? :
                </td>
                <td
                  style={{
                    fontSize: "20px",
                    color: "#fff",
                    fontWeight: "400",
                    fontFamily: '"Jost", sans-serif',
                  }}
                >
                  {resumedata.step2_legal_work}
                </td>
              </tr>
              <tr style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                <td
                  style={{
                    fontSize: "20px",
                    color: "#fff",
                    fontWeight: "400",
                    fontFamily: '"Jost", sans-serif',
                  }}
                >
                  What date do you become available? :
                </td>
                <td
                  style={{
                    fontSize: "20px",
                    color: "#fff",
                    fontWeight: "400",
                    fontFamily: '"Jost", sans-serif',
                  }}
                >
                  {resumedata.step2_available_date}
                </td>
              </tr>
              <tr style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                <td
                  style={{
                    fontSize: "20px",
                    color: "#fff",
                    fontWeight: "400",
                    fontFamily: '"Jost", sans-serif',
                  }}
                >
                  Have you ever been convicted of any criminal offenses? :
                </td>
                <td
                  style={{
                    fontSize: "20px",
                    color: "#fff",
                    fontWeight: "400",
                    fontFamily: '"Jost", sans-serif',
                  }}
                >
                  {resumedata.step2_criminal_offenses}
                </td>
              </tr>
              <tr style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                <td
                  style={{
                    fontSize: "20px",
                    color: "#fff",
                    fontWeight: "400",
                    fontFamily: '"Jost", sans-serif',
                  }}
                >
                  Have you served time in prison? :
                </td>
                <td
                  style={{
                    fontSize: "20px",
                    color: "#fff",
                    fontWeight: "400",
                    fontFamily: '"Jost", sans-serif',
                  }}
                >
                  {resumedata.step2_served_time}
                </td>
              </tr>
              <tr style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                <td
                  style={{
                    fontSize: "20px",
                    color: "#fff",
                    fontWeight: "400",
                    fontFamily: '"Jost", sans-serif',
                  }}
                >
                  Are you an ex serving member of the Australian Defence Force?
                  :
                </td>
                <td
                  style={{
                    fontSize: "20px",
                    color: "#fff",
                    fontWeight: "400",
                    fontFamily: '"Jost", sans-serif',
                  }}
                >
                  {resumedata.step2_defence_forced}
                </td>
              </tr>
              <tr style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                <td
                  style={{
                    fontSize: "20px",
                    color: "#fff",
                    fontWeight: "400",
                    fontFamily: '"Jost", sans-serif',
                  }}
                >
                  Shirt Size :
                </td>
                <td
                  style={{
                    fontSize: "20px",
                    color: "#fff",
                    fontWeight: "400",
                    fontFamily: '"Jost", sans-serif',
                  }}
                >
                  {resumedata.step2_shirt_size}
                </td>
              </tr>
              <tr
                style={{
                  display: "flex",
                  alignItems: "start",
                  flexDirection: "column",
                  gap: "5px",
                }}
              >
                <td
                  style={{
                    fontSize: "20px",
                    color: "#fff",
                    fontWeight: "400",
                    fontFamily: '"Jost", sans-serif',
                  }}
                >
                  Which shifts are you willing to work? Please select all that
                  apply ?
                </td>
                <tr
                  style={{ display: "flex", alignItems: "center", gap: "20px" }}
                >
                  <tr
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    <td
                      style={{
                        fontSize: "20px",
                        color: "#fff",
                        fontWeight: "400",
                        fontFamily: '"Jost", sans-serif',
                      }}
                    >
                      Day Shift :
                    </td>
                    <td
                      style={{
                        fontSize: "20px",
                        color: "#fff",
                        fontWeight: "400",
                        fontFamily: '"Jost", sans-serif',
                      }}
                    >
                      {resumedata.step2_which_dayshift}
                    </td>
                  </tr>
                  <tr
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    <td
                      style={{
                        fontSize: "20px",
                        color: "#fff",
                        fontWeight: "400",
                        fontFamily: '"Jost", sans-serif',
                      }}
                    >
                      Night Shift :
                    </td>
                    <td
                      style={{
                        fontSize: "20px",
                        color: "#fff",
                        fontWeight: "400",
                        fontFamily: '"Jost", sans-serif',
                      }}
                    >
                      {resumedata.step2_which_nightshift}
                    </td>
                  </tr>
                </tr>
              </tr>
              <tr
                style={{
                  display: "flex",
                  alignItems: "start",
                  flexDirection: "column",
                  gap: "5px",
                }}
              >
                <td
                  style={{
                    fontSize: "20px",
                    color: "#fff",
                    fontWeight: "400",
                    fontFamily: '"Jost", sans-serif',
                  }}
                >
                  Employment Type - Please select all that apply ?
                </td>
                <tr
                  style={{ display: "flex", alignItems: "center", gap: "20px" }}
                >
                  <tr
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    {resumedata.step2_employment_type &&
                      resumedata.step2_employment_type.includes("FIFO") && (
                        <>
                          <td
                            style={{
                              fontSize: "20px",
                              color: "#fff",
                              fontWeight: "400",
                              fontFamily: '"Jost", sans-serif',
                            }}
                          >
                            FIFO :
                          </td>
                          <td
                            style={{
                              fontSize: "20px",
                              color: "#fff",
                              fontWeight: "400",
                              fontFamily: '"Jost", sans-serif',
                            }}
                          >
                            Yes
                          </td>
                        </>
                      )}
                  </tr>
                  <tr
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    {resumedata.step2_employment_type &&
                      resumedata.step2_employment_type.includes(
                        "Shutdowns"
                      ) && (
                        <>
                          <td
                            style={{
                              fontSize: "20px",
                              color: "#fff",
                              fontWeight: "400",
                              fontFamily: '"Jost", sans-serif',
                            }}
                          >
                            Shutdowns :
                          </td>
                          <td
                            style={{
                              fontSize: "20px",
                              color: "#fff",
                              fontWeight: "400",
                              fontFamily: '"Jost", sans-serif',
                            }}
                          >
                            Yes
                          </td>
                        </>
                      )}
                  </tr>
                  <tr
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    {resumedata.step2_employment_type &&
                      resumedata.step2_employment_type.includes(
                        "Local Work"
                      ) && (
                        <>
                          <td
                            style={{
                              fontSize: "20px",
                              color: "#fff",
                              fontWeight: "400",
                              fontFamily: '"Jost", sans-serif',
                            }}
                          >
                            Local Work :
                          </td>
                          <td
                            style={{
                              fontSize: "20px",
                              color: "#fff",
                              fontWeight: "400",
                              fontFamily: '"Jost", sans-serif',
                            }}
                          >
                            Yes
                          </td>
                        </>
                      )}
                  </tr>
                  <tr
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    {resumedata.step2_employment_type &&
                      resumedata.step2_employment_type.includes("Casual") && (
                        <>
                          <td
                            style={{
                              fontSize: "20px",
                              color: "#fff",
                              fontWeight: "400",
                              fontFamily: '"Jost", sans-serif',
                            }}
                          >
                            Casual :
                          </td>
                          <td
                            style={{
                              fontSize: "20px",
                              color: "#fff",
                              fontWeight: "400",
                              fontFamily: '"Jost", sans-serif',
                            }}
                          >
                            Yes
                          </td>
                        </>
                      )}
                  </tr>
                  <tr
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    {resumedata.step2_employment_type &&
                      resumedata.step2_employment_type.includes(
                        "Full Time"
                      ) && (
                        <>
                          <td
                            style={{
                              fontSize: "20px",
                              color: "#fff",
                              fontWeight: "400",
                              fontFamily: '"Jost", sans-serif',
                            }}
                          >
                            Full Time :
                          </td>
                          <td
                            style={{
                              fontSize: "20px",
                              color: "#fff",
                              fontWeight: "400",
                              fontFamily: '"Jost", sans-serif',
                            }}
                          >
                            Yes
                          </td>
                        </>
                      )}
                  </tr>
                </tr>
              </tr>
            </td>
          </tr>
        </tr>
      </table>
      <table
        style={{
          width: "794px",
          margin: "0 auto",
          backgroundImage: `url(${require("../assets/images/certificate_bg.jpg")})`,
          backgroundSize: "cover",
          height: "1155px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <tr
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            gap: "20px",
            alignItems: "center",
          }}
        >
          <tr
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              gap: "30px",
              alignItems: "center",
              height: "100%",
            }}
          >
            <td
              style={{
                textAlign: "center",
                color: "rgb(255, 255, 255)",
                fontSize: "30px",
                textTransform: "capitalize",
                fontFamily: '"Jost", sans-serif',
                fontWeight: "500",
                borderBottom: "2px solid #fff",
                width: "100%",
              }}
            >
              Emergency Contact Details
            </td>
            <tr style={{ padding: "0px 50px" }}>
              <td
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  gap: "5px",
                }}
              >
                <tr
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  <td
                    style={{
                      fontSize: "20px",
                      color: "#fff",
                      fontWeight: "400",
                      fontFamily: '"Jost", sans-serif',
                    }}
                  >
                    Name :
                  </td>
                  <td
                    style={{
                      fontSize: "20px",
                      color: "#fff",
                      fontWeight: "400",
                      fontFamily: '"Jost", sans-serif',
                    }}
                  >
                    {resumedata.step3_title}
                    {""}
                    {resumedata.step3_first_name} {""}
                    {resumedata.step3_last_name}
                  </td>
                </tr>
                <tr
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  <td
                    style={{
                      fontSize: "20px",
                      color: "#fff",
                      fontWeight: "400",
                      fontFamily: '"Jost", sans-serif',
                    }}
                  >
                    Relationship to you (eg. spouse, parent, friend) :
                  </td>
                  <td
                    style={{
                      fontSize: "20px",
                      color: "#fff",
                      fontWeight: "400",
                      fontFamily: '"Jost", sans-serif',
                    }}
                  >
                    {resumedata.step3_relationship}
                  </td>
                </tr>
                <tr
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  <td
                    style={{
                      fontSize: "20px",
                      color: "#fff",
                      fontWeight: "400",
                      fontFamily: '"Jost", sans-serif',
                    }}
                  >
                    Is your Emergency Contact located in Australia? :
                  </td>
                  <td
                    style={{
                      fontSize: "20px",
                      color: "#fff",
                      fontWeight: "400",
                      fontFamily: '"Jost", sans-serif',
                    }}
                  >
                    Yes
                  </td>
                </tr>
                <tr
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  <td
                    style={{
                      fontSize: "20px",
                      color: "#fff",
                      fontWeight: "400",
                      fontFamily: '"Jost", sans-serif',
                    }}
                  >
                    Mobile Phone Number :
                  </td>
                  <td
                    style={{
                      fontSize: "20px",
                      color: "#fff",
                      fontWeight: "400",
                      fontFamily: '"Jost", sans-serif',
                    }}
                  >
                    {resumedata.step3_contact}
                  </td>
                </tr>
                <tr
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  <td
                    style={{
                      fontSize: "20px",
                      color: "#fff",
                      fontWeight: "400",
                      fontFamily: '"Jost", sans-serif',
                    }}
                  >
                    Alternative Phone Number :
                  </td>
                  <td
                    style={{
                      fontSize: "20px",
                      color: "#fff",
                      fontWeight: "400",
                      fontFamily: '"Jost", sans-serif',
                    }}
                  >
                    {resumedata.step3_phone_number}
                  </td>
                </tr>
                <tr
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  <td
                    style={{
                      fontSize: "20px",
                      color: "#fff",
                      fontWeight: "400",
                      fontFamily: '"Jost", sans-serif',
                    }}
                  >
                    Home Address :
                  </td>
                  <td
                    style={{
                      fontSize: "20px",
                      color: "#fff",
                      fontWeight: "400",
                      fontFamily: '"Jost", sans-serif',
                    }}
                  >
                    {resumedata.step3_address}
                  </td>
                </tr>
                <tr
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  <td
                    style={{
                      fontSize: "20px",
                      color: "#fff",
                      fontWeight: "400",
                      fontFamily: '"Jost", sans-serif',
                    }}
                  >
                    Street Address Line 2 :
                  </td>
                  <td
                    style={{
                      fontSize: "20px",
                      color: "#fff",
                      fontWeight: "400",
                      fontFamily: '"Jost", sans-serif',
                    }}
                  >
                    {resumedata.step3_address}
                  </td>
                </tr>
                <tr
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  <td
                    style={{
                      fontSize: "20px",
                      color: "#fff",
                      fontWeight: "400",
                      fontFamily: '"Jost", sans-serif',
                    }}
                  >
                    City :
                  </td>
                  <td
                    style={{
                      fontSize: "20px",
                      color: "#fff",
                      fontWeight: "400",
                      fontFamily: '"Jost", sans-serif',
                    }}
                  >
                    {resumedata.step3_city}
                  </td>
                </tr>
                <tr
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  <td
                    style={{
                      fontSize: "20px",
                      color: "#fff",
                      fontWeight: "400",
                      fontFamily: '"Jost", sans-serif',
                    }}
                  >
                    State :
                  </td>
                  <td
                    style={{
                      fontSize: "20px",
                      color: "#fff",
                      fontWeight: "400",
                      fontFamily: '"Jost", sans-serif',
                    }}
                  >
                    {resumedata.step3_city}
                  </td>
                </tr>
                <tr
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  <td
                    style={{
                      fontSize: "20px",
                      color: "#fff",
                      fontWeight: "400",
                      fontFamily: '"Jost", sans-serif',
                    }}
                  >
                    Postal Code :
                  </td>
                  <td
                    style={{
                      fontSize: "20px",
                      color: "#fff",
                      fontWeight: "400",
                      fontFamily: '"Jost", sans-serif',
                    }}
                  >
                    {resumedata.step3_postal}
                  </td>
                </tr>
                <tr
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  <td
                    style={{
                      fontSize: "20px",
                      color: "#fff",
                      fontWeight: "400",
                      fontFamily: '"Jost", sans-serif',
                    }}
                  >
                    Country :
                  </td>
                  <td
                    style={{
                      fontSize: "20px",
                      color: "#fff",
                      fontWeight: "400",
                      fontFamily: '"Jost", sans-serif',
                    }}
                  >
                    {resumedata.step3_country}
                  </td>
                </tr>
              </td>
            </tr>
          </tr>
          <tr
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              gap: "30px",
              alignItems: "start",
              width: "100%",
            }}
          >
            <td
              style={{
                textAlign: "center",
                color: "rgb(255, 255, 255)",
                fontSize: "30px",
                textTransform: "capitalize",
                fontFamily: '"Jost", sans-serif',
                fontWeight: "500",
                borderBottom: "2px solid #fff",
                width: "100%",
              }}
            >
              Employement History
            </td>
            {historyArray.length > 0 ? (
              historyArray.map((entry, index) => (
                <tr key={index} style={{ padding: "0px 50px" }}>
                  {/* Start Date */}
                  <td
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      gap: "5px",
                    }}
                  >
                    <tr
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      <td
                        style={{
                          fontSize: "20px",
                          color: "#fff",
                          fontWeight: "400",
                          fontFamily: '"Jost", sans-serif',
                        }}
                      >
                        Start date :
                      </td>
                      <td
                        style={{
                          fontSize: "20px",
                          color: "#fff",
                          fontWeight: "400",
                          fontFamily: '"Jost", sans-serif',
                        }}
                      >
                        {getYearFromDate(entry.start_date)} -{" "}
                        {getYearFromDate(entry.end_date)}
                      </td>
                    </tr>

                    {/* Role */}
                    <tr
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      <td
                        style={{
                          fontSize: "20px",
                          color: "#fff",
                          fontWeight: "400",
                          fontFamily: '"Jost", sans-serif',
                        }}
                      >
                        Your Role :
                      </td>
                      <td
                        style={{
                          fontSize: "20px",
                          color: "#fff",
                          fontWeight: "400",
                          fontFamily: '"Jost", sans-serif',
                        }}
                      >
                        {entry.role}
                      </td>
                    </tr>

                    {/* What you did in this company */}
                    <tr
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "start",
                        gap: "5px",
                      }}
                    >
                      <td
                        style={{
                          fontSize: "20px",
                          color: "#fff",
                          fontWeight: "400",
                          fontFamily: '"Jost", sans-serif',
                        }}
                      >
                        What you did in this company :
                      </td>
                      <td
                        style={{
                          fontSize: "20px",
                          color: "#fff",
                          fontWeight: "400",
                          fontFamily: '"Jost", sans-serif',
                        }}
                      >
                        {entry.company_done}
                      </td>
                    </tr>
                  </td>
                </tr>
              ))
            ) : (
              // If no employment history available, show this message
              <tr>
                <td colSpan="2" style={{ color: "#fff", textAlign: "center" }}>
                  No employment history available.
                </td>
              </tr>
            )}
          </tr>
          <tr
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              gap: "30px",
              alignItems: "start",
              width: "100%",
            }}
          >
            <td
              style={{
                textAlign: "center",
                color: "rgb(255, 255, 255)",
                fontSize: "30px",
                textTransform: "capitalize",
                fontFamily: '"Jost", sans-serif',
                fontWeight: "500",
                borderBottom: "2px solid #fff",
                width: "100%",
              }}
            >
              Education
            </td>
            {educationsArray.map((entry) => (
              <tr style={{ padding: "0px 50px" }}>
                {/* Year of Completion */}
                <td
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    gap: "5px",
                  }}
                >
                  <tr
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    <td
                      style={{
                        fontSize: "20px",
                        color: "#fff",
                        fontWeight: "400",
                        fontFamily: '"Jost", sans-serif',
                      }}
                    >
                      Year of Completion :
                    </td>
                    <td
                      style={{
                        fontSize: "20px",
                        color: "#fff",
                        fontWeight: "400",
                        fontFamily: '"Jost", sans-serif',
                      }}
                    >
                      {entry.year}
                    </td>
                  </tr>

                  {/* Course */}
                  <tr
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    <td
                      style={{
                        fontSize: "20px",
                        color: "#fff",
                        fontWeight: "400",
                        fontFamily: '"Jost", sans-serif',
                      }}
                    >
                      Course :
                    </td>
                    <td
                      style={{
                        fontSize: "20px",
                        color: "#fff",
                        fontWeight: "400",
                        fontFamily: '"Jost", sans-serif',
                      }}
                    >
                      {entry.course}
                    </td>
                  </tr>

                  {/* Institution */}
                  <tr
                    style={{
                      display: "flex",
                      width: "100%",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    <td
                      style={{
                        fontSize: "20px",
                        color: "#fff",
                        fontWeight: "400",
                        fontFamily: '"Jost", sans-serif',
                      }}
                    >
                      Institution :
                    </td>
                    <td
                      style={{
                        fontSize: "20px",
                        color: "#fff",
                        fontWeight: "400",
                        fontFamily: '"Jost", sans-serif',
                      }}
                    >
                      {entry.institution}
                    </td>
                  </tr>
                </td>
              </tr>
            ))}
          </tr>
        </tr>
      </table>
      <table
        style={{
          width: "794px",
          margin: "0 auto",
          backgroundImage: `url(${require("../assets/images/certificate_bg.jpg")})`,
          backgroundSize: "cover",
          height: "100%",
          padding: "120px 0px",
          display: "block",
        }}
      >
        <tr
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            height: "100%",
            gap: "30px",
          }}
        >
          <td
            style={{
              textAlign: "center",
              color: "rgb(255, 255, 255)",
              fontSize: "30px",
              textTransform: "capitalize",
              fontFamily: '"Jost", sans-serif',
              fontWeight: "500",
              borderBottom: "2px solid #fff",
            }}
          >
            Skill and Extra Informations
          </td>
          <tr style={{ padding: "0px 50px" }}>
            <td
              style={{ display: "flex", flexDirection: "column", gap: "5px" }}
            >
              <tr style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                <td
                  style={{
                    fontSize: "20px",
                    color: "#fff",
                    fontWeight: "400",
                    fontFamily: '"Jost", sans-serif',
                  }}
                >
                  Mention All Skills :
                </td>
                {skillsArray.map((item) => (
                  <td
                    style={{
                      fontSize: "20px",
                      color: "#fff",
                      fontWeight: "400",
                      fontFamily: '"Jost", sans-serif',
                    }}
                  >
                    {item}
                  </td>
                ))}
              </tr>
              <tr style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                <td
                  style={{
                    fontSize: "20px",
                    color: "#fff",
                    fontWeight: "400",
                    fontFamily: '"Jost", sans-serif',
                  }}
                >
                  Years of Experience :
                </td>
                <td
                  style={{
                    fontSize: "20px",
                    color: "#fff",
                    fontWeight: "400",
                    fontFamily: '"Jost", sans-serif',
                  }}
                >
                  {resumedata.years}
                </td>
              </tr>
              <tr style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                {!licfile || licfile.length === 0 ? (
                  <p></p> // or any other message or component
                ) : (
                  <>
                    {licfile
                      .reduce((rows, licensef, index) => {
                        const currentRow = Math.floor(index / 1);

                        // Initialize a new row if it doesn't exist
                        if (!rows[currentRow]) {
                          rows[currentRow] = [];
                        }

                        // Add the current image to the row
                        rows[currentRow].push(
                          <td style={{ width: "160px" }}>
                            <img
                              className="w-100 h-100"
                              src={`${apiurl}${licensef}`}
                              alt="img"
                            />
                          </td>
                        );

                        return rows;
                      }, [])
                      .map((row, rowIndex) => (
                        <tr key={rowIndex}>{row}</tr>
                      ))}
                  </>
                )}
              </tr>
              <tr style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                <td
                  style={{
                    fontSize: "20px",
                    color: "#fff",
                    fontWeight: "400",
                    fontFamily: '"Jost", sans-serif',
                  }}
                >
                  Mention Licences :
                </td>
                {licenseArray !== null && (
                  <>
                    {licenseArray.map((license, index) => (
                      <td
                        style={{
                          fontSize: "20px",
                          color: "#fff",
                          fontWeight: "400",
                          fontFamily: '"Jost", sans-serif',
                        }}
                      >
                        {license}
                      </td>
                    ))}
                  </>
                )}
              </tr>
              <tr style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                {!edfile || edfile.length === 0 ? (
                  <p></p> // or any other message or component
                ) : (
                  <>
                    {edfile
                      .reduce((rows, licensef, index) => {
                        const currentRow = Math.floor(index / 1);

                        // Initialize a new row if it doesn't exist
                        if (!rows[currentRow]) {
                          rows[currentRow] = [];
                        }

                        // Add the current image to the row
                        rows[currentRow].push(
                          <td style={{ width: "160px" }}>
                            <img
                              className="w-100 h-100"
                              src={`${apiurl}${licensef}`}
                              alt="img"
                            />
                          </td>
                        );

                        return rows;
                      }, [])
                      .map((row, rowIndex) => (
                        <tr key={rowIndex}>{row}</tr>
                      ))}
                  </>
                )}
              </tr>
              <tr style={{ display: "flex", alignItems: "start", gap: "5px" }}>
                <td
                  style={{
                    fontSize: "20px",
                    color: "#fff",
                    fontWeight: "400",
                    fontFamily: '"Jost", sans-serif',
                    flexShrink: "0",
                  }}
                >
                  Mention Other Certification :
                </td>
                {certm.length > 0 ? (
                  <>
                    {certm.map((item) => (
                      <td
                        style={{
                          fontSize: "20px",
                          color: "#fff",
                          fontWeight: "400",
                          fontFamily: '"Jost", sans-serif',
                        }}
                      >
                        {item}
                      </td>
                    ))}
                  </>
                ) : (
                  <p>
                    {/* Render some default content or a message when licfile is empty */}
                  </p>
                )}
              </tr>
              <tr style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                {!tradetextArray || tradetextArray.length === 0 ? (
                  <p></p> // or any other message or component
                ) : (
                  <>
                    {tradetextArray
                      .reduce((rows, licensef, index) => {
                        const currentRow = Math.floor(index / 1);

                        // Initialize a new row if it doesn't exist
                        if (!rows[currentRow]) {
                          rows[currentRow] = [];
                        }

                        // Add the current image to the row
                        rows[currentRow].push(
                          <td style={{ width: "160px" }}>
                            <img
                              className="w-100 h-100"
                              src={`${apiurl}${licensef}`}
                              alt="img"
                            />
                          </td>
                        );

                        return rows;
                      }, [])
                      .map((row, rowIndex) => (
                        <tr key={rowIndex}>{row}</tr>
                      ))}
                  </>
                )}
              </tr>
              <tr style={{ display: "flex", alignItems: "start", gap: "5px" }}>
                <td
                  style={{
                    fontSize: "20px",
                    color: "#fff",
                    fontWeight: "400",
                    fontFamily: '"Jost", sans-serif',
                    flexShrink: "0",
                  }}
                >
                  Trade Certifications :
                </td>
                {tradefile.length > 0 ? (
                  <>
                    {tradefile.map((item) => (
                      <td
                        style={{
                          fontSize: "20px",
                          color: "#fff",
                          fontWeight: "400",
                          fontFamily: '"Jost", sans-serif',
                        }}
                      >
                        {item}
                      </td>
                    ))}
                  </>
                ) : (
                  <p>
                    {/* Render some default content or a message when licfile is empty */}
                  </p>
                )}
              </tr>
              <tr style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                {!machinetextArray || machinetextArray.length === 0 ? (
                  <p></p> // or any other message or component
                ) : (
                  <>
                    {machinetextArray
                      .reduce((rows, licensef, index) => {
                        const currentRow = Math.floor(index / 1);

                        // Initialize a new row if it doesn't exist
                        if (!rows[currentRow]) {
                          rows[currentRow] = [];
                        }

                        // Add the current image to the row
                        rows[currentRow].push(
                          <td style={{ width: "160px" }}>
                            <img
                              className="w-100 h-100"
                              src={`${apiurl}${licensef}`}
                              alt="img"
                            />
                          </td>
                        );

                        return rows;
                      }, [])
                      .map((row, rowIndex) => (
                        <tr key={rowIndex}>{row}</tr>
                      ))}
                  </>
                )}
              </tr>
              <tr style={{ display: "flex", alignItems: "start", gap: "5px" }}>
                <td
                  style={{
                    fontSize: "20px",
                    color: "#fff",
                    fontWeight: "400",
                    fontFamily: '"Jost", sans-serif',
                    flexShrink: "0",
                  }}
                >
                  Machinery :
                </td>
                {machinefileArray.length > 0 ? (
                  <>
                    {machinefileArray.map((item) => (
                      <td
                        style={{
                          fontSize: "20px",
                          color: "#fff",
                          fontWeight: "400",
                          fontFamily: '"Jost", sans-serif',
                        }}
                      >
                        {item}
                      </td>
                    ))}
                  </>
                ) : (
                  <p>
                    {/* Render some default content or a message when licfile is empty */}
                  </p>
                )}
              </tr>
              {/* <tr style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                {!machinetextArray || machinetextArray.length === 0 ? (
                  <p></p> // or any other message or component
                ) : (
                  <>
                    {machinetextArray
                      .reduce((rows, licensef, index) => {
                        const currentRow = Math.floor(index / 1);

                        // Initialize a new row if it doesn't exist
                        if (!rows[currentRow]) {
                          rows[currentRow] = [];
                        }

                        // Add the current image to the row
                        rows[currentRow].push(
                          <td style={{ width: "160px" }}>
                            <img
                              className="w-100 h-100"
                              src="https://shorturl.at/bG1Dv"
                              alt="img"
                            />
                          </td>
                        );

                        return rows;
                      }, [])
                      .map((row, rowIndex) => (
                        <tr key={rowIndex}>{row}</tr>
                      ))}
                  </>
                )}
              </tr> */}
              <tr style={{ display: "flex", alignItems: "start", gap: "5px" }}>
                <td
                  style={{
                    fontSize: "20px",
                    color: "#fff",
                    fontWeight: "400",
                    fontFamily: '"Jost", sans-serif',
                    flexShrink: "0",
                  }}
                >
                  Vocational training :
                </td>
                {vocationalArray.length > 0 ? (
                  <>
                    {vocationalArray.map((item) => (
                      <td
                        style={{
                          fontSize: "20px",
                          color: "#fff",
                          fontWeight: "400",
                          fontFamily: '"Jost", sans-serif',
                        }}
                      >
                        {item}
                      </td>
                    ))}
                  </>
                ) : (
                  <p>
                    {/* Render some default content or a message when licfile is empty */}
                  </p>
                )}
              </tr>

              <tr style={{ display: "flex", alignItems: "start", gap: "5px" }}>
                <td
                  style={{
                    fontSize: "20px",
                    color: "#fff",
                    fontWeight: "400",
                    fontFamily: '"Jost", sans-serif',
                    flexShrink: "0",
                  }}
                >
                  Equipment worked :
                </td>
                {equipmentWorked.length > 0 ? (
                  <>
                    {equipmentWorked.map((item) => (
                      <td
                        style={{
                          fontSize: "20px",
                          color: "#fff",
                          fontWeight: "400",
                          fontFamily: '"Jost", sans-serif',
                        }}
                      >
                        {item}
                      </td>
                    ))}
                  </>
                ) : (
                  <p>
                    {/* Render some default content or a message when licfile is empty */}
                  </p>
                )}
              </tr>

              <tr
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  gap: "2px",
                }}
              >
                <td
                  style={{
                    fontSize: "20px",
                    color: "#fff",
                    fontWeight: "400",
                    fontFamily: '"Jost", sans-serif',
                  }}
                >
                  Any sites you don’t want to work at ? :
                </td>
                <td
                  style={{
                    fontSize: "20px",
                    color: "#fff",
                    fontWeight: "400",
                    fontFamily: '"Jost", sans-serif',
                  }}
                >
                  {resumedata.work}
                </td>
              </tr>
            </td>
          </tr>
        </tr>
      </table>
    </div>
  );
};
