import React, { useRef, useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Header } from "../Header";
import { Footer } from "../Footer";
import { Leftmenu } from "./Leftmenu";
import "../assets/ohs/admin.css";
export const Lesson2 = () => {
  const stepTwo = useRef();
  const stepThree = useRef();
  const currentYear = new Date().getFullYear();
  const navigate = useNavigate();
  const [unicode, setunicode] = useState("");
  const [isCourse, setCourse] = useState("");
  const [step2active, setstep2active] = useState(false);
  const [step1active, setstep1active] = useState(true);
  const [step3active, setstep3active] = useState(false);
  const [step4active, setstep4active] = useState(false);
  const [step2activeBtn, setstep2activeBtn] = useState(false);
  const { id } = useParams();
  const changeFavicon = (url) => {
    const favicon = document.querySelector('link[rel="icon"]');
    if (favicon) {
      favicon.href = url;
    }
  };

  useEffect(() => {
    const userStr = JSON.parse(localStorage.getItem("users"));

    if (userStr === null) {
      window.location.href = "/login";
    }
    changeFavicon(require("../assets/admin/images/f-icon.png"));

    document.title = "Start Course Lesson 1";
    if (id === undefined) {
      setunicode("");
      window.location.href = "/login";
    } else {
      setunicode(id);
    }
    let formdata = {
      code: id,
    };
    axios
      .post("https://jlmining.app/getcourseDetail", formdata, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log(res.data);
        console.log("cor");
        if (res.data.row.length > 0) {
          setCourse(res.data.row[0]);
        }
      })
      .catch((err) => {});
  }, [id]);
  const stepformlesson2 = (event) => {
    if (event === "Step1") {
      setstep2active(true);
      setstep1active(false);
      setstep2activeBtn(true);
      setTimeout(() => {
        stepTwo.current.scrollIntoView({ behavior: "smooth" });
      }, 100);
    }
    if (event === "Step2") {
      setstep2activeBtn(false);
      setstep3active(true);
    }
    if (event === "Step3") {
      setstep4active(true);
      setstep3active(false);
    }
    if (event === "Step4") {
      var lsteps = "Lesson_Step2";
      localStorage.setItem("LessonStep", JSON.stringify(lsteps));
      navigate(`/lesson3/${isCourse.slug}/${unicode}`);
    }
  };
  return (
    <>
      <Header />
      <div className="d-flex main-admin-block align-items-stretch">
        <Leftmenu />
        <div className="flex-grow-1 rightpart">
          <section id="legig" className="legi w-100">
            <div className="d-flex flex-column">
              <Link
                to="#introd"
                className="backpage text-center d-flex flex-column"
              >
                <i className="far fa-angle-up"></i>
                <h5>Lesson 1 Introduction</h5>
              </Link>
            </div>
            <div className="container-xxl px-0">
              <div className="row">
                <div className="col-md-10 offset-md-1">
                  <div className="d-flex flex-column gap-3 padding py-5 lesson-block intro">
                    <h4>Lesson 2 of 9</h4>
                    <h1>Laws</h1>
                    <div className="line"></div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="border-line">
                    <div className="line-per"></div>
                  </div>
                </div>
                <div className="col-md-10 offset-md-1 padding">
                  <div className="d-flex flex-column gap-5 py-3 py-md-5">
                    <p>
                      In this section, we'll explain the organization and
                      enforcement of health and safety regulations.
                    </p>
                    <h2>Overview</h2>
                    <div className="d-flex justify-content-center text-center">
                      <div
                        className="pyramid"
                        style={{
                          clipPath: "polygon(50% 0%, 0% 100%, 100% 100%)",
                          width: "320px",
                        }}
                      >
                        <div
                          className="pyramid-box1 d-flex justify-content-center align-items-center"
                          style={{
                            backgroundColor: "black",
                            color: "white",
                            height: "80px",
                            fontSize: "18px",
                            fontWeight: "500",
                          }}
                        >
                          <p>Act</p>
                        </div>
                        <div
                          className="pyramid-box2 d-flex justify-content-center align-items-center"
                          style={{
                            backgroundColor: "red",
                            color: "white",
                            height: "80px",
                            fontSize: "18px",
                            fontWeight: "500",
                          }}
                        >
                          <p>Regulation</p>
                        </div>
                        <div
                          className="pyramid-box3 d-flex justify-content-center align-items-center"
                          style={{
                            backgroundColor: "green",
                            color: "white",
                            height: "80px",
                            fontSize: "18px",
                            fontWeight: "500",
                          }}
                        >
                          <p>
                            Approved Codes of <br />
                            Practice
                          </p>
                        </div>
                        <div
                          className="pyramid-box4 d-flex justify-content-center align-items-center"
                          style={{
                            backgroundColor: "blue",
                            color: "white",
                            height: "80px",
                            fontSize: "18px",
                            fontWeight: "500",
                          }}
                        >
                          <p>
                            Guidance Materials and
                            <br />
                            ANZ Standards
                          </p>
                        </div>
                      </div>
                      <div className="d-flex flex-column justify-content-center align-items-center">
                        <p
                          id="set"
                          style={{
                            height: "80px",
                            display: "flex",
                            alignItems: "center",
                            fontSize: "18px",
                            fontWeight: "500",
                            color: "black",
                          }}
                        >
                          Set out the duties Obligations of the parties
                        </p>
                        <p
                          id="met"
                          style={{
                            height: "80px",
                            display: "flex",
                            alignItems: "center",
                            fontSize: "18px",
                            fontWeight: "500",
                            color: "black",
                          }}
                        >
                          How some of these duties must be met
                        </p>
                        <p
                          id="pract"
                          style={{
                            height: "80px",
                            display: "flex",
                            alignItems: "center",
                            fontSize: "18px",
                            fontWeight: "500",
                            color: "black",
                          }}
                        >
                          Practical guidance on how you should meet these
                          duties.
                        </p>
                        <p
                          id="guide"
                          style={{
                            height: "80px",
                            display: "flex",
                            alignItems: "center",
                            fontSize: "18px",
                            fontWeight: "500",
                            color: "black",
                          }}
                        >
                          Guidelines, factsheet and other informations
                        </p>
                      </div>
                    </div>
                    {step1active && (
                      <div className="d-block w-100 btn-block text-center">
                        <button
                          type="button"
                          onClick={() => stepformlesson2("Step1")}
                        >
                          CONTINUE
                        </button>
                      </div>
                    )}
                  </div>
                  {step2active && (
                    <div className="d-flex flex-column align-items-center py-3 py-md-5 health-safety">
                      <div
                        ref={stepTwo}
                        className="health d-flex flex-column gap-4"
                      >
                        <div className="d-flex flex-column gap-2">
                          <h3>Safety and Health Laws</h3>
                          <p>
                            In all Australian regions, the relevant health and
                            safety acts establish the foundation for health and
                            safety standards. These acts impose a general duty
                            on all parties involved, qualified by what is deemed
                            "reasonably practicable" or achievable for
                            maintaining a healthy and safe workplace.
                          </p>
                        </div>
                        <div className="d-flex flex-column gap-2">
                          <p>
                            The specific acts across Australia are as follows:
                          </p>
                          <ul className="d-flex flex-column gap-3">
                            <li>
                              <p>
                                <strong>New South Wales:</strong> Work Health
                                and Safety Act 2011.
                              </p>
                            </li>
                            <li>
                              <p>
                                <strong>Queensland:</strong> Work Health and
                                Safety Act 2011.
                              </p>
                            </li>
                            <li>
                              <p>
                                <strong>South Australia:</strong> Work Health
                                and Safety Act 2012.
                              </p>
                            </li>
                            <li>
                              <p>
                                <strong>Tasmania:</strong> Work Health and
                                Safety Act 2012 and Work Health and Safety
                                (Transitional and Consequences Provisions) Act
                                2012.
                              </p>
                            </li>
                            <li>
                              <p>
                                <strong>Northern Territory: </strong> Work
                                Health and Safety (National Uniform Legislation)
                                Act 2011.
                              </p>
                            </li>
                            <li>
                              <p>
                                <strong>Australian Capital Territory: </strong>{" "}
                                Work Health and Safety Act 2011.
                              </p>
                            </li>
                            <li>
                              <p>
                                <strong>Victoria:</strong> Occupational Health
                                and Safety Act 2004.
                              </p>
                            </li>
                            <li>
                              <p>
                                <strong>Western Australia :</strong> Work Health
                                and Safety Act 2020.
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="s-line"></div>
                      <div className="health d-flex flex-column gap-4">
                        <div className="d-flex flex-column gap-2">
                          <h3>Safety and Health Rules</h3>
                          <p>
                            In every jurisdiction, there are numerous rules
                            associated with the acts. These regulations outline
                            how certain duties specified in the acts must be
                            fulfilled and establish procedural or administrative
                            requirements to support them. For instance, they may
                            mandate licenses for particular activities and the
                            maintenance of records. There are also various
                            industry-specific regulations, such as those
                            concerning electrical installations, construction
                            work, and the handling of hazardous chemicals.
                          </p>
                        </div>
                        <div className="d-flex flex-column gap-2">
                          <p>
                            Furthermore, apart from regulations aimed at
                            safeguarding the health and safety of workers, the
                            legislation also mandates workplaces to provide
                            appropriate welfare amenities, including:
                          </p>
                          <ul
                            id="ul-number"
                            className="d-flex flex-column gap-3"
                          >
                            <li>
                              <p>
                                <strong>
                                  Toilets, with suitable changing facilities if
                                  necessary.
                                </strong>
                              </p>
                            </li>
                            <li>
                              <p>
                                <strong>
                                  Adequate washing facilities, including{" "}
                                </strong>
                                - access to hot water and soap.
                              </p>
                            </li>
                            <li>
                              <p>
                                <strong>Provision of drinking water.</strong>
                              </p>
                            </li>

                            <li>
                              <p>
                                <strong>Rest areas, </strong> - swith seats
                                provided for breaks.
                              </p>
                            </li>
                            <li>
                              <p>
                                <strong>
                                  Facilities for meal preparation and
                                  consumption.
                                </strong>
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="s-line"></div>
                      <div className="health d-flex flex-column gap-4">
                        <div className="d-flex flex-column gap-2">
                          <h3>Guidelines for Practice</h3>
                          <p>
                            SafeWork Australia creates Codes of Practice to
                            assist state and territory regulators. These codes
                            offer detailed guidance on how to implement and
                            apply regulations effectively.
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                  {step2activeBtn && (
                    <div className="d-block padding btn-block text-center">
                      <button
                        type="button"
                        onClick={() => stepformlesson2("Step2")}
                      >
                        CONTINUE
                      </button>
                    </div>
                  )}
                </div>
                {step3active && (
                  <div className="col-md-10 offset-md-1 padding py-5 Enfo">
                    <div className="d-flex flex-column gap-2 py-5">
                      <h2>Enforcement Authorities in Australia</h2>
                    </div>
                  </div>
                )}
                {step3active && (
                  <div className="staff-img py-4">
                    <img
                      className="w-100"
                      src={require("../assets/ohs/images/se4.png")}
                      alt="img"
                    />
                  </div>
                )}
                {step3active && (
                  <div className="col-md-10 offset-md-1 padding py-5 enfo">
                    <p>
                      The regulators are the governing bodies responsible for
                      enforcing and overseeing workplace health, safety, and
                      welfare within their respective jurisdiction.
                    </p>
                    <div className="d-flex align-items-center justify-content-center">
                      <div className="safe-img py-4">
                        <img
                          className="w-100"
                          src={require("../assets/ohs/images/se5.jpg")}
                          alt="img"
                        />
                      </div>
                    </div>
                    <p>
                      Some agencies also conduct formal research into
                      work-related risks, and SafeWork Australia compiles
                      national statistical data on health and safety at work.
                      However, it's important to note that SafeWork Australia is
                      not a regulatory body and lacks enforcement powers.
                    </p>

                    <p>
                      Apart from health and safety legislation, various legal
                      requirements affect worker health and safety, including
                      fire safety, environmental regulations, and building
                      codes. Typically, these are overseen by local authorities
                      such as councils or other state or territory agencies.
                    </p>
                  </div>
                )}
              </div>
              {step3active && (
                <div className="d-block padding btn-block text-center mb-4">
                  <button
                    type="button"
                    onClick={() => stepformlesson2("Step3")}
                  >
                    CONTINUE
                  </button>
                </div>
              )}
            </div>
            {step4active && (
              <div id="good" className="col-md-10 offset-md-1 py-5 padding">
                <div className="d-flex flex-column gap-3">
                  <h2>Powers Of Enforcement Authorities</h2>
                  <p>
                    To ensure compliance with health and safety laws,
                    enforcement authorities possess substantial powers. These
                    powers are delegated to health and safety inspectors,
                    granting them the authority to enter, search, and inspect
                    workplaces in response to complaints and workplace
                    incidents.
                  </p>
                </div>
              </div>
            )}
            {step4active && (
              <div className="d-block padding btn-block text-center mb-4">
                <button type="button" onClick={() => stepformlesson2("Step4")}>
                  CONTINUE
                </button>
              </div>
            )}
          </section>
        </div>
      </div>
      <Footer />
    </>
  );
};
