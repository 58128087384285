import React, { useState, useEffect } from "react";
import axios from "axios";
import { message, Form, Input, Button, Alert } from "antd";
import { Link, useNavigate, useParams, useHistory } from "react-router-dom";
import { Header } from "../Header";
import { Footer } from "../Footer";
import moment from "moment-timezone";
import Select from "react-select";
import { ReactDialogBox } from "react-js-dialog-box";
import "react-js-dialog-box/dist/index.css";
import "../assets/ohs/newstyle.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
export const Summary = () => {
  const currentYear = new Date().getFullYear();
  const navigate = useNavigate();
  const [uniquecode, setuniquecode] = useState("");
  const [getincidentData, setgetincidentData] = useState([]);
  const [iswasfirst, setwasfirst] = useState(false);
  const [iswhereother, setwhereother] = useState(false);
  const [isincidentafe, setisincidentafe] = useState(false);
  const [iswitness, setwitness] = useState(false);
  const [isOpen, setisOpen] = useState(false);
  const changeFavicon = (url) => {
    const favicon = document.querySelector('link[rel="icon"]');
    if (favicon) {
      favicon.href = url;
    }
  };
  const { id } = useParams();

  useEffect(() => {
    const userStr = JSON.parse(localStorage.getItem("users"));
    if (userStr === null) {
      window.location.href = "/login";
    }
    document.title = "Summary Page";

    if (!id) {
      window.location.href = "/incident"; // Navigate to home page if id is empty or undefined
    } else {
      setuniquecode(id);
    }
    let formdata = {
      user_id: userStr[0].id,
      code: id,
    };
    axios
      .post("https://jlmining.app/getincidentdata", formdata, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res.data.row.length > 0) {
          var indata = res.data.row[0];
          if (indata.status === "Closed") {
            const newUrl = "/incident/";
            navigate(newUrl);
          }
          setgetincidentData(indata);
          if (indata.was_first === "Yes") {
            setwasfirst(true);
          }
          if (indata.other_person_involved === "Yes") {
            setwhereother(true);
          }
          if (indata.witness === "Yes") {
            setwitness(true);
          }
          if (indata.incident_safe === "Yes") {
            setisincidentafe(true);
          }
        }
      })
      .catch((err) => {});
  }, [id]);
  const formatDate = (dateString) => {
    const options = {
      weekday: "short",
      day: "2-digit",
      month: "short",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };
    const formattedDate = new Date(dateString).toLocaleString("en-US", options);
    return formattedDate;
  };
  const formattedDate = formatDate(getincidentData.step4_date);

  const openBox = () => {
    setisOpen(true);
  };

  const closeBox = () => {
    setisOpen(false);
  };
  const completeincident = () => {
    const userStr = JSON.parse(localStorage.getItem("users"));
    let formdata = {
      user_id: userStr[0].id,
      code: id,
    };
    axios
      .post("https://jlmining.app/completeincident", formdata, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        const newUrl = "/ohs/";
        navigate(newUrl);
      })
      .catch((err) => {});
  };
  return (
    <>
      <Header />
      <div className="d-flex flex-column gap-3">
        <div className="d-flex flex-column gap-2 p-4 report-summary shadow bg-white">
          <h3>Incident report summary</h3>
          <h4>{getincidentData.reference}</h4>
          <h4>Australia — Western Australia</h4>
          <div className="d-flex gap-4 align-items-center mt-2">
            <div className="flex-shrink-0">
              <Link to={`/incident/about/${id}`} className="back-btn">
                Back
              </Link>
            </div>
            <div className="flex-grow-1">
              <Link
                to="javascript:void(0)"
                onClick={openBox}
                className="ful-btn"
              >
                Confirm submission
              </Link>
            </div>
          </div>
        </div>
        {isOpen && (
          <>
            <ReactDialogBox
              closeBox={closeBox}
              modalWidth="60%"
              headerBackgroundColor="red"
              headerTextColor="white"
              headerHeight="65"
              closeButtonColor="white"
              bodyBackgroundColor="white"
              bodyTextColor="black"
              bodyHeight="200px"
              headerText="Confirm Incident submission"
            >
              <div>
                <h1 className="text-dark">Incident Record</h1>
                <p>
                  The incident will be recorded and a notification will be sent
                  to the Responsible Person(s) who will look into this further.
                </p>
                <p>
                  For advice on incidents, please call the Advice Line on{" "}
                  <Link href="tel:1300029198">1300 029 198 (Option 2)</Link>.
                </p>
              </div>
              <div className="mt-4">
                <Link to="button" onClick={completeincident} className="cbtn">
                  Complete
                </Link>
                .
              </div>
            </ReactDialogBox>
          </>
        )}
        <div className="d-flex flex-column gap-3 bg-white shadow  p-4 inperson">
          <h3>Injured person</h3>
          <div className="d-flex flex-column gap-2">
            <div className="d-flex flex-column ">
              <label for="">Name</label>
              <h6>{getincidentData.full_name}</h6>
            </div>
            <div className="d-flex flex-column ">
              <label for="">Address</label>
              <h6>{getincidentData.address1}</h6>
            </div>
            <div className="d-flex flex-column ">
              <label for="">Occupation</label>
              <h6>{getincidentData.occupation}</h6>
            </div>
            <div className="d-flex flex-column ">
              <label for="">Email</label>
              <h6>{getincidentData.email_step2}</h6>
            </div>
            <div className="d-flex flex-column ">
              <label for="">Telephone number</label>
              <h6>{getincidentData.phone_number_step2}</h6>
            </div>
          </div>
        </div>
        <div className="d-flex flex-column gap-3 bg-white shadow  p-4 inperson">
          <h3>Reporter</h3>
          <div className="d-flex flex-column gap-2">
            <div className="d-flex flex-column ">
              <label for="">Name</label>
              <h6>{getincidentData.employee_name}</h6>
            </div>
            <div className="d-flex flex-column ">
              <label for="">Address</label>
              <h6>{getincidentData.address1_step3}</h6>
            </div>
            <div className="d-flex flex-column ">
              <label for="">Occupation</label>
              <h6>{getincidentData.occupation_step3}</h6>
            </div>
            <div className="d-flex flex-column ">
              <label for="">Email</label>
              <h6>{getincidentData.email_step3}</h6>
            </div>
            <div className="d-flex flex-column ">
              <label for="">Telephone number</label>
              <h6>{getincidentData.phone_step3}</h6>
            </div>
          </div>
        </div>
        <div className="d-flex flex-column gap-3 bg-white shadow  p-4 inperson">
          <h3>Details</h3>
          <div className="d-flex flex-column gap-2">
            <div className="d-flex flex-column ">
              <label for="">Date and time</label>
              <h6>
                {formattedDate}({getincidentData.time_zone})
              </h6>
            </div>
            <div className="d-flex flex-column ">
              <label for="">Site</label>
              <h6>{getincidentData.site_step4}</h6>
            </div>
            <div className="d-flex flex-column ">
              <label for="">Site reference</label>
              <h6>{getincidentData.site_reference}</h6>
            </div>
            <div className="d-flex flex-column ">
              <label for="">Location</label>
              <h6>{getincidentData.locations}</h6>
            </div>
            <div className="d-flex flex-column ">
              <label for="">Description</label>
              <h6>{getincidentData.chain_event}</h6>
            </div>
            <div className="d-flex flex-column ">
              <label for="">Was first aid given</label>
              <h6>{getincidentData.was_first}</h6>
            </div>
            {iswasfirst && (
              <div className="d-flex flex-column ">
                <label for="">Treatment</label>
                <h6>{getincidentData.treatment}</h6>
              </div>
            )}
            {iswasfirst && (
              <div className="d-flex flex-column ">
                <label for="">Name of first aider</label>
                <h6>{getincidentData.first_aider}</h6>
              </div>
            )}
            <div className="d-flex flex-column ">
              <label for="">
                Were there any other person/s involved in the incident?
              </label>
              <h6>{getincidentData.other_person_involved}</h6>
            </div>
            {iswhereother && (
              <div className="d-flex flex-column ">
                <label for="">Details of other person/s involved</label>
                <h6>{getincidentData.detail_person_involved}</h6>
              </div>
            )}
            <div className="d-flex flex-column ">
              <label for="">Were there any witnesses to the incident?</label>
              <h6>{getincidentData.witness}</h6>
            </div>
            {iswitness && (
              <div className="d-flex flex-column ">
                <label for="">Include name and contact number</label>
                <h6>{getincidentData.detail_witness}</h6>
              </div>
            )}
            <div className="d-flex flex-column ">
              <label for="">Has the incident site been made safe?</label>
              <h6>{getincidentData.incident_safe}</h6>
            </div>
            {isincidentafe && (
              <div className="d-flex flex-column ">
                <label for="">Details of actions taken</label>
                <h6>{getincidentData.action_token}</h6>
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
