import React, { useState, useEffect } from "react";
import axios from "axios";
import { message, Form, Input, Button, Alert } from "antd";
import { Link, useNavigate } from "react-router-dom";
export const Footer = () => {
  const currentYear = new Date().getFullYear();
  const navigate = useNavigate();
  const [valueUserDetail, setValuegetuser] = useState([]);
  const handleLogout = () => {
    sessionStorage.removeItem("users");
    window.location.href = "/login";
  };
  const changeFavicon = (url) => {
    const favicon = document.querySelector('link[rel="icon"]');
    if (favicon) {
      favicon.href = url;
    }
  };

  useEffect(() => {
    changeFavicon(require("./assets/admin/images/f-icon.png"));

    document.title = "Home Page";
  }, []);
  const [values, setValues] = useState({
    name: "",
    email: "",
    password: "",
    contact: "",
    address: "",
  });
  const [showAlert, setShowAlert] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [val, setVal] = useState("");
  const handleInput = (event) => {
    setVal((v) => (event.target.validity.valid ? event.target.value : v));
    setValues((prev) => ({
      ...prev,
      [event.target.name]: [event.target.value],
    }));
  };

  return (
    <>
      <footer>
        <div className="ft-topbox d-block py-5">
          <div className="container-lg">
            <div className="row gy-4">
              <div className="col-md-3">
                <div className="d-flex flex-column gap-3 ft-boxes">
                  <h3>Contact us</h3>
                  <div className="d-flex flex-column gap-2 ft-links">
                    <p>Address: 8 Sparks Rd, Henderson WA 6166 </p>
                    <p>
                      <Link to="javascript:void(0)">Phone: +61 894375388</Link>{" "}
                    </p>
                    <p>Hours: 7:00am - 5:00pm Monday - Friday </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="d-flex flex-column gap-3 ft-boxes">
                  <h3>Help</h3>
                  <div className="row gy-4">
                    <div className="col-md-6">
                      <div className="d-flex flex-column gap-2 ft-links">
                        <p>
                          <Link to="/contact">Contact Information</Link>{" "}
                        </p>
                        <p>
                          <Link to="javascript:void(0)">How to Use</Link>{" "}
                        </p>
                        <p>
                          <Link to="/experthelp">Get Expert Help</Link>{" "}
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="d-flex flex-column gap-2 ft-links">
                        <p>
                          <Link to="/terms&condition">
                            Terms and Conditions
                          </Link>{" "}
                        </p>
                        <p>
                          <Link to="/privacypolicy">Privacy Policy</Link>{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="d-flex flex-column gap-3 ft-boxes">
                  <h3>exprole us</h3>
                  <div className="d-flex flex-column gap-2 ft-links">
                    <p>
                      <Link to="https://www.hssonline.com.au" target="_blank">
                        www.hssonline.com.au
                      </Link>{" "}
                    </p>
                    <p>
                      <Link to="https://www.hssptyltd.com.au" target="_blank">
                        www.hssptyltd.com.au
                      </Link>{" "}
                    </p>
                    <p>
                      <Link to="https://www.jlme.com.au" target="_blank">
                        www.jlme.com.au{" "}
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="copy-ft py-2 text-center">
          <div className="container-lg">
            <p>
              Privacy | Site Terms | &copy; {currentYear}, JL Mining All rights
              reserved
            </p>
          </div>
        </div>
      </footer>
    </>
  );
};
