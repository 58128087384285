import React, { useState, useEffect } from "react";
import axios from "axios";
import { message, Form, Input, Button, Alert } from "antd";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ReactDialogBox } from "react-js-dialog-box";
import { FaPlus, FaFolder, FaTrash, FaFile } from "react-icons/fa";
import AdminHeader from "../AdminHeader";
import AdminFooter from "../AdminFooter";
import JSZip from "jszip";
import { saveAs } from "file-saver";
export const AdminOnboardingfiles = () => {
  const navigate = useNavigate();
  const [docUp1, setdocUp1] = useState(false);
  const [docUp2, setdocUp2] = useState(false);
  const [del3, setdel3] = useState(false);
  const [del2, setdel2] = useState(false);
  const [del1, setdel1] = useState(false);
  const [msgg, setmsgg] = useState(false);
  const [diss, setdiss] = useState(false);
  const [dis, setdis] = useState(false);
  const [tab1, settab1] = useState(false);
  const [tab2, settab2] = useState(false);
  const [tab3, settab3] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [selectAll_emp, setselectAll_emp] = useState(false);
  const [empmessage, setempmessage] = useState(false);
  const [getfolder, setgetfolder] = useState([]);
  const [getfolderr, setgetfolderr] = useState([]);
  const [isOpen, setisOpen] = useState(false);
  const [deleteerror, setdeleteerror] = useState(false);
  const [getfile, setgetfile] = useState([]);
  const [SelectedFile, setSelectedFile] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [checkboxes, setCheckboxes] = useState([]);
  const [checkboxes_emp, setcheckboxes_emp] = useState([]);
  const [deletemessage, setdeletemessage] = useState(false);
  const [allemployee, setallemployee] = useState([]);
  const [allfile, setallfile] = useState(true);
  const [allemp, setallemp] = useState(false);
  const { id } = useParams();
  const changeFavicon = (url) => {
    const favicon = document.querySelector('link[rel="icon"]');
    if (favicon) {
      favicon.href = url;
    }
  };
  useEffect(() => {
    changeFavicon(require("../../assets/admin/images/f-icon.png"));
    document.title = "Onboarding Document Page";
    const userStr = JSON.parse(localStorage.getItem("admin"));
    console.log(userStr);
    if (userStr == null) {
      navigate("/admin/login");
    }
    if (id === undefined) {
      navigate("/admin/onboardingdocument");
    }
    let formdata = {
      id: id,
    };
    axios
      .post("https://jlmining.app/admin/getfolderfiles", formdata, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log(res.data);
        console.log("setgetfolderr");
        var alldata = res.data.results;
        setgetfolder(alldata);
      })
      .catch((err) => {});
    axios
      .get("https://jlmining.app/admin/getemployee", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log(res.data.results);
        setallemployee(res.data.results);
      })
      .catch((err) => {});

    if (checkboxes.length > 0) {
      console.log("ss");
      console.log(checkboxes.length);
      setdis(true);
    } else {
      setdis(false);
    }
  }, [id, checkboxes]);
  var appUrl = "https://jlmining.app/uploads";
  const closeBox = () => {
    setisOpen(false);
  };
  const openpopup = () => {
    setisOpen(true);
  };
  const handleSubmit = (event) => {
    setdocUp1(true);
    event.preventDefault();
    const formData = new FormData();
    if (SelectedFile !== null) {
      if (SelectedFile.length > 0) {
        for (let i = 0; i < SelectedFile.length; i++) {
          formData.append("files", SelectedFile[i]);
        }
      }
    }
    formData.append("id", id);
    console.log(SelectedFile);
    axios
      .post("https://jlmining.app/admin/creatfile", formData, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        setSelectedFile([]);
        var alldata = res.data.results;
        setgetfolder(alldata);
        setShowAlert(true);
        setShowAlert({ type: "success" });
        event.target.reset();
        setisOpen(false);
        setTimeout(() => {
          setdocUp1(false);
          setShowAlert(false);
          window.location.reload();
        }, 3500);
      })
      .catch((err) => {});
  };
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files);
  };
  const checkall = (event) => {
    const isChecked = event.target.checked;
    setSelectAll(isChecked);

    if (isChecked) {
      const updatedCheckboxes = getfolder.map((item, index) => ({
        fid: item.fid,
        isChecked: true,
        mainfile: `${appUrl}/${item.folder_name}_${item.folder_id}/${item.name}`,
        fname: item.name,
      }));
      console.log(updatedCheckboxes);
      setCheckboxes(updatedCheckboxes);
      console.log(updatedCheckboxes);
    } else {
      setCheckboxes([]);
    }
  };

  const handleCheckboxChange_fff = (id, fname, index, mainfile, event) => {
    console.log(mainfile);
    const isChecked = event.target.checked;
    let updatedCheckboxes = [...checkboxes];

    const checkboxIndex = updatedCheckboxes.findIndex(
      (checkbox) => checkbox.fid === id // Assuming the identifier is `fid`
    );

    if (isChecked) {
      if (checkboxIndex === -1) {
        updatedCheckboxes.push({ fid: id, isChecked, mainfile, fname });
      } else {
        updatedCheckboxes[checkboxIndex].isChecked = isChecked;
      }
    } else {
      if (checkboxIndex !== -1) {
        updatedCheckboxes.splice(checkboxIndex, 1);
      }
    }

    // console.log(updatedCheckboxes);
    setCheckboxes(updatedCheckboxes);
  };

  const handleCheckboxChange_emp = (name, index, id, event) => {
    const isChecked = event.target.checked;
    let updatedCheckboxes_emp = [...checkboxes_emp];
    //console.log(isChecked);

    if (isChecked) {
      console.log(updatedCheckboxes_emp[index]);
      updatedCheckboxes_emp[index] = { id, isChecked, name };
    } else {
      updatedCheckboxes_emp = updatedCheckboxes_emp.filter(
        (checkbox, idx) => idx !== index
      );
      updatedCheckboxes_emp[index] = { id, isChecked, name };
    }
    console.log(updatedCheckboxes_emp);
    setcheckboxes_emp(updatedCheckboxes_emp);
  };
  const checkall_emp = (event) => {
    const isChecked = event.target.checked;
    setselectAll_emp(isChecked);

    if (isChecked) {
      const updatedCheckboxes_emp = allemployee.map((item, index) => ({
        id: item.id,
        isChecked: true,
        name: item.first_name + " " + item.middle_name + " " + item.last_name,
      }));
      setcheckboxes_emp(updatedCheckboxes_emp);
      console.log(updatedCheckboxes_emp);
    } else {
      setcheckboxes_emp([]);
    }
  };
  const deletehandle = (id, folder) => {
    var de = window.confirm("Are you sure you want to delete this file");
    setdeletemessage(false);
    if (de) {
      let formdata = {
        id: id,
        folderid: folder,
      };
      axios
        .post("https://jlmining.app/admin/deletefile", formdata, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "POST,PUT",
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          var alldata = res.data.results;
          setgetfolder(alldata);
          setdeleteerror(true);
          setTimeout(() => {
            setdeleteerror(false);
            setShowAlert(false);
          }, 4500);
        })
        .catch((err) => {});
    }
  };
  const deletefiles = () => {
    if (id !== "") {
      if (checkboxes.length > 0) {
        settab2(false);
        console.log(checkboxes);
        setdeletemessage(false);
        let formdata = {
          multi_Id: checkboxes,
          folderid: id,
        };
        axios
          .post("https://jlmining.app/admin/deletefileall", formdata, {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods": "POST,PUT",
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          })
          .then((res) => {
            setCheckboxes([]);
            setSelectAll(false);
            var alldata = res.data.results;
            setgetfolder(alldata);
            setdeleteerror(true);
            setTimeout(() => {
              setdeleteerror(false);
              setShowAlert(false);
              settab3(false);
            }, 4500);
          })
          .catch((err) => {});
      } else {
        setdeletemessage(true);
        setTimeout(() => {
          setdeletemessage(false);
        }, 4500);
      }
    }
  };
  const downloadfile = () => {
    if (checkboxes.length > 0) {
      checkboxes.forEach((file) => {
        console.log(file.fname);
        const fileURL = file.mainfile;
        const fileName = file.fname;

        // Create a temporary link element for each file
        const link = document.createElement("a");
        link.href = fileURL;
        link.target = "_blank"; // Open in a new tab
        link.rel = "noopener noreferrer"; // Security best practice
        link.download = fileName;

        // Append the link to the body and trigger the click event
        document.body.appendChild(link);
        link.click();

        // Cleanup: Remove the link after download
        document.body.removeChild(link);
      });
    } else {
      setdeletemessage(true);
      setTimeout(() => {
        setdeletemessage(false);
      }, 4500);
    }
  };

  const handleDownload = (url, fileName) => {
    const link = document.createElement("a");
    link.href = url;
    link.target = "_blank"; // Open in a new tab
    link.rel = "noopener noreferrer"; // Security best practice
    link.download = fileName;
    link.click();
  };
  const closetab1 = () => {
    settab1(false);
  };
  const opentab2 = () => {
    settab1(false);
    settab2(true);
  };
  const closetab2 = () => {
    settab2(false);
  };
  const deletefolder_tab1 = () => {
    if (checkboxes.length > 0) {
      settab1(true);
    } else {
      setdeletemessage(true);
      setTimeout(() => {
        setdeletemessage(false);
      }, 4500);
    }
  };
  const linkStyle = {
    opacity: dis ? 0.1 : 1, // Set opacity based on the value of 'dis'
    pointerEvents: dis ? "none" : "auto", // Disable pointer events if 'dis' is true
    textDecoration: "none", // Optional: Remove default link underline
    color: "inherit", // Optional: Inherit text color
  };
  const nextstep = () => {
    setallfile(false);
    setallemp(true);
    setdis(false);
    setdiss(true);
  };
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase();
  }
  const handleClick = (url, name) => {
    // Create a temporary anchor element
    const link = document.createElement("a");
    link.href = url;
    link.target = "_blank"; // Open in a new tab
    link.rel = "noopener noreferrer"; // Security best practice
    link.setAttribute("download", name);

    // Simulate a click on the anchor element
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const sendallfile = () => {
    //console.log(checkboxes);
    console.log(checkboxes_emp);
    if (checkboxes_emp.length > 0) {
      setdel3(true);
      setdel2(false);
      let formdata = {
        emp: checkboxes_emp,
        files: checkboxes,
        id: id,
      };
      axios
        .post("https://jlmining.app/admin/senfileemp", formdata, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "POST,PUT",
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          console.log(res.data);
          if (res.data.status === "1") {
            setmsgg(true);
            setTimeout(() => {
              setdel3(false);
              setmsgg(false);
              window.location.reload();
            }, 4500);
          }
        })
        .catch((err) => {});
      setTimeout(() => {
        setdel3(false);
        setmsgg(false);
        window.location.reload();
      }, 4500);
    } else {
      setempmessage(true);
      setTimeout(() => {
        setempmessage(false);
      }, 4500);
    }
  };
  const delete_del1 = () => {
    if (checkboxes_emp.length > 0) {
      setdel1(true);
    } else {
      setempmessage(true);
      setTimeout(() => {
        setempmessage(false);
      }, 4500);
    }
  };
  const closedel1 = () => {
    setdel1(false);
  };
  const opendel2 = () => {
    //console.log(checkboxes);
    console.log("cf");
    console.log(checkboxes_emp);
    setdel1(false);
    setdel2(true);
  };
  const searcfilename = (event) => {
    console.log(event.target.value);
    let formdata = {
      search: event.target.value,
      id: id,
    };
    axios
      .post("https://jlmining.app/admin/searchfile", formdata, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        var alldata = res.data.results;
        setgetfolder(alldata);
      })
      .catch((err) => {});
  };
  const searcemployeename = (event) => {
    console.log(event.target.value);
    let formdata = {
      search: event.target.value,
    };
    axios
      .post("https://jlmining.app/admin/searchempl", formdata, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        var alldata = res.data.results;
        setallemployee(alldata);
      })
      .catch((err) => {});
  };
  return (
    <>
      <div>
        <AdminHeader />
        <div className="main-right-body">
          <div className="block-full">
            <div className="row gy-4">
              <div className="col-md-12">
                <div className="right-form-box bg-white shadow p-2 ">
                  <h5 className=" fs-24 fw-bold mb-3 text-danger text-center">
                    Onboarding Document Files
                  </h5>
                  <div className="block  px-4">
                    <div className="d-flex justify-content-between">
                      <Link
                        to="/admin/onboardingdocument"
                        className="btn btn-secondary"
                      >
                        Back
                      </Link>
                      {allfile && (
                        <input
                          type="text"
                          className="form-control w-50"
                          onInput={searcfilename}
                          placeholder="Search file..."
                        />
                      )}
                      {allfile && <h4 className="fs-5">Select File</h4>}
                      {allfile && (
                        <Link
                          to="javascript:void(0)"
                          onClick={openpopup}
                          className="btn btn-danger"
                        >
                          Upload File <FaPlus />
                        </Link>
                      )}
                    </div>
                    {allfile && (
                      <div className="row mt-4">
                        <div className="form-check d-flex gap-2 align-items-center">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="flexCheckDefault"
                            checked={selectAll}
                            onChange={checkall}
                          />
                          <label
                            className="form-check-label "
                            for="flexCheckDefault"
                          >
                            Select All
                          </label>
                          <Link to="javascript:void(0)" onClick={downloadfile}>
                            Download
                          </Link>
                          <Link
                            to="javascript:void(0)"
                            onClick={deletefolder_tab1}
                          >
                            Delete
                          </Link>
                        </div>
                      </div>
                    )}
                    {allemp && (
                      <div className="row mt-4">
                        <div className="form-check d-flex gap-2 align-items-center">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="flexCheckDefault"
                            checked={selectAll_emp}
                            onChange={checkall_emp}
                          />
                          <label
                            className="form-check-label "
                            for="flexCheckDefault"
                          >
                            Select All
                          </label>
                        </div>
                        <div className="d-flex">
                          <input
                            type="text"
                            className="form-control w-50"
                            onInput={searcemployeename}
                            placeholder="Search employee..."
                          />
                        </div>

                        {msgg && (
                          <span className="text-success">
                            File has been successfully sent!
                          </span>
                        )}
                      </div>
                    )}
                    {showAlert?.type === "success" && (
                      <Alert
                        type="success"
                        description="File has been uploaded successfully"
                        closable
                      />
                    )}
                    {deleteerror && (
                      <span className="text-danger">
                        Files has been deleted successfully
                      </span>
                    )}
                    {empmessage && (
                      <span className="text-danger mt-4">
                        Please choose atleast one employee
                      </span>
                    )}
                    {deletemessage && (
                      <span className="text-danger mt-4">
                        Please choose atleast one file
                      </span>
                    )}
                    {allfile && (
                      <div
                        className="row mt-4 scroll-text-box "
                        style={{ height: "70vh" }}
                      >
                        {Array.isArray(getfolder) && getfolder.length > 0 ? (
                          getfolder.map((item, index) => (
                            <div
                              className="form-check d-flex gap-3 align-items-start border-bottom py-2"
                              key={index}
                            >
                              <input
                                className="form-check-input"
                                type="checkbox"
                                key={index}
                                onChange={(event) =>
                                  handleCheckboxChange_fff(
                                    item.fid,
                                    item.name,
                                    checkboxes.findIndex(
                                      (checkbox) => checkbox.fid === item.fid
                                    ),
                                    `${appUrl}/${item.folder_name}_${item.folder_id}/${item.name}`,
                                    event
                                  )
                                }
                                checked={checkboxes.some(
                                  (checkbox) =>
                                    checkbox.fid === item.fid &&
                                    checkbox.isChecked
                                )}
                              />

                              <Link
                                to="javascript:void(0)"
                                onClick={() =>
                                  handleClick(
                                    `${appUrl}/${item.folder_name}_${item.folder_id}/${item.name}`,
                                    item.name
                                  )
                                }
                              >
                                <img
                                  src={require("../../assets/images/4347587.png")}
                                  width="30"
                                  alt="Image"
                                />{" "}
                                {item.name}
                              </Link>

                              {/* <Link
                              to="javascript:void(0)"
                              title="Delete folder"
                              onClick={() =>
                                deletehandle(item.id, item.folder_id)
                              }
                            >
                              <FaTrash className="text-danger" />
                            </Link> */}
                            </div>
                          ))
                        ) : (
                          <div>
                            <p>No data available</p>
                          </div>
                        )}
                      </div>
                    )}
                    {allemp && (
                      <div
                        className="row mt-4 scroll-text-box "
                        style={{ height: "300px" }}
                      >
                        {Array.isArray(allemployee) &&
                        allemployee.length > 0 ? (
                          allemployee.map((item, index) => (
                            <div
                              className="form-check d-flex gap-3 align-items-center border-bottom py-2"
                              key={index}
                            >
                              <input
                                className="form-check-input"
                                type="checkbox"
                                key={index}
                                onChange={(event) =>
                                  handleCheckboxChange_emp(
                                    item.first_name +
                                      " " +
                                      item.middle_name +
                                      " " +
                                      item.last_name,
                                    index,
                                    item.id,
                                    event
                                  )
                                }
                                checked={
                                  (checkboxes_emp[index] &&
                                    checkboxes_emp[index].isChecked) ||
                                  false
                                }
                              />
                              <div className="border bg-danger text-white rounded-circle p-2 ">
                                {capitalizeFirstLetter(item.first_name)}{" "}
                                {capitalizeFirstLetter(item.middle_name)}{" "}
                                {capitalizeFirstLetter(item.last_name)}
                              </div>
                              <Link to="javascript:void(0)">
                                {item.first_name} {item.middle_name}{" "}
                                {item.last_name}
                              </Link>

                              {/* <Link
                              to="javascript:void(0)"
                              title="Delete folder"
                              onClick={() =>
                                deletehandle(item.id, item.folder_id)
                              }
                            >
                              <FaTrash className="text-danger" />
                            </Link> */}
                            </div>
                          ))
                        ) : (
                          <div>
                            <p>No data available</p>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  {del1 && (
                    <>
                      <ReactDialogBox
                        closeBox={closeBox}
                        closeButtonColor="white"
                        bodyTextColor="black"
                        bodyHeight="280px"
                      >
                        <style>
                          {`
                          header._br_-q {
                            display: none;
                          }
                        `}
                        </style>
                        <div style={{ height: "250px", paddingTop: "20px" }}>
                          <div className="d-flex flex-column gap-3 bg-white p-4 inperson mt-4">
                            <div className="text-center">
                              <h4 className="text-danger">Confirmation</h4>
                              <div className="d-flex flex-column gap-2 mt-2">
                                {Array.isArray(checkboxes) &&
                                checkboxes.length > 0 ? (
                                  checkboxes.map((item, index) => (
                                    <Link to="javascript:void(0)" className="">
                                      <img
                                        src={require("../../assets/images/4347587.png")}
                                        width="20"
                                        alt="Image"
                                      />{" "}
                                      {item.fname}
                                    </Link>
                                  ))
                                ) : (
                                  <div>
                                    <p>No data available</p>
                                  </div>
                                )}
                              </div>
                              <br />

                              <div className="d-flex justify-content-between ">
                                <button
                                  onClick={opendel2}
                                  type="button"
                                  className=" btn btn-danger"
                                >
                                  Yes
                                </button>
                                <button
                                  type="button"
                                  onClick={closedel1}
                                  className=" btn btn-success"
                                >
                                  No
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </ReactDialogBox>
                    </>
                  )}
                  {del2 && (
                    <>
                      <ReactDialogBox
                        closeBox={closeBox}
                        closeButtonColor="white"
                        bodyTextColor="black"
                        bodyHeight="250px"
                      >
                        <style>
                          {`
                          header._br_-q {
                            display: none;
                          }
                        `}
                        </style>
                        <div
                          style={{
                            height: "250px",
                            paddingTop: "20px",
                            overflow: "scroll",
                          }}
                        >
                          <div className="d-flex flex-column gap-3 bg-white p-4 inperson mt-4">
                            <div className="text-center">
                              <h4 className="text-danger"></h4>
                              <div className="d-flex justify-content-center gap-4">
                                <div className="d-flex flex-column gap-2 mt-2">
                                  {Array.isArray(checkboxes) &&
                                  checkboxes.length > 0 ? (
                                    checkboxes.map((item, index) => (
                                      <Link
                                        to="javascript:void(0)"
                                        className=""
                                      >
                                        <img
                                          src={require("../../assets/images/4347587.png")}
                                          width="20"
                                          alt="Image"
                                        />{" "}
                                        {item.fname}
                                      </Link>
                                    ))
                                  ) : (
                                    <div>
                                      <p>No data available</p>
                                    </div>
                                  )}
                                </div>
                                <div className="d-flex flex-column gap-2 mt-2">
                                  {Array.isArray(checkboxes_emp) &&
                                  checkboxes_emp.length > 0 ? (
                                    checkboxes_emp.map((item, index) =>
                                      item != undefined ? (
                                        <Link
                                          key={index}
                                          to="javascript:void(0)"
                                          className="text-start"
                                        >
                                          {index + 1}. {item.name}
                                        </Link>
                                      ) : null
                                    )
                                  ) : (
                                    <div>
                                      <p>No data available</p>
                                    </div>
                                  )}
                                </div>
                              </div>

                              <br />

                              <div className="d-flex justify-content-center ">
                                <Link to="#" onClick={sendallfile}>
                                  Send{" "}
                                  <img
                                    src={require("../../assets/images/5323656.png")}
                                    width="30"
                                    alt="Image"
                                  />
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </ReactDialogBox>
                    </>
                  )}
                  {del3 && (
                    <>
                      <ReactDialogBox
                        closeBox={closeBox}
                        closeButtonColor="white"
                        bodyTextColor="black"
                        bodyHeight="280px"
                      >
                        <style>
                          {`
                          header._br_-q {
                            display: none;
                          }
                        `}
                        </style>
                        <div style={{ height: "250px", paddingTop: "20px" }}>
                          <div className="d-flex flex-column gap-3 bg-white p-4 inperson mt-4">
                            <div className="text-center">
                              <h4 className="text-danger"></h4>
                              <div className="d-flex justify-content-center gap-4">
                                <div className="d-flex flex-column  gap-2 mt-2 ">
                                  <img
                                    src={require("../../assets/images/5156600.png")}
                                    width="50"
                                    style={{ margin: "auto" }}
                                    alt="Image"
                                  />{" "}
                                  <p className="mt-2">
                                    You have successfully sent the files
                                  </p>
                                </div>
                              </div>

                              <br />
                            </div>
                          </div>
                        </div>
                      </ReactDialogBox>
                    </>
                  )}
                  {tab1 && (
                    <>
                      <ReactDialogBox
                        closeBox={closeBox}
                        closeButtonColor="white"
                        bodyTextColor="black"
                        bodyHeight="280px"
                      >
                        <style>
                          {`
                          header._br_-q {
                            display: none;
                          }
                        `}
                        </style>
                        <div style={{ height: "250px", paddingTop: "20px" }}>
                          <div className="d-flex flex-column gap-3 bg-white p-4 inperson mt-4">
                            <div className="text-center">
                              <img
                                src={require("../../assets/images/3997872.png")}
                                width="100"
                                alt="Image"
                              />
                              <h4 className="text-danger">Confirmation</h4>
                              <span>
                                <b>
                                  Are You Sure You Want To Delete This Files
                                </b>
                              </span>
                              <br />
                              <span
                                className="color-grey"
                                style={{ fontSize: "12px" }}
                              >
                                If you delete this files, you might lose the
                                files in it <br />
                                and won't able to retrieve them again.
                                <br />
                                Think before deleting the files.
                              </span>
                              <div className="d-flex justify-content-between ">
                                <button
                                  onClick={opentab2}
                                  type="button"
                                  className=" btn btn-danger"
                                >
                                  Yes
                                </button>
                                <button
                                  type="button"
                                  onClick={closetab1}
                                  className=" btn btn-success"
                                >
                                  No
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </ReactDialogBox>
                    </>
                  )}
                  {tab2 && (
                    <>
                      <ReactDialogBox
                        closeBox={closeBox}
                        closeButtonColor="white"
                        bodyTextColor="black"
                        bodyHeight="280px"
                      >
                        <style>
                          {`
                          header._br_-q {
                            display: none;
                          }
                        `}
                        </style>
                        <div style={{ height: "250px", paddingTop: "20px" }}>
                          <div className="d-flex flex-column gap-3 bg-white p-4 inperson mt-4">
                            <div className="text-center">
                              <img
                                src={require("../../assets/images/2959016.png")}
                                width="100"
                                alt="Image"
                              />
                              <h4 className="text-danger">Confirmation</h4>
                              <span>
                                <b>Still You have chance by clicking No</b>
                              </span>

                              <div className="d-flex justify-content-between ">
                                <button
                                  type="button"
                                  onClick={closetab2}
                                  className=" btn btn-success"
                                >
                                  No
                                </button>
                                <button
                                  type="button"
                                  className=" btn btn-danger"
                                  onClick={deletefiles}
                                >
                                  Delete
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </ReactDialogBox>
                    </>
                  )}
                  {tab3 && (
                    <>
                      <ReactDialogBox
                        closeBox={closeBox}
                        closeButtonColor="white"
                        bodyTextColor="black"
                        bodyHeight="280px"
                      >
                        <style>
                          {`
                          header._br_-q {
                            display: none;
                          }
                        `}
                        </style>
                        <div style={{ height: "250px", paddingTop: "20px" }}>
                          <div className="d-flex flex-column gap-3 bg-white p-4 inperson mt-4">
                            <div className="text-center">
                              <img
                                src={require("../../assets/images/2441914.png")}
                                width="100"
                                alt="Image"
                              />
                              <h4 className="text-danger">Deleted</h4>
                              <span>
                                You have successfully deleted the file <br />
                                that you don't want
                              </span>
                            </div>
                          </div>
                        </div>
                      </ReactDialogBox>
                    </>
                  )}
                  {isOpen && (
                    <>
                      <ReactDialogBox
                        closeBox={closeBox}
                        modalWidth="38%"
                        headerBackgroundColor="red"
                        headerTextColor="white"
                        headerHeight="40"
                        closeButtonColor="white"
                        bodyBackgroundColor="white"
                        bodyTextColor="black"
                        bodyHeight="250px"
                        headerText="Upload File"
                      >
                        <div style={{ height: "200px", paddingTop: "20px" }}>
                          <div className="d-flex flex-column gap-3 bg-white  p-4 inperson mt-4">
                            <form
                              action="javascript:void(0)"
                              method="post"
                              onSubmit={handleSubmit}
                            >
                              <div className="d-flex flex-column gap-2">
                                <div className="mb-3">
                                  <label
                                    for="exampleFormControlInput1"
                                    className="form-label"
                                  >
                                    Upload File
                                  </label>
                                  <input
                                    type="file"
                                    name="file"
                                    multiple
                                    required
                                    onChange={handleFileChange}
                                    className="form-control"
                                    id="exampleFormControlInput1"
                                  />
                                </div>
                                <div className="mb-3">
                                  <button
                                    type="submit"
                                    style={{ background: "#F71701 !important" }}
                                    className={`btn btn-danger ${
                                      docUp1 === true ? "docup" : ""
                                    }`}
                                  >
                                    Submit
                                  </button>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </ReactDialogBox>
                    </>
                  )}
                  {dis && (
                    <Link to="#" onClick={nextstep}>
                      Next{" "}
                      <img
                        src={require("../../assets/images/2413768.png")}
                        width="30"
                        alt="Image"
                      />
                    </Link>
                  )}
                  {diss && (
                    <Link to="#" onClick={delete_del1}>
                      Send{" "}
                      <img
                        src={require("../../assets/images/5323656.png")}
                        width="30"
                        alt="Image"
                      />
                    </Link>
                  )}
                  <span className="fs-20 text-center"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <AdminFooter />
      </div>
    </>
  );
};
