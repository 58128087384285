import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import useKeyboardShortcutBlocker from "./KeyboardShortcutBlocker";
import { useNavigate, Link } from "react-router-dom";
import Slider from "react-slick";
import "./assets/banner/scss/defult.css";
import "./assets/banner/scss/main.css";
export const Banner = () => {
  const currentYear = new Date().getFullYear();
  const navigate = useNavigate();

  const changeFavicon = (url) => {
    const favicon = document.querySelector('link[rel="icon"]');
    if (favicon) {
      favicon.href = url;
    }
  };
  //useKeyboardShortcutBlocker();

  const audioRef = useRef(null); // Ref for the audio element

  // Function to handle user input changes

  // Function to start recording audio

  // Function to stop recording audio
  const [checklogin, setchecklogin] = useState(false);
  useEffect(() => {
    changeFavicon(require("./assets/admin/images/f-icon.png"));
    const userStr = JSON.parse(localStorage.getItem("users"));
    document.title = "Jlmining Page";
    if (userStr !== null) {
      setchecklogin(true);
      navigate("/home");
    }
  }, [checklogin]);
  var settings_2 = {
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: false,
    dots: false,
    infinite: true,
    speed: 1000,
    autoplaySpeed: 1000,
    autoplay: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: "0px",
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: "0px",
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <>
      <header>
        <nav className="navbar navbar-expand-md bg-body-tertiary">
          <div className="container-xl">
            <a className="navbar-branda" href="#">
              <img
                className="w-100 h-100"
                src={require("./assets/banner/images/logo.png")}
                alt="Logo"
              />
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse "
              id="navbarSupportedContent"
            >
              <div className="d-flex flex-md-row flex-column py-md-0 py-4 ms-auto gap-4 login_btn">
                <a className href="/login">
                  Login
                </a>
                <a href="/register">Register</a>
              </div>
            </div>
          </div>
        </nav>
      </header>
      <section className="d-block main_banner py-5">
        <div className="container-xl h-100">
          <div className="d-flex h-100 justify-content-center  align-items-center">
            <div className="banner_text">
              <h1>Join us Today. Light the Way to a Brighter Tomorrow.</h1>
            </div>
          </div>
        </div>
      </section>
      <section className="d-block py-5 client">
        <div className="container-xl">
          <div className="row gy-4 align-items-end">
            <div className="col-lg-4">
              <div className="px-md-4">
                <div className="d-flex flex-column gap-3 justify-content-center align-items-center client_box">
                  <div className="d-flex flex-column gap-2 justify-content-center align-items-center">
                    <div className="d-block icon_social">
                      <img
                        className="w-100 h-100"
                        src={require("./assets/banner/images/icon1.png")}
                        alt="images"
                      />
                    </div>
                    <h3>EMPLOYEE</h3>
                  </div>
                  <p>
                    Create your profile in our recruitment database for
                    exclusive access to potential job opportunities.
                  </p>
                  <a className="join_btn" href="#">
                    Join the wait List
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="d-flex flex-column gap-4 justify-content-center align-items-center client_box mid_client">
                <div className="d-flex flex-column gap-3 justify-content-center align-items-center">
                  <div className="d-block icon_social">
                    <img
                      className="w-100 h-100"
                      src={require("./assets/banner/images/icon3.png")}
                      alt="images"
                    />
                  </div>
                  <h3>CLIENT</h3>
                </div>
                <p>
                  Explore our services to discover how JL Mining can support
                  your future recruitment needs
                </p>
                <a className="join_btn" href="#">
                  View our Services
                </a>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="px-md-4">
                <div
                  className="d-flex flex-column gap-3 justify-content-center align-items-center client_box"
                  style={{ height: "325px" }}
                >
                  <div className="d-flex flex-column gap-2 justify-content-center align-items-center">
                    <div className="d-block icon_social">
                      <img
                        className="w-100 h-100"
                        src={require("./assets/banner/images/icon2.png")}
                        alt="images"
                      />
                    </div>
                    <h3>STAFF ONLY</h3>
                  </div>
                  <p>Login with your User Name and password </p>
                  <a className="join_btn" href="#">
                    Staff Login
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="d-block py-5 our-partner">
        <div className="container-xl overflow-hidden">
          <div className="d-flex flex-column gap-2 pb-3">
            <h4>Our Trusted Partners</h4>
            <p>
              We deliver great results and we have cultivated long-term
              relationships with leading brands and companies.
            </p>
          </div>
          <Slider {...settings_2} className="logo-slider ">
            <div className="logo-img d-block">
              <img
                className="w-100 h-100 object-fit-contain"
                src={require("./assets/banner/images/logo-hss.png")}
                alt="logo"
              />
            </div>
            <div className="logo-img d-block">
              <img
                className="w-100 h-100 object-fit-contain"
                src={require("./assets/banner/images/logo-bird.webp")}
                alt="logo"
              />
            </div>
            <div className="logo-img d-block">
              <img
                className="w-100 h-100 object-fit-contain"
                src={require("./assets/banner/images/jl-logo.png")}
                alt="logo"
              />
            </div>
            <div className="logo-img d-block">
              <img
                className="w-100 h-100 object-fit-contain"
                src={require("./assets/banner/images/logo-hunta.png")}
                alt="logo"
              />
            </div>
            <div className="logo-img-h d-block">
              <img
                className="w-100 h-100 object-fit-contain"
                src={require("./assets/banner/images/logo-minotaur.png")}
                alt="logo"
              />
            </div>
            <div className="logo-img d-block">
              <img
                className="w-100 h-100 object-fit-contain"
                src={require("./assets/banner/images/logo-walc.png")}
                alt="logo"
              />
            </div>
          </Slider>
        </div>
      </section>
      <section className="d-block mobile_screen py-5 ">
        <div className="container-xl">
          <div className="row gy-4 align-items-center">
            <div className="col-md-6">
              <div className="d-flex flex-column gap-0">
                <h4>Jl Mining Portal has</h4>
                <h5>
                  Every thing at <span>One Place</span>
                </h5>
              </div>
            </div>
            <div className="col-md-6">
              <div className="d-flex flex-column gap-4">
                <div className="d-flex flex-sm-row flex-column align-items-center gap-4">
                  <div className="d-block">
                    <img
                      className="w-100 h-100"
                      src={require("./assets/banner/images/s2.png")}
                      alt="img"
                    />
                  </div>
                  <div className="d-block">
                    <img
                      className="w-100 h-100"
                      src={require("./assets/banner/images/s1.png")}
                      alt="img"
                    />
                  </div>
                </div>
                <div className="d-flex flex-sm-row flex-column align-items-center gap-4">
                  <div className="d-block ">
                    <img
                      className="w-100 h-100"
                      src={require("./assets/banner/images/s3.png")}
                      alt="img"
                    />
                  </div>
                  <div className="d-block">
                    <img
                      className="w-100 h-100"
                      src={require("./assets/banner/images/s4.png")}
                      alt="img"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="d-block py-5 feature">
        <div className="container-lg">
          <div className="row gy-3 ">
            <div className="col-12">
              <div className="d-block py-3">
                <h4>Features</h4>
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex flex-column justify-content-center align-items-center gap-4 feat-box">
                <div className="d-block icon-fit_f">
                  <img
                    className="w-100 h-100"
                    src={require("./assets/banner/images/time.png")}
                    alt="images"
                  />
                </div>
                <div className="d-flex flex-column gap-2 text-center">
                  <h6>Time Management:</h6>
                  <p>
                    Employees can adjust and arrange dates in their timesheets
                    and submit work hours directly through the portal.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex flex-column justify-content-center align-items-center gap-4 feat-box">
                <div className="d-block icon-fit_f">
                  <img
                    className="w-100 h-100"
                    src={require("./assets/banner/images/client1.png")}
                    alt="images"
                  />
                </div>
                <div className="d-flex flex-column gap-2 text-center">
                  <h6>Client Verification:</h6>
                  <p>
                    Clients can view and verify employee hours, and provide
                    signatures, ensuring the management at JL Mining knows that
                    hours are authenticated as per the portal’s records.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex flex-column justify-content-center align-items-center gap-4 feat-box">
                <div className="d-block icon-fit_f">
                  <img
                    className="w-100 h-100"
                    src={require("./assets/banner/images/patroll.png")}
                    alt="images"
                  />
                </div>
                <div className="d-flex flex-column gap-2 text-center">
                  <h6>Payroll:</h6>
                  <p>
                    Based on the verified hours, we handle employee payments.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex flex-column justify-content-center align-items-center gap-4 feat-box">
                <div className="d-block icon-fit_f">
                  <img
                    className="w-100 h-100"
                    src={require("./assets/banner/images/access.png")}
                    alt="images"
                  />
                </div>
                <div className="d-flex flex-column gap-2 text-center">
                  <h6>Access to Information:</h6>
                  <p>
                    Employees can access on-site documents, view machinery
                    manuals, and obtain client details and location information,
                    including emergency contacts and the nearest town.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex flex-column justify-content-center align-items-center gap-4 feat-box">
                <div className="d-block icon-fit_f">
                  <img
                    className="w-100 h-100"
                    src={require("./assets/banner/images/training.png")}
                    alt="images"
                  />
                </div>
                <div className="d-flex flex-column gap-2 text-center">
                  <h6>Training:</h6>
                  <p>
                    Online training is available within the portal, allowing
                    employees to earn certificates that ease their workplace
                    integration.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="d-flex flex-column justify-content-center align-items-center gap-4 feat-box">
                <div className="d-block icon-fit_f">
                  <img
                    className="w-100 h-100"
                    src={require("./assets/banner/images/safety.png")}
                    alt="images"
                  />
                </div>
                <div className="d-flex flex-column gap-2 text-center">
                  <h6>Safety Reporting:</h6>
                  <p>
                    The portal supports the reporting of hazards, incidents, and
                    near-misses.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="d-block work_ji py-5">
        <div className="container-xl">
          <div className="row gy-5">
            <div className="col-md-6">
              <div className="d-flex flex-column gap-3">
                <h4>Work with Jl Mining Could Platform </h4>
                <p>designed for Mining Industry</p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="d-flex flex-column gap-3 video_play">
                <a className="let_start" href="#">
                  Lets get Start
                </a>
                <h6>Watch the video how it works</h6>
                <iframe
                  height="315"
                  src="https://www.youtube.com/embed/wDchsz8nmbo?si=KuuUQy5wKVmy1mqc"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
