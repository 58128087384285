import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Header } from "../Header";
import { Footer } from "../Footer";
import { Startcourse_leftmenu } from "./Startcourse_leftmenu";
import "../assets/ohs/admin.css";
export const Startcourse = () => {
  const currentYear = new Date().getFullYear();
  const navigate = useNavigate();
  const [unicode, setunicode] = useState("");
  const [isCourse, setCourse] = useState("");
  const { id } = useParams();
  const changeFavicon = (url) => {
    const favicon = document.querySelector('link[rel="icon"]');
    if (favicon) {
      favicon.href = url;
    }
  };

  useEffect(() => {
    const userStr = JSON.parse(localStorage.getItem("users"));

    if (userStr === null) {
      window.location.href = "/login";
    }
    changeFavicon(require("../assets/admin/images/f-icon.png"));

    document.title = "Start Course";
    if (id === undefined) {
      setunicode("");
      window.location.href = "/login";
    } else {
      setunicode(id);
    }
    let formdata = {
      code: id,
    };
    axios
      .post("https://jlmining.app/getcourseDetail", formdata, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log(res.data);
        console.log("cor");
        if (res.data.row.length > 0) {
          setCourse(res.data.row[0]);
        }
      })
      .catch((err) => {});
  }, [id]);

  return (
    <>
      <Header />
      <div className="top-main">
        <section className="health-block d-block">
          <div className="overlay-box d-flex flex-column h-100">
            <div className="container-lg my-auto">
              <div className="row justify-content-center">
                <div className="col-md-6">
                  <div className="d-flex flex-column gap-4 course-textblock">
                    <h1>{isCourse.title}</h1>
                    <div className="start-btn">
                      {isCourse.slug ===
                        "health-and-safety-responsibilities" && (
                        <Link
                          to={`/course/healthresponsibility/${unicode}`}
                          className="anilink"
                        >
                          Start Course
                        </Link>
                      )}
                      {isCourse.slug === "health-and-safety-awareness" && (
                        <Link
                          to={`/lesson1/${isCourse.slug}/${unicode}`}
                          className="anilink"
                        >
                          Start Course
                        </Link>
                      )}
                      {isCourse.slug ===
                        "worker-induction-emergency-preparedness" && (
                        <Link
                          to={`/course/${isCourse.slug}/${unicode}`}
                          className="anilink"
                        >
                          Start Course
                        </Link>
                      )}
                      {isCourse.slug ===
                        "workplace-mental-health-awareness" && (
                        <Link
                          to={`/course/${isCourse.slug}/${unicode}`}
                          className="anilink"
                        >
                          Start Course
                        </Link>
                      )}
                      {isCourse.slug === "display-screen-equipment" && (
                        <Link
                          to={`/course/${isCourse.slug}/${unicode}`}
                          className="anilink"
                        >
                          Start Course
                        </Link>
                      )}
                      {isCourse.slug === "hazardous-chemicals" && (
                        <Link
                          to={`/course/${isCourse.slug}/${unicode}`}
                          className="anilink"
                        >
                          Start Course
                        </Link>
                      )}
                      {isCourse.slug === "manual-handling" && (
                        <Link
                          to={`/course/${isCourse.slug}/${unicode}`}
                          className="anilink"
                        >
                          Start Course
                        </Link>
                      )}
                      {isCourse.slug === "asbestos-awareness" && (
                        <Link
                          to={`/course/${isCourse.slug}/${unicode}`}
                          className="anilink"
                        >
                          Start Course
                        </Link>
                      )}
                      {isCourse.slug === "risk-assessment" && (
                        <Link
                          to={`/course/${isCourse.slug}/${unicode}`}
                          className="anilink"
                        >
                          Start Course
                        </Link>
                      )}
                      {isCourse.slug === "driving-for-work" && (
                        <Link
                          to={`/course/${isCourse.slug}/${unicode}`}
                          className="anilink"
                        >
                          Start Course
                        </Link>
                      )}
                      {isCourse.slug === "remote-isolated-work" && (
                        <Link
                          to={`/course/${isCourse.slug}/${unicode}`}
                          className="anilink"
                        >
                          Start Course
                        </Link>
                      )}
                      {isCourse.slug === "working-at-heights" && (
                        <Link
                          to={`/course/${isCourse.slug}/${unicode}`}
                          className="anilink"
                        >
                          Start Course
                        </Link>
                      )}
                      {isCourse.slug === "noise-awareness" && (
                        <Link
                          to={`/course/${isCourse.slug}/${unicode}`}
                          className="anilink"
                        >
                          Start Course
                        </Link>
                      )}
                      {isCourse.slug === "first-aid-awareness" && (
                        <Link
                          to={`/course/${isCourse.slug}/${unicode}`}
                          className="anilink"
                        >
                          Start Course
                        </Link>
                      )}
                      {isCourse.slug ===
                        "preparing-safe-work-method-statements" && (
                        <Link
                          to={`/course/${isCourse.slug}/${unicode}`}
                          className="anilink"
                        >
                          Start Course
                        </Link>
                      )}
                      {isCourse.slug === "silica-awareness" && (
                        <Link
                          to={`/course/${isCourse.slug}/${unicode}`}
                          className="anilink"
                        >
                          Start Course
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {isCourse.slug === "health-and-safety-awareness" && (
          <section className="bright-content-block py-5">
            <div className="container-lg">
              <div className="row justify-content-center">
                <div className="col-md-6">
                  <div className="d-flex flex-column gap-4 text-block">
                    <h2>
                      This course will take approximately 40 minutes, please
                      ensure you have adequate time to complete.
                    </h2>
                    <div className="d-flex flex-column gap-2">
                      <p>
                        Thousands of workers are injured on the job every year.
                        Some of these injuries are serious and even fatal.
                        Reducing the occurrence of workplace injuries is one of
                        the main objectives of health and safety.
                      </p>
                    </div>
                    <p>
                      This course will help you continue your learning and
                      reinforce key messages about awareness. Additionally, it
                      will provide you with tips and resources for reducing risk
                      and avoiding these on-the-job injuries.
                    </p>
                    <p>
                      <b>
                        To successfully complete this course, you must view each
                        lesson and pass the final assessment.
                      </b>
                    </p>
                    <p>Let's get started!</p>
                  </div>
                  <Startcourse_leftmenu />
                </div>
              </div>
            </div>
          </section>
        )}
        {isCourse.slug === "health-and-safety-responsibilities" && (
          <section className="bright-content-block py-5">
            <div className="container-lg">
              <div className="row justify-content-center">
                <div className="col-md-6">
                  <div className="d-flex flex-column gap-4 text-block">
                    <p>
                      This course is to reinforce your learning of your health
                      and safety responsibilities and in addition it will guide
                      you on how to use your resources to eliminate or minimise
                      risks at the workplace.
                    </p>
                    <h2>
                      To successfully complete this course, you must view each
                      lesson and pass the final assessment.{" "}
                    </h2>
                    <div className="d-flex flex-column gap-2">
                      <p>
                        For the purpose of this course, workers mean any person
                        undertaking work for a business or enterprise and
                        includes employees and staff, and organisations mean
                        employers and persons conducting a business or
                        undertaking.
                      </p>
                    </div>
                    <p>
                      Let's get started! This course is not suitable for use
                      with Internet Explorer, you must also ensure cookies are
                      enabled.
                    </p>
                  </div>
                  <Startcourse_leftmenu />
                </div>
              </div>
            </div>
          </section>
        )}
        {isCourse.slug === "worker-induction-emergency-preparedness" && (
          <section className="bright-content-block py-5">
            <div className="container-lg">
              <div className="row justify-content-center">
                <div className="col-md-6">
                  <div className="d-flex flex-column gap-4 text-block">
                    <h2>
                      This course will take approximately 45 minutes, please
                      ensure you have adequate time to complete.
                    </h2>
                    <p>
                      An emergency plan must be prepared for all workplaces. It
                      must be suitable and appropriate for the workplace and the
                      types of emergencies that may arise.
                    </p>

                    <div className="d-flex flex-column gap-2">
                      <p>
                        To ensure the emergency plan is effective, workers must
                        be provided sufficient information in order to be
                        prepared for any emergency situation at the workplace.
                        This course will assist workers with a general
                        understanding of why evacuations may be necessary, what
                        occurs during an emergency and an overview of worker
                        responsibilities.
                      </p>
                    </div>
                    <p>
                      During this course, participants will learn about: What an
                      emergency plan is and the important principles to be
                      considered when establishing an emergency plan. What is an
                      emergency and the need for emergency planning and
                      preparedness training. Their health and safety obligations
                      and responsibilities. How to put plans in place to
                      practice how to respond to an emergency.
                    </p>
                    <p>
                      <strong>Note:</strong> Nothing in this course, either
                      stated or implied, is intended to conflict with local or
                      state/territory government obligations in relation to
                      emergency planning or any other specific requirements
                      placed upon the Organisation by the emergency services
                      and/or any applicable emergency management legislation.
                    </p>
                    <h2>
                      To successfully complete this course, you must view each
                      lesson and pass the final assessment.
                    </h2>
                    <p>Let's get started!</p>
                  </div>
                  <Startcourse_leftmenu />
                </div>
              </div>
            </div>
          </section>
        )}
        {isCourse.slug === "workplace-mental-health-awareness" && (
          <section className="bright-content-block py-5">
            <div className="container-lg">
              <div className="row justify-content-center">
                <div className="col-md-6">
                  <div className="d-flex flex-column gap-4 text-block">
                    <h2>
                      This course will take approximately 45 minutes, please
                      ensure you have adequate time to complete.
                    </h2>
                    <p>
                      Mental health includes the emotional, psychological and
                      social wellbeing. Creating a positive impact on mental
                      health (psychological safety) for all workers is important
                      for our workplace.
                    </p>

                    <div className="d-flex flex-column gap-2">
                      <p>
                        The working environment can often present hazards that
                        may impact on the mental health of workers, potentially
                        causing the worker to sustain a psychological injury or
                        experience an exacerbation of a pre-existing condition.
                        This may occur at a physical workplace, or any location
                        or situation related to work or in which work is
                        performed.
                      </p>
                    </div>
                    <p>
                      This course is designed for all workers to increase
                      awareness of the hazards and risks as well as the controls
                      related to mental health in the workplace, support
                      wellbeing and promote workplace mental health.
                    </p>
                    <p>
                      Note that the course is only intended as a useful
                      reference to gain a general understanding of workplace
                      mental health risks and does not extend any of the
                      existing duties, but rather explain how to meet those
                      duties. It is not intended as professional and/or medical
                      advice in addressing mental health injuries or to be in
                      conflict with any such advice.
                    </p>
                    <h2>
                      To successfully complete this course, you must view each
                      lesson and pass the final assessment.
                    </h2>
                    <p>Let's get started!</p>
                  </div>
                  <Startcourse_leftmenu />
                </div>
              </div>
            </div>
          </section>
        )}
        {isCourse.slug === "display-screen-equipment" && (
          <section className="bright-content-block py-5">
            <div className="container-lg">
              <div className="row justify-content-center">
                <div className="col-md-6">
                  <div className="d-flex flex-column gap-4 text-block">
                    <h2>
                      This course will take approximately 25 minutes, please
                      ensure you have adequate time to complete.
                    </h2>
                    <p>
                      The aim of this course is to enable personnel to be both
                      competent and confident to carry out suitable assessments
                      in relation to display screen equipment (DSE) and
                      workstations to ensure they comply with the relevant
                      regulations.
                    </p>

                    <h2>
                      To successfully complete this course, you must view each
                      lesson and pass the final assessment.
                    </h2>
                    <p>Let's get started!</p>
                  </div>
                  <Startcourse_leftmenu />
                </div>
              </div>
            </div>
          </section>
        )}
        {isCourse.slug === "hazardous-chemicals" && (
          <section className="bright-content-block py-5">
            <div className="container-lg">
              <div className="row justify-content-center">
                <div className="col-md-6">
                  <div className="d-flex flex-column gap-4 text-block">
                    <h2>
                      This course will take approximately 40 minutes, please
                      ensure you have adequate time to complete.
                    </h2>
                    <p>
                      This course is available to all workers who would benefit
                      from knowledge on hazardous chemicals that may cause harm
                      to a person's health. It covers the basics on identifying,
                      measuring and controlling exposure to harmful hazardous
                      chemicals.
                    </p>

                    <h2>
                      To successfully complete this course, you must view each
                      lesson and pass the final assessment.
                    </h2>
                    <p>
                      For the purposes of this course, the following terms are
                      defined as:
                    </p>
                    <p>
                      Airborne contaminant means a contaminant in the form of a
                      fume, mist, gas, vapour or dust, and includes
                      mircro-organisms.
                    </p>
                    <p>
                      Hazardous chemical includes the definition of a hazardous
                      substance and means a substance, mixture or article that
                      satisfies the criteria for being hazardous under the
                      Global Harmonised System (GHS).
                    </p>
                    <p>Let's get started!</p>
                  </div>
                  <Startcourse_leftmenu />
                </div>
              </div>
            </div>
          </section>
        )}
        {isCourse.slug === "manual-handling" && (
          <section className="bright-content-block py-5">
            <div className="container-lg">
              <div className="row justify-content-center">
                <div className="col-md-6">
                  <div className="d-flex flex-column gap-4 text-block">
                    <h2>
                      This course will take approximately 25 minutes, please
                      ensure you have adequate time to complete.
                    </h2>
                    <p>
                      This course will provide those with a duty of care the
                      understanding of the risks from manual handling and the
                      appropriate techniques to reduce the risk of manual
                      handling injuries.
                    </p>

                    <h2>
                      To successfully complete this course, you must view each
                      lesson and pass the final assessment.
                    </h2>

                    <p>Let's get started!</p>
                  </div>
                  <Startcourse_leftmenu />
                </div>
              </div>
            </div>
          </section>
        )}
        {isCourse.slug === "asbestos-awareness" && (
          <section className="bright-content-block py-5">
            <div className="container-lg">
              <div className="row justify-content-center">
                <div className="col-md-6">
                  <div className="d-flex flex-column gap-4 text-block">
                    <h2>
                      Please allocate approximately 25 minutes for this course.
                      Ensure you have sufficient time to complete it.
                    </h2>
                    <p>
                      This course offers a comprehensive understanding of
                      asbestos and the legal obligations imposed on individuals
                      conducting a business or undertaking (PCBUs),
                      self-employed individuals, and subcontractors. Attendees
                      will gain the necessary skills and knowledge to
                      effectively monitor and control asbestos in their
                      workplace.
                    </p>
                    <p>
                      This course is designed for managers and personnel with
                      specific responsibilities for managing asbestos or areas
                      where asbestos is known or suspected to be present in the
                      workplace.
                    </p>

                    <h2>
                      To successfully finish this course, it is essential to
                      review each lesson thoroughly and pass the final
                      assessment.
                    </h2>

                    <p>Let's begin!</p>
                  </div>
                  <Startcourse_leftmenu />
                </div>
              </div>
            </div>
          </section>
        )}
        {isCourse.slug === "risk-assessment" && (
          <section className="bright-content-block py-5">
            <div className="container-lg">
              <div className="row justify-content-center">
                <div className="col-md-6">
                  <div className="d-flex flex-column gap-4 text-block">
                    <h2>
                      This course will take approximately 40 minutes, please
                      ensure you have adequate time to complete.
                    </h2>
                    <p>
                      Persons conducting a business or undertaking (PCBUs),
                      contractors and the self-employed all have a statutory
                      duty of care for those affected by their business
                      undertakings.
                    </p>
                    <p>
                      This course gives those with a duty of care, the skills to
                      identify the hazards and risks associated with their
                      workplace, and the knowledge to implement controls whilst
                      supporting statutory compliance. It is important to note
                      that a person can have more than one duty and more than
                      one person can have the same duty at the same time. As
                      such, this course is designed for those who may be
                      responsible for undertaking risk assessments or ensuring
                      that risks assessments are completed.
                    </p>

                    <h2>
                      To successfully complete this course, you must view each
                      lesson and pass the final assessment.
                    </h2>

                    <p>Let's get started!</p>
                  </div>
                  <Startcourse_leftmenu />
                </div>
              </div>
            </div>
          </section>
        )}
        {isCourse.slug === "driving-for-work" && (
          <section className="bright-content-block py-5">
            <div className="container-lg">
              <div className="row justify-content-center">
                <div className="col-md-6">
                  <div className="d-flex flex-column gap-4 text-block">
                    <h2>
                      This course will take approximately 25 minutes, please
                      ensure you have adequate time to complete.
                    </h2>
                    <p>
                      This course provides a general awareness of road rules,
                      common hazards and control measures when using the roads
                      for work.
                    </p>
                    <p>
                      The course is aimed at any person driving a vehicle,
                      riding a bicycle or a motorcycle in the course of their
                      work and those with responsibility for fleet management.
                    </p>
                    <p>
                      The course will promote the general rules and procedures
                      for workers driving on company business and raise
                      awareness of common hazards and control measures.
                    </p>

                    <h2>
                      To successfully complete this course, you must view each
                      lesson and pass the final assessment.
                    </h2>

                    <p>Let's get started!</p>
                  </div>
                  <Startcourse_leftmenu />
                </div>
              </div>
            </div>
          </section>
        )}
        {isCourse.slug === "remote-isolated-work" && (
          <section className="bright-content-block py-5">
            <div className="container-lg">
              <div className="row justify-content-center">
                <div className="col-md-6">
                  <div className="d-flex flex-column gap-4 text-block">
                    <h2>
                      This course will take approximately 20 minutes, please
                      ensure you have adequate time to complete.
                    </h2>
                    <p>
                      This course covers the potential hazards that relate
                      specifically to working alone or in remote locations and
                      the appropriate actions that should be taken to reduce the
                      risks.
                    </p>
                    <h2>
                      To successfully complete this course, you must view each
                      lesson and pass the final assessment.
                    </h2>

                    <p>Let's get started!</p>
                  </div>
                  <Startcourse_leftmenu />
                </div>
              </div>
            </div>
          </section>
        )}
        {isCourse.slug === "working-at-heights" && (
          <section className="bright-content-block py-5">
            <div className="container-lg">
              <div className="row justify-content-center">
                <div className="col-md-6">
                  <div className="d-flex flex-column gap-4 text-block">
                    <p>
                      This course is designed to provide essential knowledge on
                      safe procedures for working at heights and is open to all
                      workers who can benefit from it. The course will require
                      approximately 30 minutes to complete, so please make sure
                      you have enough time available. Throughout the course, you
                      will cover the necessary requirements to ensure that work
                      at heights is conducted in compliance with current legal
                      standards.
                    </p>
                    <h2>
                      To successfully finish the course, you’ll need to engage
                      with each lesson and pass the final assessment.
                    </h2>

                    <p>Let's get started!</p>
                  </div>
                  <Startcourse_leftmenu />
                </div>
              </div>
            </div>
          </section>
        )}
        {isCourse.slug === "noise-awareness" && (
          <section className="bright-content-block py-5">
            <div className="container-lg">
              <div className="row justify-content-center">
                <div className="col-md-6">
                  <div className="d-flex flex-column gap-4 text-block">
                    <p>
                      This course is expected to last around 30 minutes. Please
                      make sure you have enough time available to finish it.
                    </p>
                    <h2>Course Overview:</h2>
                    <p>
                      In this course, you will learn about noise-induced hearing
                      loss and methods for its detection. It also covers the
                      noise exposure standards that require persons conducting a
                      business or undertaking (PCBUs) to safeguard workers from
                      hazardous noise levels. Throughout this module, practical
                      strategies will be presented to reduce noise levels.
                    </p>
                    <h2>
                      To successfully complete the course, it is necessary to go
                      through each lesson and pass the final assessment.
                    </h2>

                    <p>Let's begin!</p>
                  </div>
                  <Startcourse_leftmenu />
                </div>
              </div>
            </div>
          </section>
        )}

        {isCourse.slug === "first-aid-awareness" && (
          <section className="bright-content-block py-5">
            <div className="container-lg">
              <div className="row justify-content-center">
                <div className="col-md-6">
                  <div className="d-flex flex-column gap-4 text-block">
                    <p>
                      This course will take approximately 30 minutes, please
                      ensure you have adequate time to complete.
                    </p>
                    <p>
                      This course provides you with an overview of the legal and
                      practical requirements for the provision of first aid in
                      the workplace.
                    </p>
                    <h2>
                      Upon completion of this course you will be issued with a
                      certificate of attendance. This is not a first aid
                      qualification.
                    </h2>

                    <p>Let's get started!</p>
                  </div>
                  <Startcourse_leftmenu />
                </div>
              </div>
            </div>
          </section>
        )}
        {isCourse.slug === "preparing-safe-work-method-statements" && (
          <section className="bright-content-block py-5">
            <div className="container-lg">
              <div className="row justify-content-center">
                <div className="col-md-6">
                  <div className="d-flex flex-column gap-4 text-block">
                    <h2>
                      Please allocate approximately 20 minutes for this course
                      and ensure you have enough time to finish.
                    </h2>
                    <p>
                      This course aims to provide you with an understanding of
                      what constitutes a safe work method statement and how to
                      develop one. Additionally, it will delineate the
                      distinction between a safe work method statement and a
                      risk assessment.
                    </p>
                    <p>
                      To successfully complete this course, you must finish the
                      end assessment.
                    </p>
                    <h2>
                      Please note that this course is incompatible with Internet
                      Explorer, and you must also enable cookies for optimal
                      functionality.
                    </h2>
                    <p>Let's get started!</p>
                  </div>
                  <Startcourse_leftmenu />
                </div>
              </div>
            </div>
          </section>
        )}
        {isCourse.slug === "silica-awareness" && (
          <section className="bright-content-block py-5">
            <div className="container-lg">
              <div className="row justify-content-center">
                <div className="col-md-6">
                  <div className="d-flex flex-column gap-4 text-block">
                    <h2>
                      This course will take approximately 45 to 60 minutes,
                      please ensure you have adequate time to complete.
                    </h2>
                    <p>
                      Silica is a natural substance found in most rocks, sand
                      and clay. The biggest risk to a person’s health is from
                      crystalline silica. Crystalline silica is common in the
                      construction sector as it is used in the production of
                      bricks, sandstone blocks, concrete and mortar as well as
                      stonemasons who process many types of stone. The
                      processing of silica containing products, particularly
                      abrasive or mechanical actions, such as cutting, crushing,
                      drilling, polishing, sawing or grinding generates silica
                      dust, which can be extremely hazardous to a person’s
                      health due to the size of the dust particle. This course
                      will assist workers with a general understanding of the
                      risks and controls related to crystalline silica and an
                      overview of their responsibilities.
                    </p>

                    <h2>
                      To successfully complete this course, you must view each
                      lesson and pass the final assessment.
                    </h2>
                    <p>Let's get started!</p>
                  </div>
                  <Startcourse_leftmenu />
                </div>
              </div>
            </div>
          </section>
        )}
      </div>
      <Footer />
    </>
  );
};
