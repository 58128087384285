import React, { useState, useEffect } from "react";
import axios from "axios";
import useKeyboardShortcutBlocker from "./KeyboardShortcutBlocker";
import { message, Form, Input, Button, Alert } from "antd";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { ReactDialogBox } from "react-js-dialog-box";
import $ from "jquery";
export const Header = () => {
  const currentYear = new Date().getFullYear();
  const navigate = useNavigate();
  const [succ1, setsucc1] = useState(false);
  const [succ, setsucc] = useState(false);
  const [opendialogheader1, setopendialogheader] = useState(false);
  const [opendialogheader2, setopendialogheader2] = useState(false);
  const [valueUserDetail, setValuegetuser] = useState([]);
  const [showssheader, setshowssheader] = useState(false);
  const [showsisClickedopen, setshowsisClickedopen] = useState(true);
  const [notification1, setnotification1] = useState(false);
  const [Usernotification1, setUsernotification1] = useState([]);
  const [Usernotification1_total, setUsernotification1_total] = useState(0);
  const [SelectedFile_doc, setSelectedFile_doc] = useState([]);
  const [SelectedFile_doc2, setSelectedFile_doc2] = useState(null);
  const [SelectedFile_doc3, setSelectedFile_doc3] = useState(null);
  const [SelectedFile_doc4, setSelectedFile_doc4] = useState(null);
  const [SelectedFile_doc5, setSelectedFile_doc5] = useState(null);
  const [setSelectedFile_lic, setsetSelectedFile_lic] = useState(null);
  const [SelectedFile_docarray, setSelectedFile_docarray] = useState(null);

  const [doc1, setdoc1] = useState("");
  const [doc2, setdoc2] = useState("");
  const [doc3, setdoc3] = useState("");
  const [doc4, setdoc4] = useState("");
  const [doc5, setdoc5] = useState("");
  const handleLogout = () => {
    localStorage.removeItem("users");
    navigate("/login");
  };
  const changeFavicon = (url) => {
    const favicon = document.querySelector('link[rel="icon"]');
    if (favicon) {
      favicon.href = url;
    }
  };
  const isMobileScreen = window.innerWidth <= 767.98;
  const [isDivVisible, setDivVisibility] = useState(!isMobileScreen);
  //useKeyboardShortcutBlocker();
  useEffect(() => {
    changeFavicon(require("./assets/admin/images/f-icon.png"));
    const userStr = JSON.parse(localStorage.getItem("users"));

    if (userStr === null) {
      navigate("/login");
    }
    const handleResize = () => {
      const isMobile = window.innerWidth <= 767.98;
      if (isMobile !== isDivVisible) {
        setDivVisibility(!isMobile);
      }
    };
    if (userStr === null) {
      setshowssheader(false);
      document.title = "Home Page";
    } else {
      setshowssheader(true);
      let formdata = {
        user_id: userStr[0].id,
      };

      axios
        .post("https://jlmining.app/admin/getuserdetails", formdata, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "POST,PUT",
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          var alldata = res.data.row[0];
          if (alldata.status === "Inactive") {
            navigate("/onboarding");
          }
          if (alldata === undefined) {
            localStorage.removeItem("users");
            navigate("/login");
          }
          setValuegetuser(alldata);
        })
        .catch((err) => {});
      axios
        .post("https://jlmining.app/getusernotification", formdata, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "POST,PUT",
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          var alldata = res.data.results;
          setUsernotification1(alldata);
          var unseenCount = alldata.filter(
            (notification) => notification.status === "Unseen"
          ).length;
          setUsernotification1_total(unseenCount);
        })
        .catch((err) => {});
    }

    $(window).resize(handleResize);
    alterClass();
    return () => {
      $(window).off("resize", handleResize);
    };
    console.log(SelectedFile_doc);
  }, [SelectedFile_doc]);
  const handleResize = () => {
    alterClass();
  };
  const alterClass = () => {
    const ww = document.body.clientWidth;
    if (ww < 600) {
      $(".main-admin-block").addClass("close-box");
    } else if (ww >= 601) {
      $(".main-admin-block").removeClass("close-box");
    }
  };
  const [values, setValues] = useState({
    name: "",
    email: "",
    password: "",
    contact: "",
    address: "",
  });

  const mobilepopup = () => {
    setshowsisClickedopen(!showsisClickedopen);
  };
  const fname = valueUserDetail.first_name;
  const mname = valueUserDetail.middle_name;
  const lname = valueUserDetail.last_name;
  const fLetter = fname ? fname.charAt(0) : "";
  const mLetter = mname ? mname.charAt(0) : "";
  const lLetter = lname ? lname.charAt(0) : "";
  const location = useLocation();
  const toggleDivVisibility = () => {
    setDivVisibility(!isDivVisible);
  };
  const notifica_tion = () => {
    setnotification1(!notification1);
  };
  const closeBox = () => {
    setopendialogheader(false);
    setopendialogheader2(false);
  };
  const opendoc = () => {
    setopendialogheader(true);
  };
  const openlic = () => {
    setopendialogheader2(true);
  };
  const handleFileChange_doc1 = (event, index) => {
    if (event.target.files != null) {
      const file = event.target.files[0];
      if (index === 0) {
        setdoc1(file.name);
      }
      if (index === 1) {
        setdoc2(file.name);
      }
      if (index === 2) {
        setdoc3(file.name);
      }
      if (index === 3) {
        setdoc4(file.name);
      }
      if (index === 4) {
        setdoc5(file.name);
      }

      setSelectedFile_doc((prevFiles) => {
        const newFiles = Array.isArray(prevFiles) ? [...prevFiles] : [];
        newFiles[index] = file;
        const ss = newFiles.slice(0, 2); // Ensure array length does not exceed 2
        console.log(ss);
        return ss;
      });
    }
  };

  const handleSubmitdoc = (event) => {
    event.preventDefault();
    console.log(SelectedFile_doc);
    const userStr = JSON.parse(localStorage.getItem("users"));
    const formDataa = new FormData();
    if (SelectedFile_doc !== null) {
      if (SelectedFile_doc.length > 0) {
        for (let i = 0; i < SelectedFile_doc.length; i++) {
          formDataa.append("files", SelectedFile_doc[i]);
        }
      }
    }

    formDataa.append("user_id", userStr[0].id);
    axios
      .post("https://jlmining.app/userUpdateDocfile", formDataa, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        console.log(res.data);
        if (res.data.status === 1) {
          setsucc(true);
          event.target.reset();
          setSelectedFile_doc([]);
          setSelectedFile_docarray([]);
          setdoc1("");
          setdoc2("");
          setdoc3("");
          setdoc4("");
          setdoc5("");
          setTimeout(() => {
            setsucc(false);
          }, 2500);
        }
      })
      .catch((err) => {});
  };
  const handleFileChange_lic = (event) => {
    if (event.target.files != null) {
      const files = event.target.files;
      setsetSelectedFile_lic(event.target.files[0]);
    }
  };
  const handleSubmitlic = (event) => {
    event.preventDefault();
    const userStr = JSON.parse(localStorage.getItem("users"));
    const vl = event.target;

    const formData = new FormData();
    formData.append("name", vl.name.value);
    formData.append("user_id", userStr[0].id);
    formData.append("licence_number", vl.licence_number.value);
    formData.append("expiryMonth", vl.expiryMonth.value);
    formData.append("expiryYear", vl.expiryYear.value);
    formData.append("file", setSelectedFile_lic);
    console.log(setSelectedFile_lic);

    axios
      .post("https://jlmining.app/useruploadlic", formData, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        console.log(res.data);
        event.target.reset();
        setsucc1(true);
        setTimeout(() => {
          setsucc1(false);
        }, 2500);
      })
      .catch((err) => {});
  };
  const seeallnoti = () => {
    const userStr = JSON.parse(localStorage.getItem("users"));
    let formData = {
      user_id: userStr[0].id,
    };
    console.log("sddd");
    axios
      .post("https://jlmining.app/seeAllnotification", formData, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log(res.data);
        setUsernotification1(res.data.results);
        var alldata = res.data.results;
        var unseenCount = alldata.filter(
          (notification) => notification.status === "Unseen"
        ).length;
        setUsernotification1_total(unseenCount);
      })
      .catch((err) => {});
  };
  return (
    <>
      <header
        style={{ position: "relative", left: "0", right: "0" }}
        className="d-none d-sm-block"
      >
        <div className="container-lg">
          <div className="row gy-4 align-items-center justify-content-between">
            <div className="col-3 col-md-2">
              <Link to="/home" className="logo-top">
                <img src={require("./assets/images/logo2.png")} alt="logo" />
              </Link>
            </div>
            <div className="col-md-2 d-none d-md-block">
              <Link to="/home" className="logo-top">
                <img src={require("./assets/images/hss.png")} alt="logo" />
              </Link>
            </div>

            <div className="col-9 col-md-6">
              {showssheader && (
                <div className="navbar-list align-items-center  d-flex gap-3 justify-content-end">
                  <Link
                    to="/home"
                    className={location.pathname === "/home" ? "active" : ""}
                  >
                    Home
                  </Link>
                  {/* <Link
                    to="/profile"
                    className={location.pathname === "/profile" ? "active" : ""}
                  >
                    Profile
                  </Link> */}
                  {/* <Link
                    to="/newroster"
                    className={
                      location.pathname === "/newroster" ? "active" : ""
                    }
                  >
                    Roster
                  </Link> */}
                  <Link
                    to="/currentroster"
                    className={
                      location.pathname === "/currentroster" ? "active" : ""
                    }
                  >
                    Current Roster
                  </Link>
                  <Link
                    to="/timesheet"
                    className={
                      location.pathname === "/timesheet" ? "active" : ""
                    }
                  >
                    Signature
                  </Link>
                  <Link
                    to="/clinetinformation"
                    className={
                      location.pathname === "/clinetinformation" ? "active" : ""
                    }
                  >
                    Client Info
                  </Link>
                  <Link
                    to="/ohs"
                    className={
                      location.pathname === "/ohs" ? "active fs-6" : "fs-6"
                    }
                  >
                    OHS
                  </Link>
                  <Link
                    to="/site_access"
                    className={
                      location.pathname === "/site_access"
                        ? "active fs-6"
                        : "fs-6"
                    }
                  >
                    Site Access
                  </Link>
                  <p className="user-text-icon">
                    <Link to="/profile">
                      {fLetter} {lLetter}
                    </Link>
                  </p>
                  <div
                    onClick={notifica_tion}
                    className="position-relative cursor-pointer"
                  >
                    <div className="imgcount">
                      <img
                        src={require("./assets/images/3602145.png")}
                        width="30"
                        alt="Image"
                      />
                    </div>
                    {Usernotification1_total > 0 && (
                      <span className="notcount">
                        {Usernotification1_total}
                      </span>
                    )}
                    {notification1 && (
                      <>
                        {Usernotification1.length === 0 && (
                          <div className="d-flex well-info">
                            <ul style={{ height: "57px" }}>
                              {Array.isArray(Usernotification1) &&
                              Usernotification1.length > 0 ? (
                                Usernotification1.map((item, index) => (
                                  <li key={index}>
                                    <Link>{item.message}</Link>
                                  </li>
                                ))
                              ) : (
                                <li>No result found</li>
                              )}
                            </ul>
                            <div className="d-flex gap-3 align-items-center pb-3 px-3">
                              <div className="d-flex gap-3 align-items-center">
                                <Link
                                  to="javascript:void(0)"
                                  onClick={seeallnoti}
                                >
                                  See all Notifications
                                </Link>
                              </div>
                              <div className="d-flex flex-column gap-2 align-items-center">
                                <button type="button" onClick={opendoc}>
                                  Upload Document
                                </button>
                                <button type="button" onClick={openlic}>
                                  Upload Licence
                                </button>
                              </div>
                            </div>
                          </div>
                        )}
                        {Usernotification1.length > 0 && (
                          <div className="d-flex well-info">
                            <ul>
                              {Array.isArray(Usernotification1) &&
                              Usernotification1.length > 0 ? (
                                Usernotification1.map((item, index) => (
                                  <li key={index}>
                                    <Link>{item.message}</Link>
                                  </li>
                                ))
                              ) : (
                                <li>No data available</li>
                              )}
                            </ul>
                            <div className="d-flex gap-3 align-items-center pb-3 px-3">
                              <div className="d-flex gap-3 align-items-center">
                                <Link
                                  to="javascript:void(0)"
                                  onClick={seeallnoti}
                                >
                                  See all Notifications
                                </Link>
                              </div>
                              <div className="d-flex flex-column gap-2 align-items-center">
                                <button type="button" onClick={opendoc}>
                                  Upload Document
                                </button>
                                <button type="button" onClick={openlic}>
                                  Upload Licence
                                </button>
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </div>

                  <Link className="logout" to="/logout">
                    Logout
                  </Link>
                </div>
              )}
              {!showssheader && (
                <div className="navbar-list align-items-center  d-flex gap-3 justify-content-end">
                  <Link to="/login" className="active">
                    Login
                  </Link>
                  <Link to="/register">Register</Link>
                </div>
              )}
            </div>
          </div>
        </div>
      </header>
      {/* mobile header */}
      {opendialogheader1 && (
        <ReactDialogBox
          closeBox={closeBox}
          modalWidth="40%"
          headerBackgroundColor="red"
          headerTextColor="white"
          headerHeight="50"
          closeButtonColor="white"
          bodyBackgroundColor="white"
          bodyTextColor="black"
          bodyHeight="400px"
          headerText="Upload Docmument"
        >
          <div style={{ height: "400px", overflowY: "auto" }}>
            <div className="d-flex flex-column gap-3 bg-white   p-4 inperson">
              <p className="mt-4">
                You have successfully accepted the job you applied for. Please
                upload the documents listed below{" "}
              </p>
              <form onSubmit={handleSubmitdoc} method="post">
                <div className="d-flex flex-column gap-3 py-3">
                  {succ && (
                    <p className="text-success ">File uploaded successfully</p>
                  )}
                  <div className="d-flex flex-row align-items-center gap-3 justify-content-center">
                    {doc1 && <p>{doc1}</p>}

                    <div className="position-relative Document-nm">
                      <button>Upload</button>
                      <input
                        type="file"
                        name="file"
                        onChange={(event) => handleFileChange_doc1(event, 0)}
                      />
                    </div>
                  </div>
                  <div className="d-flex flex-row align-items-center gap-3 justify-content-center">
                    {doc2 && <p>{doc2}</p>}

                    <div className="position-relative Document-nm">
                      <button>Upload</button>
                      <input
                        type="file"
                        name="file"
                        onChange={(event) => handleFileChange_doc1(event, 1)}
                      />
                    </div>
                  </div>
                  <div className="d-flex flex-row align-items-center gap-3 justify-content-center">
                    {doc3 && <p>{doc3}</p>}

                    <div className="position-relative Document-nm">
                      <button>Upload</button>
                      <input
                        type="file"
                        name="file"
                        onChange={(event) => handleFileChange_doc1(event, 2)}
                      />
                    </div>
                  </div>
                  <div className="d-flex flex-row align-items-center gap-3 justify-content-center">
                    {doc4 && <p>{doc4}</p>}

                    <div className="position-relative Document-nm">
                      <button>Upload</button>
                      <input
                        type="file"
                        name="file"
                        onChange={(event) => handleFileChange_doc1(event, 3)}
                      />
                    </div>
                  </div>
                  <div className="d-flex flex-row align-items-center gap-3 justify-content-center">
                    {doc5 && <p>{doc5}</p>}

                    <div className="position-relative Document-nm">
                      <button>Upload</button>
                      <input
                        type="file"
                        name="file"
                        onChange={(event) => handleFileChange_doc1(event, 4)}
                      />
                    </div>
                  </div>

                  <div className="d-flex justify-content-center align-items-center">
                    <div className="position-relative  Document-nm1">
                      <button>Submit</button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </ReactDialogBox>
      )}
      {opendialogheader2 && (
        <ReactDialogBox
          closeBox={closeBox}
          modalWidth="40%"
          headerBackgroundColor="red"
          headerTextColor="white"
          headerHeight="50"
          closeButtonColor="white"
          bodyBackgroundColor="white"
          bodyTextColor="black"
          bodyHeight="350px"
          headerText="Upload Docmument"
        >
          <div style={{ height: "350px", overflowY: "auto" }}>
            <form
              onSubmit={handleSubmitlic}
              method="post"
              action="javascript:void(0)"
            >
              <div className="d-flex flex-column gap-3 bg-white  mt-4  p-4 inperson">
                {succ1 && (
                  <p className="text-success ">
                    Licence file uploaded successfully
                  </p>
                )}
                <div className="d-flex">
                  <label className="w-100">
                    Enter Name of the licence{" "}
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    className="w-100"
                    type="text"
                    required
                    name="licence_number"
                  />
                </div>
                <div className="d-flex">
                  <label className="w-100">
                    Enter Your Name <span className="text-danger">*</span>
                  </label>
                  <input className="w-100" type="text" name="name" required />
                </div>
                <div className="d-flex align-items-center justify-content-between gap-3 ">
                  <label className="w-100">
                    Expiry Date <span className="text-danger">*</span>
                  </label>
                  <div className="d-flex align-items-center gap-3 date">
                    <input
                      required
                      className="w-100"
                      type="text"
                      placeholder="MM"
                      name="expiryMonth"
                      pattern="[0-9]*"
                      min="1"
                      maxLength="2"
                    />
                    <input
                      required
                      className="w-100"
                      type="text"
                      placeholder="YYYY"
                      pattern="[0-9]*"
                      min="1"
                      maxLength="4"
                      name="expiryYear"
                    />
                  </div>
                </div>
                <div className="d-flex">
                  <div className="position-relative opacity-0 w-100"></div>
                  <div className="position-relative Document-nm1 w-100">
                    <button type="button">
                      Upload <span className="text-white">*</span>
                    </button>
                    <input
                      type="file"
                      name="file"
                      onChange={handleFileChange_lic}
                      required
                    />
                  </div>
                </div>
                <div className="d-flex">
                  <div className="position-relative opacity-0 w-100"></div>
                  <div className="position-relative Document-nm1 w-100">
                    <button type="submit">Submit</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </ReactDialogBox>
      )}
      <header
        style={{ position: "relative", left: "0", right: "0" }}
        className="d-block d-sm-none"
      >
        <div
          className="navbar-top"
          style={{ display: isDivVisible ? "block" : "none" }}
        >
          <div className="d-flex flex-column">
            <div className="nav-link-main d-flex flex-column">
              <div className="navbar-list  justify-content-end d-flex flex-column">
                <Link
                  to="/home"
                  className={
                    location.pathname === "/home" ? "active fs-6" : "fs-6"
                  }
                >
                  Home
                </Link>
                {/* <Link
                  to="/profile"
                  className={
                    location.pathname === "/profile" ? "active fs-6" : "fs-6"
                  }
                >
                  Profile
                </Link> */}
                {/* <Link
                  to="/newroster"
                  className={location.pathname === "/newroster" ? "active" : ""}
                >
                  Roster
                </Link> */}
                <Link
                  to="/currentroster"
                  className={
                    location.pathname === "/currentroster"
                      ? "active fs-6"
                      : "fs-6"
                  }
                >
                  Current Roster
                </Link>
                <Link
                  to="/timesheet"
                  className={
                    location.pathname === "/timesheet" ? "active fs-6" : "fs-6"
                  }
                >
                  Signature
                </Link>
                <Link
                  to="/clinetinformation"
                  className={
                    location.pathname === "/clinetinformation"
                      ? "active fs-6"
                      : "fs-6"
                  }
                >
                  Client Info
                </Link>
                <Link
                  to="/ohs"
                  className={
                    location.pathname === "/ohs" ? "active fs-6" : "fs-6"
                  }
                >
                  OHS
                </Link>
                <Link
                  to="/site_access"
                  className={
                    location.pathname === "/site_access"
                      ? "active fs-6"
                      : "fs-6"
                  }
                >
                  Site Access
                </Link>

                <Link className="" to="/logout">
                  Logout
                </Link>
              </div>
            </div>
          </div>
        </div>
        <nav class="navbar navbar-expand-lg bg-body-tertiary">
          <div class="container-fluid">
            <div class="d-flex justify-content-between  align-items-center">
              <div className="col-2 nav-mob">
                <button type="button" onClick={toggleDivVisibility}>
                  <i className="fal fa-bars"></i>
                </button>
              </div>
              <Link to="#" className="logo-top col-3 px-2 offset-1">
                <img src={require("./assets/images/logo2.png")} alt="logo" />
              </Link>
              <Link to="#" className="logo-top col-3 px-2">
                <img src={require("./assets/images/hss.png")} alt="logo" />
              </Link>
              <div
                onClick={notifica_tion}
                className="position-relative cursor-pointer"
              >
                <img
                  src={require("./assets/images/3602145.png")}
                  width="30"
                  alt="Image"
                />
                {notification1 && (
                  <>
                    {Usernotification1.length === 0 && (
                      <div className="d-flex well-info">
                        <ul style={{ height: "57px" }}>
                          {Array.isArray(Usernotification1) &&
                          Usernotification1.length > 0 ? (
                            Usernotification1.map((item, index) => (
                              <li key={index}>
                                <Link>{item.message}</Link>
                              </li>
                            ))
                          ) : (
                            <li>No data available</li>
                          )}
                        </ul>
                        <div className="d-flex gap-3 align-items-center pb-3 px-3">
                          <div className="d-flex gap-3 align-items-center">
                            <Link to="javascript:void(0)" onClick={seeallnoti}>
                              See all Notifications
                            </Link>
                          </div>
                          <div className="d-flex flex-column gap-2 align-items-center">
                            <button type="button" onClick={opendoc}>
                              Upload Document
                            </button>
                            <button type="button" onClick={openlic}>
                              Upload Licence
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                    {Usernotification1.length > 0 && (
                      <div className="d-flex well-info">
                        <ul>
                          {Array.isArray(Usernotification1) &&
                          Usernotification1.length > 0 ? (
                            Usernotification1.map((item, index) => (
                              <li key={index}>
                                <Link>{item.message}</Link>
                              </li>
                            ))
                          ) : (
                            <li>No data available</li>
                          )}
                        </ul>
                        <div className="d-flex gap-3 align-items-center pb-3 px-3">
                          <div className="d-flex gap-3 align-items-center">
                            <Link>See all Notifications</Link>
                          </div>
                          <div className="d-flex flex-column gap-2 align-items-center">
                            <button type="button" onClick={opendoc}>
                              Upload Document
                            </button>
                            <button type="button" onClick={openlic}>
                              Upload Licence
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
              <div className="col-2 ms-auto">
                <div className="navbar-list float-end">
                  <p className="user-text-icon">
                    <Link to="/profile">
                      {fLetter} {mLetter} {lLetter}
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </nav>
        {/* 
        <div className="container-lg">
          <div className="row gy-3 align-items-center justify-content-between">
            <div className="col-4">
              <Link to="#" className="logo-top">
                <img src={require("./assets/images/logo2.png")} alt="logo" />
              </Link>
            </div>
            <div className="col-4">
              <Link to="#" className="logo-top">
                <img src={require("./assets/images/logo2.png")} alt="logo" />
              </Link>
            </div>

            <div className="col-8"></div>
            <div className="col-12"></div>
          </div>
        </div> */}
      </header>
    </>
  );
};
