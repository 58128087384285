import { useEffect } from "react";

const useKeyboardShortcutBlocker = () => {
  useEffect(() => {
    const handleKeyPress = (e) => {
      // Check if the pressed key is F12 (key code 123)
      if (
        e.keyCode === 123 ||
        (e.ctrlKey && e.keyCode === 85) ||
        (e.ctrlKey && e.shiftKey && e.keyCode === 73)
      ) {
        e.preventDefault(); // Prevent the default behavior (opening developer tools)
        // You can add your custom logic here if needed
      }
    };

    const handleContextMenu = (e) => {
      e.preventDefault(); // Prevent the default context menu
      // You can add your custom logic here if needed
    };

    // Attach the event listeners when the component mounts
    window.addEventListener("keydown", handleKeyPress);
    window.addEventListener("contextmenu", handleContextMenu);

    // Remove the event listeners when the component unmounts
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
      window.removeEventListener("contextmenu", handleContextMenu);
    };
  }, []); // Empty dependency array ensures the effect runs only once on mount
};

export default useKeyboardShortcutBlocker;
