import React, { useState, useEffect } from "react";
import axios from "axios";
import { message, Form, Input, Button, Alert } from "antd";
import { useNavigate } from "react-router-dom";
import TextArea from "antd/es/input/TextArea";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
// import '@fullcalendar/core/main.css';
// import '@fullcalendar/daygrid/main.css';
import AdminHeader from "./AdminHeader";
import AdminFooter from "./AdminFooter";

export const AdminCalendar = () => {
  const navigate = useNavigate();
  const changeFavicon = (url) => {
    const favicon = document.querySelector('link[rel="icon"]');
    if (favicon) {
      favicon.href = url;
    }
  };
  useEffect(() => {
    changeFavicon(require("../assets/admin/images/f-icon.png"));
    document.title = "Notification Page";
    const userStr = JSON.parse(localStorage.getItem("admin"));
    console.log(userStr);
    if (userStr == null) {
      navigate("/admin/login");
    }
  }, []);

  return (
    <>
      <div>
        <AdminHeader />
        <div style={{ height: "100vh", backgroundColor: "yellow" }}>
          <FullCalendar plugins={[dayGridPlugin]} />
        </div>
      </div>
    </>
  );
};
