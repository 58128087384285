import React, { useState, useEffect } from "react";
import axios from "axios";
import { Alert } from "antd";
import { useNavigate, Link } from "react-router-dom";
import TextArea from "antd/es/input/TextArea";
import { Header } from "./Header";
import { Footer } from "./Footer";
export const Termscondition = () => {
  const currentYear = new Date().getFullYear();
  const navigate = useNavigate();
  const [items, setItems] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [val, setVal] = useState("");
  const changeFavicon = (url) => {
    const favicon = document.querySelector('link[rel="icon"]');
    if (favicon) {
      favicon.href = url;
    }
  };
  useEffect(() => {
    changeFavicon(require("./assets/admin/images/f-icon.png"));
    const userStr = JSON.parse(localStorage.getItem("users"));
    document.title = "Terms & Conditions Page";
  }, []);
  const handleSubmit = (event) => {
    var vll = event.target;
    let formdata = {
      email: vll.email.value,
      address: vll.address.value,
      phone: vll.phone.value,
    };
  };

  return (
    <>
      <Header />
      <section className="innerbanner text-center py-5 d-block">
        <div className="container-lg">
          <h3 className="fs-30 fw-800 text-white">
            Terms and Conditions for Roster and Time-Sheet Website
          </h3>
        </div>
      </section>
      <section className="profile-box d-block">
        <div className="container-lg">
          <div className="row gy-4 align-items-center">
            <div className="col-md-12 py-2 px-2">
              <div className="d-flex flex-column gap-2 mt-2">
                <h5 className="py-2 px-2 text-secondary lh-base">
                  These Terms and Conditions ("Terms") govern your use of the
                  Roster and Time-Sheet website (the "Website"), which is
                  designed for employee attendance tracking and time-sheet
                  generation. By accessing or using the Website, you agree to
                  comply with and be bound by these Terms. If you do not agree
                  with these Terms, please do not use the Website.
                </h5>
              </div>
            </div>
            <div className="col-md-12 py-2 px-2">
              <div className="d-flex flex-column">
                <h3 className="fs-25 fw-800 text-black">
                  1. Acceptance of Terms
                </h3>
                <h5 className="py-2 px-2 text-secondary lh-base">
                  By accessing and using the Website, you acknowledge that you
                  have read, understood, and agree to abide by these Terms.
                  These Terms constitute a legally binding agreement between you
                  and the Website's administrators.
                </h5>{" "}
                <h3 className="fs-25 fw-800 text-black">
                  2. User Registration
                </h3>
                <h5 className="py-2 px-2 text-secondary lh-base">
                  <b>2.1. </b> To use the Website, you must create an account by
                  providing accurate and complete information as prompted during
                  the registration process.
                </h5>{" "}
                <h5 className="py-2 px-2 text-secondary lh-base">
                  <b>2.2. </b> You are responsible for maintaining the
                  confidentiality of your account credentials, and you agree not
                  to share your account information with others.
                </h5>{" "}
                <h3 className="fs-25 fw-800 text-black">
                  3. Use of the Website
                </h3>
                <h5 className="py-2 px-2 text-secondary lh-base">
                  <b>3.1. </b> The Website is intended solely for employees and
                  administrators to manage employee rosters, attendance, and
                  time sheets.
                  <b>3.2. </b> You may select your roster type, client, and
                  location based on your work requirements.
                  <b>3.3. </b> You are responsible for accurately recording your
                  attendance, including hours worked, leave travel information,
                  and any other required data.
                </h5>{" "}
                <h3 className="fs-25 fw-800 text-black">
                  4. Submission of timesheets
                </h3>
                <h5 className="py-2 px-2 text-secondary lh-base">
                  <b>4.1. </b> You are responsible for submitting accurate and
                  complete time-sheet information in a timely manner.
                  <b>4.2. </b> You may be required to upload supporting
                  documents such as receipts for travel expenses. All submitted
                  information must be truthful and valid.
                </h5>{" "}
                <h3 className="fs-25 fw-800 text-black">
                  5. Time-Sheet Generation and Payroll
                </h3>
                <h5 className="py-2 px-2 text-secondary lh-base">
                  <b>5.1. </b> The Website will generate timesheets based on the
                  data you provide. These timesheets will be used for payroll
                  administration.
                  <b>5.2. </b> Payroll calculations will be made based on the
                  time-sheet data. Any discrepancies or errors should be
                  reported to the Website administrator promptly.
                </h5>{" "}
                <h3 className="fs-25 fw-800 text-black">
                  6. Signature Collection
                </h3>
                <h5 className="py-2 px-2 text-secondary lh-base">
                  <b>6.1. </b> You may have the option to collect signatures
                  from clients for verification purposes. This signature
                  collection feature is provided for your convenience.
                  <b>6.2. </b> Any collected signatures will be submitted to the
                  Website's administrator for review and storage.
                </h5>{" "}
                <h3 className="fs-25 fw-800 text-black">7. Privacy</h3>
                <h5 className="py-2 px-2 text-secondary lh-base">
                  <b>7.1. </b> Your personal information and data will be
                  handled in accordance with the Website's Privacy Policy.
                </h5>{" "}
                <h3 className="fs-25 fw-800 text-black">
                  8. Compliance with Laws
                </h3>
                <h5 className="py-2 px-2 text-secondary lh-base">
                  <b>8.1. </b> You agree to use the Website in compliance with
                  all applicable laws and regulations.
                </h5>{" "}
                <h3 className="fs-25 fw-800 text-black">
                  9. Termination of Account
                </h3>
                <h5 className="py-2 px-2 text-secondary lh-base">
                  <b>9.1. </b> The Website administrators reserve the right to
                  suspend or terminate your account at their discretion if you
                  violate these Terms or engage in any fraudulent or unethical
                  activities.
                </h5>{" "}
                <h3 className="fs-25 fw-800 text-black">
                  10. Modifications to Terms
                </h3>
                <h5 className="py-2 px-2 text-secondary lh-base">
                  <b>10.1. </b> The Website administrators may update or modify
                  these Terms at any time. Any changes will be posted on the
                  Website, and it is your responsibility to review these Terms
                  periodically.
                </h5>{" "}
                <h3 className="fs-25 fw-800 text-black">
                  11. Contact Information
                </h3>
                <h5 className="py-2 px-2 text-secondary lh-base">
                  <b>11.1. </b> For questions, concerns, or support related to
                  the Website, you can contact the Website administrators using
                  the provided contact information.
                  <br />
                  By using the Roster and Time-Sheet Website, you acknowledge
                  that you have read and understood these Terms and agree to be
                  bound by them. Failure to comply with these Terms may result
                  in the termination of your account and access to the Website.
                </h5>{" "}
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};
