import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import WorkHeader from "./WorkHeader";
import WorkFooter from "./WorkFooter";
export const Workhome = () => {
  const navigate = useNavigate();
  const changeFavicon = (url) => {
    const favicon = document.querySelector('link[rel="icon"]');
    if (favicon) {
      favicon.href = url;
    }
  };
  useEffect(() => {
    changeFavicon(require("../assets/admin/images/f-icon.png"));
    document.title = "Home Page Work Information";
  }, []);

  return (
    <>
      <WorkHeader />
      <section className="main-slider d-block rel">
        <div
          id="carouselExampleAutoplaying"
          className="carousel slide"
          data-bs-ride="carousel"
        >
          <div className="carousel-inner">
            <div className="carousel-item active" data-bs-interval="100">
              <img src="images/s5.jpg" className="d-block w-100" alt="img" />
            </div>
            <div className="carousel-item" data-bs-interval="">
              <img src="images/s1.jpg" className="d-block w-100" alt="img" />
            </div>
            <div className="carousel-item">
              <img src="images/s2.jpg" className="d-block w-100" alt="img" />
            </div>
            <div className="carousel-item">
              <img src="images/s3.jpg" className="d-block w-100" alt="img" />
            </div>
            <div className="carousel-item">
              <img src="images/s4.jpg" className="d-block w-100" alt="img" />
            </div>
          </div>
        </div>
        <div className="dicover-block d-flex flex-column gap-4">
          <h1>"Discover the perfect Skilled labor Service, instantly"</h1>
          <div className="d-flex flex-column gap-3">
            <div className="block top-search-box">
              <form action="">
                <div className="d-flex align-self-stretch">
                  <div className="flex-grow-1">
                    <input type="text" placeholder="search for service..." />
                  </div>
                  <div className="flex-shirnk-0">
                    <button type="submit">
                      <i className="fal fa-search" aria-hidden="true"></i>
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div className="d-flex align-items-center gap-3 search-tags">
              <div className="flex-shrink-0">
                <h4>Popular:</h4>
              </div>
              <div className="d-flex align-items-center gap-3 flex-grow-1">
                <Link to="#" className="">
                  Mining
                </Link>
                <Link to="#" className="">
                  Truck Driver
                </Link>
                <Link to="#" className="">
                  Construction
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="hlogos d-block py-3">
        <div className="container-fluid">
          <div className="d-flex align-items-center flex-wrap flex-sm-nowrap justify-content-between gap-2">
            <div className="hlogo-box fulw">
              <img
                src={require("../assets/workinformation/images/hlogo1.png")}
                alt="logo"
              />
            </div>
            <div className="hlogo-box fulw">
              <img
                src={require("../assets/workinformation/images/hlogo2.png")}
                alt="logo"
              />
            </div>
            <div className="hlogo-box fulw">
              <img
                src={require("../assets/workinformation/images/hlogo3.png")}
                alt="logo"
              />
            </div>
            <div className="hlogo-box fulw">
              <img
                src={require("../assets/workinformation/images/hlogo4.png")}
                alt="logo"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="d-block py-4">
        <div className="container-fluid">
          <div className="d-flex flex-column gap-3">
            <div>
              <h2 className="main-heading">Popular jobs</h2>
            </div>
            <div>
              <ul className="list-slider ls p-0">
                <li className="px-2">
                  <Link to="#" className="img-block d-block rel">
                    <div className="img-pic">
                      <img
                        src={require("../assets/workinformation/images/j1.webp")}
                        alt="image"
                      />
                    </div>
                    <div className="text-list">
                      <h4>Add talent to AI</h4>
                      <h5>AI Artists</h5>
                    </div>
                  </Link>
                </li>
                <li className="px-2">
                  <Link to="#" className="img-block d-block rel">
                    <div className="img-pic">
                      <img
                        src={require("../assets/workinformation/images/j2.webp")}
                        alt="image"
                      />
                    </div>
                    <div className="text-list">
                      <h4>Build your brand</h4>
                      <h5>Logo Design</h5>
                    </div>
                  </Link>
                </li>
                <li className="px-2">
                  <Link to="#" className="img-block d-block rel">
                    <div className="img-pic">
                      <img
                        src={require("../assets/workinformation/images/j3.webp")}
                        alt="image"
                      />
                    </div>
                    <div className="text-list">
                      <h4>Customize your site</h4>
                      <h5>WordPress</h5>
                    </div>
                  </Link>
                </li>
                <li className="px-2">
                  <Link to="#" className="img-block d-block rel">
                    <div className="img-pic">
                      <img
                        src={require("../assets/workinformation/images/j4.webp")}
                        alt="image"
                      />
                    </div>
                    <div className="text-list">
                      <h4>Share your message</h4>
                      <h5>Voice Over</h5>
                    </div>
                  </Link>
                </li>
                <li className="px-2">
                  <Link to="#" className="img-block d-block rel">
                    <div className="img-pic">
                      <img
                        src={require("../assets/workinformation/images/j5.webp")}
                        alt="image"
                      />
                    </div>
                    <div className="text-list">
                      <h4>Engage your audience</h4>
                      <h5>Video Explainer</h5>
                    </div>
                  </Link>
                </li>
                <li className="px-2">
                  <Link to="#" className="img-block d-block rel">
                    <div className="img-pic">
                      <img
                        src={require("../assets/workinformation/images/j3.webp")}
                        alt="image"
                      />
                    </div>
                    <div className="text-list">
                      <h4>Add talent to AI</h4>
                      <h5>AI Artists</h5>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="d-block py-4">
        <div className="container-fluid">
          <div className="d-flex flex-column gap-3">
            <div>
              <h2 className="main-heading">Find jobs by categories</h2>
            </div>
            <div>
              <ul className="job-slider ls p-0">
                <li className="p-2">
                  <Link to="#" className="icon-pic-box d-block">
                    <div className="icon-photo mx-auto ofit">
                      <img
                        src={require("../assets/workinformation/images/g1.png")}
                        alt="image"
                      />
                    </div>
                    <h3>Health and safety</h3>
                  </Link>
                </li>
                <li className="p-2">
                  <Link to="#" className="icon-pic-box d-block">
                    <div className="icon-photo mx-auto ofit">
                      <img
                        src={require("../assets/workinformation/images/g2.png")}
                        alt="image"
                      />
                    </div>
                    <h3>Exploration</h3>
                  </Link>
                </li>
                <li className="p-2">
                  <Link to="#" className="icon-pic-box d-block">
                    <div className="icon-photo mx-auto ofit">
                      <img
                        src={require("../assets/workinformation/images/g3.png")}
                        alt="image"
                      />
                    </div>
                    <h3>Mine Development</h3>
                  </Link>
                </li>
                <li className="p-2">
                  <Link to="#" className="icon-pic-box d-block">
                    <div className="icon-photo mx-auto ofit">
                      <img
                        src={require("../assets/workinformation/images/g4.png")}
                        alt="image"
                      />
                    </div>
                    <h3>Oil mining</h3>
                  </Link>
                </li>
                <li className="p-2">
                  <Link to="#" className="icon-pic-box d-block">
                    <div className="icon-photo mx-auto ofit">
                      <img
                        src={require("../assets/workinformation/images/g3.png")}
                        alt="image"
                      />
                    </div>
                    <h3>Mine Development</h3>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <WorkFooter />
    </>
  );
};
