import React, { useState, useEffect } from "react";
import axios from "axios";
import { message, Form, Input, Button, Alert } from "antd";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Header } from "../Header";
import { Footer } from "../Footer";
import moment from "moment-timezone";
import Select from "react-select";
import { Documentsnotes } from "./Documentsnotes";
import { Documents } from "./Documents";
import "../assets/ohs/newstyle.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
export const About = () => {
  const currentYear = new Date().getFullYear();
  const navigate = useNavigate();
  const [timezones, setTimezones] = useState([]);
  const [timezonesus, setausTimezones] = useState("");
  const [activeTab, setActiveTab] = useState("home-tab");
  const [activeTabMenu, setactiveTabMenu] = useState("details");
  const [activetab1, setactivetab1] = useState(true);
  const [activetab2, setactivetab2] = useState(false);
  const [isDisabledstep2, setisDisabledstep2] = useState(false);
  const [isDisabledtomenu, setisDisabledtomenu] = useState(false);
  const [activetab3, setactivetab3] = useState(false);
  const [activetab4, setactivetab4] = useState(false);
  const [activecounrty, setactivecounrty] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);
  const [perdetailstep2, setperdetailstep2] = useState(false);
  const [iscountryChecked, setIscountryChecked] = useState("au");
  const [isyesNoChecked, setyesNoChecked] = useState("Yes");
  const [isStateChecked, setStateChecked] = useState("");
  const [isSet3employee, setisSet3employee] = useState("");
  const [isstep2employee, setisstep2employee] = useState("Employee");
  const [persondetail, setpersondetail] = useState("");
  const [isreferences, setreferences] = useState("");
  const [isstep2_persondetail, setisstep2_persondetail] = useState("Yes");
  const [iswasChecked, setiswasChecked] = useState("No");
  const [iswasfirst, setwasfirst] = useState(false);
  const [iswhereotherChecked, setiswhereotherChecked] = useState("No");
  const [iswhereother, setwhereother] = useState(false);
  const [uniquecode, setuniquecode] = useState("");
  const [isselectedtimezone, selectedtimezone] = useState(null);
  const [iswitnessChecked, setiswitnessChecked] = useState("No");
  const [iswitness, setwitness] = useState(false);
  const [countries, setCountries] = useState([]);
  const [isetcounty, setisetcounty] = useState("");
  const [isetcountystep3, setisetcountystep3] = useState("");
  const [getincidentData, setgetincidentData] = useState([]);
  const [isincident_safeChecked, setisincident_safeChecked] = useState("No");
  const [isincidentafe, setisincidentafe] = useState(false);
  const [issetgetLocations, setgetLocations] = useState([]);
  const [issetSelectedsite, setSelectedsite] = useState("");
  const changeFavicon = (url) => {
    const favicon = document.querySelector('link[rel="icon"]');
    if (favicon) {
      favicon.href = url;
    }
  };
  const { id } = useParams();

  useEffect(() => {
    const userStr = JSON.parse(localStorage.getItem("users"));
    if (userStr === null) {
      window.location.href = "/login";
    }
    document.title = "About Page";
    if (id === undefined) {
      setuniquecode("");
    } else {
      setuniquecode(id);
    }

    axios
      .get("https://jlmining.app/country", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        const options = res.data.results.map((country) => ({
          value: country.country_name,
          label: country.country_name,
        }));
        setCountries(options);
      })
      .catch((err) => {});
    const australianTimezones = [
      "Australia/Lord_Howe",
      "Antarctica/Macquarie",
      "Australia/Hobart",
      "Australia/Melbourne",
      "Australia/Sydney",
      "Australia/Broken_Hill",
      "Australia/Brisbane",
      "Australia/Lindeman",
      "Australia/Adelaide",
      "Australia/Darwin",
      "Australia/Perth",
      "Australia/Eucla",
    ].map((zone) => ({ value: zone, label: zone }));
    setTimezones(australianTimezones);

    let formdata = {
      user_id: userStr[0].id,
      code: id,
    };
    axios
      .post("https://jlmining.app/getlocationohs", formdata, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log("locations");
        console.log(res.data.results);
        if (res.data.results.length > 0) {
          const options = res.data.results.map((item) => ({
            value: item.location_name,
            label: item.location_name,
          }));
          setgetLocations(options);
        }
      })
      .catch((err) => {});
    axios
      .post("https://jlmining.app/getincidentdata", formdata, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res.data.row.length > 0) {
          var indata = res.data.row[0];
          if (indata.status === "Closed") {
            const newUrl = "/incident/";
            navigate(newUrl);
          }
          setgetincidentData(indata);
          if (indata.was_first === "Yes") {
            setwasfirst(true);
          }
          setiswhereotherChecked(indata.other_person_involved);
          if (indata.other_person_involved === "Yes") {
            setwhereother(true);
          }
          setiswitnessChecked(indata.witness);
          if (indata.witness === "Yes") {
            setwitness(true);
          }
          setisincident_safeChecked(indata.incident_safe);
          if (indata.incident_safe === "Yes") {
            setisincidentafe(true);
          }
          setiswasChecked(indata.was_first);
          selectedtimezone({
            value: indata.time_zone,
            label: indata.time_zone,
          });
          setSelectedsite({
            value: indata.site_step4,
            label: indata.site_step4,
          });
        }
      })
      .catch((err) => {});
  }, [id]);
  //console.log(uniquecode);
  const step2Country = (event) => {
    if (event !== "") {
      setisetcounty(event.value);
    }
  };
  const step3Country = (event) => {
    if (event !== "") {
      setisetcountystep3(event.value);
    }
  };
  const step3site = (event) => {
    console.log(event.value);
    if (event !== "") {
      setSelectedsite({
        value: event.value,
        label: event.value,
      });
    }
  };
  const handinjured_check = (event) => {
    if (event === "Yes") {
      setyesNoChecked("Yes");
      setIsDisabled(false);
    } else {
      setyesNoChecked("No");
      setIsDisabled(true);
    }
    console.log(event);
  };
  const handcountry = (event) => {
    if (event === "au") {
      setIscountryChecked("au");
      setactivecounrty(true);
    } else {
      setIscountryChecked("nz");
      setactivecounrty(false);
    }
  };
  const handstate = (event) => {
    setStateChecked(event);
  };
  const handstep2employee = (event) => {
    setisstep2employee(event);
    setisDisabledstep2(false);
    if (event !== "Employee") {
      setperdetailstep2(true);
    } else {
      setpersondetail("");
      setisstep2_persondetail("Yes");
      setperdetailstep2(false);
    }
  };
  const handstep2persondetail = (event) => {
    setisstep2_persondetail(event);
    setpersondetail("");
    if (event === "Yes") {
      setisDisabledstep2(false);
      setpersondetail("");
    } else {
      setisDisabledstep2(true);
      setpersondetail(event);
    }
  };
  const backbuttonStep2 = (event) => {
    setactivetab1(true);
    setactivetab2(false);
    setactiveTabMenu("details");
  };
  const backbuttonStep3 = (event) => {
    setactivetab1(false);
    setactivetab3(false);
    setactivetab2(true);
    setactiveTabMenu("injuredperson");
  };
  const backbuttonStep4 = (event) => {
    setactivetab4(false);
    setactivetab3(true);
    setactiveTabMenu("reporter");
  };
  const step2fullname = (e) => {
    setpersondetail(e.target.value);
  };
  const handmenu_check = (e) => {
    if (uniquecode !== undefined || uniquecode !== "") {
      if (e === "details") {
        const newUrl = "/incident/detail/" + uniquecode;
        navigate(newUrl);
      }

      if (e === "injuredperson") {
        const newUrl = "/incident/victim/" + uniquecode;
        navigate(newUrl);
      }
      if (e === "reporter") {
        const newUrl = "/incident/reporter/" + uniquecode;
        navigate(newUrl);
      }
    }
  };
  const handincident_safe = (event) => {
    setisincident_safeChecked(event);
    if (event === "Yes") {
      setisincidentafe(true);
    } else {
      setisincidentafe(false);
    }
  };
  const handwasfirst = (event) => {
    setiswasChecked(event);
    if (event === "Yes") {
      setwasfirst(true);
    } else {
      setwasfirst(false);
    }
  };
  const handwhereother = (event) => {
    setiswhereotherChecked(event);
    if (event === "Yes") {
      setwhereother(true);
    } else {
      setwhereother(false);
    }
  };
  const handwitness = (event) => {
    setiswitnessChecked(event);
    if (event === "Yes") {
      setwitness(true);
    } else {
      setwitness(false);
    }
  };
  const references = (event) => {
    setreferences(event.target.value);
  };

  const handbuttonStep4 = (event) => {
    var vll = event.target;
    const userStr = JSON.parse(localStorage.getItem("users"));
    if (iswasChecked === "Yes") {
      var f_or = vll.first_aider.value;
      var treat_ment = vll.treatment.value;
    } else {
      var f_or = "";
      var treat_ment = "";
    }
    if (iswhereotherChecked === "Yes") {
      var detperson = vll.detail_person_involved.value;
    } else {
      var detperson = "";
    }
    if (iswitnessChecked === "Yes") {
      var detwit = vll.detail_witness.value;
    } else {
      var detwit = "";
    }
    if (isincident_safeChecked === "Yes") {
      var acto = vll.action_token.value;
    } else {
      var acto = "";
    }
    let formdata = {
      check_code: uniquecode,
      site_step4: issetSelectedsite.value,
      site_reference: vll.site_reference.value,
      locations: vll.locations.value,
      step4_date: vll.step4_date.value,
      step4_time: vll.step4_time.value,
      chain_event: vll.chain_event.value,
      was_first: iswasChecked,
      first_aider: f_or,
      treatment: treat_ment,
      other_person_involved: iswhereotherChecked,
      detail_person_involved: detperson,
      witness: iswitnessChecked,
      detail_witness: detwit,
      incident_safe: isincident_safeChecked,
      action_token: acto,
      time_zone: isselectedtimezone.value,
    };

    axios
      .post("https://jlmining.app/incident_step4", formdata, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res.data.code !== "") {
          const newUrl = "/incident/summary/" + uniquecode;
          navigate(newUrl);
        }
      })
      .catch((err) => {});
  };
  const step3_employeename = (e) => {
    setisSet3employee(e.target.value);
  };
  const handleincident = (event) => {};
  const handletimezone = (event) => {
    selectedtimezone({
      value: event.value,
      label: event.value,
    });
  };
  function getFormattedDate(dateString) {
    if (!dateString) return ""; // Return empty string if dateString is empty or undefined
    const date = new Date(dateString);
    const year = date.getFullYear();
    let month = (date.getMonth() + 1).toString().padStart(2, "0"); // Add leading zero if needed
    let day = date.getDate().toString().padStart(2, "0"); // Add leading zero if needed
    return `${year}-${month}-${day}`;
  }

  return (
    <>
      <Header />
      <section className="d-block py-5">
        <div className="container-lg">
          <div className="row gy-4">
            <div className="col-md-8">
              {/* <Documents /> */}
              <div className="d-block">
                <div className="row gy-3 mb-4">
                  <div className="col-12 hazard-form-block">
                    <ul className="brow-nav">
                      <li>
                        <Link
                          to="javascript:void(0)"
                          onClick={() => handmenu_check("details")}
                        >
                          Details
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="javascript:void(0)"
                          onClick={() => handmenu_check("injuredperson")}
                        >
                          Injured person
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="javascript:void(0)"
                          onClick={() => handmenu_check("reporter")}
                        >
                          Reporter
                        </Link>
                      </li>
                      <li className="active">
                        <Link
                          to="javascript:void(0)"
                          onClick={() => handmenu_check("about")}
                        >
                          About
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <form
                action="javascript:void(0)"
                onSubmit={handbuttonStep4}
                method="post"
              >
                <div className="row gy-3">
                  <div className="col-12 hazard-form-block">
                    <div className="row gy-4">
                      <div className="col-12">
                        <div className="d-flex flex-column gap-2">
                          <h1 className="h-title">About the near miss</h1>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="row gy-4">
                          <div className="col-12">
                            <h5 className="in-title">Where did it happen?</h5>
                          </div>

                          <div className="col-md-6">
                            <div className="d-flex flex-column gap-2">
                              <label for="">
                                Site
                                <span className="text-danger">*</span>
                              </label>
                              {/* <input
                                type="text"
                                required
                                defaultValue={getincidentData.site_step4}
                                name="site_step4"
                                placeholder=""
                              /> */}
                              <Select
                                options={issetgetLocations}
                                value={issetSelectedsite}
                                onChange={step3site}
                                name="site_step4"
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="d-flex flex-column gap-2">
                              <label for="">Site reference </label>
                              <input
                                type="text"
                                name="site_reference"
                                placeholder=""
                                defaultValue={getincidentData.site_reference}
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="d-flex flex-column gap-2">
                              <label for="">Location </label>
                              <input
                                type="text"
                                name="locations"
                                placeholder=""
                                defaultValue={getincidentData.locations}
                              />
                            </div>
                          </div>
                          <div className="col-12">
                            <h5 className="in-title">When did it happen?</h5>
                          </div>
                          <div className="col-md-6">
                            <div className="d-flex flex-column gap-2">
                              <label for="">
                                Date <span className="text-danger">*</span>
                              </label>
                              <input
                                type="date"
                                name="step4_date"
                                required
                                defaultValue={getFormattedDate(
                                  getincidentData.step4_date
                                )}
                                placeholder=""
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="d-flex flex-column gap-2">
                              <label for="">
                                Time <span className="text-danger">*</span>
                              </label>
                              <input
                                type="time"
                                required
                                defaultValue={getincidentData.step4_time}
                                name="step4_time"
                                placeholder=""
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="d-flex flex-column gap-2">
                              <label for="">
                                Time Zone <span className="text-danger">*</span>
                              </label>
                              <Select
                                options={timezones}
                                value={isselectedtimezone}
                                onChange={handletimezone}
                                placeholder="Select an Australian time zone"
                              />
                            </div>
                          </div>
                          <div className="col-12">
                            <h5 className="in-title">What happened?</h5>
                          </div>
                          <div className="col-12">
                            <div className="d-flex flex-column gap-2">
                              <label for="">
                                Description of chain of events
                                <span className="text-danger">*</span>
                              </label>
                              <textarea
                                name="chain_event"
                                required
                                id=""
                                cols="30"
                                defaultValue={getincidentData.chain_event}
                                rows="4"
                              ></textarea>
                            </div>
                          </div>

                          <div className="col-12">
                            <h5 className="in-title">First Aid</h5>
                            <div className="d-flex flex-column gap-2 mt-2">
                              <label for="">
                                Was first aid given?
                                <span className="text-danger">*</span>
                              </label>
                              <div className="d-flex gap-4 align-items-center">
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    required
                                    name="was_first"
                                    id="the1"
                                    checked={iswasChecked === "Yes"}
                                    onClick={() => handwasfirst("Yes")}
                                  />
                                  <label
                                    className="form-check-label rabutton rabutton"
                                    for="the1"
                                  >
                                    Yes
                                  </label>
                                </div>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="was_first"
                                    id="the2"
                                    checked={iswasChecked === "No"}
                                    onClick={() => handwasfirst("No")}
                                  />
                                  <label
                                    className="form-check-label rabutton rabutton"
                                    for="the2"
                                  >
                                    No
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                          {iswasfirst && (
                            <div className="col-md-6">
                              <div className="d-flex flex-column gap-2">
                                <label for="">
                                  Name of first aider{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <input
                                  type="text"
                                  required
                                  defaultValue={getincidentData.first_aider}
                                  name="first_aider"
                                  placeholder=""
                                />
                              </div>
                            </div>
                          )}
                          {iswasfirst && (
                            <div className="col-12">
                              <div className="d-flex flex-column gap-2">
                                <label for="">
                                  Details of treatment
                                  <span className="text-danger">*</span>
                                </label>
                                <textarea
                                  name="treatment"
                                  required
                                  id=""
                                  cols="30"
                                  defaultValue={getincidentData.treatment}
                                  rows="4"
                                ></textarea>
                              </div>
                            </div>
                          )}
                          <div className="col-12">
                            <div className="d-flex flex-column gap-2 mt-2">
                              <label for="">
                                Were there any other person/s involved in the
                                incident?
                                <span className="text-danger">*</span>
                              </label>
                              <div className="d-flex gap-4 align-items-center">
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    required
                                    name="other_person_involved"
                                    id="the4"
                                    checked={iswhereotherChecked === "Yes"}
                                    onClick={() => handwhereother("Yes")}
                                  />
                                  <label
                                    className="form-check-label rabutton rabutton"
                                    for="the4"
                                  >
                                    Yes
                                  </label>
                                </div>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="other_person_involved"
                                    id="the5"
                                    checked={iswhereotherChecked === "No"}
                                    onClick={() => handwhereother("No")}
                                  />
                                  <label
                                    className="form-check-label rabutton rabutton"
                                    for="the5"
                                  >
                                    No
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                          {iswhereother && (
                            <div className="col-12">
                              <div className="d-flex flex-column gap-2">
                                <label for="">
                                  Details of other person/s involved
                                  <span className="text-danger">*</span>
                                </label>
                                <textarea
                                  name="detail_person_involved"
                                  required
                                  id=""
                                  defaultValue={
                                    getincidentData.detail_person_involved
                                  }
                                  cols="30"
                                  rows="4"
                                ></textarea>
                              </div>
                            </div>
                          )}
                          <div className="col-12">
                            <div className="d-flex flex-column gap-2 mt-2">
                              <label for="">
                                Were there any witnesses to the incident?
                                <span className="text-danger">*</span>
                              </label>
                              <div className="d-flex gap-4 align-items-center">
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="witness"
                                    required
                                    id="the6"
                                    checked={iswitnessChecked === "Yes"}
                                    onClick={() => handwitness("Yes")}
                                  />
                                  <label
                                    className="form-check-label rabutton rabutton"
                                    for="the6"
                                  >
                                    Yes
                                  </label>
                                </div>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="witness"
                                    id="the7"
                                    checked={iswitnessChecked === "No"}
                                    onClick={() => handwitness("No")}
                                  />
                                  <label
                                    className="form-check-label rabutton rabutton"
                                    for="the7"
                                  >
                                    No
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                          {iswitness && (
                            <div className="col-12">
                              <div className="d-flex flex-column gap-2">
                                <label for="">
                                  Details of witnesses
                                  <span className="text-danger">*</span>
                                </label>
                                <textarea
                                  name="detail_witness"
                                  required
                                  id=""
                                  cols="30"
                                  defaultValue={getincidentData.detail_witness}
                                  rows="4"
                                ></textarea>
                              </div>
                            </div>
                          )}
                          <div className="col-12">
                            <div className="d-flex flex-column gap-2 mt-2">
                              <label for="">
                                Has the incident site been made safe?
                                <span className="text-danger">*</span>
                              </label>
                              <div className="d-flex gap-4 align-items-center">
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="incident_safe"
                                    required
                                    id="the8"
                                    checked={isincident_safeChecked === "Yes"}
                                    onClick={() => handincident_safe("Yes")}
                                  />
                                  <label
                                    className="form-check-label rabutton rabutton"
                                    for="the8"
                                  >
                                    Yes
                                  </label>
                                </div>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="incident_safe"
                                    id="the9"
                                    checked={isincident_safeChecked === "No"}
                                    onClick={() => handincident_safe("No")}
                                  />
                                  <label
                                    className="form-check-label rabutton rabutton"
                                    for="the9"
                                  >
                                    No
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                          {isincidentafe && (
                            <div className="col-12">
                              <div className="d-flex flex-column gap-2">
                                <label for="">
                                  Details of actions taken
                                  <span className="text-danger">*</span>
                                </label>
                                <textarea
                                  name="action_token"
                                  required
                                  id=""
                                  defaultValue={getincidentData.action_token}
                                  cols="30"
                                  rows="4"
                                ></textarea>
                              </div>
                            </div>
                          )}
                          <div className="col-12">
                            <div className="d-flex gap-2 align-items-center buttons-two">
                              <Link
                                to={`/incident/reporter/${uniquecode}`}
                                className="cel-btn"
                              >
                                Back
                              </Link>
                              <button type="submit" className="rep-btn">
                                Review Report
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="col-md-4">
              <div className="d-flex flex-column gap-3">
                <div className="d-flex flex-column need-block border border-secondary border-opacity-25 rounded-2 overflow-hidden">
                  <div className="d-block fulw">
                    <img
                      src={require("../assets/ohs/images/need.png")}
                      alt="image"
                    />
                  </div>
                  <div className="d-block px-3 pb-3">
                    <p>
                      Need help with the hazard report process? Call the Health
                      & Safety Advice Line on
                      <Link to="tel:1300 029 198">
                        0431 619 015,0406 400 994
                      </Link>
                    </p>
                  </div>
                </div>
                {/* <Documentsnotes /> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};
