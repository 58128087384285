import React, { useState, useEffect } from "react";
import axios from "axios";
import { message, Form, Input, Button, Alert } from "antd";
import { Link, useNavigate } from "react-router-dom";
import TextArea from "antd/es/input/TextArea";
import AdminHeader from "./AdminHeader";
import AdminFooter from "./AdminFooter";
export const AdminAddclient = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const changeFavicon = (url) => {
    const favicon = document.querySelector('link[rel="icon"]');
    if (favicon) {
      favicon.href = url;
    }
  };
  useEffect(() => {
    changeFavicon(require("../assets/admin/images/f-icon.png"));
    document.title = "Add New Client";
    const userStr = JSON.parse(localStorage.getItem("admin"));
    if (userStr == null) {
      navigate("/admin/login");
    }
    axios
      .get("https://jlmining.app/admin/getclient", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log(res.data.results);
        setData(res.data.results);
      })
      .catch((err) => {});
  }, []);

  const [value, setValue] = useState("");
  const handleInputChange = (e) => {
    const inputValue = e.target.value;

    // Validate input as integer
    if (/^\d*$/.test(inputValue)) {
      setValue(inputValue);
    }
  };

  const [showAlert, setShowAlert] = useState(false);
  const handleSubmit = (event) => {
    event.preventDefault();
    var vll = event.target;
    let formdata = {
      email: vll.email.value,
      name: vll.name.value,
      position: vll.position.value,
      department: vll.department.value,
      phone_number: vll.phone_number.value,
      mobile_number: vll.mobile_number.value,
      home_phone_number: vll.home_phone_number.value,
      fax_number: vll.fax_number.value,
    };
    axios
      .post("https://jlmining.app/admin/addclient", formdata, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        // console.log(res.data);
        if (res.data.status == "2") {
          setShowAlert(true);
          setShowAlert({ type: "error" });
        } else {
          axios
            .get("https://jlmining.app/admin/getclient", {
              headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "POST,PUT",
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            })
            .then((res) => {
              console.log(res.data.results);
              setData(res.data.results);
            })
            .catch((err) => {});
          setShowAlert(true);
          setShowAlert({ type: "success" });
          event.target.reset();
          //(event.target).text()
        }
        setTimeout(() => {
          setShowAlert(false);
        }, 4500);
      })
      .catch((err) => {});
  };

  return (
    <>
      <div>
        <AdminHeader />
        <div className="main-right-body">
          <div className="block-full">
            <div className="row gy-4">
              <div className="col-md-4">
                <div className="d-block red-heading bg-white shadow p-2">
                  <h3 className="mb-3">Clients</h3>
                  <div className="over-empl">
                    <div className="d-flex flex-column h-100">
                      {Array.isArray(data) ? (
                        data.map((item, index) => (
                          <Link to="">{item.name}</Link>
                        ))
                      ) : (
                        <a href="#"> No data available </a>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-8">
                {showAlert?.type === "error" && (
                  <Alert
                    type="error"
                    description="Email is already exist"
                    closable
                  />
                )}
                {showAlert?.type === "success" && (
                  <Alert
                    type="success"
                    description="Client has been successfully created!"
                    closable
                  />
                )}
                <div className="right-form-box bg-white shadow p-2">
                  <form action="" onSubmit={handleSubmit}>
                    <div className="row gy-2">
                      <div className="col-12 col-lg-6">
                        <div className="d-flex flex-column gap-2">
                          <label for="">Client Name </label>
                          <input
                            type="text"
                            name="name"
                            placeholder="type here"
                          />
                        </div>
                      </div>

                      <div className="col-12 col-lg-6">
                        <div className="d-flex flex-column gap-2">
                          <label for="">Position </label>
                          <input
                            type="text"
                            name="position"
                            placeholder="type here"
                          />
                        </div>
                      </div>
                      <div className="col-12 col-lg-6">
                        <div className="d-flex flex-column gap-2">
                          <label for="">Department </label>
                          <input
                            type="text"
                            name="department"
                            placeholder="type here"
                          />
                        </div>
                      </div>
                      <div className="col-12 col-lg-6">
                        <div className="d-flex flex-column gap-2">
                          <label for="">Work Phone Number </label>
                          <input
                            type="text"
                            pattern="[0-9]*"
                            minlength="10"
                            onChange={handleInputChange}
                            name="phone_number"
                            placeholder="type here"
                          />
                        </div>
                      </div>
                      <div className="col-12 col-lg-6">
                        <div className="d-flex flex-column gap-2">
                          <label for="">Mobile Phone Number </label>
                          <input
                            type="text"
                            pattern="[0-9]*"
                            minlength="10"
                            onChange={handleInputChange}
                            name="mobile_number"
                            placeholder="type here"
                          />
                        </div>
                      </div>
                      <div className="col-12 col-lg-6">
                        <div className="d-flex flex-column gap-2">
                          <label for="">Emergency Contact Number </label>
                          <input
                            type="text"
                            pattern="[0-9]*"
                            minlength="10"
                            onChange={handleInputChange}
                            name="home_phone_number"
                            placeholder="type here"
                          />
                        </div>
                      </div>
                      <div className="col-12 col-lg-6">
                        <div className="d-flex flex-column gap-2">
                          <label for="">Fax Number </label>
                          <input
                            type="text"
                            name="fax_number"
                            placeholder="type here"
                          />
                        </div>
                      </div>
                      <div className="col-12 col-lg-6">
                        <div className="d-flex flex-column gap-2">
                          <label for="">
                            Email-Id{" "}
                            <span className="text-danger fs-14">*</span>
                          </label>
                          <input
                            type="email"
                            required
                            name="email"
                            placeholder="type here"
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="d-flex align-items-center gap-3 justify-content-end">
                          <button className="sub-btn" type="submit">
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <AdminFooter />
      </div>
    </>
  );
};
