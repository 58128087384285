import React, { useRef, useState, useEffect } from "react";
import axios from "axios";
import { message, Form, Input, Button, Alert } from "antd";
import { useNavigate, Link } from "react-router-dom";
import TextArea from "antd/es/input/TextArea";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import { FaTrash } from "react-icons/fa";
import Slider from "react-slick";
import AdminHeader from "../AdminHeader";
import AdminFooter from "../AdminFooter";

export const Assignment = () => {
  const inputFileRef = useRef(null);
  const navigate = useNavigate();
  const changeFavicon = (url) => {
    const favicon = document.querySelector('link[rel="icon"]');
    if (favicon) {
      favicon.href = url;
    }
  };
  const [checkedCourses, setCheckedCourses] = useState([]);
  const [closedd, setclosedd] = useState(false);
  const [openn, setopenn] = useState(true);
  const [activeTab, setactiveTab] = useState("first-tab");
  const [showAlert_r, setShowAlert_r] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);
  const [values, setValues] = useState({});
  const [val, setVal] = useState("");
  const [getincidentData, setgetincidentData] = useState([]);
  const [getincidentDataLength, setgetincidentDataLength] = useState([]);
  const [ispinnerset, spinnerset] = useState(false);
  const [filemain, setfilemain] = useState("");
  const [isallcourse, setallcourse] = useState([]);
  const [step1, setstep1] = useState(true);
  const [stepsection_1, setstepsection_1] = useState(true);
  const [stepsection_2, setstepsection_2] = useState(true);
  const [step2, setstep2] = useState(false);
  const [isalluser, setisalluser] = useState([]);
  useEffect(() => {
    document.title = "Assign New Course Page";
    const userStr = JSON.parse(localStorage.getItem("admin"));
    console.log(userStr);
    if (userStr == null) {
      navigate("/admin/login");
    }
    let formdata = {
      table: "elearningCourse",
    };
    axios
      .post("https://jlmining.app/getselectCommonForhazard", formdata, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setallcourse(res.data.results);
      })
      .catch((err) => {});

    axios
      .post("https://jlmining.app/getalluseradmin", formdata, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setisalluser(res.data.results);
      })
      .catch((err) => {});
    console.log(checkedCourses.length);
    if (checkedCourses.length > 0) {
      setstep1(false);
    } else {
      setstep1(true);
    }
  }, [checkedCourses]);

  //Get User Time Sheet
  const handsubmit = (event) => {
    let formdata = {
      title: event.target.title.value,
      description: event.target.description.value,
      file: filemain,
    };
    axios
      .post("https://jlmining.app/admin/elearningsave", formdata, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        event.target.reset();
        if (inputFileRef.current) {
          inputFileRef.current.value = "";
        }
        setfilemain("");
        setallcourse(res.data.results);
        if (res.data.results.length > 0) {
          setShowAlert(true);
          setShowAlert({ type: "success" });
          setTimeout(() => {
            setShowAlert(false);
          }, 4500);
        }
      })
      .catch((err) => {});
  };
  const docfile = (event) => {
    const file = event.target.files[0]; // Get the first file from the input
    const file_Name = file.name; // Get the file name
    if (file_Name != "") {
      setfilemain(file);
    } else {
      setfilemain("");
    }
  };
  const showhide = (e, index) => {
    setActiveIndex(index);
  };
  const removeskills = (e, index) => {
    let formdata = {
      id: e,
    };
    axios
      .post("https://jlmining.app/admin/removecourse", formdata, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setallcourse(res.data.results);
        setShowAlert_r(true);
        setShowAlert_r({ type: "success" });
        setTimeout(() => {
          setShowAlert(false);
          setShowAlert_r(false);
        }, 4500);
      })
      .catch((err) => {});
  };
  const checkopt = (event) => {
    setactiveTab(event);
    if (event === "home-tab") {
      setopenn(true);
      setclosedd(false);
    } else {
      setopenn(false);
      setclosedd(true);
    }
  };
  const searchinput = (event) => {
    var vl = event.target.value;
    let formdata = {
      search: vl,
    };
    console.log(formdata);
    axios
      .post("https://jlmining.app/searchcourse", formdata, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setallcourse(res.data.results);
      })
      .catch((err) => {});
  };
  const checkbutton = (id) => {
    const isChecked = checkedCourses.includes(id);
    if (isChecked) {
      setCheckedCourses(checkedCourses.filter((courseId) => courseId !== id));
    } else {
      setCheckedCourses([...checkedCourses, id]);
    }
  };
  const nextstep = (event) => {
    if (event === "1") {
      if (checkedCourses.length > 0) {
        setstepsection_2(true);
        setstepsection_1(false);
      }
      setactiveTab("second-tab");
    }
    if (event === "2") {
      if (checkedCourses.length > 0) {
        setstepsection_2(true);
        setstepsection_1(false);
      }
      setactiveTab("third-tab");
    }
  };
  const apiurl = "https://jlmining.app/uploads/elearning";
  return (
    <>
      <div>
        <AdminHeader />
        <div className="main-right-body">
          <div className="block-full">
            <div className="row mb-4">
              <h4>Assign New Course</h4>
              <div className="col-md-12">
                <ul className="nav nav-tabs inci-tab" id="myTab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button
                      className={`nav-link ${
                        activeTab === "first-tab" ? "active" : ""
                      }`}
                      id="home-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#home-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="home-tab-pane"
                      aria-selected="true"
                    >
                      Select courses
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className={`nav-link ${
                        activeTab === "second-tab" ? "active" : ""
                      }`}
                      id="closed-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#closed-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="closed-tab-pane"
                      aria-selected="true"
                    >
                      Select employees
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className={`nav-link ${
                        activeTab === "third-tab" ? "active" : ""
                      }`}
                      id="closed-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#closed-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="closed-tab-pane"
                      aria-selected="true"
                    >
                      Set due date
                    </button>
                  </li>
                </ul>
              </div>
            </div>
            {stepsection_1 && (
              <div className="row">
                <div className="d-flex">
                  <div className="col-md-6">
                    <label>Search</label>
                    <br />
                    <input
                      type="text"
                      class="serch"
                      onInput={searchinput}
                      name="daterange"
                      placeholder="Title, description"
                    />
                    {ispinnerset && (
                      <span className="spinner-border text-success mb-2"></span>
                    )}
                  </div>
                  <div className="col-md-6"></div>
                </div>
              </div>
            )}
            {stepsection_1 && (
              <div className="row">
                <div className="container">
                  <div style={{ height: "350px", overflow: "scroll" }}>
                    {isallcourse.map((item, index) => (
                      <div className="optionss">
                        <label>
                          <div className="d-flex gap-3 ">
                            <div className="flex-shrink-0">
                              <div className="dataimg">
                                <img
                                  src={apiurl + "/" + item.file}
                                  alt="image"
                                />
                              </div>
                            </div>

                            <div className="flex-grow-1">
                              <div className="d-flex flex-column gap-2 data justify-content-center">
                                <span className="cotile">
                                  {item.title}{" "}
                                  <span className="cotiming">
                                    <svg
                                      width="16"
                                      height="16"
                                      viewBox="0 0 32 32"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path d="M16 29.875C8.275 29.875 2 23.6 2 15.875C2 8.15 8.275 1.875 16 1.875C23.725 1.875 30 8.15 30 15.875C30 23.6 23.725 29.875 16 29.875ZM16 3.875C9.387 3.875 4 9.262 4 15.875C4 22.488 9.387 27.875 16 27.875C22.613 27.875 28 22.488 28 15.875C28 9.262 22.613 3.875 16 3.875Z"></path>
                                      <path d="M23 18H15C14.45 18 14 17.55 14 17V9C14 8.45 14.45 8 15 8C15.55 8 16 8.45 16 9V16H23C23.55 16 24 16.45 24 17C24 17.55 23.55 18 23 18Z"></path>
                                    </svg>{" "}
                                    40 minutes{" "}
                                  </span>{" "}
                                </span>
                                <p>{item.description}</p>
                              </div>
                            </div>
                          </div>
                          <input
                            type="checkbox"
                            onChange={() => checkbutton(item.id)}
                            name="course"
                          />
                          <span className="checkmarks"></span>
                        </label>
                      </div>
                    ))}
                  </div>
                  <div className="col-12 mt-4">
                    <div className="d-flex align-items-center gap-3 justify-content-end">
                      <button className="sub-btn rounded p-2">Cancel</button>
                      <button
                        className={`sub-btn rounded p-2 ${
                          step1 ? "step1" : ""
                        }`}
                        type="button"
                        onClick={() => nextstep("1")}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {stepsection_2 && (
              <div className="row">
                <div className="d-flex">
                  <div className="col-md-6">
                    <label>Search</label>
                    <br />
                    <input
                      type="text"
                      class="serch"
                      onInput={searchinput}
                      name="daterange"
                      placeholder="Name, job, site"
                    />
                    {ispinnerset && (
                      <span className="spinner-border text-success mb-2"></span>
                    )}
                  </div>
                  <div className="col-md-6"></div>
                </div>
              </div>
            )}
            {stepsection_2 && (
              <div className="row">
                <div className="container">
                  <div style={{ height: "350px", overflow: "scroll" }}>
                    {isalluser.map((item, index) => (
                      <div className="optionss" style={{ height: "70px" }}>
                        <label>
                          <div className="d-flex gap-3 ">
                            <div className="flex-shrink-0">
                              <div className="dataimg ">
                                <div
                                  className="rounded-circle"
                                  style={{
                                    width: "30px",
                                    height: "30px",
                                    backgroundColor: "#c62026",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    borderRadius: "50%",
                                    color: "white",
                                    margin: "15px",
                                  }}
                                >
                                  {item.first_name.charAt(0)}{" "}
                                  {item.last_name.charAt(0)}
                                </div>
                              </div>
                            </div>

                            <div className="flex-grow-1">
                              <div className="d-flex flex-column gap-2 data justify-content-center">
                                <span className="cotile">
                                  {item.first_name} {item.middle_name}
                                  {item.last_name}
                                </span>
                                <p>
                                  <strong>Job</strong> Not specified
                                </p>
                              </div>
                            </div>
                          </div>
                          <input
                            type="checkbox"
                            onChange={() => checkbutton(item.id)}
                            name="course"
                          />
                          <span className="checkmarks"></span>
                        </label>
                      </div>
                    ))}
                  </div>
                  <div className="col-12 mt-4">
                    <div className="d-flex align-items-center gap-3 justify-content-end">
                      <button className="sub-btn rounded p-2">Cancel</button>
                      <button
                        className={`sub-btn rounded p-2 ${
                          step2 ? "step1" : ""
                        }`}
                        type="button"
                        onClick={() => nextstep("2")}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <AdminFooter />
      </div>
    </>
  );
};
