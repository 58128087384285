import React, { useRef, useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";

export const EmployeeResume = () => {
  //useKeyboardShortcutBlocker();
  const [resumedata, setresumedata] = useState("");
  const [licenseArray, setlicenseArray] = useState([]);
  const [educationsArray, seteducations] = useState([]);
  const [skillsArray, setskillsArray] = useState([]);
  const [licfile, setlicfile] = useState([]);
  const [edfile, setedfile] = useState([]);
  const [vocationalArray, setvocationalArray] = useState([]);

  const [tradetextArray, settradetextArray] = useState([]);
  const [equipmentWorked, setEquipmentWorked] = useState([]);
  const [tradefile, setedtradefile] = useState([]);
  const [certm, setedcertif_file] = useState([]);
  const [machinetextArray, setmachinetextArray] = useState([]);
  const [machinefileArray, semachinefile] = useState([]);

  const [refreArray, setrefreArray] = useState([]);
  const [prevworkArray, setprevworkArray] = useState([]);
  const [historyArray, sethostoryArray] = useState([]);
  const currentYear = new Date().getFullYear();
  const navigate = useNavigate();
  const changeFavicon = (url) => {
    const favicon = document.querySelector('link[rel="icon"]');
    if (favicon) {
      favicon.href = url;
    }
  };
  const apiurl = "https://jlmining.app/uploads/";

  const empid = useParams();

  useEffect(() => {
    changeFavicon(require("../assets/admin/images/f-icon.png"));
    const userStr = JSON.parse(localStorage.getItem("admin"));
    if (userStr == null) {
      navigate("/admin/login");
    }
    if (empid === null) {
      navigate("/admin/employee");
    }

    let formdata = {
      userId: empid.id,
    };
    axios
      .post("https://jlmining.app/admin/getuser", formdata, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log(res.data.row);
        if (res.data.row.length !== 0) {
          setresumedata(res.data.row[0]);

          if (res.data.row[0].licence != null) {
            const resultArrays = JSON.parse(res.data.row[0].licence);
            setlicenseArray(resultArrays);
          }
          //Lic file
          if (res.data.row[0].licence_file != null) {
            const lf = JSON.parse(res.data.row[0].licence_file);
            setlicfile(lf);
          }

          //edu
          if (res.data.row[0].certificate_file != null) {
            const edd = JSON.parse(res.data.row[0].certificate_file);
            console.log(edd);
            setedfile(edd);
          }
          //Education
          if (res.data.row[0].education != null) {
            const educations = JSON.parse(res.data.row[0].education);
            seteducations(educations);
          }

          //Skills
          if (res.data.row[0].skills != null) {
            const ss = JSON.parse(res.data.row[0].skills);
            setskillsArray(ss);
          }
          // console.log(skillsArray);

          //vocational
          if (res.data.row[0].vocational_training != null) {
            const sssv = JSON.parse(res.data.row[0].vocational_training);
            setvocationalArray(sssv);
          }

          //History

          const h = JSON.parse(res.data.row[0].employmentHistorySections);
          if (h != null) {
            sethostoryArray(h);
          }

          //refre
          const refreh = JSON.parse(res.data.row[0].references);
          if (res.data.row[0].previous_work !== null) {
            const prevw = JSON.parse(res.data.row[0].previous_work);
            setprevworkArray(prevw);
          }
          //console.log(refreh);
          if (res.data.row[0].certificate != null) {
            const sssvtc = JSON.parse(res.data.row[0].certificate);
            setedcertif_file(sssvtc);
          }

          //Trade Text certificate
          if (res.data.row[0].trade != null) {
            const sssvt = JSON.parse(res.data.row[0].trade);
            setedtradefile(sssvt);
          }
          if (res.data.row[0].trade_file != null) {
            const eddt = JSON.parse(res.data.row[0].trade_file);
            console.log(eddt);
            settradetextArray(eddt);
          }
          //Trade Text Certificate

          //Machinary
          if (res.data.row[0].machinery != null) {
            const sssvm = JSON.parse(res.data.row[0].machinery);
            semachinefile(sssvm);
          }
          if (res.data.row[0].machinery_file != null) {
            const eddm = JSON.parse(res.data.row[0].machinery_file);
            setmachinetextArray(eddm);
          }

          //Machinary
          if (res.data.row[0].equipment_work != null) {
            const eqp = JSON.parse(res.data.row[0].equipment_work);
            setEquipmentWorked(eqp);
          }
          //Eq Worked
          if (refreh != null) {
            setrefreArray(refreh);
          }
        } else {
          navigate("/admin/employee");
        }
      })
      .catch((err) => {
        //console.log(err.response.data);
      });
  }, []);
  function getYearFromDate(dateString) {
    const dateObject = new Date(dateString);
    return dateObject.getFullYear();
  }
  const dynamicImagePath = require("../assets/images/background/bg.jpg");
  const tableStyle1 = {
    border: "none",
    borderSpacing: 0,
    width: "100%",
    padding: "1cm",
    height: "297mm",
  };

  const containerStyle = {
    background: `url(${dynamicImagePath}) no-repeat center top`,
    backgroundSize: "cover",
    textAlign: "center",
    height: "100vh",
  };

  const tableStyle = {
    border: "none",
    borderSpacing: 0,
    textAlign: "center",
    width: "100%",
    marginTop: "100px",
  };

  const textStyle = {
    textAlign: "center",
    color: "#fff",
    fontSize: "18px",
    textTransform: "capitalize",
    fontFamily: "'Times New Roman', Times, serif",
  };
  const mainPageStyles = {
    width: "210mm",
    minHeight: "297mm",
    background: "white",
    margin: "10mm auto",
    boxShadow: "0 0 0.5cm rgba(0, 0, 0, 0.5)",
  };

  const printStyles = {
    "@media print": {
      html: {
        width: "210mm",
        height: "297mm",
      },
      body: {
        width: "210mm",
        height: "297mm",
        WebkitPrintColorAdjust: "exact",
        position: "relative",
        margin: 0,
      },
      ".main-page": {
        margin: "0",
        border: "initial",
        borderRadius: "initial",
        width: "initial",
        minHeight: "initial",
        boxShadow: "initial",
        background: "initial",
        pageBreakAfter: "always",
      },
    },
  };
  const mergedStyles = {
    ...containerStyle,
    ...printStyles,
  };
  return (
    <>
      <div style={mainPageStyles}>
        <table style={tableStyle1}>
          <tr>
            <td style={mergedStyles}>
              <table
                style={{
                  border: "none",
                  borderSpacing: 0,
                  textAlign: "center",
                  width: "100%",
                  height: "100vh",
                }}
              >
                <tr>
                  <td
                    style={{
                      textAlign: "center",
                      color: "#fff",
                      fontSize: "22px",
                      textTransform: "capitalize",
                      fontFamily: "'Times New Roman', Times, serif",
                    }}
                  >
                    Confidential
                  </td>
                </tr>
              </table>
              <table
                style={{
                  border: "none",
                  borderSpacing: 0,
                  textAlign: "center",
                  width: "100%",
                }}
              >
                <tr>
                  <td
                    style={{
                      textAlign: "center",
                      color: "#fff",
                      fontSize: "30px",
                      textTransform: "capitalize",
                      fontFamily: "'Times New Roman', Times, serif",
                    }}
                  >
                    CURRICULUM VITAE
                  </td>
                </tr>
              </table>
              <table style={{ ...tableStyle, marginBottom: "200px" }}>
                <tr>
                  <td style={textStyle}>MOBILE: {resumedata.contact}</td>
                </tr>
                <tr>
                  <td style={textStyle}>EMAIL: {resumedata.email}</td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td
              style={{
                background: `url(${dynamicImagePath}) no-repeat center top`,
                backgroundSize: "100% 100%",
                textAlign: "center",
                height: "100vh",
              }}
            >
              <table
                style={{
                  border: "none",
                  borderSpacing: 0,
                  textAlign: "center",
                  width: "100%",
                  marginTop: "100px",
                }}
              >
                <tr>
                  <td
                    style={{
                      textAlign: "center",
                      color: "#fff",
                      fontSize: "35px",
                      textTransform: "capitalize",
                      fontFamily: "Arial, Helvetica, sans-serif",
                      fontWeight: 900,
                    }}
                  >
                    {resumedata.first_name} {resumedata.middle_name}{" "}
                    {resumedata.last_name}
                  </td>
                </tr>
              </table>

              <table
                style={{
                  border: "none",
                  borderSpacing: 0,
                  textAlign: "center",
                  marginTop: "60px",
                  width: "100%",
                }}
              >
                <tr>
                  <td
                    style={{
                      textAlign: "center",
                      color: "#fff",
                      textTransform: "capitalize",
                      fontFamily: "Arial, Helvetica, sans-serif, serif",
                      fontSize: "30px",
                      fontWeight: 900,
                      textDecoration: "underline",
                    }}
                  >
                    PERSONAL PROFILE
                  </td>
                </tr>
              </table>

              <table
                style={{
                  border: "none",
                  borderSpacing: 0,
                  textAlign: "center",
                  width: "600px",
                  margin: "60px auto 0",
                }}
              >
                <tr>
                  <td
                    style={{
                      color: "#fff",
                      fontSize: "20px",
                      textAlign: "left",
                      paddingBottom: "15px",
                      fontWeight: 800,
                      fontFamily: "Arial, Helvetica, sans-serif",
                    }}
                  >
                    ADDRESS:
                  </td>
                  <td
                    style={{
                      color: "#fff",
                      fontSize: "18px",
                      textAlign: "left",
                      paddingBottom: "15px",
                      fontWeight: 800,
                      fontFamily: "Arial, Helvetica, sans-serif",
                    }}
                  >
                    {resumedata.address}
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      color: "#fff",
                      fontSize: "20px",
                      textAlign: "left",
                      paddingBottom: "15px",
                      fontWeight: 800,
                      fontFamily: "Arial, Helvetica, sans-serif",
                    }}
                  >
                    MOBILE:
                  </td>
                  <td
                    style={{
                      color: "#fff",
                      fontSize: "18px",
                      textAlign: "left",
                      paddingBottom: "15px",
                      fontWeight: 800,
                      fontFamily: "Arial, Helvetica, sans-serif",
                    }}
                  >
                    {resumedata.contact}
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      color: "#fff",
                      fontSize: "20px",
                      textAlign: "left",
                      fontWeight: 800,
                      paddingBottom: "15px",
                      fontFamily: "Arial, Helvetica, sans-serif",
                    }}
                  >
                    EMAIL:
                  </td>
                  <td
                    style={{
                      color: "#fff",
                      fontSize: "18px",
                      textAlign: "left",
                      fontWeight: 800,
                      paddingBottom: "15px",
                      fontFamily: "Arial, Helvetica, sans-serif",
                    }}
                  >
                    {resumedata.email}
                  </td>
                </tr>
              </table>
              <table
                style={{
                  border: "none",
                  borderSpacing: 0,
                  textAlign: "center",
                  width: "100%",
                  marginTop: "100px",
                }}
              >
                <tr>
                  <td
                    style={{
                      textAlign: "center",
                      color: "#fff",
                      fontSize: "35px",
                      textTransform: "capitalize",
                      fontFamily: "Arial, Helvetica, sans-serif, serif",
                      fontWeight: 900,
                      textDecoration: "underline",
                    }}
                  >
                    EDUCATION
                  </td>
                </tr>
              </table>

              <table
                style={{
                  border: "none",
                  borderSpacing: 0,
                  textAlign: "center",
                  fontWeight: 800,
                  width: "600px",
                  margin: "30px auto 100px",
                }}
              >
                {educationsArray.map((item) => (
                  <tr>
                    <td
                      style={{
                        color: "#fff",
                        fontSize: "20px",
                        textAlign: "left",
                        paddingBottom: "20px",
                        width: "200px",
                        fontFamily: "Arial, Helvetica, sans-serif",
                      }}
                    >
                      {item.year}
                    </td>
                    <td
                      style={{
                        color: "#fff",
                        fontSize: "18px",
                        textAlign: "left",
                        paddingBottom: "18px",
                        width: "200px",
                        fontFamily: "Arial, Helvetica, sans-serif",
                      }}
                    >
                      {item.course}
                      <br />
                      {item.institution}
                    </td>
                  </tr>
                ))}
              </table>
              {/* ... Additional tables for Licences, Education, etc. ... */}
            </td>
          </tr>
          <tr>
            <td
              style={{
                background: `url(${dynamicImagePath}) no-repeat center top`,
                backgroundSize: "cover",
                height: "100vh",
              }}
            >
              <table
                style={{
                  border: "none",
                  borderSpacing: 0,
                  textAlign: "center",
                  width: "100%",
                }}
              >
                <tr>
                  <td
                    style={{
                      textAlign: "center",
                      color: "#fff",
                      fontSize: "34px",
                      textTransform: "capitalize",
                      fontWeight: 900,
                      textDecoration: "underline",
                      fontFamily: "Arial, Helvetica, sans-serif, serif",
                    }}
                  >
                    LICENCES
                  </td>
                </tr>
              </table>
              <table
                style={{
                  border: "none",
                  borderSpacing: 0,
                  textAlign: "center",
                  width: "600px",
                  margin: "60px auto 0",
                }}
              >
                <tr>
                  <td
                    style={{
                      color: "#fff",
                      fontSize: "20px",
                      textAlign: "left",
                      paddingBottom: "10px",
                      fontWeight: 800,
                      width: "200px",
                      fontFamily: "Arial, Helvetica, sans-serif",
                    }}
                  >
                    WESTERN AUSTRALIA CLASSES:
                  </td>
                </tr>
                {licenseArray !== null && (
                  <>
                    {licenseArray.map((license, index) => (
                      <tr key={index}>
                        <td
                          style={{
                            color: "#fff",
                            fontSize: "18px",
                            textAlign: "left",
                            paddingBottom: "10px",
                            width: "200px",
                            fontFamily: "Arial, Helvetica, sans-serif",
                          }}
                        ></td>
                        <td
                          style={{
                            color: "#fff",
                            fontSize: "18px",
                            textAlign: "left",
                            paddingBottom: "10px",
                            width: "200px",
                            fontWeight: 800,
                            fontFamily: "Arial, Helvetica, sans-serif",
                          }}
                          key={index}
                        >
                          {license}
                        </td>
                      </tr>
                    ))}
                  </>
                )}
              </table>
              {licfile !== null && (
                <>
                  {licfile.length > 0 ? (
                    <table
                      style={{
                        border: "none",
                        borderSpacing: 0,
                        textAlign: "center",
                        width: "600px",
                        margin: "60px auto 0",
                      }}
                    >
                      <tbody>
                        {licfile
                          .reduce((rows, licensef, index) => {
                            const currentRow = Math.floor(index / 1);

                            // Initialize a new row if it doesn't exist
                            if (!rows[currentRow]) {
                              rows[currentRow] = [];
                            }

                            // Add the current image to the row
                            rows[currentRow].push(
                              <td
                                key={index}
                                style={{
                                  width: "300px",
                                  padding: "6px",
                                }}
                              >
                                <img
                                  src={`${apiurl}${licensef}`}
                                  alt="image"
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "contain",
                                  }}
                                />
                              </td>
                            );

                            return rows;
                          }, [])
                          .map((row, rowIndex) => (
                            <tr key={rowIndex}>{row}</tr>
                          ))}
                      </tbody>
                    </table>
                  ) : (
                    <p></p>
                  )}
                </>
              )}

              <table
                style={{
                  border: "none",
                  borderSpacing: 0,
                  textAlign: "center",
                  marginTop: "60px",
                  width: "100%",
                }}
              >
                <tr>
                  <td
                    style={{
                      textAlign: "center",
                      color: "#fff",
                      fontSize: "35px",
                      textTransform: "capitalize",
                      fontFamily: "Arial, Helvetica, sans-serif, serif",
                      fontWeight: 900,
                      textDecoration: "underline",
                    }}
                  >
                    MENTION CERTIFICATE
                  </td>
                </tr>
              </table>
              {certm.length > 0 ? (
                <table
                  style={{
                    border: "none",
                    borderSpacing: 0,
                    textAlign: "center",
                    width: "600px",
                    margin: "40px auto 0",
                  }}
                >
                  {certm.map((item) => (
                    <tr>
                      <td
                        style={{
                          color: "#fff",
                          fontSize: "18px",
                          textAlign: "left",
                          paddingBottom: "15px",
                          width: "300px",
                          fontWeight: 800,
                          fontFamily: "Arial, Helvetica, sans-serif",
                        }}
                      >
                        • {item}
                      </td>
                    </tr>
                  ))}
                </table>
              ) : (
                <p>
                  {/* Render some default content or a message when licfile is empty */}
                </p>
              )}
              {!edfile || edfile.length === 0 ? (
                <p></p> // or any other message or component
              ) : (
                <table
                  style={{
                    border: "none",
                    borderSpacing: 0,
                    textAlign: "center",
                    width: "600px",
                    margin: "50px auto 0",
                  }}
                >
                  <tbody>
                    {edfile
                      .reduce((rows, licensef, index) => {
                        const currentRow = Math.floor(index / 1);

                        // Initialize a new row if it doesn't exist
                        if (!rows[currentRow]) {
                          rows[currentRow] = [];
                        }

                        // Add the current image to the row
                        rows[currentRow].push(
                          <td
                            key={licensef}
                            style={{
                              width: "300px",
                              padding: "6px",
                            }}
                          >
                            <img
                              src={`${apiurl}${licensef}`}
                              alt="image"
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "contain",
                              }}
                            />
                          </td>
                        );

                        return rows;
                      }, [])
                      .map((row, rowIndex) => (
                        <tr key={rowIndex}>{row}</tr>
                      ))}
                  </tbody>
                </table>
              )}
            </td>
          </tr>
          {/******Trade */}
          <tr>
            <td
              style={{
                background: `url(${dynamicImagePath}) no-repeat center top`,
                backgroundSize: "cover",
                height: "100vh",
              }}
            >
              <table
                style={{
                  border: "none",
                  borderSpacing: 0,
                  textAlign: "center",
                  marginTop: "60px",
                  width: "100%",
                }}
              >
                <tr>
                  <td
                    style={{
                      textAlign: "center",
                      color: "#fff",
                      fontSize: "35px",
                      textTransform: "capitalize",
                      fontWeight: 900,
                      textDecoration: "underline",
                      fontFamily: "Arial, Helvetica, sans-serif, serif",
                    }}
                  >
                    TRADE
                  </td>
                </tr>
              </table>

              {tradefile.length > 0 ? (
                <table
                  style={{
                    border: "none",
                    borderSpacing: 0,
                    textAlign: "center",
                    width: "600px",
                    margin: "30px auto 0",
                  }}
                >
                  {tradefile.map((item) => (
                    <tr>
                      <td
                        style={{
                          color: "#fff",
                          fontSize: "18px",
                          textAlign: "left",
                          paddingBottom: "10px",
                          width: "300px",
                          fontWeight: 800,
                          fontFamily: "Arial, Helvetica, sans-serif",
                        }}
                      >
                        • {item}
                      </td>
                    </tr>
                  ))}
                </table>
              ) : (
                <p>
                  {/* Render some default content or a message when licfile is empty */}
                </p>
              )}
              <table
                style={{
                  border: "none",
                  borderSpacing: 0,
                  textAlign: "center",
                  marginTop: "30px",
                  width: "100%",
                }}
              >
                <tr>
                  <td
                    style={{
                      textAlign: "center",
                      color: "#fff",
                      fontSize: "35px",
                      textTransform: "capitalize",
                      fontWeight: 900,
                      textDecoration: "underline",
                      fontFamily: "Arial, Helvetica, sans-serif, serif",
                    }}
                  >
                    TRADE CERTIFICATE
                  </td>
                </tr>
              </table>
              {!tradetextArray || tradetextArray.length === 0 ? (
                <p></p> // or any other message or component
              ) : (
                <table
                  style={{
                    border: "none",
                    borderSpacing: 0,
                    textAlign: "center",
                    width: "600px",
                    margin: "50px auto 0",
                  }}
                >
                  <tbody>
                    {tradetextArray
                      .reduce((rows, licensef, index) => {
                        const currentRow = Math.floor(index / 1);

                        // Initialize a new row if it doesn't exist
                        if (!rows[currentRow]) {
                          rows[currentRow] = [];
                        }

                        // Add the current image to the row
                        rows[currentRow].push(
                          <td
                            key={index}
                            style={{
                              width: "300px",
                              padding: "6px",
                            }}
                          >
                            <img
                              src={`${apiurl}${licensef}`}
                              alt="image"
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "contain",
                              }}
                            />
                          </td>
                        );

                        return rows;
                      }, [])
                      .map((row, rowIndex) => (
                        <tr key={rowIndex}>{row}</tr>
                      ))}
                  </tbody>
                </table>
              )}

              {/*********Trade  End*/}
            </td>
          </tr>
          {/******Trade End*/}
          {/******Machinery */}
          <tr>
            <td
              style={{
                background: `url(${dynamicImagePath}) no-repeat center top`,
                backgroundSize: "cover",
                height: "100vh",
              }}
            >
              <table
                style={{
                  border: "none",
                  borderSpacing: 0,
                  textAlign: "center",
                  marginTop: "60px",
                  width: "100%",
                }}
              >
                <tr>
                  <td
                    style={{
                      textAlign: "center",
                      color: "#fff",
                      fontSize: "35px",
                      textTransform: "capitalize",
                      textDecoration: "underline",
                      fontWeight: 900,
                      fontFamily: "Arial, Helvetica, sans-serif, serif",
                    }}
                  >
                    MACHINAERY
                  </td>
                </tr>
              </table>

              {machinefileArray.length > 0 ? (
                <table
                  style={{
                    border: "none",
                    borderSpacing: 0,
                    textAlign: "center",
                    width: "600px",
                    margin: "50px auto 0",
                  }}
                >
                  {machinefileArray.map((item) => (
                    <tr>
                      <td
                        style={{
                          color: "#fff",
                          fontSize: "18px",
                          textAlign: "left",
                          paddingBottom: "10px",
                          width: "300px",
                          fontWeight: 800,
                          fontFamily: "Arial, Helvetica, sans-serif",
                        }}
                      >
                        • {item}
                      </td>
                    </tr>
                  ))}
                </table>
              ) : (
                <p>
                  {/* Render some default content or a message when licfile is empty */}
                </p>
              )}
              <table
                style={{
                  border: "none",
                  borderSpacing: 0,
                  textAlign: "center",
                  marginTop: "100px",
                  width: "100%",
                }}
              >
                <tr>
                  <td
                    style={{
                      textAlign: "center",
                      color: "#fff",
                      fontSize: "35px",
                      textTransform: "capitalize",
                      fontFamily: "Arial, Helvetica, sans-serif, serif",
                      fontWeight: 900,
                      textDecoration: "underline",
                    }}
                  >
                    MACHINERY CERTIFICATE
                  </td>
                </tr>
              </table>
              {!machinetextArray || machinetextArray.length === 0 ? (
                <p></p> // or any other message or component
              ) : (
                <table
                  style={{
                    border: "none",
                    borderSpacing: 0,
                    textAlign: "center",
                    width: "600px",
                    margin: "60px auto 0",
                  }}
                >
                  <tbody>
                    {machinetextArray
                      .reduce((rows, lic, licIndex) => {
                        const currentRow = Math.floor(licIndex / 1);

                        // Initialize a new row if it doesn't exist
                        if (!rows[currentRow]) {
                          rows[currentRow] = [];
                        }

                        // Add the current image to the row
                        rows[currentRow].push(
                          <td
                            key={licIndex}
                            style={{
                              width: "300px",
                              padding: "6px",
                            }}
                          >
                            <img
                              src={`${apiurl}${lic}`}
                              alt="image"
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "contain",
                              }}
                            />
                          </td>
                        );

                        return rows;
                      }, [])
                      .map((row, rowIndex) => (
                        <tr key={rowIndex}>{row}</tr>
                      ))}
                  </tbody>
                </table>
              )}

              {/*********Machinery  End*/}
            </td>
          </tr>
          {/******Machinery End*/}
          <tr>
            <td
              style={{
                background: `url(${dynamicImagePath}) no-repeat center`,
                backgroundSize: "cover",
                height: "100vh",
              }}
            >
              <table
                style={{
                  border: "none",
                  borderSpacing: 0,
                  textAlign: "center",
                  marginTop: "60px",
                  width: "100%",
                }}
              >
                <tbody>
                  <tr>
                    <td
                      style={{
                        textAlign: "center",
                        color: "#fff",
                        fontSize: "34px",
                        textTransform: "capitalize",
                        fontFamily: "Arial, Helvetica, sans-serif, serif",
                        textDecoration: "underline",
                        fontWeight: "900",
                      }}
                    >
                      SKILLS SUMMARY & EXPERIENCE
                    </td>
                  </tr>
                </tbody>
              </table>

              {/* Repairs & Servicing Table */}
              <table
                style={{
                  border: "none",
                  borderSpacing: 0,
                  textAlign: "center",
                  width: "600px",
                  margin: "40px auto 0",
                }}
              >
                <tr>
                  <td
                    style={{
                      color: "#fff",
                      fontSize: "18px",
                      textAlign: "left",
                      paddingBottom: "15px",
                      width: "100%",
                      fontFamily: "Arial, Helvetica, sans-serif",
                      textDecoration: "underline",
                      fontWeight: "800",
                    }}
                  >
                    REPAIRS & SERVICING OF THE FOLLOWING MACHINERY
                  </td>
                </tr>
              </table>

              {/* Machinery List Table */}
              <table
                style={{
                  border: "none",
                  borderSpacing: 0,
                  textAlign: "center",
                  width: "600px",
                  margin: "30px auto 70px",
                  fontWeight: "800",
                }}
              >
                {skillsArray.map((item) => (
                  <tr>
                    <td
                      style={{
                        color: "#fff",
                        fontSize: "18px",
                        textAlign: "left",
                        paddingBottom: "15px",
                        width: "100%",
                        fontFamily: "Arial, Helvetica, sans-serif",
                      }}
                    >
                      • {item}
                    </td>
                  </tr>
                ))}
              </table>
            </td>
          </tr>
          <tr>
            <td
              style={{
                background: `url(${dynamicImagePath}) no-repeat center top`,
                backgroundSize: "cover",
                height: "100vh",
              }}
            >
              <table
                style={{
                  border: "none",
                  borderSpacing: 0,
                  textAlign: "center",
                  marginTop: "100px",
                  width: "100%",
                }}
              >
                <tbody>
                  <tr>
                    <td
                      style={{
                        textAlign: "center",
                        color: "#fff",
                        fontSize: "35px",
                        textTransform: "capitalize",
                        fontFamily: "Arial, Helvetica, sans-serif, serif",
                        textDecoration: "underline",
                        fontWeight: "900",
                      }}
                    >
                      VOCATIONAL TRAINING
                    </td>
                  </tr>
                </tbody>
              </table>
              {vocationalArray.length > 0 ? (
                <table
                  style={{
                    border: "none",
                    borderSpacing: 0,
                    textAlign: "center",
                    width: "600px",
                    margin: "60px auto 0",
                  }}
                >
                  {vocationalArray.map((item) => (
                    <tr>
                      <td
                        style={{
                          color: "#fff",
                          fontSize: "18px",
                          textAlign: "left",
                          paddingBottom: "15px",
                          width: "300px",
                          fontWeight: "800",
                          fontFamily: "Arial, Helvetica, sans-serif",
                        }}
                      >
                        • {item}
                      </td>
                    </tr>
                  ))}
                </table>
              ) : (
                <p>
                  {/* Render some default content or a message when licfile is empty */}
                </p>
              )}
              <table
                style={{
                  border: "none",
                  borderSpacing: 0,
                  textAlign: "center",
                  marginTop: "100px",
                  width: "100%",
                }}
              >
                <tbody>
                  <tr>
                    <td
                      style={{
                        textAlign: "center",
                        color: "#fff",
                        fontSize: "35px",
                        textTransform: "capitalize",
                        fontFamily: "Arial, Helvetica, sans-serif, serif",
                        textDecoration: "underline",
                        fontWeight: "900",
                      }}
                    >
                      EQUIPMENT WORKED
                    </td>
                  </tr>
                </tbody>
              </table>
              {equipmentWorked.length > 0 ? (
                <table
                  style={{
                    border: "none",
                    borderSpacing: 0,
                    textAlign: "center",
                    width: "600px",
                    margin: "60px auto 0",
                  }}
                >
                  {equipmentWorked.map((item) => (
                    <tr>
                      <td
                        style={{
                          color: "#fff",
                          fontSize: "18px",
                          textAlign: "left",
                          paddingBottom: "15px",
                          width: "300px",
                          fontWeight: "800",
                          fontFamily: "Arial, Helvetica, sans-serif",
                        }}
                      >
                        • {item}
                      </td>
                    </tr>
                  ))}
                </table>
              ) : (
                <p>
                  {/* Render some default content or a message when licfile is empty */}
                </p>
              )}
            </td>

            {/*Eq Worked */}
          </tr>

          <tr>
            <td
              style={{
                background: `url(${dynamicImagePath}) no-repeat center top`,
                backgroundSize: "100% 100%",
                height: "100vh",
              }}
            >
              <table
                style={{
                  border: "none",
                  borderSpacing: 0,
                  textAlign: "center",
                  marginTop: "100px",
                  width: "100%",
                }}
              >
                <tbody>
                  <tr>
                    <td
                      style={{
                        textAlign: "center",
                        color: "#fff",
                        fontSize: "34px",
                        textTransform: "capitalize",
                        fontFamily: "Arial, Helvetica, sans-serif, serif",
                        fontWeight: "900",
                        textDecoration: "underline",
                      }}
                    >
                      EMPLOYMENT HISTORY
                    </td>
                  </tr>
                </tbody>
              </table>
              {historyArray.length > 0 ? (
                <table
                  style={{
                    border: "none",
                    borderSpacing: 0,
                    textAlign: "center",
                    width: "600px",
                    margin: "100px auto 60px",
                  }}
                >
                  {historyArray.map((item) => (
                    <tr>
                      <td
                        style={{
                          color: "#fff",
                          fontSize: "18px",
                          textAlign: "left",
                          paddingBottom: "15px",
                          width: "300px",
                          fontWeight: "800",
                          fontFamily: "Arial, Helvetica, sans-serif",
                        }}
                      >
                        {getYearFromDate(item.start_date)}-
                        {getYearFromDate(item.end_date)}: <br />
                        EMPLOYER: <br />
                        POSITION:
                      </td>
                      <td
                        style={{
                          color: "#fff",
                          fontSize: "18px",
                          textAlign: "left",
                          paddingBottom: "15px",
                          width: "300px",
                          fontFamily: "Arial, Helvetica, sans-serif",
                          fontWeight: "800",
                        }}
                      >
                        {item.company}
                        <br />
                        {item.role}
                        <br />
                        {item.company_done}
                      </td>
                    </tr>
                  ))}
                </table>
              ) : (
                <p>
                  {/* Render some default content or a message when licfile is empty */}
                </p>
              )}
              <table
                style={{
                  border: "none",
                  borderSpacing: 0,
                  textAlign: "center",
                  marginTop: "100px",
                  width: "100%",
                }}
              >
                <tbody>
                  <tr>
                    <td
                      style={{
                        textAlign: "center",
                        color: "#fff",
                        fontSize: "35px",
                        textTransform: "capitalize",
                        fontFamily: "Arial, Helvetica, sans-serif, serif",
                        fontWeight: "900",
                        textDecoration: "underline",
                      }}
                    >
                      {" "}
                      PREVIOUS WORK IN MINING
                    </td>
                  </tr>
                </tbody>
              </table>

              <table
                style={{
                  border: "none",
                  borderSpacing: 0,
                  textAlign: "center",
                  width: "500px",
                  margin: "40px auto 200px",
                }}
              >
                {prevworkArray.map((item) => (
                  <tr>
                    <td
                      style={{
                        textAlign: "left",
                        color: "#fff",
                        fontSize: "18px",
                        fontFamily: "Arial, Helvetica, sans-serif",
                        paddingBottom: "20px",
                        fontWeight: "800",
                      }}
                    >
                      • {item}
                    </td>
                  </tr>
                ))}
              </table>
            </td>
          </tr>
          <tr>
            <td
              style={{
                background: `url(${dynamicImagePath}) no-repeat center`,
                backgroundSize: "cover",
                backgroundPosition: "top center",
                height: "100vh",
              }}
            >
              <table
                style={{
                  border: "none",
                  borderSpacing: 0,
                  textAlign: "center",
                  marginTop: "100px",
                  width: "100%",
                }}
              >
                <tbody>
                  <tr>
                    <td
                      style={{
                        textAlign: "center",
                        color: "#fff",
                        fontSize: "35px",
                        textTransform: "capitalize",
                        fontFamily: "Arial, Helvetica, sans-serif, serif",
                        fontWeight: "900",
                        textDecoration: "underline",
                      }}
                    >
                      {" "}
                      REFERENCES
                    </td>
                  </tr>
                </tbody>
              </table>

              <table
                style={{
                  border: "none",
                  borderSpacing: 0,
                  textAlign: "center",
                  width: "500px",
                  margin: "40px auto 200px",
                }}
              >
                {refreArray.map((item) => (
                  <tr>
                    <td
                      style={{
                        textAlign: "left",
                        color: "#fff",
                        fontSize: "18px",
                        fontFamily: "Arial, Helvetica, sans-serif",
                        paddingBottom: "20px",
                        fontWeight: "800",
                      }}
                    >
                      • {item}
                    </td>
                  </tr>
                ))}
              </table>
            </td>
          </tr>
        </table>
      </div>
    </>
  );
};
