import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import SignaturePad from "react-signature-canvas";
import { Header } from "./Header";
import { Footer } from "./Footer";
export const Timesheet = () => {
  const currentYear = new Date().getFullYear();
  const [valueUserDetail, setValuegetuser] = useState([]);
  const [valueClient, setvalueClient] = useState([]);
  const [valueemployee, setvalueemployee] = useState([]);
  const [isClickedsuccess, setIsClickedSuccess] = useState(false);
  const [isClickedno, setIsClickedno] = useState(false);
  const [weekd, setweekd] = useState(false);
  const [isClickederror, setIsClickederror] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [signatureDataURL, setSignatureDataURL] = useState("");
  const isActive = true;
  const [startend, setstartend] = useState("");
  const [clname, setclname] = useState("");
  const [setarrvalue, setarr] = useState("");
  const [weekdetails, setweekdetails] = useState([]);
  const [weekarrdata, setweekarrdata] = useState([]);
  const [indx, setindx] = useState(null);
  const clear = () => {
    this.sigPad.clear();
  };
  const handleLogout = () => {
    localStorage.removeItem("users");
    window.location.href = "/login";
  };
  const navigate = useNavigate();
  const changeFavicon = (url) => {
    const favicon = document.querySelector('link[rel="icon"]');
    if (favicon) {
      favicon.href = url;
    }
  };
  useEffect(() => {
    changeFavicon(require("./assets/admin/images/f-icon.png"));
    document.title = "Signature Page";
    const userStr = JSON.parse(localStorage.getItem("users"));
    if (userStr === null) {
      window.location.href = "#/login";
    }
    let formdata = {
      user_id: userStr[0].id,
    };
    axios
      .post("https://jlmining.app/admin/getuserdetails", formdata, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        var alldata = res.data.row[0];
        setValuegetuser(alldata);
      })
      .catch((err) => {});

    //Get User Detail

    //Get User Detail

    //Get Client
    axios
      .post("https://jlmining.app/admin/getclientFuser", formdata, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        var alldata = res.data.results;
        setvalueClient(alldata);
        if (alldata.length > 0) {
          let formdataa = {
            user_id: userStr[0].id,
            client_id: alldata[0].client_id,
          };
          axios
            .post(
              "https://jlmining.app/users/getweeklytimesheetuser",
              formdataa,
              {
                headers: {
                  "Access-Control-Allow-Origin": "*",
                  "Access-Control-Allow-Methods": "POST,PUT",
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
              }
            )
            .then((res) => {
              setstartend(res.data.arr);
            })
            .catch((err) => {});
        }
      })
      .catch((err) => {});
    //Get Client
  }, []);

  const handlegetClientdata = (client_id, index, name) => {
    setindx(index);
    const userStr = JSON.parse(localStorage.getItem("users"));
    let formdata = {
      user_id: userStr[0].id,
      client_id: client_id,
    };
    setclname(name);
    axios
      .post("https://jlmining.app/admin/getEmployeeDetail", formdata, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res.data.row != "" && res.data.row != undefined) {
          var alldata = res.data.row[0];
          console.log("ss");
          console.log(res.data.row);
          setvalueemployee(alldata);
          setIsClicked(true);
          setIsClickedno(false);
        }
        if (res.data.row == undefined || res.data.row.length == 0) {
          setIsClicked(false);
          setIsClickedno(true);
        }
      })
      .catch((err) => {});
    axios
      .post("https://jlmining.app/users/getweeklytimesheetuser", formdata, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log(res.data.arr.length);
        setstartend(res.data.arr);
        if (res.data.arr.length > 0) {
          setarr("1");
        } else {
          setarr("");
        }
        console.log(setarrvalue);
      })
      .catch((err) => {});
  };
  const handleformsubmit = (event) => {
    const vl = event.target;
    console.log(vl.client_id.value);
    const canvas = canvasRef.current;
    const signatureData = canvas.toDataURL(); // Convert canvas to image data URL
    //return false;
    setSignatureDataURL(signatureData);

    if (vl.client_id.value) {
      let formdata = {
        client_id: vl.client_id.value,
        user_id: vl.user_id.value,
        start: weekarrdata.start,
        end: weekarrdata.end,
        signature_img: signatureData,
      };
      console.log(formdata);
      //return false;
      axios
        .post("https://jlmining.app/admin/employeAttendanceForm", formdata, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "POST,PUT",
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          setIsClicked(false);
          if (res.data.status == "2") {
            setIsClickederror(true);
            setIsClickedSuccess(false);
          } else {
            setIsClickederror(false);
            setIsClickedSuccess(true);
          }
          setTimeout(() => {
            window.location.reload();
          }, 4500);
        })
        .catch((err) => {});
    }
  };
  const canvasRef = useRef(null);
  const [isDrawing, setIsDrawing] = useState(false);

  const startDrawing = (e) => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    const rect = canvas.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    context.beginPath();
    context.moveTo(x, y);
    setIsDrawing(true);
  };

  const draw = (e) => {
    if (!isDrawing) return;

    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    const rect = canvas.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    context.lineTo(x, y);
    context.stroke();
  };

  const endDrawing = () => {
    setIsDrawing(false);
  };
  const clearCanvas = () => {
    canvasRef.current.clear();
  };
  const captureSignature = () => {
    const canvas = canvasRef.current;
    const signatureData = canvas.toDataURL(); // Convert canvas to image data URL
    setSignatureDataURL(signatureData);
  };
  const apiurl = "https://jlmining.app/uploads/";

  var imageUrl = `${apiurl}${valueUserDetail.image}`;
  if (valueUserDetail.image == null) {
    var im = "male-upload.png";
    var imageUrl = `${apiurl}${im}`;
  } else {
    var imageUrl = `${apiurl}${valueUserDetail.image}`;
  }
  const fname = valueUserDetail.first_name;
  const mname = valueUserDetail.middle_name;
  const lname = valueUserDetail.last_name;
  const fLetter = fname ? fname.charAt(0) : "";
  const mLetter = mname ? mname.charAt(0) : "";
  const lLetter = lname ? lname.charAt(0) : "";
  const sigPad = {};
  const getuserweeklydata = (start, end, user_id) => {
    let data = {
      start: start,
      end: end,
      user_id: user_id,
    };
    console.log(data);
    setweekarrdata(data);
    axios
      .post("https://jlmining.app/user/getuserweeklydataclient", data, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log(res.data.data);
        //setDataTimesheet(res.data.data);
        if (res.data.data.length > 0) {
          setweekd(true);
          setweekdetails(res.data.data);
        } else {
          setweekd(false);
        }
      })
      .catch((err) => {});
  };
  return (
    <>
      <Header />
      <section className="innerbanner text-center py-5 d-block">
        <div className="container-lg">
          <h3 className="fs-30 fw-800 text-white">Signature</h3>
        </div>
      </section>
      <section className="profile-box d-block">
        <div className="container-lg">
          <div className="row gy-4 align-items-center">
            <div className="col-md-4 col-lg-3">
              <div className="d-flex flex-column gap-2 text-center pic-emp">
                <div className="emplo-box-pic ofit mx-auto">
                  <img src={imageUrl} alt="image" />
                </div>
                <div className="d-flex flex-column">
                  <h3 className="fs-26 text-black fw-800">
                    {valueUserDetail.first_name} {valueUserDetail.middle_name}{" "}
                    {valueUserDetail.last_name}
                  </h3>
                  <h4 className="fs-18 text-black">{valueUserDetail.role}</h4>
                  {/* <p className="fs-12 text-black">{valueUserDetail.firstname}</p> */}
                </div>
              </div>
            </div>
            <div className="col-md-8 col-lg-9">
              <div className="row gy-2 emplo-textblock">
                <div className="col-md-6">
                  <h3 className="fs-16 text-black fw-400">
                    <span className="fw-800"> Phone no: </span>
                    {valueUserDetail.contact}
                  </h3>
                </div>
                <div className="col-md-6">
                  <h3 className="fs-16 text-black fw-400">
                    <span className="fw-800"> Email id: </span>
                    {valueUserDetail.email}
                  </h3>
                </div>
                <div className="col-12">
                  <h3 className="fs-16 text-black fw-400">
                    <span className="fw-800"> Address: </span>
                    {valueUserDetail.address}
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="d-block py-5">
        <div className="container-lg">
          <div className="row gy-4">
            <div className="col-md-3">
              <div className="d-flex flex-column gap-1">
                <h3 className="text-black fs-24 fw-bold mb-3">Select client</h3>

                <div className="d-flex flex-column gap-1">
                  {Array.isArray(valueClient) ? (
                    valueClient.map((item, index) => (
                      <Link
                        to="javascript:void(0)"
                        onClick={() =>
                          handlegetClientdata(item.client_id, index, item.name)
                        }
                        className={`client-btn-text d-block ${
                          index === indx && isActive ? "client-active" : ""
                        }`}
                      >
                        {item.name}
                      </Link>
                    ))
                  ) : (
                    <Link> No data available </Link>
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-9 col-lg-6">
              <div className="d-flex flex-column gap-2">
                {setarrvalue === "" ? (
                  <div className="d-flex flex-column mb-2 shadow border border-secondary border-opacity-25 p-4">
                    <h4 class="text-black fs-16 fw-400">
                      <p>No results found</p>
                    </h4>
                  </div>
                ) : (
                  // Render a message when no result is found
                  <p></p>
                )}

                {setarrvalue != "" ? (
                  <div className="d-flex flex-column gap-2 flex-md-row justify-content-between">
                    <div className="d-block red-heading p-2 w-100 w-md-50 maintimesheet">
                      {Array.isArray(startend) ? (
                        startend.map((item, index) => (
                          <div className="mb-2 fs-12 fw-bold shadow  timesheet p-2 text-center">
                            <Link
                              className="text-danger"
                              to="javascript:void(0)"
                              onClick={() =>
                                getuserweeklydata(
                                  item.start,
                                  item.end,
                                  item.user_id
                                )
                              }
                            >
                              Time sheet from {item.start} to {item.end}
                            </Link>
                          </div>
                        ))
                      ) : (
                        <Link> No data available </Link>
                      )}
                    </div>
                    {weekd && (
                      <div className="d-block red-heading w-100 w-md-50 p-2 maintimesheet">
                        <table className="date-box">
                          <tr>
                            <th></th>
                            <th>Dates</th>
                            <th>Hours</th>
                          </tr>
                          {Array.isArray(weekdetails) ? (
                            weekdetails.map((item, index) => (
                              <tr>
                                <td colspan="1">{item.dd}</td>
                                <td>{item.nd}</td>
                                <td>{item.hours}</td>
                              </tr>
                            ))
                          ) : (
                            <Link> No data available </Link>
                          )}
                        </table>
                      </div>
                    )}
                  </div>
                ) : (
                  // Render a message when no result is found
                  <p></p>
                )}

                {weekd && (
                  <div>
                    {setarrvalue != "" ? (
                      <form
                        action="javascript:void(0)"
                        onSubmit={handleformsubmit}
                        method="post"
                      >
                        <input
                          type="hidden"
                          name="client_id"
                          value={valueemployee.client_id}
                        />
                        <input
                          type="hidden"
                          name="attend_id"
                          value={valueemployee.id}
                        />
                        <input
                          type="hidden"
                          name="user_id"
                          value={valueemployee.user_id}
                        />
                        <input
                          type="hidden"
                          name="hours"
                          value={valueemployee.hours}
                        />
                        <div className="d-flex flex-column gap-3 mb-2">
                          {/* <h4 className="text-black fs-16 fw-400">
                            Client Signature:
                          </h4> */}
                          <span class="text-black">
                            <strong>Client Name:</strong> {clname}
                          </span>
                        </div>
                        <div className="d-flex flex-column gap-3">
                          <h4 className="text-black fs-16 fw-400">
                            Client Signature:
                          </h4>
                          <div className="w-100  shadow signimg">
                            <SignaturePad
                              ref={canvasRef}
                              canvasProps={{
                                style: {
                                  border: "1px solid black",
                                  height: "200px",
                                },
                              }}
                            />
                          </div>
                        </div>

                        <div className="d-flex justify-content-end gap-2">
                          <button
                            onClick={clearCanvas}
                            className="subbtn"
                            type="button"
                          >
                            Clear
                          </button>

                          <button
                            type="submit"
                            onClick={captureSignature}
                            className="subbtn"
                          >
                            Submit
                          </button>
                        </div>
                      </form>
                    ) : (
                      <p></p>
                    )}
                  </div>
                )}
              </div>

              {isClickedsuccess && (
                <div className="d-block text-center p-4 shadow border border-secondary border-opacity-25 mt-4">
                  <h3 className="myytext-primary fs-20 fw-800">
                    You are successfully submitted your Time Sheet
                  </h3>
                </div>
              )}
              {isClickederror && (
                <div className="d-block text-center p-4 shadow border border-secondary border-opacity-25 mt-4">
                  <h3 className="myytext-primary fs-20 fw-800">
                    You are successfully submitted your Time Sheet
                  </h3>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};
