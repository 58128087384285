import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Alert } from "antd";
import { saveAs } from "file-saver";
import { Link, useNavigate } from "react-router-dom";
import AdminHeader from "./AdminHeader";
import Modal from "react-modal";
import moment from "moment";
import Select from "react-select";
import { Calendar, momentLocalizer } from "react-big-calendar";
import {
  FaTrash,
  FaStar,
  FaFilePdf,
  FaEye,
  FaMoon,
  FaPlaneArrival,
  FaPlaneDeparture,
  FaThermometer,
  FaSun,
  FaEdit,
  FaUmbrella,
  FaSync,
  FaTrashAlt,
} from "react-icons/fa";
import CreatableSelect from "react-select/creatable";
import AdminFooter from "./AdminFooter";
import jsPDF from "jspdf";
import { ReactDialogBox } from "react-js-dialog-box";
//import { EmployeeResumePdf } from "./EmployeeResumePdf";
import generatePDF from "./PDFGeneration";
import { render } from "@testing-library/react";
export const AdminEmployee = () => {
  const navigate = useNavigate();

  //
  const [Equipmentwork, setSelectedEquipmentwork_g] = useState([]);
  const [textmessage, settextmessage] = useState(false);
  const [textmessages, settextmessages] = useState(false);
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const emailerr = useRef();

  const [allrecord, setallrecord] = useState("");
  const [tab1, settab1] = useState("tab2");
  const formatCreateLabel = (inputValue) => `"${inputValue}"`;
  const placeholderText = "Type here...";
  const [loaderfirst, setloaderfirst] = useState(false);
  const [copylink, setcopylink] = useState(false);
  const [mainSection, setmainSection] = useState(true);
  const [sentMailForm, setsentMailForm] = useState(true);
  const [allcountry, setallcountry] = useState([]);
  //Step1
  const [Url_Step1, setUrl_Step1] = useState("");
  const [readyValue, setReadyValue] = useState("");
  const [checkbuttonStep1, setcheckbuttonStep1] = useState("0");
  const [TextCopyClipboard, setTextCopyClipboard] = useState("");
  //Step1

  //
  const [isemail, setemail] = useState("Email does not match");
  const [issstep2_title, setstep2_title] = useState("");
  const [isstep2_shirt_size, setstep2_shirt_size] = useState("");
  const [isstep2_postal_country, setisstep2_postal_country] = useState("");
  const [isstep2_postal_state, setisstep2_postal_state] = useState("");
  const [optioncountryfirst, setoptioncountryfirst] = useState("");
  const [optionstatefirst, setoptionstatefirst] = useState("");
  const [optionorigin, setoptionorigin] = useState("");
  const [genderOptions, setgenderOptions] = useState("");
  const [erroremail, seterroremail] = useState(false);
  const [errorpassword, seterrorpassword] = useState(false);
  const [step2_Postaladdress, setstep2_Postaladdress] = useState(false);
  const [auscitizenNo, setauscitizenNo] = useState(false);
  const [auscitizenYes, setauscitizenYes] = useState(false);

  const [auscitizenYesBirth, setauscitizenYesBirth] = useState(false);
  const [auscitizenYesPassport, setauscitizenYesPassport] = useState(false);
  const [auscitizenYesCert, setauscitizenYesCert] = useState(false);

  const [isbirthcheck, setisbirthcheck] = useState("");
  const [ispassportcheck, setispassportcheck] = useState("");
  const [iscertificatecheck, setiscertificatecheck] = useState("");

  const [islegalwork, setislegalwork] = useState("");
  const [islegalworkdesc, setislegalworkdesc] = useState(false);
  const [isresidentalAddress, setresidentalAddress] = useState("");
  const [isSelectedEmployeePhoto, setSelectedEmployeePhoto] = useState(null);
  const [isselectcountrybirth, setselectcountrybirth] = useState(null);
  const [isSelectedpermanentAddress, setSelectedpermanentAddress] = useState(
    ""
  );
  const [isSelectedPermanentpassport, setSelectedPermanentpassport] = useState(
    null
  );
  const [selectedProofs, setSelectedProofs] = useState([]);
  const [isBirthcertificate, setBirthcertificate] = useState(null);
  const [isPassportcertificate, setPassportcertificate] = useState(null);
  const [iscertificatefile, setcertificatefile] = useState(null);
  const [iscriminaloffensenses, setcriminaloffensenses] = useState("");
  const [isservedtime, setservedtime] = useState("");
  const [isdefenceforced, setdefenceforced] = useState("");
  const [isdayshift, setdayshift] = useState("");
  const [isnightshift, setnightshift] = useState("");
  const [selectedEmployetype, setselectedEmployetype] = useState([]);
  const [formValues, setFormValues] = useState({
    step2_title: allrecord.step2_title || "",
    first_name: allrecord.first_name || "",
    last_name: "",
    contact: "",
    step2_gender: "",
    step2_origin: "",
    email: "",
    step2_confirm_email: "",
    password: "",
    step2_confirm_password: "",
    address: "",
    step2_address: "",
    step2_city: "",
    step2_state: "",
    step2_Postal: "",
    step2_country: "",
    step2_postal_address: "",
    step2_postal_address2: "",
    step2_postal_city: "",
    step2_postal_state: "",
    step2_postal_code: "",
    step2_postal_country: "",
    step2_dob: "",
    step2_country_birth: "",
    step2_proof_upload: "",
    step2_available_date: "",
    step2_shirt_size: "",
  });
  const [contactstep2, setContactstep2] = useState("");
  const [invalidnumberstep2, setinvalidnumberstep2] = useState("");
  const [invalidnumberstep3, setinvalidnumberstep3] = useState("");
  //Step2

  //Step3
  const [invalidnumberstep3_alter, setinvalidnumberstep3_alter] = useState("");
  const [optioncountrythird, setoptioncountrythird] = useState("");
  const [isstep3_postal_state, setisstep3_postal_state] = useState("");
  const [formValues_Step3, setFormValues_Step3] = useState({
    step3_title: "",
    step3_first_name: "",
    step3_last_name: "",
    step3_relationship: "",
    step3_contact: "",
    step3_mobile_number: "",
    step3_phone_number: "",
    step3_address: "",
    step3_address2: "",
    step3_city: "",
    step3_state: "",
    step3_postal: "",
    step3_country: "",
  });
  const [issstep3_title, setissstep3_title] = useState("");
  const [isstep3_contact, setisstep3_contact] = useState("");
  const [contactstep3, setContactstep3] = useState("");
  const [contactstep3_alter, setContactstep3_alter] = useState("");
  //Step3

  //Step 5
  const [reviewApplicationform, setreviewApplicationform] = useState(false);
  const [checkwork, setcheckwork] = useState("");
  const [typecheck, settypecheck] = useState("");

  const [selectvalue_skill, setselectvalue_skill] = useState([]);

  const [MentionLCert, setSelectedMentionCert] = useState([]);
  const [MentionLCert_m, setSelectedMentionCert_m] = useState([]);

  const [formValues_step5, setFormValues_step5] = useState({
    years: "",
  });
  //Step 5
  //
  const [vlexp, setexp] = useState(0);
  const [Docdelete1, setDocdelete1] = useState(false);
  const [Docdown1, setDocdown1] = useState(false);
  const [docUp1, setdocUp1] = useState(false);
  const [docUp2, setdocUp2] = useState(false);
  const [Docdown2, setDocdown2] = useState(false);
  const [Docdelete, setDocdelete] = useState(false);
  const [admindoc1, setadmindoc1] = useState(false);
  const [adminlic1, setadminlic1] = useState(false);
  const [selectedItemsLic, setselectedItemsLic] = useState([]);
  const [uploadfiledialog, setuploadfiledialog] = useState(false);
  const [doca2, setdoca2] = useState(false);
  const [doca1, setdoca1] = useState(false);
  const [Userdocs, setUserdocs] = useState([]);
  const [Userlic, setUserlic] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const apiurl = "https://jlmining.app/uploads/";
  var im = "male-upload.png";
  var imageUrl = `${apiurl}${im}`;
  const [selectcertmsg, setselect_certmsg] = useState("");
  const [selectedcertificate_file, setSelectedcertificate_file] = useState(
    null
  );
  const [
    selectvalue_vocational_training,
    setselectvalue_vocational_training,
  ] = useState([]);
  const [SelectedEquipmentwork, setSelectedEquipmentwork] = useState([]);
  const [SelectedEquipmentwork_get, setSelectedEquipmentwork_get] = useState(
    []
  );
  const [setSelectedFile_doc, setsetSelectedFile_doc] = useState(null);
  const [setSelectedFile_lic, setsetSelectedFile_lic] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [showPass, setShowPass] = useState(false);

  const [isActivec, setIsActivec] = useState(false);

  const [references_get, setreferences_get] = useState([]);
  const [sgetreferences, setSelectedreferences] = useState([]);
  const [selectvalue_equipment_work, setselectvalue_equipment_work] = useState(
    []
  );
  const [selectvalue_previous_work, setselectvalue_previous_work] = useState(
    []
  );
  const [selectvalue_reference, setselectvalue_reference] = useState([]);
  const [Previouswork, setSelectedprevwork] = useState([]);
  const [Previouswork_get, setSelectedprevwork_get] = useState([]);
  const [machinetextArrayy, setmachinetextArrayy] = useState([]);
  const [selectedmachinery_file, setSelectedmachinery_file] = useState(null);
  const [selectvalue_machinery, setselectvalue_machinery] = useState([]);
  const [machineryget, setSelectedmachinery] = useState([]);
  const [machineryget_get, setSelectedmachinery_get] = useState([]);
  const [selectvalue_trade, setselectvalue_trade] = useState([]);
  const [selectvalue_mentionc, setselectvalue_mentionc] = useState([]);
  const [trademach, setSelectedTrade] = useState([]);
  const [Vocationtra, setSelectedVocationtra] = useState([]);
  const [Vocationtra_get, setSelectedVocationtra_get] = useState([]);
  const [trademach_get, setSelectedTrade_get] = useState([]);
  const [setselect_m_msg, setsetselect_m_msg] = useState("");
  const [setselect_t_msg, setsetselect_t_msg] = useState("");
  const [setselect_l_msg, setsetselect_l_msg] = useState("");
  const [selectedtrade_file, setSelectedtrade_file] = useState(null);
  const [selectedlicenec_File, setSelectedlicenec_File] = useState(null);
  const [selectvalue_mentionl, setselectvalue_mentionl] = useState([]);
  const [MentionCertt, setMentionCertt] = useState([]);
  const [MentionCertt_get, setMentionCertt_get] = useState([]);
  const [MentionLic, setSelectedMentionLic] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedOptions_get, setSelectedOptions_get] = useState([]);
  const [MentionLic_get, setMentionLic_get] = useState([]);

  const [selectvalue, setselectvalue] = useState([]);
  const [educations, seteducationsr] = useState([]);
  const [rosterdialog, setrosterdialog] = useState(false);
  const [emData, setData] = useState([]);
  const [springloader, setspringloader] = useState(false);
  const [validd, setvalidd] = useState("");
  const [base64url, setbase64url] = useState("");
  const [backurlurl, setbackurlurl] = useState("");
  const [userimage, setuserimage] = useState("");
  const [isClicked, setIsClicked] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);
  const [istatus, setIsstaus] = useState(false);
  const [vsetval, setvall] = useState([]);
  const [valueclients, setvalueclients] = useState([]);
  const [notatus, setNostaus] = useState(false);
  const [gdata, getData] = useState("");
  const [rostertype, setrostertype] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [isClickedclient, setisClickedclient] = useState(false);
  const [isClickedetails, setisClickedetails] = useState(false);
  const [isClickedyourroster, setisClickedyourroster] = useState(false);
  const [valuesickleave, setValuesickleave] = useState({});
  const [valueannualleave, setValueannualleave] = useState({});
  const [valueworkerscomp, setValueworkerscomp] = useState({});
  const [valueflyinpm, setValueflyinpm] = useState({});
  const [valueflyoutam, setValueflyoutam] = useState({});
  const [valueflyinam, setValueflyinam] = useState({});
  const [valueinisolationonsite, setValueinisolationonsite] = useState({});
  const [valueleavewithoutpay, setValueleavewithoutpay] = useState({});
  const [valueflyoutpm, setValueflyoutpm] = useState({});
  const [valueworkoffsite, setValueworkoffsite] = useState({});
  const [valuenightshift, setValuenightshift] = useState({});
  const [valueget, setValueget] = useState({});
  const [ValueDayoff, setValueDayoff] = useState({});

  const [Valueworkingdays, setValueworkingdays] = useState({});
  const [Valuealloffdays, setValuealloffdays] = useState({});

  const [valueemplyId, setvalueemplyId] = useState("");

  const [Valuemainroster, setValuemainroster] = useState({});

  //Resume Pdf
  const [valuegetroster, setValuegetroster] = useState([]);
  const [resumedata, setresumedata] = useState("");
  const [licenseArray, setlicenseArray] = useState([]);
  const [educationsArray, seteducations] = useState([]);
  const [skillsArray, setskillsArray] = useState([]);
  const [licfile, setlicfile] = useState([]);
  const [edfile, setedfile] = useState([]);
  const [vocationalArray, setvocationalArray] = useState([]);

  const [tradetextArray, settradetextArray] = useState([]);
  const [equipmentWorked, setEquipmentWorked] = useState([]);
  const [tradefile, setedtradefile] = useState([]);
  const [certm, setedcertif_file] = useState([]);
  const [machinetextArray, setmachinetextArray] = useState([]);
  const [machinefileArray, semachinefile] = useState([]);

  const [refreArray, setrefreArray] = useState([]);
  const [historyArray, sethostoryArray] = useState([]);
  //Resume Pdf
  //Client Roster
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [valueroster, setValueroster] = useState([]);
  const [setdate_s, setDatessvalue] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isClickedopen, setisClickedopen] = useState(false);
  const [colorActive, setcolorActive] = useState("");
  const iconClassName = `weather-top-icon active ${colorActive}`;
  const [backgroundColor, setBackgroundColor] = useState("white");
  const [iconset, setIcon] = useState("");
  const [isClickedopen_roster, setisClickedopen_roster] = useState(false);
  const [currentroster, setcurrentroster] = useState(null);
  const [remember_roster, setremember_roster] = useState(false);
  const [shifts, setshifts] = useState("");
  const [shiftmessagevalue, setshiftmessage] = useState("");
  const [isClickedloader, setIsClickedloader] = useState(false);
  const [clientreq, setclientreq] = useState(false);
  const [locatreq, setlocatreq] = useState(false);
  const [isConditionMet, setIsConditionMet] = useState(false);
  const [isConditionMetbtn, setIsConditionMetbtn] = useState("Submit");
  const [rosterreq, setrosterreq] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [rosterclient, setrosterclient] = useState([]);
  const [locationname, setlocationname] = useState([]);
  const [inputvalue_data, setinputvalue_data] = useState("");
  const [locatename, setlocatename] = useState("");
  const [getallroster, setValuegetallroster] = useState([]);
  const [minisClicked, setMinClicked] = useState(false);
  const [IsClickedUser, setIsClickedUser] = useState(false);
  const [clientinfo, setclientInfo] = useState("");
  const [activeIndex_index, setActiveIndex_index] = useState(null);
  const [activesIndex, setActivesIndex] = useState(null);
  const [clientID, setClientId] = useState("");
  const [locatid, setlocatid] = useState("");
  const [locationID, setLocationId] = useState("");
  const [isClickedloc, setIsClickedloc] = useState(false);
  const [located, setlocated] = useState("");
  const [UserId, setUserId] = useState("");
  const [gldata, getDatal] = useState("");
  const [opencloseroster, setopencloseroster] = useState(false);
  const [isModalOpen_hrs, setIsModalOpen_hrs] = useState(false);
  const [openclosecalender, setopenclosecalender] = useState(false);
  const [emDataclient, setDataclient] = useState([]);
  const [locatedname, setlocatedname] = useState("");
  const targetDivRef = useRef(null);
  const [events, setValuegetCalendar] = useState([]);
  const [valueclientId, setvalueclientId] = useState("");
  const [valuelocations, setvaluelocations] = useState("");
  const [valuelocationsId, setvaluelocationsId] = useState("");
  const [setdate, setDates] = useState(null);
  const [isClickededit, setisClickedaddorEdit] = useState(true);
  const [valueclient, setvalueclient] = useState("");
  const [isClickedadd, setisClickedadd] = useState(true);
  //Client Roster
  //edit Profile
  const [editprofile, seteditprofile] = useState(false);
  const [opennotification, setopennotification] = useState(false);
  const [succ_n, setsucc_n] = useState(false);
  var appUrl = "https://jlmining.app/uploads";
  //edit Profile
  const handlecheckApprove = (userid, email) => {
    //console.log(userid);
    let user_id = {
      user_id: userid,
      email: email,
    };

    axios
      .post("https://jlmining.app/admin/userApprove", user_id, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setIsstaus(true);
        setNostaus(false);
        setShowAlert(true);
        setShowAlert({ type: "success" });
        setTimeout(() => {
          setShowAlert(false);
        }, 3500);
      })
      .catch((err) => {});
  };
  const handleuserRemove = (userid) => {
    //console.log(userid);
    let user_id = {
      user_id: userid,
    };
    const del = window.confirm("Are you sure you want to delete this user?");
    if (del) {
      axios
        .post("https://jlmining.app/admin/userdelete", user_id, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "POST,PUT",
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          setIsstaus(true);
          setNostaus(false);
          setShowAlert(true);
          setShowAlert({ type: "error" });
          setIsClicked(false);
          setActiveIndex(null);
          axios
            .get("https://jlmining.app/admin/getemployee", {
              headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "POST,PUT",
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            })
            .then((res) => {
              //  console.log(res.data.results);
              setData(res.data.results);
            })
            .catch((err) => {});
          setTimeout(() => {
            setShowAlert(false);
          }, 3500);
        })
        .catch((err) => {});
    }
  };
  const handlegetClient = (userId, index) => {
    let formdata = {
      userId: userId,
    };
    setvalueclients([]);
    setvalueemplyId(userId);
    setActiveIndex(index);
    setisClickedyourroster(false);
    setisClickedetails(false);
    setValuealloffdays([]);
    setValueworkingdays([]);
    axios
      .post("https://jlmining.app/admin/getuser", formdata, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log(res.data.row[0].skills);
        if (res.data.row !== "") {
          // console.log(res.data.row[0].skills);
          //return false;
          getData(res.data.row[0]);
          if (res.data.row[0].skills !== null) {
            setvall(JSON.parse(res.data.row[0].skills));
          }
          var st = res.data.row[0].status;
          var stt = res.data.row[0].image;
          if (stt != null) {
            var stt = stt;
          } else {
            var stt = "male-upload.png";
          }
          const apiurl = "https://jlmining.app/uploads/";
          var im = stt;
          var imageUrl = `${apiurl}${im}`;
          setuserimage(imageUrl);
          if (st === "Active") {
            setIsstaus(true);
            setNostaus(false);
          }
          if (st === "Inactive") {
            setIsstaus(false);
            setNostaus(true);
          }
          setIsClicked(true);
        }
      })
      .catch((err) => {
        //console.log(err.response.data);
      });
    let formdataa = {
      user_id: userId,
    };
    setisClickedclient(true);
    axios
      .post("https://jlmining.app/admin/getallrosters", formdataa, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res.data.results.length > 0) {
          setValueroster(res.data.results);
        }
      })
      .catch((err) => {
        //console.log(err.response.data);
      });
    axios
      .post("https://jlmining.app/admin/getallclients_admin", formdataa, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res.data.results.length > 0) {
          setvalueclients(res.data.results);
        }
      })
      .catch((err) => {
        //console.log(err.response.data);
      });

    //Get Document
    axios
      .post("https://jlmining.app/getonboardingDocAdmin", formdataa, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setUserdocs(res.data.results);
      })
      .catch((err) => {});
    axios
      .post("https://jlmining.app/getonboardinglic", formdataa, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setUserlic(res.data.results);
      })
      .catch((err) => {});
    //Get Document
  };

  const changeFavicon = (url) => {
    const favicon = document.querySelector('link[rel="icon"]');
    if (favicon) {
      favicon.href = url;
    }
  };
  const employeeResume_Pdf = (idd) => {
    const apiurl = "https://jlmining.app/uploads/";
    let formdata = {
      userId: idd,
    };
    axios
      .post("https://jlmining.app/admin/getuser", formdata, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        //console.log("empty");
        //console.log(res.data.row);
        if (res.data.row.length > 0) {
          setresumedata(res.data.row[0]);
        }
      })
      .catch((err) => {});
  };
  const pdfgenerate = (iddd) => {
    // console.log(iddd);
    var exp = employeeResume_Pdf(iddd);
  };
  const options = selectedOptions;
  const MentionLicc = MentionLic;
  const toDataUrl = async function (url) {
    try {
      const response = await fetch(url);

      if (!response.ok) {
        console.error(
          `HTTP error! status: ${
            response.status
          }, response text: ${await response.text()}`
        );
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const blob = await response.blob();
      const reader = new FileReader();

      return new Promise((resolve, reject) => {
        reader.onloadend = () => {
          const dataUrl = reader.result;
          resolve(dataUrl);
        };

        reader.onerror = reject;

        reader.readAsDataURL(blob);
      });
    } catch (error) {
      console.error("Error fetching and converting image:", error);
      throw error; // Re-throw the error to be caught in the calling code
    }
  };
  function getimagedata(urll) {
    let formdata = {
      url: urll,
    };

    return axios
      .post("https://jlmining.app/admin/convertimage", formdata, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        //console.log("back");
        //console.log(res.data.ret);
        setbackurlurl(res.data.ret);
      })
      .catch((err) => {
        // Handle the error here
        console.error(err);
      });
  }
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };
  const pdfdataget = async () => {
    setspringloader(true);
    //const apiurl = "https://i.imgur.com/gBi0yjr.png";

    const cc = getimagedata("bg.jpg");
    //console.log("fggh");
    // console.log(JSON.parse(resumedata.licence));
    //return false;
    const licfile = [];
    if (resumedata.licence_file != null) {
      try {
        const lf = JSON.parse(resumedata.licence_file);

        // Use map to create an array of promises
        const requests = lf.map(async (url) => {
          let formdata = { url };

          try {
            const response = await axios.post(
              "https://jlmining.app/admin/convertimage",
              formdata,
              {
                headers: {
                  "Access-Control-Allow-Origin": "*",
                  "Access-Control-Allow-Methods": "POST,PUT",
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
              }
            );

            licfile.push(response.data.ret);
          } catch (err) {
            // Handle the error for each request
            console.error(err);
          }
        });

        // Wait for all promises to resolve
        await Promise.all(requests);

        // At this point, all requests are completed
      } catch (error) {}
    }
    //console.log(resumedata.certificate);
    const edfile = [];
    if (resumedata.certificate_file != null) {
      try {
        const lf = JSON.parse(resumedata.certificate_file);

        // Use map to create an array of promises
        const requests = lf.map(async (url) => {
          let formdata = { url };

          try {
            const response = await axios.post(
              "https://jlmining.app/admin/convertimage_cert",
              formdata,
              {
                headers: {
                  "Access-Control-Allow-Origin": "*",
                  "Access-Control-Allow-Methods": "POST,PUT",
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
              }
            );

            // Push the result into the edfile array
            edfile.push(response.data.ret);
            //console.log(response.data.ret);
          } catch (err) {
            // Handle the error for each request
            console.error(err);
          }
        });

        // Wait for all promises to resolve
        await Promise.all(requests);
      } catch (error) {}
    }

    const tradetextArray = [];
    if (resumedata.trade_file != null) {
      try {
        const lf = JSON.parse(resumedata.trade_file);

        // Use map to create an array of promises
        const requests = lf.map(async (url) => {
          let formdata = { url };

          try {
            const response = await axios.post(
              "https://jlmining.app/admin/convertimage",
              formdata,
              {
                headers: {
                  "Access-Control-Allow-Origin": "*",
                  "Access-Control-Allow-Methods": "POST,PUT",
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
              }
            );

            // Push the result into the edfile array
            tradetextArray.push(response.data.ret);
            //console.log(response.data.ret);
          } catch (err) {
            // Handle the error for each request
            console.error(err);
          }
        });

        // Wait for all promises to resolve
        await Promise.all(requests);

        // At this point, all requests are completed
      } catch (error) {}
    }

    const machinetextArray = [];
    if (resumedata.machinery_file != null) {
      try {
        const lf = JSON.parse(resumedata.machinery_file);

        // Use map to create an array of promises
        const requests = lf.map(async (url) => {
          let formdata = { url };

          try {
            const response = await axios.post(
              "https://jlmining.app/admin/convertimage",
              formdata,
              {
                headers: {
                  "Access-Control-Allow-Origin": "*",
                  "Access-Control-Allow-Methods": "POST,PUT",
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
              }
            );

            // Push the result into the edfile array
            machinetextArray.push(response.data.ret);
            //console.log(response.data.ret);
          } catch (err) {
            // Handle the error for each request
            console.error(err);
          }
        });

        // Wait for all promises to resolve
        await Promise.all(requests);

        // At this point, all requests are completed
      } catch (error) {}
    }

    const dynamicImagePath = require("../assets/images/background/bg.jpg");
    const tableStyle1 = {
      border: "none",
      borderSpacing: 0,
      width: "100%",
      padding: "1cm",
      height: "297mm",
    };

    const containerStyle = {
      background: `url(${dynamicImagePath}) no-repeat center top`,
      backgroundSize: "cover",
      textAlign: "center",
      height: "100vh",
    };

    const tableStyle = {
      border: "none",
      borderSpacing: 0,
      textAlign: "center",
      width: "100%",
      marginTop: "100px",
    };

    const textStyle = {
      textAlign: "center",
      color: "#fff",
      fontSize: "16px",
      textTransform: "capitalize",
      fontFamily: "'Times New Roman', Times, serif",
    };
    const mainPageStyles = {
      width: "210mm",
      minHeight: "297mm",
      background: "white",
      margin: "10mm auto",
      boxShadow: "0 0 0.5cm rgba(0, 0, 0, 0.5)",
    };

    const printStyles = {
      "@media print": {
        html: {
          width: "210mm",
          height: "297mm",
        },
        body: {
          width: "210mm",
          height: "297mm",
        },
        ".main-page": {
          margin: "0",
          border: "initial",
          borderRadius: "initial",
          width: "initial",
          minHeight: "initial",
          boxShadow: "initial",
          background: "initial",
          pageBreakAfter: "always",
        },
      },
    };
    const mergedStyles = {
      ...containerStyle,
      ...printStyles,
    };

    const mainpdf = (
      <div>
        <table
          style={{
            width: "794px",
            margin: "0 auto",
            backgroundImage: `url(${require("../assets/images/certificate_bg.jpg")})`,
            backgroundSize: "cover",
            height: "1158px",
          }}
        >
          <tr
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              gap: "30px",
            }}
          >
            <td
              style={{
                textAlign: "center",
                color: "rgb(255, 255, 255)",
                fontSize: "30px",
                textTransform: "capitalize",
                fontFamily: '"Jost", sans-serif',
                fontWeight: "500",
                borderBottom: "2px solid #fff",
              }}
            >
              Application Form
            </td>
            <td
              style={{
                border: "2px solid #fff",
                borderRadius: "12px",
                padding: "10px 30px",
                fontSize: "28px",
                fontWeight: "400",
                color: "#fff",
                fontFamily: '"Jost", sans-serif;',
                textAlign: "center",
              }}
            >
              Confidential Profile of: <br />
              ANDRE POTGIETER
            </td>
          </tr>
        </table>
        <table
          style={{
            width: "794px",
            margin: "0 auto",
            backgroundImage: `url(${require("../assets/images/certificate_bg.jpg")})`,
            backgroundSize: "cover",
            height: "1158px",
          }}
        >
          <tr
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              height: "100%",
              gap: "30px",
            }}
          >
            <td
              style={{
                textAlign: "center",
                color: "rgb(255, 255, 255)",
                fontSize: "30px",
                textTransform: "capitalize",
                fontFamily: '"Jost", sans-serif',
                fontWeight: "500",
                borderBottom: "2px solid #fff",
              }}
            >
              Basic Detail
            </td>
            <tr style={{ padding: "0px 50px" }}>
              <td
                style={{ display: "flex", flexDirection: "column", gap: "5px" }}
              >
                <tr
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  <td
                    style={{
                      fontSize: "20px",
                      color: "#fff",
                      fontWeight: "400",
                      fontFamily: '"Jost", sans-serif',
                    }}
                  >
                    Name :
                  </td>
                  <td
                    style={{
                      fontSize: "20px",
                      color: "#fff",
                      fontWeight: "400",
                      fontFamily: '"Jost", sans-serif',
                    }}
                  >
                    {resumedata.first_name} {resumedata.middle_name}{" "}
                    {resumedata.last_name}
                  </td>
                </tr>
                <tr
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  <td
                    style={{
                      fontSize: "20px",
                      color: "#fff",
                      fontWeight: "400",
                      fontFamily: '"Jost", sans-serif',
                    }}
                  >
                    Mobile Number :
                  </td>
                  <td
                    style={{
                      fontSize: "20px",
                      color: "#fff",
                      fontWeight: "400",
                      fontFamily: '"Jost", sans-serif',
                    }}
                  >
                    {resumedata.contact}
                  </td>
                </tr>
                <tr
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  <td
                    style={{
                      fontSize: "20px",
                      color: "#fff",
                      fontWeight: "400",
                      fontFamily: '"Jost", sans-serif',
                    }}
                  >
                    Gender :
                  </td>
                  <td
                    style={{
                      fontSize: "20px",
                      color: "#fff",
                      fontWeight: "400",
                      fontFamily: '"Jost", sans-serif',
                    }}
                  >
                    {resumedata.step2_gender}
                  </td>
                </tr>
                <tr
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  <td
                    style={{
                      fontSize: "20px",
                      color: "#fff",
                      fontWeight: "400",
                      fontFamily: '"Jost", sans-serif',
                    }}
                  >
                    Are you of Aboriginal or Torres Strait Island origin? :
                  </td>
                  <td
                    style={{
                      fontSize: "20px",
                      color: "#fff",
                      fontWeight: "400",
                      fontFamily: '"Jost", sans-serif',
                    }}
                  >
                    {resumedata.step2_origin}
                  </td>
                </tr>
                <tr
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  <td
                    style={{
                      fontSize: "20px",
                      color: "#fff",
                      fontWeight: "400",
                      fontFamily: '"Jost", sans-serif',
                    }}
                  >
                    Email :
                  </td>
                  <td
                    style={{
                      fontSize: "20px",
                      color: "#fff",
                      fontWeight: "400",
                      fontFamily: '"Jost", sans-serif',
                    }}
                  >
                    {resumedata.email}
                  </td>
                </tr>

                <tr
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  <td
                    style={{
                      fontSize: "20px",
                      color: "#fff",
                      fontWeight: "400",
                      fontFamily: '"Jost", sans-serif',
                    }}
                  >
                    Home Address :
                  </td>
                  <td
                    style={{
                      fontSize: "20px",
                      color: "#fff",
                      fontWeight: "400",
                      fontFamily: '"Jost", sans-serif',
                    }}
                  >
                    {resumedata.step2_residential_address}{" "}
                  </td>
                </tr>
                <tr
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  <td
                    style={{
                      fontSize: "20px",
                      color: "#fff",
                      fontWeight: "400",
                      fontFamily: '"Jost", sans-serif',
                    }}
                  >
                    Street Address Line 2 :
                  </td>
                  <td
                    style={{
                      fontSize: "20px",
                      color: "#fff",
                      fontWeight: "400",
                      fontFamily: '"Jost", sans-serif',
                    }}
                  >
                    {" "}
                    {resumedata.step2_postal_address2}{" "}
                    {resumedata.step2_postal_city}
                    {resumedata.step2_postal_state}
                    {resumedata.step2_postal_code}
                    {resumedata.step2_postal_country}
                  </td>
                </tr>
                <tr
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  <td
                    style={{
                      fontSize: "20px",
                      color: "#fff",
                      fontWeight: "400",
                      fontFamily: '"Jost", sans-serif',
                    }}
                  >
                    City :
                  </td>
                  <td
                    style={{
                      fontSize: "20px",
                      color: "#fff",
                      fontWeight: "400",
                      fontFamily: '"Jost", sans-serif',
                    }}
                  >
                    {resumedata.step2_postal_city}
                  </td>
                </tr>
                <tr
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  <td
                    style={{
                      fontSize: "20px",
                      color: "#fff",
                      fontWeight: "400",
                      fontFamily: '"Jost", sans-serif',
                    }}
                  >
                    State :
                  </td>
                  <td
                    style={{
                      fontSize: "20px",
                      color: "#fff",
                      fontWeight: "400",
                      fontFamily: '"Jost", sans-serif',
                    }}
                  >
                    {resumedata.step2_state}
                  </td>
                </tr>
                <tr
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  <td
                    style={{
                      fontSize: "20px",
                      color: "#fff",
                      fontWeight: "400",
                      fontFamily: '"Jost", sans-serif',
                    }}
                  >
                    Postal Code :
                  </td>
                  <td
                    style={{
                      fontSize: "20px",
                      color: "#fff",
                      fontWeight: "400",
                      fontFamily: '"Jost", sans-serif',
                    }}
                  >
                    {resumedata.step2_postal_code}
                  </td>
                </tr>
                <tr
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  <td
                    style={{
                      fontSize: "20px",
                      color: "#fff",
                      fontWeight: "400",
                      fontFamily: '"Jost", sans-serif',
                    }}
                  >
                    Country :
                  </td>
                  <td
                    style={{
                      fontSize: "20px",
                      color: "#fff",
                      fontWeight: "400",
                      fontFamily: '"Jost", sans-serif',
                    }}
                  >
                    {resumedata.step2_postal_country}
                  </td>
                </tr>
                <tr
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  <td
                    style={{
                      fontSize: "20px",
                      color: "#fff",
                      fontWeight: "400",
                      fontFamily: '"Jost", sans-serif',
                    }}
                  >
                    Date of Birth :
                  </td>
                  <td
                    style={{
                      fontSize: "20px",
                      color: "#fff",
                      fontWeight: "400",
                      fontFamily: '"Jost", sans-serif',
                    }}
                  >
                    {resumedata.step2_dob}
                  </td>
                </tr>
                <tr
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  <td
                    style={{
                      fontSize: "20px",
                      color: "#fff",
                      fontWeight: "400",
                      fontFamily: '"Jost", sans-serif',
                    }}
                  >
                    Country of Birth :
                  </td>
                  <td
                    style={{
                      fontSize: "20px",
                      color: "#fff",
                      fontWeight: "400",
                      fontFamily: '"Jost", sans-serif',
                    }}
                  >
                    {resumedata.step2_country_birth}
                  </td>
                </tr>
                <tr
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  <td
                    style={{
                      fontSize: "20px",
                      color: "#fff",
                      fontWeight: "400",
                      fontFamily: '"Jost", sans-serif',
                    }}
                  >
                    Are you a permanent Resident/Citizen of Australia? :
                  </td>
                  <td
                    style={{
                      fontSize: "20px",
                      color: "#fff",
                      fontWeight: "400",
                      fontFamily: '"Jost", sans-serif',
                    }}
                  >
                    Yes
                  </td>
                </tr>
                <tr
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  <td
                    style={{
                      fontSize: "20px",
                      color: "#fff",
                      fontWeight: "400",
                      fontFamily: '"Jost", sans-serif',
                    }}
                  >
                    Do you have the legal right to live and work in Australia? :
                  </td>
                  <td
                    style={{
                      fontSize: "20px",
                      color: "#fff",
                      fontWeight: "400",
                      fontFamily: '"Jost", sans-serif',
                    }}
                  >
                    {resumedata.step2_legal_work}
                  </td>
                </tr>
                <tr
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  <td
                    style={{
                      fontSize: "20px",
                      color: "#fff",
                      fontWeight: "400",
                      fontFamily: '"Jost", sans-serif',
                    }}
                  >
                    What date do you become available? :
                  </td>
                  <td
                    style={{
                      fontSize: "20px",
                      color: "#fff",
                      fontWeight: "400",
                      fontFamily: '"Jost", sans-serif',
                    }}
                  >
                    {resumedata.step2_available_date}
                  </td>
                </tr>
                <tr
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  <td
                    style={{
                      fontSize: "20px",
                      color: "#fff",
                      fontWeight: "400",
                      fontFamily: '"Jost", sans-serif',
                    }}
                  >
                    Have you ever been convicted of any criminal offenses? :
                  </td>
                  <td
                    style={{
                      fontSize: "20px",
                      color: "#fff",
                      fontWeight: "400",
                      fontFamily: '"Jost", sans-serif',
                    }}
                  >
                    {resumedata.step2_criminal_offenses}
                  </td>
                </tr>
                <tr
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  <td
                    style={{
                      fontSize: "20px",
                      color: "#fff",
                      fontWeight: "400",
                      fontFamily: '"Jost", sans-serif',
                    }}
                  >
                    Have you served time in prison? :
                  </td>
                  <td
                    style={{
                      fontSize: "20px",
                      color: "#fff",
                      fontWeight: "400",
                      fontFamily: '"Jost", sans-serif',
                    }}
                  >
                    {resumedata.step2_served_time}
                  </td>
                </tr>
                <tr
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  <td
                    style={{
                      fontSize: "20px",
                      color: "#fff",
                      fontWeight: "400",
                      fontFamily: '"Jost", sans-serif',
                    }}
                  >
                    Are you an ex serving member of the Australian Defence
                    Force? :
                  </td>
                  <td
                    style={{
                      fontSize: "20px",
                      color: "#fff",
                      fontWeight: "400",
                      fontFamily: '"Jost", sans-serif',
                    }}
                  >
                    {resumedata.step2_defence_forced}
                  </td>
                </tr>
                <tr
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  <td
                    style={{
                      fontSize: "20px",
                      color: "#fff",
                      fontWeight: "400",
                      fontFamily: '"Jost", sans-serif',
                    }}
                  >
                    Shirt Size :
                  </td>
                  <td
                    style={{
                      fontSize: "20px",
                      color: "#fff",
                      fontWeight: "400",
                      fontFamily: '"Jost", sans-serif',
                    }}
                  >
                    {resumedata.step2_shirt_size}
                  </td>
                </tr>
                <tr
                  style={{
                    display: "flex",
                    alignItems: "start",
                    flexDirection: "column",
                    gap: "5px",
                  }}
                >
                  <td
                    style={{
                      fontSize: "20px",
                      color: "#fff",
                      fontWeight: "400",
                      fontFamily: '"Jost", sans-serif',
                    }}
                  >
                    Which shifts are you willing to work? Please select all that
                    apply ?
                  </td>
                  <tr
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "20px",
                    }}
                  >
                    <tr
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      <td
                        style={{
                          fontSize: "20px",
                          color: "#fff",
                          fontWeight: "400",
                          fontFamily: '"Jost", sans-serif',
                        }}
                      >
                        Day Shift :
                      </td>
                      <td
                        style={{
                          fontSize: "20px",
                          color: "#fff",
                          fontWeight: "400",
                          fontFamily: '"Jost", sans-serif',
                        }}
                      >
                        {resumedata.step2_which_dayshift}
                      </td>
                    </tr>
                    <tr
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      <td
                        style={{
                          fontSize: "20px",
                          color: "#fff",
                          fontWeight: "400",
                          fontFamily: '"Jost", sans-serif',
                        }}
                      >
                        Night Shift :
                      </td>
                      <td
                        style={{
                          fontSize: "20px",
                          color: "#fff",
                          fontWeight: "400",
                          fontFamily: '"Jost", sans-serif',
                        }}
                      >
                        {resumedata.step2_which_nightshift}
                      </td>
                    </tr>
                  </tr>
                </tr>
                <tr
                  style={{
                    display: "flex",
                    alignItems: "start",
                    flexDirection: "column",
                    gap: "5px",
                  }}
                >
                  <td
                    style={{
                      fontSize: "20px",
                      color: "#fff",
                      fontWeight: "400",
                      fontFamily: '"Jost", sans-serif',
                    }}
                  >
                    Employment Type - Please select all that apply ?
                  </td>
                  <tr
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "20px",
                    }}
                  >
                    <tr
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      {resumedata.step2_employment_type &&
                        resumedata.step2_employment_type.includes("FIFO") && (
                          <>
                            <td
                              style={{
                                fontSize: "20px",
                                color: "#fff",
                                fontWeight: "400",
                                fontFamily: '"Jost", sans-serif',
                              }}
                            >
                              FIFO :
                            </td>
                            <td
                              style={{
                                fontSize: "20px",
                                color: "#fff",
                                fontWeight: "400",
                                fontFamily: '"Jost", sans-serif',
                              }}
                            >
                              Yes
                            </td>
                          </>
                        )}
                    </tr>
                    <tr
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      {resumedata.step2_employment_type &&
                        resumedata.step2_employment_type.includes(
                          "Shutdowns"
                        ) && (
                          <>
                            <td
                              style={{
                                fontSize: "20px",
                                color: "#fff",
                                fontWeight: "400",
                                fontFamily: '"Jost", sans-serif',
                              }}
                            >
                              Shutdowns :
                            </td>
                            <td
                              style={{
                                fontSize: "20px",
                                color: "#fff",
                                fontWeight: "400",
                                fontFamily: '"Jost", sans-serif',
                              }}
                            >
                              Yes
                            </td>
                          </>
                        )}
                    </tr>
                    <tr
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      {resumedata.step2_employment_type &&
                        resumedata.step2_employment_type.includes(
                          "Local Work"
                        ) && (
                          <>
                            <td
                              style={{
                                fontSize: "20px",
                                color: "#fff",
                                fontWeight: "400",
                                fontFamily: '"Jost", sans-serif',
                              }}
                            >
                              Local Work :
                            </td>
                            <td
                              style={{
                                fontSize: "20px",
                                color: "#fff",
                                fontWeight: "400",
                                fontFamily: '"Jost", sans-serif',
                              }}
                            >
                              Yes
                            </td>
                          </>
                        )}
                    </tr>
                    <tr
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      {resumedata.step2_employment_type &&
                        resumedata.step2_employment_type.includes("Casual") && (
                          <>
                            <td
                              style={{
                                fontSize: "20px",
                                color: "#fff",
                                fontWeight: "400",
                                fontFamily: '"Jost", sans-serif',
                              }}
                            >
                              Casual :
                            </td>
                            <td
                              style={{
                                fontSize: "20px",
                                color: "#fff",
                                fontWeight: "400",
                                fontFamily: '"Jost", sans-serif',
                              }}
                            >
                              Yes
                            </td>
                          </>
                        )}
                    </tr>
                    <tr
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      {resumedata.step2_employment_type &&
                        resumedata.step2_employment_type.includes(
                          "Full Time"
                        ) && (
                          <>
                            <td
                              style={{
                                fontSize: "20px",
                                color: "#fff",
                                fontWeight: "400",
                                fontFamily: '"Jost", sans-serif',
                              }}
                            >
                              Full Time :
                            </td>
                            <td
                              style={{
                                fontSize: "20px",
                                color: "#fff",
                                fontWeight: "400",
                                fontFamily: '"Jost", sans-serif',
                              }}
                            >
                              Yes
                            </td>
                          </>
                        )}
                    </tr>
                  </tr>
                </tr>
              </td>
            </tr>
          </tr>
        </table>
        <table
          style={{
            width: "794px",
            margin: "0 auto",
            backgroundImage: `url(${require("../assets/images/certificate_bg.jpg")})`,
            backgroundSize: "cover",
            height: "1155px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <tr
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              gap: "20px",
              alignItems: "center",
            }}
          >
            <tr
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                gap: "30px",
                alignItems: "center",
                height: "100%",
              }}
            >
              <td
                style={{
                  textAlign: "center",
                  color: "rgb(255, 255, 255)",
                  fontSize: "30px",
                  textTransform: "capitalize",
                  fontFamily: '"Jost", sans-serif',
                  fontWeight: "500",
                  borderBottom: "2px solid #fff",
                  width: "100%",
                }}
              >
                Emergency Contact Details
              </td>
              <tr style={{ padding: "0px 50px" }}>
                <td
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    gap: "5px",
                  }}
                >
                  <tr
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    <td
                      style={{
                        fontSize: "20px",
                        color: "#fff",
                        fontWeight: "400",
                        fontFamily: '"Jost", sans-serif',
                      }}
                    >
                      Name :
                    </td>
                    <td
                      style={{
                        fontSize: "20px",
                        color: "#fff",
                        fontWeight: "400",
                        fontFamily: '"Jost", sans-serif',
                      }}
                    >
                      {resumedata.step3_title}
                      {""}
                      {resumedata.step3_first_name} {""}
                      {resumedata.step3_last_name}
                    </td>
                  </tr>
                  <tr
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    <td
                      style={{
                        fontSize: "20px",
                        color: "#fff",
                        fontWeight: "400",
                        fontFamily: '"Jost", sans-serif',
                      }}
                    >
                      Relationship to you (eg. spouse, parent, friend) :
                    </td>
                    <td
                      style={{
                        fontSize: "20px",
                        color: "#fff",
                        fontWeight: "400",
                        fontFamily: '"Jost", sans-serif',
                      }}
                    >
                      {resumedata.step3_relationship}
                    </td>
                  </tr>
                  <tr
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    <td
                      style={{
                        fontSize: "20px",
                        color: "#fff",
                        fontWeight: "400",
                        fontFamily: '"Jost", sans-serif',
                      }}
                    >
                      Is your Emergency Contact located in Australia? :
                    </td>
                    <td
                      style={{
                        fontSize: "20px",
                        color: "#fff",
                        fontWeight: "400",
                        fontFamily: '"Jost", sans-serif',
                      }}
                    >
                      Yes
                    </td>
                  </tr>
                  <tr
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    <td
                      style={{
                        fontSize: "20px",
                        color: "#fff",
                        fontWeight: "400",
                        fontFamily: '"Jost", sans-serif',
                      }}
                    >
                      Mobile Phone Number :
                    </td>
                    <td
                      style={{
                        fontSize: "20px",
                        color: "#fff",
                        fontWeight: "400",
                        fontFamily: '"Jost", sans-serif',
                      }}
                    >
                      {resumedata.step3_contact}
                    </td>
                  </tr>
                  <tr
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    <td
                      style={{
                        fontSize: "20px",
                        color: "#fff",
                        fontWeight: "400",
                        fontFamily: '"Jost", sans-serif',
                      }}
                    >
                      Alternative Phone Number :
                    </td>
                    <td
                      style={{
                        fontSize: "20px",
                        color: "#fff",
                        fontWeight: "400",
                        fontFamily: '"Jost", sans-serif',
                      }}
                    >
                      {resumedata.step3_phone_number}
                    </td>
                  </tr>
                  <tr
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    <td
                      style={{
                        fontSize: "20px",
                        color: "#fff",
                        fontWeight: "400",
                        fontFamily: '"Jost", sans-serif',
                      }}
                    >
                      Home Address :
                    </td>
                    <td
                      style={{
                        fontSize: "20px",
                        color: "#fff",
                        fontWeight: "400",
                        fontFamily: '"Jost", sans-serif',
                      }}
                    >
                      {resumedata.step3_address}
                    </td>
                  </tr>
                  <tr
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    <td
                      style={{
                        fontSize: "20px",
                        color: "#fff",
                        fontWeight: "400",
                        fontFamily: '"Jost", sans-serif',
                      }}
                    >
                      Street Address Line 2 :
                    </td>
                    <td
                      style={{
                        fontSize: "20px",
                        color: "#fff",
                        fontWeight: "400",
                        fontFamily: '"Jost", sans-serif',
                      }}
                    >
                      {resumedata.step3_address}
                    </td>
                  </tr>
                  <tr
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    <td
                      style={{
                        fontSize: "20px",
                        color: "#fff",
                        fontWeight: "400",
                        fontFamily: '"Jost", sans-serif',
                      }}
                    >
                      City :
                    </td>
                    <td
                      style={{
                        fontSize: "20px",
                        color: "#fff",
                        fontWeight: "400",
                        fontFamily: '"Jost", sans-serif',
                      }}
                    >
                      {resumedata.step3_city}
                    </td>
                  </tr>
                  <tr
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    <td
                      style={{
                        fontSize: "20px",
                        color: "#fff",
                        fontWeight: "400",
                        fontFamily: '"Jost", sans-serif',
                      }}
                    >
                      State :
                    </td>
                    <td
                      style={{
                        fontSize: "20px",
                        color: "#fff",
                        fontWeight: "400",
                        fontFamily: '"Jost", sans-serif',
                      }}
                    >
                      {resumedata.step3_city}
                    </td>
                  </tr>
                  <tr
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    <td
                      style={{
                        fontSize: "20px",
                        color: "#fff",
                        fontWeight: "400",
                        fontFamily: '"Jost", sans-serif',
                      }}
                    >
                      Postal Code :
                    </td>
                    <td
                      style={{
                        fontSize: "20px",
                        color: "#fff",
                        fontWeight: "400",
                        fontFamily: '"Jost", sans-serif',
                      }}
                    >
                      {resumedata.step3_postal}
                    </td>
                  </tr>
                  <tr
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    <td
                      style={{
                        fontSize: "20px",
                        color: "#fff",
                        fontWeight: "400",
                        fontFamily: '"Jost", sans-serif',
                      }}
                    >
                      Country :
                    </td>
                    <td
                      style={{
                        fontSize: "20px",
                        color: "#fff",
                        fontWeight: "400",
                        fontFamily: '"Jost", sans-serif',
                      }}
                    >
                      {resumedata.step3_country}
                    </td>
                  </tr>
                </td>
              </tr>
            </tr>
            <tr
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                gap: "30px",
                alignItems: "start",
                width: "100%",
              }}
            >
              <td
                style={{
                  textAlign: "center",
                  color: "rgb(255, 255, 255)",
                  fontSize: "30px",
                  textTransform: "capitalize",
                  fontFamily: '"Jost", sans-serif',
                  fontWeight: "500",
                  borderBottom: "2px solid #fff",
                  width: "100%",
                }}
              >
                Employement History
              </td>
              {historyArray.length > 0 ? (
                historyArray.map((entry, index) => (
                  <tr key={index} style={{ padding: "0px 50px" }}>
                    {/* Start Date */}
                    <td
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        gap: "5px",
                      }}
                    >
                      <tr
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        <td
                          style={{
                            fontSize: "20px",
                            color: "#fff",
                            fontWeight: "400",
                            fontFamily: '"Jost", sans-serif',
                          }}
                        >
                          Start date :
                        </td>
                        <td
                          style={{
                            fontSize: "20px",
                            color: "#fff",
                            fontWeight: "400",
                            fontFamily: '"Jost", sans-serif',
                          }}
                        >
                          {getYearFromDate(entry.start_date)} -{" "}
                          {getYearFromDate(entry.end_date)}
                        </td>
                      </tr>

                      {/* Role */}
                      <tr
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        <td
                          style={{
                            fontSize: "20px",
                            color: "#fff",
                            fontWeight: "400",
                            fontFamily: '"Jost", sans-serif',
                          }}
                        >
                          Your Role :
                        </td>
                        <td
                          style={{
                            fontSize: "20px",
                            color: "#fff",
                            fontWeight: "400",
                            fontFamily: '"Jost", sans-serif',
                          }}
                        >
                          {entry.role}
                        </td>
                      </tr>

                      {/* What you did in this company */}
                      <tr
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "start",
                          gap: "5px",
                        }}
                      >
                        <td
                          style={{
                            fontSize: "20px",
                            color: "#fff",
                            fontWeight: "400",
                            fontFamily: '"Jost", sans-serif',
                          }}
                        >
                          What you did in this company :
                        </td>
                        <td
                          style={{
                            fontSize: "20px",
                            color: "#fff",
                            fontWeight: "400",
                            fontFamily: '"Jost", sans-serif',
                          }}
                        >
                          {entry.company_done}
                        </td>
                      </tr>
                    </td>
                  </tr>
                ))
              ) : (
                // If no employment history available, show this message
                <tr>
                  <td
                    colSpan="2"
                    style={{ color: "#fff", textAlign: "center" }}
                  >
                    No employment history available.
                  </td>
                </tr>
              )}
            </tr>
            <tr
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                gap: "30px",
                alignItems: "start",
                width: "100%",
              }}
            >
              <td
                style={{
                  textAlign: "center",
                  color: "rgb(255, 255, 255)",
                  fontSize: "30px",
                  textTransform: "capitalize",
                  fontFamily: '"Jost", sans-serif',
                  fontWeight: "500",
                  borderBottom: "2px solid #fff",
                  width: "100%",
                }}
              >
                Education
              </td>
              {educationsArray.map((entry) => (
                <tr style={{ padding: "0px 50px" }}>
                  {/* Year of Completion */}
                  <td
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      gap: "5px",
                    }}
                  >
                    <tr
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      <td
                        style={{
                          fontSize: "20px",
                          color: "#fff",
                          fontWeight: "400",
                          fontFamily: '"Jost", sans-serif',
                        }}
                      >
                        Year of Completion :
                      </td>
                      <td
                        style={{
                          fontSize: "20px",
                          color: "#fff",
                          fontWeight: "400",
                          fontFamily: '"Jost", sans-serif',
                        }}
                      >
                        {entry.year}
                      </td>
                    </tr>

                    {/* Course */}
                    <tr
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      <td
                        style={{
                          fontSize: "20px",
                          color: "#fff",
                          fontWeight: "400",
                          fontFamily: '"Jost", sans-serif',
                        }}
                      >
                        Course :
                      </td>
                      <td
                        style={{
                          fontSize: "20px",
                          color: "#fff",
                          fontWeight: "400",
                          fontFamily: '"Jost", sans-serif',
                        }}
                      >
                        {entry.course}
                      </td>
                    </tr>

                    {/* Institution */}
                    <tr
                      style={{
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      <td
                        style={{
                          fontSize: "20px",
                          color: "#fff",
                          fontWeight: "400",
                          fontFamily: '"Jost", sans-serif',
                        }}
                      >
                        Institution :
                      </td>
                      <td
                        style={{
                          fontSize: "20px",
                          color: "#fff",
                          fontWeight: "400",
                          fontFamily: '"Jost", sans-serif',
                        }}
                      >
                        {entry.institution}
                      </td>
                    </tr>
                  </td>
                </tr>
              ))}
            </tr>
          </tr>
        </table>
        <table
          style={{
            width: "794px",
            margin: "0 auto",
            backgroundImage: `url(${require("../assets/images/certificate_bg.jpg")})`,
            backgroundSize: "cover",
            height: "100%",
            padding: "120px 0px",
            display: "block",
          }}
        >
          <tr
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              height: "100%",
              gap: "30px",
            }}
          >
            <td
              style={{
                textAlign: "center",
                color: "rgb(255, 255, 255)",
                fontSize: "30px",
                textTransform: "capitalize",
                fontFamily: '"Jost", sans-serif',
                fontWeight: "500",
                borderBottom: "2px solid #fff",
              }}
            >
              Skill and Extra Informations
            </td>
            <tr style={{ padding: "0px 50px" }}>
              <td
                style={{ display: "flex", flexDirection: "column", gap: "5px" }}
              >
                <tr
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  <td
                    style={{
                      fontSize: "20px",
                      color: "#fff",
                      fontWeight: "400",
                      fontFamily: '"Jost", sans-serif',
                    }}
                  >
                    Mention All Skills :
                  </td>
                  {skillsArray.map((item) => (
                    <td
                      style={{
                        fontSize: "20px",
                        color: "#fff",
                        fontWeight: "400",
                        fontFamily: '"Jost", sans-serif',
                      }}
                    >
                      {item}
                    </td>
                  ))}
                </tr>
                <tr
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  <td
                    style={{
                      fontSize: "20px",
                      color: "#fff",
                      fontWeight: "400",
                      fontFamily: '"Jost", sans-serif',
                    }}
                  >
                    Years of Experience :
                  </td>
                  <td
                    style={{
                      fontSize: "20px",
                      color: "#fff",
                      fontWeight: "400",
                      fontFamily: '"Jost", sans-serif',
                    }}
                  >
                    {resumedata.years}
                  </td>
                </tr>
                <tr
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  {!licfile || licfile.length === 0 ? (
                    <p></p> // or any other message or component
                  ) : (
                    <>
                      {licfile
                        .reduce((rows, licensef, index) => {
                          const currentRow = Math.floor(index / 1);

                          // Initialize a new row if it doesn't exist
                          if (!rows[currentRow]) {
                            rows[currentRow] = [];
                          }

                          // Add the current image to the row
                          rows[currentRow].push(
                            <td style={{ width: "160px" }}>
                              <img
                                className="w-100 h-100"
                                src={`${apiurl}${licensef}`}
                                alt="img"
                              />
                            </td>
                          );

                          return rows;
                        }, [])
                        .map((row, rowIndex) => (
                          <tr key={rowIndex}>{row}</tr>
                        ))}
                    </>
                  )}
                </tr>
                <tr
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  <td
                    style={{
                      fontSize: "20px",
                      color: "#fff",
                      fontWeight: "400",
                      fontFamily: '"Jost", sans-serif',
                    }}
                  >
                    Mention Licences :
                  </td>
                  {licenseArray !== null && (
                    <>
                      {licenseArray.map((license, index) => (
                        <td
                          style={{
                            fontSize: "20px",
                            color: "#fff",
                            fontWeight: "400",
                            fontFamily: '"Jost", sans-serif',
                          }}
                        >
                          {license}
                        </td>
                      ))}
                    </>
                  )}
                </tr>
                <tr
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  {!edfile || edfile.length === 0 ? (
                    <p></p> // or any other message or component
                  ) : (
                    <>
                      {edfile
                        .reduce((rows, licensef, index) => {
                          const currentRow = Math.floor(index / 1);

                          // Initialize a new row if it doesn't exist
                          if (!rows[currentRow]) {
                            rows[currentRow] = [];
                          }

                          // Add the current image to the row
                          rows[currentRow].push(
                            <td style={{ width: "160px" }}>
                              <img
                                className="w-100 h-100"
                                src={`${apiurl}${licensef}`}
                                alt="img"
                              />
                            </td>
                          );

                          return rows;
                        }, [])
                        .map((row, rowIndex) => (
                          <tr key={rowIndex}>{row}</tr>
                        ))}
                    </>
                  )}
                </tr>
                <tr
                  style={{ display: "flex", alignItems: "start", gap: "5px" }}
                >
                  <td
                    style={{
                      fontSize: "20px",
                      color: "#fff",
                      fontWeight: "400",
                      fontFamily: '"Jost", sans-serif',
                      flexShrink: "0",
                    }}
                  >
                    Mention Other Certification :
                  </td>
                  {certm.length > 0 ? (
                    <>
                      {certm.map((item) => (
                        <td
                          style={{
                            fontSize: "20px",
                            color: "#fff",
                            fontWeight: "400",
                            fontFamily: '"Jost", sans-serif',
                          }}
                        >
                          {item}
                        </td>
                      ))}
                    </>
                  ) : (
                    <p>
                      {/* Render some default content or a message when licfile is empty */}
                    </p>
                  )}
                </tr>
                <tr
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  {!tradetextArray || tradetextArray.length === 0 ? (
                    <p></p> // or any other message or component
                  ) : (
                    <>
                      {tradetextArray
                        .reduce((rows, licensef, index) => {
                          const currentRow = Math.floor(index / 1);

                          // Initialize a new row if it doesn't exist
                          if (!rows[currentRow]) {
                            rows[currentRow] = [];
                          }

                          // Add the current image to the row
                          rows[currentRow].push(
                            <td style={{ width: "160px" }}>
                              <img
                                className="w-100 h-100"
                                src={`${apiurl}${licensef}`}
                                alt="img"
                              />
                            </td>
                          );

                          return rows;
                        }, [])
                        .map((row, rowIndex) => (
                          <tr key={rowIndex}>{row}</tr>
                        ))}
                    </>
                  )}
                </tr>
                <tr
                  style={{ display: "flex", alignItems: "start", gap: "5px" }}
                >
                  <td
                    style={{
                      fontSize: "20px",
                      color: "#fff",
                      fontWeight: "400",
                      fontFamily: '"Jost", sans-serif',
                      flexShrink: "0",
                    }}
                  >
                    Trade Certifications :
                  </td>
                  {tradefile.length > 0 ? (
                    <>
                      {tradefile.map((item) => (
                        <td
                          style={{
                            fontSize: "20px",
                            color: "#fff",
                            fontWeight: "400",
                            fontFamily: '"Jost", sans-serif',
                          }}
                        >
                          {item}
                        </td>
                      ))}
                    </>
                  ) : (
                    <p>
                      {/* Render some default content or a message when licfile is empty */}
                    </p>
                  )}
                </tr>
                <tr
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  {!machinetextArray || machinetextArray.length === 0 ? (
                    <p></p> // or any other message or component
                  ) : (
                    <>
                      {machinetextArray
                        .reduce((rows, licensef, index) => {
                          const currentRow = Math.floor(index / 1);

                          // Initialize a new row if it doesn't exist
                          if (!rows[currentRow]) {
                            rows[currentRow] = [];
                          }

                          // Add the current image to the row
                          rows[currentRow].push(
                            <td style={{ width: "160px" }}>
                              <img
                                className="w-100 h-100"
                                src={`${apiurl}${licensef}`}
                                alt="img"
                              />
                            </td>
                          );

                          return rows;
                        }, [])
                        .map((row, rowIndex) => (
                          <tr key={rowIndex}>{row}</tr>
                        ))}
                    </>
                  )}
                </tr>
                <tr
                  style={{ display: "flex", alignItems: "start", gap: "5px" }}
                >
                  <td
                    style={{
                      fontSize: "20px",
                      color: "#fff",
                      fontWeight: "400",
                      fontFamily: '"Jost", sans-serif',
                      flexShrink: "0",
                    }}
                  >
                    Machinery :
                  </td>
                  {machinefileArray.length > 0 ? (
                    <>
                      {machinefileArray.map((item) => (
                        <td
                          style={{
                            fontSize: "20px",
                            color: "#fff",
                            fontWeight: "400",
                            fontFamily: '"Jost", sans-serif',
                          }}
                        >
                          {item}
                        </td>
                      ))}
                    </>
                  ) : (
                    <p>
                      {/* Render some default content or a message when licfile is empty */}
                    </p>
                  )}
                </tr>
                {/* <tr style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                {!machinetextArray || machinetextArray.length === 0 ? (
                  <p></p> // or any other message or component
                ) : (
                  <>
                    {machinetextArray
                      .reduce((rows, licensef, index) => {
                        const currentRow = Math.floor(index / 1);

                        // Initialize a new row if it doesn't exist
                        if (!rows[currentRow]) {
                          rows[currentRow] = [];
                        }

                        // Add the current image to the row
                        rows[currentRow].push(
                          <td style={{ width: "160px" }}>
                            <img
                              className="w-100 h-100"
                              src="https://shorturl.at/bG1Dv"
                              alt="img"
                            />
                          </td>
                        );

                        return rows;
                      }, [])
                      .map((row, rowIndex) => (
                        <tr key={rowIndex}>{row}</tr>
                      ))}
                  </>
                )}
              </tr> */}
                <tr
                  style={{ display: "flex", alignItems: "start", gap: "5px" }}
                >
                  <td
                    style={{
                      fontSize: "20px",
                      color: "#fff",
                      fontWeight: "400",
                      fontFamily: '"Jost", sans-serif',
                      flexShrink: "0",
                    }}
                  >
                    Vocational training :
                  </td>
                  {vocationalArray.length > 0 ? (
                    <>
                      {vocationalArray.map((item) => (
                        <td
                          style={{
                            fontSize: "20px",
                            color: "#fff",
                            fontWeight: "400",
                            fontFamily: '"Jost", sans-serif',
                          }}
                        >
                          {item}
                        </td>
                      ))}
                    </>
                  ) : (
                    <p>
                      {/* Render some default content or a message when licfile is empty */}
                    </p>
                  )}
                </tr>

                <tr
                  style={{ display: "flex", alignItems: "start", gap: "5px" }}
                >
                  <td
                    style={{
                      fontSize: "20px",
                      color: "#fff",
                      fontWeight: "400",
                      fontFamily: '"Jost", sans-serif',
                      flexShrink: "0",
                    }}
                  >
                    Equipment worked :
                  </td>
                  {equipmentWorked.length > 0 ? (
                    <>
                      {equipmentWorked.map((item) => (
                        <td
                          style={{
                            fontSize: "20px",
                            color: "#fff",
                            fontWeight: "400",
                            fontFamily: '"Jost", sans-serif',
                          }}
                        >
                          {item}
                        </td>
                      ))}
                    </>
                  ) : (
                    <p>
                      {/* Render some default content or a message when licfile is empty */}
                    </p>
                  )}
                </tr>

                <tr
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    gap: "2px",
                  }}
                >
                  <td
                    style={{
                      fontSize: "20px",
                      color: "#fff",
                      fontWeight: "400",
                      fontFamily: '"Jost", sans-serif',
                    }}
                  >
                    Any sites you don’t want to work at ? :
                  </td>
                  <td
                    style={{
                      fontSize: "20px",
                      color: "#fff",
                      fontWeight: "400",
                      fontFamily: '"Jost", sans-serif',
                    }}
                  >
                    {resumedata.work}
                  </td>
                </tr>
              </td>
            </tr>
          </tr>
        </table>
      </div>
    );

    //setTimeout(() => {
    generatePDF(mainpdf);
    setspringloader(false);
    // }, 2000);
  };

  useEffect(() => {
    changeFavicon(require("../assets/admin/images/f-icon.png"));
    document.title = "Employee Page";
    const userStr = JSON.parse(localStorage.getItem("admin"));
    //console.log(userStr);
    if (userStr == null) {
      navigate("/admin/login");
    }
    axios
      .get("https://jlmining.app/admin/getemployeeAdmin", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        //console.log(res.data.results);
        setData(res.data.results);
        if (res.data.results.length > 0) {
          // Initialize a variable to store the total uploads count
          let totalUploadsCount = 0;

          // Loop through the results array and sum up the total_uploads parameter
          res.data.results.forEach((employee) => {
            totalUploadsCount += employee.total_uploads;
          });

          // Use the totalUploadsCount as needed
          setexp(totalUploadsCount);
        }
      })
      .catch((err) => {});

    if (resumedata != "") {
      var d = JSON.parse(resumedata.licence_file);
      //console.log(d);
      pdfdataget();
      setresumedata("");
    }
    let formdata = {
      user_id: 1,
    };
    axios
      .post("https://jlmining.app/getskills", formdata, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setSelectedOptions(res.data.results);
        //const options = res.data.results;
      })
      .catch((err) => {
        //console.log(err.response.data);
      });
    //Mention Licence
    axios
      .post("https://jlmining.app/getmentionlicence", formdata, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setSelectedMentionLic(res.data.results);
      })
      .catch((err) => {});
    axios
      .post("https://jlmining.app/getequipmentwork", formdata, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setSelectedEquipmentwork_g(res.data.results);
        setSelectedEquipmentwork(res.data.results);
      })
      .catch((err) => {});
    axios
      .post("https://jlmining.app/getmentioncertificate", formdata, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setMentionCertt(res.data.results);
      })
      .catch((err) => {});
    //Mention certificate
    axios
      .get("https://jlmining.app/admin/getclient", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setrosterclient(res.data.results);
      })
      .catch((err) => {});
    //Previous Work
    axios
      .post("https://jlmining.app/getprevwork", formdata, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setSelectedprevwork(res.data.results);
      })
      .catch((err) => {});
    axios
      .post("https://jlmining.app/getreferences", formdata, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log("refer");
        console.log(res.data.results);
        setSelectedreferences(res.data.results);
      })
      .catch((err) => {});
    //Trade
    axios
      .post("https://jlmining.app/gettrade", formdata, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setSelectedTrade(res.data.results);
      })
      .catch((err) => {});
    //Machinery
    axios
      .post("https://jlmining.app/getmachinery", formdata, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setSelectedmachinery(res.data.results);
      })
      .catch((err) => {});
    axios
      .post("https://jlmining.app/getvocationaltra", formdata, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setSelectedVocationtra(res.data.results);
      })
      .catch((err) => {});
  }, [resumedata, UserId, vlexp]);

  const handle_getallattendance = (event) => {
    let formdata = {
      user_id: valueemplyId,
      client_id: event.target.value,
    };
    console.log(formdata);
    if (event.target.value != "") {
      setisClickedetails(true);
      setisClickedyourroster(true);
    } else {
      setisClickedetails(false);
      setisClickedyourroster(false);
    }
    axios
      .post("https://jlmining.app/admin/getrosterrelated", formdata, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        var alldata = res.data;
        if (alldata.row[0] != "") {
          // console.log(alldata.row[0]);
          setrostertype(alldata.row[0].type);
          setValuemainroster(alldata.row[0]);
        }
      })
      .catch((err) => {});

    //For All Working Days
    axios
      .post(
        "https://jlmining.app/admin/getAttendanceAllworkingDays",
        formdata,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "POST,PUT",
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        var alldata = res.data.data;
        //console.log(alldata);
        setValueworkingdays(alldata);
      })
      .catch((err) => {});
    //For All Working Days

    //For All Working Days
    axios
      .post("https://jlmining.app/admin/getAttendanceAlloffDays", formdata, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        var alldata = res.data.data;
        //console.log(alldata);
        setValuealloffdays(alldata);
      })
      .catch((err) => {});
    //For All Working Days

    //For Sick Leave
    axios
      .post(
        "https://jlmining.app/admin/getAttendancedetailsickLeave",
        formdata,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "POST,PUT",
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        var alldata = res.data.data;
        //console.log(alldata);
        setValuesickleave(alldata);
      })
      .catch((err) => {});
    //For Sick Leave

    //For Annual Leave
    axios
      .post(
        "https://jlmining.app/admin/getAttendancedetailannualLeave",
        formdata,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "POST,PUT",
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        var alldata = res.data.data;
        //console.log(alldata);
        setValueannualleave(alldata);
      })
      .catch((err) => {});
    //For Annual Leave

    //For Works Comp
    axios
      .post(
        "https://jlmining.app/admin/getAttendancedetailworkerscomp",
        formdata,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "POST,PUT",
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        var alldata = res.data.data;
        //console.log(alldata);
        setValueworkerscomp(alldata);
      })
      .catch((err) => {});
    //For Works Comp

    //For Fly In Pm
    axios
      .post("https://jlmining.app/admin/getAttendancedetailflyinpm", formdata, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        var alldata = res.data.data;
        //console.log(alldata);
        setValueflyinpm(alldata);
      })
      .catch((err) => {});
    //For Fly In Pm

    //For Fly Out Am
    axios
      .post(
        "https://jlmining.app/admin/getAttendancedetailflyoutam",
        formdata,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "POST,PUT",
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        var alldata = res.data.data;
        //console.log(alldata);
        setValueflyoutam(alldata);
      })
      .catch((err) => {});
    //For Fly Out Am

    //For Fly in Am
    axios
      .post("https://jlmining.app/admin/getAttendancedetailflyinam", formdata, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        var alldata = res.data.data;
        setValueflyinam(alldata);
      })
      .catch((err) => {});
    //For Fly in Am

    //For Fly In Isolation
    axios
      .post(
        "https://jlmining.app/admin/getAttendancedetailinisolationonsite",
        formdata,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "POST,PUT",
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        var alldata = res.data.data;
        setValueinisolationonsite(alldata);
      })
      .catch((err) => {});
    //For Fly In Isolation

    //For Leave Without pay
    axios
      .post(
        "https://jlmining.app/admin/getAttendancedetailleavewithoutpay",
        formdata,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "POST,PUT",
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        var alldata = res.data.data;
        setValueleavewithoutpay(alldata);
      })
      .catch((err) => {});
    //For Leave Without pay

    //For Fly out PM
    axios
      .post(
        "https://jlmining.app/admin/getAttendancedetailflyoutpm",
        formdata,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "POST,PUT",
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        var alldata = res.data.data;
        setValueflyoutpm(alldata);
      })
      .catch((err) => {});
    //For Fly out PM

    //For Work of Site
    axios
      .post(
        "https://jlmining.app/admin/getAttendancedetailworkoffsite",
        formdata,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "POST,PUT",
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        var alldata = res.data.data;
        setValueworkoffsite(alldata);
      })
      .catch((err) => {});
    //For Work of Site

    //Check day off
    axios
      .post("https://jlmining.app/admin/getuserdayoff", formdata, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        var alldata = res.data.data;
        setValueDayoff(alldata);
      })
      .catch((err) => {});
    //Check day off

    axios
      .post("https://jlmining.app/admin/getAttendancedetailForDay", formdata, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        var alldata = res.data.data;

        setValueget(alldata);
      })
      .catch((err) => {});
    //For Night Shift

    axios
      .post(
        "https://jlmining.app/admin/getAttendancedetailForNight",
        formdata,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "POST,PUT",
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        var nightshift = res.data.data;
        //console.log(alldata);
        setValuenightshift(nightshift);
      })
      .catch((err) => {});
    //For Night Shift
  };
  const numbers = Array.from({ length: 24 }, (_, index) => index + 1);
  const kms = Array.from({ length: 1000 }, (_, index) => index + 1);
  const isObjectEmpty = Object.keys(valuenightshift).length === 0;
  const isObjectEmptyday = Object.keys(valueget).length === 0;
  const isObjectEmptysickleave = Object.keys(valuesickleave).length === 0;
  const isObjectEmptydayoff = Object.keys(ValueDayoff).length === 0;

  const isObjectEmptyannualleave = Object.keys(valueannualleave).length === 0;
  const isObjectEmptyworkerscomp = Object.keys(valueworkerscomp).length === 0;
  const isObjectEmptyflyinpm = Object.keys(valueflyinpm).length === 0;
  const isObjectEmptyflyoutam = Object.keys(valueflyoutam).length === 0;
  const isObjectEmptyflyinam = Object.keys(valueflyinam).length === 0;
  const isObjectEmptyinisolationonsite =
    Object.keys(valueinisolationonsite).length === 0;
  const isObjectEmptyleavewithoutpay =
    Object.keys(valueleavewithoutpay).length === 0;
  const isObjectEmptyflyoutpm = Object.keys(valueflyoutpm).length === 0;
  const isObjectEmptyworkoffsite = Object.keys(valueworkoffsite).length === 0;

  const isObjectEmptyworkingdays = Object.keys(Valueworkingdays).length === 0;
  const isObjectEmptyalldaysOff = Object.keys(Valuealloffdays).length === 0;
  const dynamicImagePath = require("../assets/images/background/bg.jpg");
  const tableStyle1 = {
    border: "none",
    borderSpacing: 0,
    width: "100%",
    padding: "1cm",
    height: "297mm",
  };

  const containerStyle = {
    background: `url(${dynamicImagePath}) no-repeat center top`,
    backgroundSize: "cover",
    textAlign: "center",
    height: "100vh",
  };

  const tableStyle = {
    border: "none",
    borderSpacing: 0,
    textAlign: "center",
    width: "100%",
    marginTop: "100px",
  };

  const textStyle = {
    textAlign: "center",
    color: "#fff",
    fontSize: "18px",
    textTransform: "capitalize",
    fontFamily: "'Times New Roman', Times, serif",
  };
  const mainPageStyles = {
    width: "210mm",
    minHeight: "297mm",
    background: "white",
    margin: "10mm auto",
    boxShadow: "0 0 0.5cm rgba(0, 0, 0, 0.5)",
  };

  const printStyles = {
    "@media print": {
      html: {
        width: "210mm",
        height: "297mm",
      },
      body: {
        width: "210mm",
        height: "297mm",
        WebkitPrintColorAdjust: "exact",
        position: "relative",
        margin: 0,
      },
      ".main-page": {
        margin: "0",
        border: "initial",
        borderRadius: "initial",
        width: "initial",
        minHeight: "initial",
        boxShadow: "initial",
        background: "initial",
        pageBreakAfter: "always",
      },
    },
  };
  const mergedStyles = {
    ...containerStyle,
    ...printStyles,
  };

  //Resume Pdf

  //Resusme Pdf
  function getYearFromDate(dateString) {
    const dateObject = new Date(dateString);
    return dateObject.getFullYear();
  }
  function getYearFromDate(dateString) {
    const dateObject = new Date(dateString);
    return dateObject.getFullYear();
  }

  //Roster
  const rosterupdate = (event) => {
    let formdsata = {
      user_id: event,
    };
    axios
      .post("https://jlmining.app/admin/getuserroster", formdsata, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        //console.log("checkcurrent");
        // console.log(res.data.row.length);
        if (res.data.row.length > 0) {
          setopenclosecalender(true);
          setopencloseroster(false);
        } else {
          setopenclosecalender(false);
          setopencloseroster(true);
        }
        setUserId(event);
        setrosterdialog(true);
      })
      .catch((err) => {});
  };
  const closeBox = () => {
    setrosterdialog(false);
    seteditprofile(false);
    setsucc_n(false);
    setadmindoc1(false);
    setadminlic1(false);
    setopennotification(false);
  };
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };
  const handlegetmnesites = (clientId, index) => {
    setActiveIndex_index(index);
    setActivesIndex("");
    setClientId(clientId);
    let formdata = {
      clientId: clientId,
    };
    axios
      .post("https://jlmining.app/admin/getminesites", formdata, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        //console.log(res.data.results);
        getDatal(res.data.results);
        setIsClickedUser(true);
        setMinClicked(false);
      })
      .catch((err) => {});
    axios
      .post("https://jlmining.app/admin/getclientinfo", formdata, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        //console.log(res.data.row);
        if (res.data.row != "") {
          setclientInfo(res.data.row[0]);
        }
      })
      .catch((err) => {});
  };
  const handlegetmoinesites = (locationId, index) => {
    var locationId = locationId;
    setActivesIndex(index);

    setlocatid(locationId);
    setMinClicked(true);
    setLocationId(locationId);
    setIsClickedloc(true);
    let location_Id = {
      locationId: locationId,
    };
    axios
      .post("https://jlmining.app/admin/getlocationr", location_Id, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        // console.log(res.data.ss);
        if (res.data.ss != "") {
          setlocated(res.data.ss);
        }
      })
      .catch((err) => {});
  };
  const handlechooseRostersubmit = (event) => {
    //console.log(clientID);
    if (clientID === "") {
      setclientreq(true);
      return false;
    }

    if (locationID === "") {
      setclientreq(true);
      return false;
    }
    if (selectedOption === "" || selectedOption === undefined) {
      setrosterreq(true);
      return false;
    }
    setclientreq(false);
    setlocatreq(false);
    var vl = event.target;
    // console.log(vl.date.value);

    let formdata = {
      type: selectedOption,
      date: vl.date.value,
      locationId: locationID,
      clientID: clientID,
      user_id: UserId,
    };
    // console.log(formdata);

    setrosterreq(false);
    setclientreq(false);
    setlocatreq(false);
    let formdsata = {
      user_id: UserId,
    };
    setIsConditionMet(true);
    setIsConditionMetbtn("Don't refresh the page, Please wait...");
    axios
      .post("https://jlmining.app/admin/setRoster", formdata, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        //console.log(res.data);
        if (res.data.status == "1") {
          setShowAlert(true);
          setShowAlert({ type: "error" });
          setTimeout(() => {
            setShowAlert(false);
          }, 4500);
          setIsConditionMet(false);
          setIsConditionMetbtn("Submit");
        }
        if (res.data.status == "2") {
          setShowAlert(true);
          setShowAlert({ type: "success" });
          axios
            .post("https://jlmining.app/admin/addmissingDay", formdata, {
              headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "POST,PUT",
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            })
            .then((res) => {})
            .catch((err) => {});
          axios
            .post("https://jlmining.app/admin/getallrosters", formdsata, {
              headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "POST,PUT",
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            })
            .then((res) => {
              var alldata = res.data.results;

              setValuegetallroster(alldata);
              setTimeout(() => {
                setShowAlert(false);
                window.location.reload();
              }, 6500);
            })
            .catch((err) => {});
        }
        if (res.data.status == "3") {
          setShowAlert(true);
          setShowAlert({ type: "errorroster" });
          setTimeout(() => {
            setShowAlert(false);
          }, 5500);
        }
        axios
          .post("https://jlmining.app/admin/getallrosters", formdata, {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods": "POST,PUT",
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          })
          .then((res) => {
            var alldata = res.data.results;

            setValuegetallroster(alldata);
          })
          .catch((err) => {});
      })
      .catch((err) => {
        //console.log(err.response.data);
      });
  };
  const deleterotser = (id) => {};
  const checkcondition = () => {
    if (clientID === "") {
      setclientreq(true);
      return false;
    }
    if (locationID === "") {
      setlocatreq(true);
      return false;
    }
    if (selectedOption === "" || selectedOption === undefined) {
      setrosterreq(true);
      return false;
    }
    setrosterreq(false);
    setclientreq(false);
    setlocatreq(false);
  };
  const handleclientcheck = (e) => {
    // console.log(e.target.value);

    let fromdata = {
      user_id: UserId,
      client_id: e.target.value,
    };
    axios
      .post("https://jlmining.app/admin/getlocationcalender", fromdata, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res.data.row != "") {
          setlocatedname(res.data.row[0].location_name);
        }
      })
      .catch((err) => {});
  };
  const deletecurrentroster = () => {
    let fromdata = {
      user_id: UserId,
    };
    var conf = window.confirm("Are you sure you to delete this roster?");
    if (conf) {
      axios
        .post("https://jlmining.app/admin/deletecurrentroster", fromdata, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "POST,PUT",
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        })
        .catch((err) => {});
    }
  };
  const handle_getIcon = (event, bg, shift) => {
    setBackgroundColor(bg);
    //setcolorActive(bg);
    setIcon(event);
    setshifts(shift);
    setshiftmessage("");
    setisClickedopen(true);
  };
  const handleget_refresh = (event) => {
    setIsClickedloader(true);
    if (!UserId) {
      navigate("/login");
    }

    let formdata = {
      user_id: UserId,
    };
    setTimeout(() => {
      setIsClickedloader(false);
    }, 1000);
    //window.location.reload();
    getallcalender();
  };
  const getallcalender = () => {
    let formdata = {
      user_id: UserId,
    };
    axios
      .post("https://jlmining.app/admin/getallCalendardetail", formdata, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        var alldata = res.data.maindata;
        //var d = getday();
        const all_data = [];
        for (let i = 0; i < alldata.length; i++) {
          //   console.log(alldata[i]);
          var cudate = new Date();
          var st_date = moment(cudate).format("YYYY-MM-DD");

          var clr = "";
          var id = alldata[i].attend_id;
          if (alldata[i].id == "") {
            var title = "";
            var ic = "";
            var colr = "white";
            var pop = "Open";
            var cudate = new Date();

            var st_date = moment(cudate).format("YYYY-MM-DD");

            var start = st_date;
            var end = st_date;
            var shft = "";
            var hours = "";
          }
          if (alldata[i].shift === "Day") {
            var title = "DS";
            var ic = <FaSun />;
            var colr = "#B2DAF2";
            var clr = "black";
            var pop = "Close";
            var st_date = moment(alldata[i].date).format("YYYY-MM-DD");

            var start = st_date;
            var end = st_date;
            var shft = alldata[i].shift;
            var hours = alldata[i].hours;
          }
          if (alldata[i].shift === "Edit") {
            var title = "Edit | " + alldata[i].client_name;
            var ic = <FaEdit />;
            var pop = "Open";
            var colr = "gray";
            var st_date = moment(alldata[i].date).format("YYYY-MM-DD");

            var start = st_date;
            var end = st_date;
            var shft = alldata[i].shift;
            var hours = alldata[i].hours;
          }
          if (alldata[i].shift === "Add") {
            var title = "Add";
            var ic = "";
            var pop = "Open";
            var colr = "white";
            var clr = "red";
            var st_date = moment(alldata[i].date).format("YYYY-MM-DD");

            var start = st_date;
            var end = st_date;
            var shft = alldata[i].shift;
            var hours = alldata[i].hours;
          }
          if (alldata[i].shift === "Night") {
            var title = "DN";
            var ic = <FaMoon />;
            var colr = "#1D0303";
            var pop = "Close";
            var st_date = moment(alldata[i].date).format("YYYY-MM-DD");

            var start = st_date;
            var end = st_date;
            var shft = alldata[i].shift;
            var hours = alldata[i].hours;
          }
          if (alldata[i].shift === "FLIAM") {
            var title = "FLIAM";
            var ic = <FaPlaneArrival />;
            var colr = "#B9723D";
            var pop = "Close";
            var st_date = moment(alldata[i].date).format("YYYY-MM-DD");

            var start = st_date;
            var end = st_date;
            var shft = alldata[i].shift;
            var hours = alldata[i].hours;
          }
          if (alldata[i].shift === "FLIPM") {
            var title = "FLIPM";
            var ic = <FaPlaneArrival />;
            var colr = "#7DBFFC";
            var pop = "Close";
            var st_date = moment(alldata[i].date).format("YYYY-MM-DD");

            var start = st_date;
            var end = st_date;
            var shft = alldata[i].shift;
            var hours = alldata[i].hours;
          }
          if (alldata[i].shift === "FLOAM") {
            var title = "FLOAM";
            var ic = <FaPlaneDeparture />;
            var colr = "#9F20AA";
            var pop = "Close";
            var st_date = moment(alldata[i].date).format("YYYY-MM-DD");

            var start = st_date;
            var end = st_date;
            var shft = alldata[i].shift;
            var hours = alldata[i].hours;
          }
          if (alldata[i].shift === "FLOPM") {
            var title = "FLOPM";
            var ic = <FaPlaneDeparture />;
            var colr = "#77D928";
            var pop = "Close";
            var st_date = moment(alldata[i].date).format("YYYY-MM-DD");

            var start = st_date;
            var end = st_date;
            var shft = alldata[i].shift;
            var hours = alldata[i].hours;
          }
          if (alldata[i].shift === "Sick Leave") {
            var title = "Sick Leave";
            var ic = <FaThermometer />;
            var colr = "#395AEF";
            var pop = "Close";
            var st_date = moment(alldata[i].date).format("YYYY-MM-DD");

            var start = st_date;
            var end = st_date;
            var shft = alldata[i].shift;
            var hours = alldata[i].hours;
          }
          if (alldata[i].shift === "AL") {
            var title = "AL";
            var ic = <FaUmbrella />;
            var colr = "#F71718";
            var pop = "Close";
            var st_date = moment(alldata[i].date).format("YYYY-MM-DD");

            var start = st_date;
            var end = st_date;
            var shft = alldata[i].shift;
            var hours = alldata[i].hours;
          }
          if (alldata[i].shift === "Work Offsite") {
            var title = "Work Offsite";
            var ic = <FaUmbrella />;
            var colr = "#490909";
            var pop = "Close";
            var st_date = moment(alldata[i].date).format("YYYY-MM-DD");

            var start = st_date;
            var end = st_date;
            var shft = alldata[i].shift;
            var hours = alldata[i].hours;
          }
          if (alldata[i].shift === "Leave Without Pay") {
            var title = "Leave Without Pay";
            var ic = "";
            var colr = "#F3DC0C";
            var pop = "Close";
            var st_date = moment(alldata[i].date).format("YYYY-MM-DD");

            var start = st_date;
            var end = st_date;
            var shft = alldata[i].shift;
            var hours = alldata[i].hours;
          }
          if (alldata[i].shift === "In Isolation on site") {
            var title = "In Isolation on site";
            var ic = <FaStar />;
            var colr = "#FB02F5";
            var pop = "Close";
            var st_date = moment(alldata[i].date).format("YYYY-MM-DD");

            var start = st_date;
            var end = st_date;
            var shft = alldata[i].shift;
            var hours = alldata[i].hours;
          }
          if (alldata[i].shift === "Work Camp") {
            var title = "Work Camp";
            var ic = <FaStar />;
            var colr = "#F8B70D";
            var pop = "Close";
            var st_date = moment(alldata[i].date).format("YYYY-MM-DD");

            var start = st_date;
            var end = st_date;
            var shft = alldata[i].shift;
            var hours = alldata[i].hours;
          }

          let findata = {
            title: title,
            start: start,
            end: end,
            color: clr,
            backgroundColor: colr,
            text: "center",
            icon: ic,
            pop: pop,
            id: id,
            shft: shft,
            hours: hours,
          };

          all_data.push(findata);
        }

        setValuegetCalendar(all_data);
      })
      .catch((err) => {});
  };
  const localizer = momentLocalizer(moment);
  const handleevt = (event) => {
    setSelectedEvent(event);
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Month is 0-indexed, so we add 1
    const day = String(currentDate.getDate()).padStart(2, "0");

    const formattedDate = `${year}-${month}-${day}`;
    //console.log(event);
    //console.log("dd");
    //console.log(shifts);
    if (event.shft === "Add") {
      if (event.pop === "Open") {
        setIsModalOpen(true);
      }
      setDatessvalue(event.start);
      setDates(event.start);

      let formdata = {
        checkdate: event.start,
        user_id: UserId,
      };

      setTimeout(() => {
        let fdata = {
          checkdate: event.start,
          user_id: UserId,
        };
        if (event.title === "Add") {
          //console.log(fdata);
          axios
            .post("https://jlmining.app/admin/getforEmp_client", fdata, {
              headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "POST,PUT",
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            })
            .then((res) => {
              var alldata = res.data.results;
              //console.log("add");
              //console.log(alldata);
              if (alldata.length > 0) {
                setValueroster(alldata);
                // setlocatename([]);
              }
            })
            .catch((err) => {});
        } else {
          axios
            .post("https://jlmining.app/admin/getallrosterlimit", formdata, {
              headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "POST,PUT",
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            })
            .then((res) => {
              var alldata = res.data.dataa;

              if (alldata.length > 0) {
                setvalueclient(alldata[0].name);
                setvalueclientId(alldata[0].client_id);
                //setValueroster(alldata);
                let fdata = {
                  checkdate: event.start,
                  user_id: UserId,
                  client_id: alldata[0].client_id,
                };
                axios
                  .post("https://jlmining.app/admin/getlocation_check", fdata, {
                    headers: {
                      "Access-Control-Allow-Origin": "*",
                      "Access-Control-Allow-Methods": "POST,PUT",
                      Accept: "application/json",
                      "Content-Type": "application/json",
                    },
                  })
                  .then((res) => {
                    var alldata = res.data.row;
                    // console.log("gttt");
                    // console.log(alldata);
                    if (alldata.length > 0) {
                      // setlocationname(alldata);
                      setvaluelocations(alldata[0].location_name);
                      setvaluelocationsId(alldata[0].location_id);
                      // setlocatename([]);
                    }
                  })
                  .catch((err) => {});
                axios
                  .post("https://jlmining.app/admin/getroster", formdata, {
                    headers: {
                      "Access-Control-Allow-Origin": "*",
                      "Access-Control-Allow-Methods": "POST,PUT",
                      Accept: "application/json",
                      "Content-Type": "application/json",
                    },
                  })
                  .then((res) => {
                    var alldata = res.data.results;
                    //console.log("getroster");
                    //console.log(res.data.results);
                    const fn = [];
                    for (let i = 0; i < alldata.length; i++) {
                      fn.push(alldata[i].type);
                    }
                    setValueget(fn);
                  })
                  .catch((err) => {});
              }
            });
        }
        setDataclient([]);
        setlocatedname("");
        axios
          .post("https://jlmining.app/admin/getclientforroster", formdata, {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods": "POST,PUT",
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          })
          .then((res) => {
            // console.log("client idd");
            // console.log(res.data.results);
            setDataclient(res.data.results);
          })
          .catch((err) => {});
        if (event.title === "Add") {
          setisClickedaddorEdit(false);
          setisClickedadd(true);
        } else {
          setisClickedaddorEdit(true);
          setisClickedadd(false);
        }
      }, 1000);
    } else {
      //console.log(shifts);
      if (shifts === "") {
        setshiftmessage("Please choose the shift");
      } else {
        const userStr = JSON.parse(localStorage.getItem("users"));
        let formdata = {
          checkdate: event.start,
          user_id: userStr[0].id,
          shift: shifts,
        };
        axios
          .post("https://jlmining.app/admin/multiplerosteradd", formdata, {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods": "POST,PUT",
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          })
          .then((res) => {
            console.log("getallcalender");
            getallcalender();
          })
          .catch((err) => {});
        setlocatedname("");
        setDataclient([]);
        axios
          .post("https://jlmining.app/admin/getclientforroster", formdata, {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods": "POST,PUT",
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          })
          .then((res) => {
            // console.log("client idd");
            // console.log(res.data.results);
            setDataclient(res.data.results);
          })
          .catch((err) => {});
      }
    }
  };
  const CustomEvent1 = ({ event }) => (
    <div
      style={{
        padding: "4px 1px",
        backgroundColor:
          event.title === "Add"
            ? "red"
            : event.title === "Edit"
            ? "gray"
            : event.title === "DN"
            ? "#1D0303"
            : event.title === "FLIAM"
            ? "#B9723D"
            : event.title === "FLIPM"
            ? "#7DBFFC"
            : event.title === "FLOAM"
            ? "#9F20AA"
            : event.title === "FLOPM"
            ? "#77D928"
            : event.title === "Sick Leave"
            ? "#395AEF"
            : event.title === "AL"
            ? "#F71718"
            : event.title === "Work Offsite"
            ? "#490909"
            : event.title === "Leave Without Pay"
            ? "#F3DC0C"
            : event.title === "In Isolation on site"
            ? "#FB02F5"
            : event.title === "Work Camp"
            ? "#F8B70D"
            : event.backgroundColor,
      }}
    >
      <div
        style={{
          display: "flex",
          gap: "4px",
          fontSize: "13px",
          padding: "4px 1px",
          backgroundColor:
            event.title === "Add"
              ? "red"
              : event.title === "Edit"
              ? "gray"
              : event.title === "DN"
              ? "#1D0303"
              : event.title === "FLIAM"
              ? "#B9723D"
              : event.title === "FLIPM"
              ? "#7DBFFC"
              : event.title === "FLOAM"
              ? "#9F20AA"
              : event.title === "FLOPM"
              ? "#77D928"
              : event.title === "Sick Leave"
              ? "#395AEF"
              : event.title === "AL"
              ? "#F71718"
              : event.title === "Work Offsite"
              ? "#490909"
              : event.title === "Leave Without Pay"
              ? "#F3DC0C"
              : event.title === "In Isolation on site"
              ? "#FB02F5"
              : event.title === "Work Camp"
              ? "#F8B70D"
              : event.backgroundColor,
        }}
        onClick={() => handleevt(event)}
      >
        {event.icon}
        {event.title}
      </div>
      {event.shft !== "Add" &&
      event.shft !== "Edit" &&
      event.id !== undefined ? (
        <div>
          <select
            className="w-100 mt-2"
            value={event.hours}
            onChange={(e) =>
              handlesubmithours(event.start, e.target.value, event.shft)
            }
          >
            <option value={""}>Choose Hours</option>
            {numbers.map((number) => (
              <option key={number} value={number}>
                {number}
              </option>
            ))}
          </select>
        </div>
      ) : null}
    </div>
  );
  const handlesubmithours = (event, e, s) => {
    //if (e != "") {
    //setshifts("");
    if (e == "") {
      var e = null;
    }
    setIsClickedloader(true);
    setinputvalue_data(e);
    const userStr = JSON.parse(localStorage.getItem("users"));
    let formvl = {
      date: event,
      value: e,
      shift: s,
      user_id: userStr[0].id,
    };
    axios
      .post("https://jlmining.app/admin/calenderhoursadd", formvl, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setIsModalOpen_hrs(true);
        setTimeout(() => {
          getallcalender();
          setIsClickedloader(false);
          setIsModalOpen_hrs(false);
        }, 1000);
      })
      .catch((err) => {});
    // }
  };
  const CustomEvent2 = ({ event }) => <div></div>;
  const EventComponent = ({ event }) => {
    //console.log(event);
    if (event.id !== "") {
      return <CustomEvent1 event={event} />;
    } else if (
      event.shft !== "Add" &&
      event.shft !== "Edit" &&
      event.id !== undefined
    ) {
      return <CustomEvent2 event={event} />;
    }

    return null;
  };
  const handleAttend = (event) => {
    var fr = event.target;
    //console.log(event);

    var sf = fr.shift.value;

    if (fr.shift.value === "") {
      return false;
    }

    let formvl = {
      clientId: valueclientId,
      location: valuelocationsId,
      roster: currentroster.type,
      user_id: UserId,
      daystart: setdate,
      shift: fr.shift.value,
    };
    //console.log(formvl);
    //return false;

    axios
      .post("https://jlmining.app/admin/attendancesave", formvl, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res.data.status == "2") {
          setShowAlert(true);
          setShowAlert({ type: "error" });
        } else {
          setShowAlert(true);
          setShowAlert({ type: "success" });
        }

        setTimeout(() => {
          setIsModalOpen(false);
          getallcalender();
          //window.location.reload();
          setShowAlert(false);
          setDataclient([]);
          setlocatedname("");
          let formdata = {
            user_id: UserId,
          };
          axios
            .post("https://jlmining.app/admin/getclientforroster", formdata, {
              headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "POST,PUT",
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            })
            .then((res) => {
              //console.log("client idd");
              // console.log(res.data.results);
              setDataclient(res.data.results);
            })
            .catch((err) => {});
        }, 1500);
      })
      .catch((err) => {});
  };
  const handle_getClient = (event) => {
    let formdata = {
      clientId: event.target.value,
    };
    // console.log(formdata);
    setvalueclientId(event.target.value);
    axios
      .post("https://jlmining.app/admin/getclient_check", formdata, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        var alldata = res.data.results;
        // console.log("clid");
        //console.log(alldata);
        setlocationname(alldata);
        setlocatename([]);
      })
      .catch((err) => {});
  };
  const handle_getClientlocate = (event) => {
    let formdata = {
      clientId: event.target.value,
      user_id: UserId,
      datess: setdate_s,
    };
    setDatessvalue(null);
    // console.log("aaa");
    // console.log(formdata);
    setvaluelocationsId(event.target.value);
    axios
      .post("https://jlmining.app/admin/getlocaterostercheck", formdata, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        var alldata = res.data.filteredResults;
        setlocatename(alldata);
      })
      .catch((err) => {});
  };
  //Roster

  //Edit profile
  const handlelicense_File = (event) => {
    // console.log(event.target.files);

    const selectedFiles = event.target.files;

    if (selectedFiles.length > 0) {
      const maxSize = 5 * 1024 * 1024; // 4 MB

      const totalSize = Array.from(selectedFiles).reduce(
        (acc, file) => acc + file.size,
        0
      );

      if (totalSize > maxSize) {
        setsetselect_l_msg(
          "The total size of selected files exceeds the maximum allowed size (5MB). Please choose smaller files."
        );

        event.target.value = "";
        setSelectedlicenec_File(null);
      } else {
        setsetselect_l_msg("");
        setSelectedlicenec_File(selectedFiles);
      }
    }
  };
  const handleSelectChange_equipment_work = (selectedOptions) => {
    setselectvalue_equipment_work(selectedOptions);
  };
  const handleSelectChange_mentionl = (selectedOptions) => {
    setselectvalue_mentionl(selectedOptions);
  };
  const handleSelectChange = (selectedOptions) => {
    console.log(selectedOptions);
    setselectvalue(selectedOptions);
  };
  const handleSelectChange_trade = (selectedOptions) => {
    setselectvalue_trade(selectedOptions);
  };
  const handleSelectChange_vocational_training = (selectedOptions) => {
    setselectvalue_vocational_training(selectedOptions);
  };
  const handleSelectChange_machinery = (selectedOptions) => {
    setselectvalue_machinery(selectedOptions);
  };
  const handleInputChange_previous_work = (selectedOptions) => {
    setselectvalue_previous_work(selectedOptions);
  };
  const handleInputChange_reference = (selectedOptions) => {
    setselectvalue_reference(selectedOptions);
  };
  const handlemachinery_File = (event) => {
    // console.log(event.target.files);

    const selectedFiles = event.target.files;
    if (selectedFiles.length > 0) {
      const maxSize = 5 * 1024 * 1024; // 4 MB
      console.log(maxSize);

      const totalSize = Array.from(selectedFiles).reduce(
        (acc, file) => acc + file.size,
        0
      );
      if (totalSize > maxSize) {
        setsetselect_m_msg(
          "Some selected files exceed the maximum allowed size (4MB). Please choose smaller files."
        );

        event.target.value = "";
        setSelectedmachinery_file(null);
      } else {
        setsetselect_m_msg("");
        setSelectedmachinery_file(selectedFiles);
      }
    }
  };
  const [userdetail, setuserdetail] = useState("");
  const [employmenteducation, setEmploymenteducation] = useState([
    {
      year: "",
      course: "",
      institution: "",
    },
  ]);

  const handleInputChange_edication = (index, name, value) => {
    const updatedSections = [...employmenteducation];
    updatedSections[index][name] = value;
    setEmploymenteducation(updatedSections);
  };
  const trashdiveducation = (index) => {
    const updatedSections = [...employmenteducation];
    updatedSections.splice(index, 1);
    setEmploymenteducation(updatedSections);
  };
  const addMoreDivEducation = () => {
    setEmploymenteducation([
      ...employmenteducation,
      {
        year: "",
        course: "",
        institution: "",
      },
    ]);
  };
  const [employmentHistorySections, setEmploymentHistorySections] = useState([
    {
      start_date: "",
      end_date: "",
      company: "",
      role: "",
      company_done: "",
    },
  ]);

  const handleInputChange = (index, name, value) => {
    const updatedSections = [...employmentHistorySections];
    updatedSections[index][name] = value;
    setEmploymentHistorySections(updatedSections);
  };

  const addMoreDiv = () => {
    setEmploymentHistorySections([
      ...employmentHistorySections,
      {
        start_date: "",
        end_date: "",
        company: "",
        role: "",
        company_done: "",
      },
    ]);
  };
  const trashdiv = (index) => {
    const updatedSections = [...employmentHistorySections];
    updatedSections.splice(index, 1);
    setEmploymentHistorySections(updatedSections);
  };
  const edit_profile = (event) => {
    setUserId(event);
    let formdata = {
      user_id: event,
    };
    axios
      .post("https://jlmining.app/admin/getuserdetails", formdata, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        var alldata = res.data.row[0];
        if (alldata !== "") {
          setuserdetail(alldata);
          var ress = alldata;
          setallrecord(ress);
          setstep2_title(ress.step2_title);
          setisstep2_postal_state(ress.step2_postal_state);
          setisstep2_postal_country(ress.step2_postal_country);
          setgenderOptions(ress.step2_gender);
          setoptionorigin(ress.step2_origin);
          setoptionstatefirst(ress.step2_state);
          setoptioncountryfirst(ress.step2_country);
          setresidentalAddress(ress.step2_residential_address);
          if (ress.step2_residential_address === "No") {
            setstep2_Postaladdress(true);
          }
          if (ress.step2_residential_address === "Yes") {
            setstep2_Postaladdress(false);
          }

          setselectcountrybirth({
            value: ress.step2_country_birth,
            label: ress.step2_country_birth,
          });
          if (ress.step2_proof_upload) {
            const proofs = ress.step2_proof_upload
              .split(",")
              .map((item) => item.trim());
            const hasBirthCertificate = proofs.includes("Birth Certificate");
            const hasPassport = proofs.includes("Passport");
            const hasCitizenship = proofs.includes("Citizenship Certificate");
            if (hasBirthCertificate === true) {
              setauscitizenYesBirth(true);
            }
            if (hasPassport === true) {
              setauscitizenYesPassport(true);
            }
            if (hasCitizenship === true) {
              setauscitizenYesCert(true);
            }
            //console.log(hasBirthCertificate);
            setSelectedProofs(proofs);
          }
          setislegalwork(ress.step2_legal_work);
          if (ress.step2_legal_work === "No") {
            setislegalworkdesc(true);
          } else {
            setislegalworkdesc(false);
          }
          setContactstep2(ress.contact);
          setcriminaloffensenses(ress.step2_criminal_offenses);
          setservedtime(ress.step2_served_time);
          setdefenceforced(ress.step2_defence_forced);
          setstep2_shirt_size(ress.step2_shirt_size);
          setdayshift(ress.step2_which_dayshift);
          setnightshift(ress.step2_which_nightshift);
          if (ress.step2_employment_type) {
            const proofs = ress.step2_employment_type
              .split(",")
              .map((item) => item.trim());
            setselectedEmployetype(proofs);
          }
          setSelectedpermanentAddress(ress.step2_permanent_address);
          //console.log(ress.step2_permanent_address);
          if (ress.step2_permanent_address !== "") {
            // console.log("sss");
            if (ress.step2_permanent_address === "Yes") {
              setauscitizenYes(true);
              setauscitizenNo(false);
            }
            if (ress.step2_permanent_address === "No") {
              setauscitizenYes(false);
              setauscitizenNo(true);
            }
          }
          setSelectedEmployeePhoto(ress.image);
          setBirthcertificate(ress.step2_birthcertificate_file);
          setPassportcertificate(ress.step2_passportcertificate_file);
          setcertificatefile(ress.step2_auscitizencertificate_file);
          setSelectedPermanentpassport(ress.step2_passport);
          setFormValues({
            step2_title: ress.step2_title || "",
            first_name: ress.first_name || "",
            last_name: ress.last_name || "",
            contact: ress.contact || "",
            step2_gender: ress.step2_gender || "",
            email: ress.email || "",
            step2_confirm_email: ress.step2_confirm_email || "",
            password: ress.password || "",
            step2_confirm_password: ress.step2_confirm_password || "",
            address: ress.address || "",
            step2_address: ress.step2_address || "",
            step2_city: ress.step2_city || "",
            step2_Postal: ress.step2_Postal || "",
            step2_postal_address: ress.step2_postal_address || "",
            step2_postal_address2: ress.step2_postal_address2 || "",
            step2_postal_city: ress.step2_postal_city || "",
            step2_postal_code: ress.step2_postal_code || "",
            step2_dob: ress.step2_dob || "",
            step2_available_date: ress.step2_available_date || "",
          });
          setContactstep3(ress.step3_mobile_number);
          setContactstep3_alter(ress.step3_phone_number);
          setFormValues_Step3({
            step3_title: ress.step3_title || "",
            step3_first_name: ress.step3_first_name || "",
            step3_last_name: ress.step3_last_name || "",
            step3_relationship: ress.step3_relationship || "",

            step3_address: ress.step3_address || "",
            step3_address2: ress.step3_address2 || "",
            step3_city: ress.step3_city || "",

            step3_postal: ress.step3_postal || "",
            step3_country: ress.step3_country || "",
          });
          setFormValues_step5({
            years: ress.years || "",
          });
          if (ress.employmentHistorySections !== null) {
            setEmploymentHistorySections(
              JSON.parse(ress.employmentHistorySections)
            );
          }
          setissstep3_title(ress.step3_title);
          setisstep3_contact(ress.step3_contact);
          setisstep3_postal_state(ress.step3_state);
          setoptioncountrythird(ress.step3_country);

          if (ress.education !== null) {
            setEmploymenteducation(JSON.parse(ress.education));
          }
          if (ress.skills !== null) {
            const inputString = JSON.parse(ress.skills);
            const optionss = inputString.map((item) => ({
              value: item,
              label: item,
            }));

            setselectvalue_skill(optionss);
          }
          if (ress.licence !== null) {
            const mentionss = JSON.parse(ress.licence);
            const options_m = mentionss.map((item) => ({
              value: item,
              label: item,
            }));
            setMentionLic_get(options_m);
          }
          if (ress.certificate !== null) {
            const edd = JSON.parse(ress.certificate);
            const options_m = edd.map((item) => ({
              value: item,
              label: item,
            }));
            setSelectedMentionCert_m(options_m);
          }
          if (ress.trade !== null) {
            const sssvt = JSON.parse(ress.trade);
            const options_m = sssvt.map((item) => ({
              value: item,
              label: item,
            }));
            setselectvalue_trade(options_m);
          }
          if (ress.machinery !== null) {
            const sssvm = JSON.parse(ress.machinery);

            const options_m = sssvm.map((item) => ({
              value: item,
              label: item,
            }));
            setselectvalue_machinery(options_m);
          }
          if (ress.vocational_training !== null) {
            const sssv = JSON.parse(ress.vocational_training);
            const options_m = sssv.map((item) => ({
              value: item,
              label: item,
            }));
            setselectvalue_vocational_training(options_m);
          }
          if (ress.equipment_work !== null) {
            const eqp = JSON.parse(ress.equipment_work);
            const options_m = eqp.map((item) => ({
              value: item,
              label: item,
            }));
            setselectvalue_equipment_work(options_m);
          }
          if (ress.licence_file !== null) {
            const resultArrays = JSON.parse(ress.licence_file);
            setlicenseArray(resultArrays);
          }
          if (ress.certificate_file !== null) {
            const edd = JSON.parse(ress.certificate_file);
            setedfile(edd);
          }
          if (ress.trade_file !== null) {
            const sssvt = JSON.parse(ress.trade_file);
            settradetextArray(sssvt);
          }
          if (ress.machinery_file !== null) {
            const eddm = JSON.parse(ress.machinery_file);
            setmachinetextArrayy(eddm);
          }
          setuserdetail(ress);
        }
        seteditprofile(true);
      })
      .catch((err) => {});
  };
  const removemachine = (img, id) => {
    if (UserId !== "") {
      let formdata = {
        user_id: UserId,
        img: img,
        id: id,
      };
      axios
        .post("https://jlmining.app/removemachine", formdata, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "POST,PUT",
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          var alldata = res.data.row[0];
          if (alldata.machinery_file != null) {
            const resultArrays = JSON.parse(alldata.machinery_file);
            setmachinetextArrayy(resultArrays);
          }
        })
        .catch((err) => {});
    }
  };
  const removemention = (img, id) => {
    if (UserId !== "") {
      let formdata = {
        user_id: UserId,
        img: img,
        id: id,
      };
      axios
        .post("https://jlmining.app/removemention", formdata, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "POST,PUT",
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          var alldata = res.data.row[0];
          if (alldata.licence_file != null) {
            const resultArrays = JSON.parse(alldata.licence_file);
            setlicenseArray(resultArrays);
          }
        })
        .catch((err) => {});
    }
  };
  const handletrade_File = (event) => {
    // console.log(event.target.files);

    const selectedFiles = event.target.files;

    if (selectedFiles.length > 0) {
      const maxSize = 5 * 1024 * 1024; // 4 MB

      const totalSize = Array.from(selectedFiles).reduce(
        (acc, file) => acc + file.size,
        0
      );
      if (totalSize > maxSize) {
        setsetselect_t_msg(
          "Some selected files exceed the maximum allowed size (4MB). Please choose smaller files."
        );

        event.target.value = "";
        setSelectedtrade_file(null);
      } else {
        setsetselect_t_msg("");
        setSelectedtrade_file(selectedFiles);
      }
    }
  };
  const removecertificate = (img, id) => {
    if (UserId !== "") {
      let formdata = {
        user_id: UserId,
        img: img,
        id: id,
      };
      axios
        .post("https://jlmining.app/removecertificate", formdata, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "POST,PUT",
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          var alldata = res.data.row[0];
          if (alldata.certificate_file != null) {
            const resultArrays = JSON.parse(alldata.certificate_file);
            setedfile(resultArrays);
          }
        })
        .catch((err) => {});
    }
  };
  const removetrade = (img, id) => {
    if (UserId !== "") {
      let formdata = {
        user_id: UserId,
        img: img,
        id: id,
      };
      axios
        .post("https://jlmining.app/removetrade", formdata, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "POST,PUT",
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          var alldata = res.data.row[0];
          if (alldata.trade_file != null) {
            const resultArrays = JSON.parse(alldata.trade_file);
            settradetextArray(resultArrays);
          }
        })
        .catch((err) => {});
    }
  };
  const handleSelectChange_mentionc = (selectedOptions) => {
    setselectvalue_mentionc(selectedOptions);
  };
  const handlecertificate_File = (event) => {
    const selectedFiles = event.target.files;

    if (selectedFiles.length > 0) {
      const maxSize = 5 * 1024 * 1024; // 4 MB

      const totalSize = Array.from(selectedFiles).reduce(
        (acc, file) => acc + file.size,
        0
      );
      if (totalSize > maxSize) {
        setselect_certmsg(
          "Some selected files exceed the maximum allowed size (4MB). Please choose smaller files."
        );

        event.target.value = "";
        setSelectedcertificate_file(null);
      } else {
        setselect_certmsg("");
        setSelectedcertificate_file(selectedFiles);
      }
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    var vll = event.target;
    const formData = new FormData();
    const formDataa = new FormData();
    const skidata = [];
    if (selectvalue.length > 0) {
      for (let i = 0; i < selectvalue.length; i++) {
        skidata.push(selectvalue[i].value);
      }
    }

    if (selectedlicenec_File !== null) {
      if (selectedlicenec_File.length > 0) {
        for (let i = 0; i < selectedlicenec_File.length; i++) {
          formData.append("licence_file", selectedlicenec_File[i]);
        }
      }
    }
    if (selectedtrade_file !== null) {
      if (selectedtrade_file.length > 0) {
        for (let i = 0; i < selectedtrade_file.length; i++) {
          formData.append("trade_file", selectedtrade_file[i]);
        }
      }
    }
    if (selectedmachinery_file !== null) {
      if (selectedmachinery_file.length > 0) {
        for (let i = 0; i < selectedmachinery_file.length; i++) {
          formData.append("machinery_file", selectedmachinery_file[i]);
        }
      }
    }
    if (selectedcertificate_file !== null) {
      console.log("sdd");
      if (selectedcertificate_file.length > 0) {
        for (let i = 0; i < selectedcertificate_file.length; i++) {
          //console.log(selectedcertificate_file[i]);
          formData.append("certificate_file", selectedcertificate_file[i]);
        }
      }
    }

    const selectvaluementionl = [];
    if (selectvalue_mentionl !== null) {
      if (selectvalue_mentionl.length > 0) {
        for (let i = 0; i < selectvalue_mentionl.length; i++) {
          selectvaluementionl.push(selectvalue_mentionl[i].value);
        }
      }
    }

    const selectvaluementionc = [];
    if (selectvalue_mentionc !== null) {
      if (selectvalue_mentionc.length > 0) {
        for (let i = 0; i < selectvalue_mentionc.length; i++) {
          selectvaluementionc.push(selectvalue_mentionc[i].value);
        }
      }
    }

    const selectvaluetrade = [];
    if (selectvalue_trade !== null) {
      if (selectvalue_trade.length > 0) {
        for (let i = 0; i < selectvalue_trade.length; i++) {
          selectvaluetrade.push(selectvalue_trade[i].value);
        }
      }
    }

    const selectvaluemachinery = [];
    if (selectvalue_machinery !== null) {
      if (selectvalue_machinery.length > 0) {
        for (let i = 0; i < selectvalue_machinery.length; i++) {
          selectvaluemachinery.push(selectvalue_machinery[i].value);
        }
      }
    }

    const selectvalue_vocationaltraining = [];
    if (selectvalue_vocational_training !== null) {
      if (selectvalue_vocational_training.length > 0) {
        for (let i = 0; i < selectvalue_vocational_training.length; i++) {
          selectvalue_vocationaltraining.push(
            selectvalue_vocational_training[i].value
          );
        }
      }
    }

    const selectvalue_equipmentwork = [];
    if (selectvalue_equipment_work !== null) {
      if (selectvalue_equipment_work.length > 0) {
        for (let i = 0; i < selectvalue_equipment_work.length; i++) {
          selectvalue_equipmentwork.push(selectvalue_equipment_work[i].value);
        }
      }
    }

    const selectvalue_ref = [];
    if (selectvalue_reference !== null) {
      if (selectvalue_reference.length > 0) {
        for (let i = 0; i < selectvalue_reference.length; i++) {
          selectvalue_ref.push(selectvalue_reference[i].value);
        }
      }
    }

    const selectvalue_previouswork = [];
    if (selectvalue_previous_work !== null) {
      if (selectvalue_previous_work.length > 0) {
        for (let i = 0; i < selectvalue_previous_work.length; i++) {
          selectvalue_previouswork.push(selectvalue_previous_work[i].value);
        }
      }
    }

    formData.append("first_name", vll.first_name.value);
    formData.append("UserId", UserId);
    formData.append("middle_name", vll.middle_name.value);
    formData.append("last_name", vll.last_name.value);
    formData.append("contact", vll.contact.value);
    formData.append("address", vll.address.value);
    formData.append("skills", skidata);
    formData.append("years", vll.years.value);
    formData.append("licence", selectvaluementionl);
    formData.append("certificate", selectvaluementionc);
    formData.append("trade", selectvaluetrade);

    formData.append("machinery", selectvaluemachinery);
    formData.append("vocational_training", selectvalue_vocationaltraining);
    formData.append("equipment_work", selectvalue_equipmentwork);
    formData.append("previous_work", selectvalue_previouswork);

    formData.append(
      "employmentHistorySections",
      JSON.stringify(employmentHistorySections)
    );
    formData.append("references", selectvalue_ref);
    formData.append("education", JSON.stringify(employmenteducation));

    setButtonDisabled(true);
    setIsActivec(true);
    settextmessage("Don't refresh the page.Please wait...");

    axios
      .post("https://jlmining.app/userUpdateprofile", formData, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        console.log(res.data.status);

        if (res.data.status === "2") {
          //setShowAlert(true);
          //setShowAlert({ type: "error" });
        } else {
          console.log("checkprogress");
          console.log(selectedFile);
          //return false;
          formDataa.append("file", selectedFile);
          formDataa.append("UserId", UserId);
          formDataa.append("profiledate", event.target.profiledate.value);
          axios
            .post("https://jlmining.app/admin/userprofileupdate", formDataa, {
              headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "POST,PUT",
                Accept: "application/json",
                "Content-Type": "multipart/form-data",
              },
            })
            .then((res) => {})
            .catch((err) => {});
          setTimeout(() => {
            setButtonDisabled(false);
            settextmessage("");
            setIsActivec(false);
            edit_profile(UserId);
            window.location.reload();
          }, 3500);
        }
      })
      .catch((err) => {});
  };
  //Edit profile

  const sendnotification = () => {
    setopennotification(true);
  };
  const handlenotification = (event) => {
    event.preventDefault();
    let formdata = {
      notification: event.target.notification.value,
      user_id: valueemplyId,
    };
    axios
      .post("https://jlmining.app/sendnotification", formdata, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setsucc_n(true);
        event.target.reset();
        setTimeout(() => {
          setsucc_n(false);
          setopennotification(false);
        }, 3500);
      })
      .catch((err) => {});
  };
  const handlesubmitDoc = (event) => {
    setdocUp1(true);
    event.preventDefault();
    const formData = new FormData();
    formData.append("user_id", valueemplyId);
    formData.append("file", setSelectedFile_doc);
    axios
      .post("https://jlmining.app/adminUploadDoc", formData, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        console.log(res.data);
        if (res.data.status === 1) {
          setdoca1(true);
          event.target.reset();
          setTimeout(() => {
            setadmindoc1(false);
            setdoca1(false);
            let formdataa = {
              user_id: valueemplyId,
            };
            axios
              .post("https://jlmining.app/getonboardingDocAdmin", formdataa, {
                headers: {
                  "Access-Control-Allow-Origin": "*",
                  "Access-Control-Allow-Methods": "POST,PUT",
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
              })
              .then((res) => {
                setdocUp1(false);
                setUserdocs(res.data.results);
              })
              .catch((err) => {});
          }, 3500);
        }
      })
      .catch((err) => {});
  };
  const handlesubmitlic = (event) => {
    setdocUp2(true);
    event.preventDefault();
    const formData = new FormData();
    formData.append("expiryMonth", event.target.expiryMonth.value);
    formData.append("expiryYear", event.target.expiryYear.value);
    formData.append("user_id", valueemplyId);
    formData.append("file", setSelectedFile_lic);
    axios
      .post("https://jlmining.app/adminUploadlic", formData, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        console.log(res.data);
        if (res.data.status === 1) {
          setdoca2(true);
          event.target.reset();
          setTimeout(() => {
            setadminlic1(false);
            setdoca2(false);
            let formdataa = {
              user_id: valueemplyId,
            };
            axios
              .post("https://jlmining.app/getonboardinglic", formdataa, {
                headers: {
                  "Access-Control-Allow-Origin": "*",
                  "Access-Control-Allow-Methods": "POST,PUT",
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
              })
              .then((res) => {
                setdocUp2(false);
                setUserlic(res.data.results);
              })
              .catch((err) => {});
          }, 3500);
        }
      })
      .catch((err) => {});
  };
  const handleFileChange_doc = (event) => {
    if (event.target.files != null) {
      const files = event.target.files;
      setsetSelectedFile_doc(event.target.files[0]);
    }
  };
  const handleFileChange_lic = (event) => {
    if (event.target.files != null) {
      const files = event.target.files;
      setsetSelectedFile_lic(event.target.files[0]);
    }
  };
  const UpfileDialog = () => {
    setuploadfiledialog(!uploadfiledialog);
  };
  const openadminDoc = () => {
    setadmindoc1(true);
  };
  const openadminlic = () => {
    setadminlic1(true);
  };

  function formatDate_notes(dateString) {
    const options = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    };
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", options);
  }
  const handleCheckboxChange = (name, id, event, index, url) => {
    const isChecked = event.target.checked;
    let updatedCheckboxes = [...selectedItems];

    // Find if the checkbox with the same id already exists
    const checkboxIndex = updatedCheckboxes.findIndex(
      (checkbox) => checkbox.id === id
    );

    if (isChecked) {
      // If the checkbox is checked and not found, add it to the array
      if (checkboxIndex === -1) {
        updatedCheckboxes.push({ id, isChecked, url, name });
      } else {
        // If the checkbox is checked and found, update its state
        updatedCheckboxes[checkboxIndex] = { id, isChecked, url, name };
      }
    } else {
      // If the checkbox is unchecked, remove it from the array
      updatedCheckboxes = updatedCheckboxes.filter(
        (checkbox) => checkbox.id !== id
      );
    }
    console.log(updatedCheckboxes);
    setSelectedItems(updatedCheckboxes);
  };

  const handleCheckboxChangeLic = (name, id, event, index, url) => {
    const isChecked = event.target.checked;
    let updatedCheckboxes = [...selectedItemsLic];

    // Find if the checkbox with the same id already exists
    const checkboxIndex = updatedCheckboxes.findIndex(
      (checkbox) => checkbox.id === id
    );

    if (isChecked) {
      // If the checkbox is checked and not found, add it to the array
      if (checkboxIndex === -1) {
        updatedCheckboxes.push({ id, isChecked, url, name });
      } else {
        // If the checkbox is checked and found, update its state
        updatedCheckboxes[checkboxIndex] = { id, isChecked, url, name };
      }
    } else {
      // If the checkbox is unchecked, remove it from the array
      updatedCheckboxes = updatedCheckboxes.filter(
        (checkbox) => checkbox.id !== id
      );
    }
    console.log(updatedCheckboxes);

    setselectedItemsLic(updatedCheckboxes); // Make sure 'setselectedItemsLic' is named correctly
  };

  const docdelete = () => {
    console.log(selectedItems);
    let formData = {
      id: selectedItems,
      user_id: valueemplyId,
    };

    axios
      .post("https://jlmining.app/admindocdelete", formData, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setSelectedItems([]);

        setDocdelete(true);
        setTimeout(() => {
          setUserdocs(res.data.results);
          setDocdelete(false);
        }, 2500);
      })
      .catch((err) => {});
  };
  const licdelete = () => {
    let formData = {
      id: selectedItemsLic,
      user_id: valueemplyId,
    };
    axios
      .post("https://jlmining.app/adminlicdelete", formData, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setselectedItemsLic([]);
        setDocdelete1(true);
        setTimeout(() => {
          setUserlic(res.data.results);
          setDocdelete1(false);
        }, 2500);
        axios
          .get("https://jlmining.app/admin/getemployeeAdmin", {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods": "POST,PUT",
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          })
          .then((res) => {
            //  console.log(res.data.results);
            setData(res.data.results);
            if (res.data.results.length > 0) {
              // Initialize a variable to store the total uploads count
              let totalUploadsCount = 0;

              // Loop through the results array and sum up the total_uploads parameter
              res.data.results.forEach((employee) => {
                totalUploadsCount += employee.total_uploads;
              });

              // Use the totalUploadsCount as needed
              setexp(totalUploadsCount);
            } else {
              setexp(0);
            }
          })
          .catch((err) => {});
      })
      .catch((err) => {});
  };
  const licdownload = () => {
    console.log(selectedItemsLic);
    if (selectedItemsLic.length > 0) {
      selectedItemsLic.forEach((file) => {
        const fileURL = file.url;
        const fileName = file.name;

        // Create a temporary link element for each file
        const link = document.createElement("a");
        link.href = fileURL;
        link.target = "_blank"; // Open in a new tab
        link.rel = "noopener noreferrer"; // Security best practice
        link.download = fileName;

        // Append the link to the body and trigger the click event
        document.body.appendChild(link);
        link.click();

        // Cleanup: Remove the link after download
        document.body.removeChild(link);
      });
    } else {
      setDocdown2(true);
      setTimeout(() => {
        setDocdown2(false);
      }, 2500);
    }
  };
  const docdownload = () => {
    if (selectedItems.length > 0) {
      selectedItems.forEach((file) => {
        const fileURL = file.url;
        const fileName = file.name;

        // Create a temporary link element for each file
        const link = document.createElement("a");
        link.href = fileURL;
        link.target = "_blank"; // Open in a new tab
        link.rel = "noopener noreferrer"; // Security best practice
        link.download = fileName;

        // Append the link to the body and trigger the click event
        document.body.appendChild(link);
        link.click();

        // Cleanup: Remove the link after download
        document.body.removeChild(link);
      });
    } else {
      setDocdown1(true);
      setTimeout(() => {
        setDocdown1(false);
      }, 2500);
    }
  };

  const downloadimage = (name, id, url) => {
    const fileURL = url;
    const fileName = name;

    // Create a temporary link element for each file
    const link = document.createElement("a");
    link.href = fileURL;
    link.target = "_blank"; // Open in a new tab
    link.rel = "noopener noreferrer"; // Security best practice
    link.download = fileName;

    // Append the link to the body and trigger the click event
    document.body.appendChild(link);
    link.click();

    // Cleanup: Remove the link after download
    document.body.removeChild(link);
  };
  const downloadimageLic = (name, id, url) => {
    const fileURL = url;
    const fileName = name;

    // Create a temporary link element for each file
    const link = document.createElement("a");
    link.href = fileURL;
    link.target = "_blank"; // Open in a new tab
    link.rel = "noopener noreferrer"; // Security best practice
    link.download = fileName;

    // Append the link to the body and trigger the click event
    document.body.appendChild(link);
    link.click();

    // Cleanup: Remove the link after download
    document.body.removeChild(link);
  };
  //New Edit Profile
  const handlesubmitStep2 = (event) => {
    event.preventDefault();
    var vll = event.target;

    const formattedValue = formatNumber(contactstep2);

    if (isValidFormat(formattedValue)) {
      setContactstep2(formattedValue);
      setinvalidnumberstep2("");
    } else {
      setinvalidnumberstep2("Invalid formate");
      return false;
    }

    console.log("sd");
    settab1("tab3");
  };
  const formatNumber = (number) => {
    // Remove all non-digit characters
    const cleaned = number.replace(/\D/g, "");
    // Apply formatting: #### ### ###
    const match = cleaned.match(/^(\d{0,4})(\d{0,3})(\d{0,3})$/);
    if (match) {
      return [match[1], match[2], match[3]].filter(Boolean).join(" ");
    }
    return number;
  };
  const isValidFormat = (number) => {
    // Remove all non-digit characters and spaces
    const cleaned = number.replace(/\D/g, "");
    console.log(cleaned);
    // Check if the cleaned number matches the format #### ### ###
    return /^\d{4} ?\d{3} ?\d{3}$/.test(cleaned);
  };
  const handleEmployeephoto = (event) => {
    setSelectedEmployeePhoto(event.target.files[0]);
  };
  const handlePermanentpassport = (event) => {
    setSelectedPermanentpassport(event.target.files[0]);
  };
  const handleSelectCountryBirth = (selectedOptions) => {
    //console.log(selectedOptions);
    setselectcountrybirth(selectedOptions);
  };
  const handlebirthcertificate = (event) => {
    setBirthcertificate(event.target.files[0]);
  };
  const handlepassportcertificate = (event) => {
    setPassportcertificate(event.target.files[0]);
  };
  const handlecertificatefile = (event) => {
    setcertificatefile(event.target.files[0]);
  };
  const criminaloffensenses = (event) => {
    setcriminaloffensenses(event);
  };
  const servedtime = (event) => {
    setservedtime(event);
  };
  const defenceforced = (event) => {
    setdefenceforced(event);
  };
  const dayshift = (event) => {
    setdayshift(event);
  };
  const nightshift = (event) => {
    setnightshift(event);
  };
  const handlegenderOptions = (event) => {
    //console.log(event.target.value);
    setgenderOptions(event.target.value);
  };
  const handleoptionorigin = (event) => {
    setoptionorigin(event.target.value);
  };
  const handleoptionstatefirst = (event) => {
    setoptionstatefirst(event.target.value);
  };
  const handleoptioncountryfirst = (event) => {
    setoptioncountryfirst(event.target.value);
  };
  const handlestep2_postal_state = (event) => {
    setisstep2_postal_state(event.target.value);
  };
  const handlestep2_postal_country = (event) => {
    setisstep2_postal_country(event.target.value);
  };
  const handlestep2_shirt_size = (event) => {
    setstep2_shirt_size(event.target.value);
  };
  const handlestep2_title = (event) => {
    setstep2_title(event.target.value);
  };
  const residentalAddress = (event) => {
    setresidentalAddress(event);
    if (event === "Yes") {
      setstep2_Postaladdress(false);
    }
    if (event === "No") {
      setstep2_Postaladdress(true);
    }
  };
  const citizenaus = (event) => {
    setSelectedpermanentAddress(event);
    if (event === "Yes") {
      setauscitizenYes(true);
      setauscitizenNo(false);
    }
    if (event === "No") {
      setauscitizenYes(false);
      setauscitizenNo(true);
    }
  };
  const birthcheck = (event) => {
    var value = "Birth Certificate";
    if (selectedProofs.includes(value)) {
      // Remove value if already selected
      setSelectedProofs(selectedProofs.filter((item) => item !== value));
    } else {
      // Add value if not selected
      setSelectedProofs([...selectedProofs, value]);
    }
    setisbirthcheck(event.target.checked);
    if (event.target.checked === true) {
      setauscitizenYesBirth(true);
    } else {
      setauscitizenYesBirth(false);
    }
  };
  const passportcheck = (event) => {
    var value = "Passport";
    if (selectedProofs.includes(value)) {
      // Remove value if already selected
      setSelectedProofs(selectedProofs.filter((item) => item !== value));
    } else {
      // Add value if not selected
      setSelectedProofs([...selectedProofs, value]);
    }
    setispassportcheck(event.target.checked);
    if (event.target.checked === true) {
      setauscitizenYesPassport(true);
    } else {
      setauscitizenYesPassport(false);
    }
  };
  const certificatecheck = (event) => {
    var value = "Citizenship Certificate";
    if (selectedProofs.includes(value)) {
      // Remove value if already selected
      setSelectedProofs(selectedProofs.filter((item) => item !== value));
    } else {
      // Add value if not selected
      setSelectedProofs([...selectedProofs, value]);
    }
    setiscertificatecheck(event.target.checked);
    if (event.target.checked === true) {
      setauscitizenYesCert(true);
    } else {
      setauscitizenYesCert(false);
    }
  };
  const employetype = (event) => {
    var value = event;
    if (selectedEmployetype.includes(value)) {
      // Remove value if already selected
      setselectedEmployetype(
        selectedEmployetype.filter((item) => item !== value)
      );
    } else {
      // Add value if not selected
      setselectedEmployetype([...selectedEmployetype, value]);
    }
  };
  const legalwork = (event) => {
    setislegalwork(event.target.value);
    //console.log(event.target.checked);
    if (event.target.value === "Yes") {
      setislegalworkdesc(false);
    } else {
      setislegalworkdesc(true);
    }
  };
  const handleInputChangeStep2 = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };
  const handleInputmobiletsep2 = (event) => {
    let { value } = event.target;

    // Allow only numbers and spaces
    const regex = /^[0-9\b ]+$/;
    if (regex.test(value)) {
      // Remove non-digit characters and limit the length to 12 digits
      const cleanedValue = value.replace(/\D/g, "").slice(0, 10);
      const formattedValue = formatNumber(cleanedValue);
      setContactstep2(formattedValue);
      console.log(formattedValue);
      if (isValidFormat(cleanedValue)) {
        // Valid format logic (e.g., enable submit button)
        console.log("Valid format");
        setinvalidnumberstep2("");
      } else {
        // Invalid format logic (e.g., disable submit button)
        setinvalidnumberstep2("Invalid format");
        console.log("Invalid format");
      }
    }
  };

  const handleKeyDownstep2 = (event) => {
    if (event.key === "Backspace") {
      // Allow backspace only if not already at the beginning
      if (contactstep2.length > 0) {
        setContactstep2((prevState) => prevState.slice(0, -1));
      }
    }
  };
  function formatDateForState(dateString) {
    if (!dateString) return ""; // Handle empty values

    // Create a new Date object from the string
    const date = new Date(dateString);

    // Check if the date is valid
    if (isNaN(date.getTime())) {
      console.warn("Invalid date format provided:", dateString);
      return ""; // Handle invalid dates gracefully (optional)
    }
    // Format the date in YYYY-MM-DD using template literals
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Add leading zero for single-digit months
    const day = String(date.getDate()).padStart(2, "0"); // Add leading zero for single-digit days
    var dd = `${month}/${day}/${year}`;
    //console.log(dd);
    return `${year}-${month}-${day}`;
  }
  const isImageValid = (image) => {
    return image !== null && image !== undefined && image !== "";
  };
  const previousstep = (id) => {
    ///console.log(id);
    settab1(id);
  };
  const handlestep3_title = (event) => {
    setissstep3_title(event.target.value);
  };
  const handleInputChangeStep3 = (e) => {
    const { name, value } = e.target;
    setFormValues_Step3({
      ...formValues_Step3,
      [name]: value,
    });
  };
  const handlestep3_postal_state = (event) => {
    setisstep3_postal_state(event.target.value);
  };
  const handlestep3_contact = (event) => {
    setisstep3_contact(event.target.value);
  };
  const handleoptioncountryfirstthird = (event) => {
    setoptioncountrythird(event.target.value);
  };
  const handlesubmitStep3 = (event) => {
    const formattedValue = formatNumber(contactstep3);

    if (isValidFormat(formattedValue)) {
      setContactstep3(formattedValue);
      setinvalidnumberstep3("");
    } else {
      setinvalidnumberstep3("Invalid formate");
      return false;
    }
    const formattedValue_alter = formatNumber(contactstep3_alter);

    if (isValidFormat(formattedValue_alter)) {
      setContactstep3(formattedValue_alter);
      setinvalidnumberstep3_alter("");
    } else {
      setinvalidnumberstep3_alter("Invalid formate");
      return false;
    }
    settab1("tab4");
  };
  const handleInputMobilephone_step3 = (event) => {
    let { value } = event.target;

    // Allow only numbers and spaces
    const regex = /^[0-9\b ]+$/;
    if (regex.test(value)) {
      const cleanedValue = value.replace(/\D/g, "").slice(0, 10);
      const formattedValue = formatNumber(cleanedValue);
      setContactstep3(formattedValue);
      if (isValidFormat(cleanedValue)) {
        setinvalidnumberstep3("");
      } else {
        setinvalidnumberstep3("Invalid format");
      }
    }
  };
  const handleKeyDownstep3 = (event) => {
    if (event.key === "Backspace") {
      // Allow backspace only if not already at the beginning
      if (contactstep3.length > 0) {
        setContactstep3((prevState) => prevState.slice(0, -1));
      }
    }
  };
  const handleInputMobilephone_step3__alter = (event) => {
    let { value } = event.target;

    // Allow only numbers and spaces
    const regex = /^[0-9\b ]+$/;
    if (regex.test(value)) {
      const cleanedValue = value.replace(/\D/g, "").slice(0, 10);
      const formattedValue = formatNumber(cleanedValue);
      setContactstep3_alter(formattedValue);
      if (isValidFormat(cleanedValue)) {
        setinvalidnumberstep3_alter("");
      } else {
        setinvalidnumberstep3_alter("Invalid format");
      }
    }
  };
  const handleKeyDownstep3_alter = (event) => {
    if (event.key === "Backspace") {
      // Allow backspace only if not already at the beginning
      if (contactstep3_alter.length > 0) {
        setContactstep3_alter((prevState) => prevState.slice(0, -1));
      }
    }
  };
  const handleSubmitstep4 = (event) => {
    settab1("tab5");
  };
  const handleFormFinalsubmit = () => {
    const formData = new FormData();
    setButtonDisabled(true);
    settextmessage(true);

    formData.append(
      "employmentHistorySections",
      JSON.stringify(employmentHistorySections)
    );

    var selectvaluementionl = "";

    if (isselectcountrybirth !== null) {
      var selectvaluementionl = isselectcountrybirth.value;
    }

    formData.append(
      "step2_confirm_password",
      formValues.step2_confirm_password
    );
    const skidata = [];
    if (selectvalue_skill.length > 0) {
      for (let i = 0; i < selectvalue_skill.length; i++) {
        skidata.push(selectvalue_skill[i].value);
      }
    }
    const selectvaluemachinery = [];
    if (selectvalue_machinery !== null) {
      if (selectvalue_machinery.length > 0) {
        for (let i = 0; i < selectvalue_machinery.length; i++) {
          selectvaluemachinery.push(selectvalue_machinery[i].value);
        }
      }
    }
    const selectvaluementionll = [];
    if (MentionLic_get !== null) {
      if (MentionLic_get.length > 0) {
        for (let i = 0; i < MentionLic_get.length; i++) {
          selectvaluementionll.push(MentionLic_get[i].value);
        }
      }
    }
    if (selectedlicenec_File !== null) {
      if (selectedlicenec_File.length > 0) {
        for (let i = 0; i < selectedlicenec_File.length; i++) {
          formData.append("licence_file", selectedlicenec_File[i]);
        }
      }
    }
    if (selectedcertificate_file !== null) {
      if (selectedcertificate_file.length > 0) {
        for (let i = 0; i < selectedcertificate_file.length; i++) {
          console.log(selectedcertificate_file[i]);
          formData.append("certificate_file", selectedcertificate_file[i]);
        }
      }
    }
    const selectvaluetrade = [];
    if (selectvalue_trade !== null) {
      if (selectvalue_trade.length > 0) {
        for (let i = 0; i < selectvalue_trade.length; i++) {
          selectvaluetrade.push(selectvalue_trade[i].value);
        }
      }
    }
    if (selectedtrade_file !== null) {
      if (selectedtrade_file.length > 0) {
        for (let i = 0; i < selectedtrade_file.length; i++) {
          formData.append("trade_file", selectedtrade_file[i]);
        }
      }
    }
    if (selectedmachinery_file !== null) {
      if (selectedmachinery_file.length > 0) {
        for (let i = 0; i < selectedmachinery_file.length; i++) {
          formData.append("machinery_file", selectedmachinery_file[i]);
        }
      }
    }
    const selectvaluementionc = [];
    if (MentionLCert_m !== null) {
      if (MentionLCert_m.length > 0) {
        for (let i = 0; i < MentionLCert_m.length; i++) {
          selectvaluementionc.push(MentionLCert_m[i].value);
        }
      }
    }
    const selectvalue_vocationaltraining = [];
    if (selectvalue_vocational_training !== null) {
      if (selectvalue_vocational_training.length > 0) {
        for (let i = 0; i < selectvalue_vocational_training.length; i++) {
          selectvalue_vocationaltraining.push(
            selectvalue_vocational_training[i].value
          );
        }
      }
    }
    const selectvalue_equipmentwork = [];
    if (selectvalue_equipment_work !== null) {
      if (selectvalue_equipment_work.length > 0) {
        for (let i = 0; i < selectvalue_equipment_work.length; i++) {
          selectvalue_equipmentwork.push(selectvalue_equipment_work[i].value);
        }
      }
    }
    formData.append("equipment_work", selectvalue_equipmentwork);
    formData.append("vocational_training", selectvalue_vocationaltraining);
    formData.append("certificate", selectvaluementionc);
    formData.append("education", JSON.stringify(employmenteducation));
    formData.append("trade", selectvaluetrade);
    formData.append("licence", selectvaluementionll);
    formData.append("machinery", selectvaluemachinery);
    formData.append("skills", skidata);
    formData.append("years", formValues_step5.years);
    formData.append("step2_confirm_email", formValues.step2_confirm_email);
    formData.append("step2_title", issstep2_title);
    formData.append("first_name", formValues.first_name);
    formData.append("last_name", formValues.last_name);
    formData.append("contact", contactstep2);
    formData.append("step2_gender", genderOptions);
    formData.append("step2_origin", optionorigin);
    formData.append("email", formValues.email);
    formData.append("password", formValues.password);
    formData.append("address", formValues.address);
    formData.append("step2_address", formValues.step2_address);
    formData.append("step2_city", formValues.step2_city);
    formData.append("step2_state", optionstatefirst);
    formData.append("step2_Postal", formValues.step2_Postal);
    formData.append("step2_country", optioncountryfirst);
    formData.append("step2_postal_address", formValues.step2_postal_address);
    formData.append("step2_postal_address2", formValues.step2_postal_address2);
    formData.append("step2_postal_city", formValues.step2_postal_city);
    formData.append("step2_postal_state", isstep2_postal_state);
    formData.append("step2_postal_code", formValues.step2_postal_code);
    formData.append("step2_postal_country", isstep2_postal_country);
    formData.append("step2_dob", formValues.step2_dob);
    formData.append("step2_country_birth", formValues.step2_country_birth);
    formData.append("step2_available_date", formValues.step2_available_date);
    formData.append("step2_shirt_size", isstep2_shirt_size);
    formData.append("step2_passport", isSelectedPermanentpassport);
    formData.append("step2_residential_address", isresidentalAddress);
    formData.append("image", isSelectedEmployeePhoto);
    formData.append("step2_country_birth", selectvaluementionl);
    formData.append("step2_permanent_address", isSelectedpermanentAddress);
    formData.append("step2_proof_upload", selectedProofs);
    formData.append("step2_birthcertificate_file", isBirthcertificate);
    formData.append("step2_passportcertificate_file", isPassportcertificate);
    formData.append("step2_auscitizencertificate_file", iscertificatefile);
    formData.append("step2_legal_work", islegalwork);
    formData.append("step2_criminal_offenses", iscriminaloffensenses);
    formData.append("step2_served_time", isservedtime);
    formData.append("step2_defence_forced", isdefenceforced);
    formData.append("step2_which_nightshift", isnightshift);
    formData.append("step2_which_dayshift", isdayshift);
    formData.append("step2_employment_type", selectedEmployetype);
    formData.append("proceed", readyValue);

    formData.append("step3_title", issstep3_title);
    formData.append("step3_first_name", formValues_Step3.step3_first_name);
    formData.append("step3_last_name", formValues_Step3.step3_last_name);
    formData.append("step3_relationship", formValues_Step3.step3_relationship);
    formData.append("step3_contact", isstep3_contact);
    formData.append("step3_mobile_number", contactstep3);
    formData.append("step3_phone_number", contactstep3_alter);
    formData.append("step3_address", formValues_Step3.step3_address);
    formData.append("step3_address2", formValues_Step3.step3_address2);
    formData.append("step3_city", formValues_Step3.step3_city);
    formData.append("step3_state", isstep3_postal_state);
    formData.append("step3_postal", formValues_Step3.step3_postal);
    formData.append("step3_country", optioncountrythird);
    formData.append("id", UserId);
    setTimeout(function () {
      axios
        .post("https://jlmining.app/userFormUpdated", formData, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "POST,PUT",
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          if (res.data.code === 2) {
            settextmessages(false);

            setButtonDisabled(false);
            settab1("tab2");
            seterroremail(true);
            setemail("Email is already exists");
            setTimeout(() => {
              emailerr.current.scrollIntoView({ behavior: "smooth" });
            }, 100);
          } else {
            setemail("");
            settextmessages(true);

            setButtonDisabled(false);
            settextmessage(false);
            setTimeout(function () {
              settextmessages(false);
              window.location.reload();
            }, 500);
          }
        })
        .catch((err) => {});
    }, 500);
  };
  const handleInputChangeStep5 = (e) => {
    const { name, value } = e.target;
    setFormValues_step5({
      ...formValues_step5,
      [name]: value,
    });
  };
  const TradeM = trademach;
  const machinerygett = machineryget;
  const Vocationtraa = Vocationtra;
  const Equipmentworkk = Equipmentwork;
  //New Edit Profile
  return (
    <>
      <style>
        {`
        ._Xt-75 {
          margin-top:30px;
          z-index:99;
         
        }
        @media only screen and (max-width: 768px){
          ._Xt-75{
            width:89% !important;
          }
        }
      `}
      </style>
      <div>
        <AdminHeader content={vlexp} />
        <div className="main-right-body">
          <div className="block-full">
            <div className="row">
              <div className="float-end d-flex justify-content-end">
                <Link to="/admin/addemployee" className="addclient">
                  Add Employee
                </Link>
              </div>
            </div>
            <div className="row gy-4">
              <div className="col-md-4">
                {showAlert?.type === "error" && (
                  <Alert
                    type="error"
                    description="User deleted successfully!"
                    closable
                  />
                )}
                <div className="d-block red-heading bg-white shadow p-2">
                  <h3 className="mb-3">Select Employee</h3>
                  <div className="over-empl">
                    <div className="d-flex flex-column h-100 clientselectmain">
                      {Array.isArray(emData) ? (
                        emData.map((item, index) => (
                          <div key={item.id}>
                            <Link
                              to="#"
                              className={`item ${
                                activeIndex === index ? "active plic" : "plic"
                              }`}
                              onClick={() => handlegetClient(item.id, index)}
                            >
                              {item.step2_title} {item.first_name}{" "}
                              {item.last_name}{" "}
                              {item.total_uploads > 0 && (
                                <span className="textlic">
                                  {item.total_uploads}
                                </span>
                              )}
                            </Link>
                          </div>
                        ))
                      ) : (
                        <Link to="#">No data available</Link>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {isClicked && (
                <div className="col-md-8">
                  {showAlert?.type === "success" && (
                    <Alert
                      type="success"
                      description="Successfully approved!"
                      closable
                    />
                  )}

                  <div className="right-form-box bg-white shadow p-2">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="d-flex justify-content-between p-2">
                          <label for="">Employee Name:-</label>
                          <span className="fs-14">
                            {gdata.step2_title} {gdata.first_name}{" "}
                            {gdata.last_name}
                          </span>
                        </div>
                        <div className="d-flex justify-content-between p-2">
                          <label for="">Phone Number:-</label>
                          <span className="fs-14">{gdata.contact}</span>
                        </div>
                        <div className="d-flex justify-content-between p-2">
                          <label for="">Email-Id:-</label>
                          <span className="fs-14">{gdata.email}</span>
                        </div>
                        <div className="d-flex justify-content-between p-2">
                          <label for="">Address:-</label>
                          <span className="fs-14">{gdata.address}</span>
                        </div>
                        <div className="d-flex justify-content-between p-2">
                          <label for="">Skills:-</label>
                          <span className="fs-14">{vsetval.join(", ")}</span>
                        </div>
                        <div className="d-flex justify-content-between p-2">
                          <label for="">Years Of Experience:-</label>
                          <span className="fs-14">{gdata.years}</span>
                        </div>
                        <div className="d-flex justify-content-between p-2 gap-1">
                          {notatus && (
                            <Link
                              title="Approve"
                              to="javascript:void(0)"
                              onClick={() =>
                                handlecheckApprove(gdata.id, gdata.email)
                              }
                              className="addclient"
                            >
                              Approve
                            </Link>
                          )}
                          {istatus && (
                            <Link
                              title="Approved"
                              to="javascript:void(0)"
                              className="addclientapp"
                            >
                              Approved
                            </Link>
                          )}
                          <Link
                            title="Delete"
                            to="javascript:void(0)"
                            className="addclient"
                            onClick={() => handleuserRemove(gdata.id)}
                          >
                            Delete
                          </Link>
                        </div>
                      </div>
                      <div className="col-lg-6 ">
                        <div className="usernamediv">
                          <img src={userimage} className="username" />
                        </div>
                        <div className="d-flex justify-content-between p-1 gap-1 mt-5">
                          <Link
                            title="View Resume"
                            target="_blank"
                            to={`/admin/previousresume/${gdata.id}`}
                            className="addclientapp"
                          >
                            <FaEye size={15} /> Preview
                          </Link>
                          <button
                            title="Download"
                            type="button"
                            onClick={() => pdfgenerate(gdata.id)}
                            className="addclientappfile "
                          >
                            <FaFilePdf />
                          </button>
                          {/* <button
                            title="Roster"
                            type="button"
                            onClick={() => rosterupdate(gdata.id)}
                            className="btn btn-primary"
                          >
                            Roster
                          </button> */}
                          <button
                            title="Roster"
                            style={{ height: "39px" }}
                            type="button"
                            onClick={() => edit_profile(gdata.id)}
                            className="btn btn-primary"
                          >
                            Edit Profile
                          </button>
                          {springloader && (
                            <div className="spinner-border"></div>
                          )}
                          {/* <Link
                            title="PDF/Print"
                            target="_blank"
                            to={`/admin/employeeresumepdf/${gdata.id}`}
                            className="addclientappfile"
                          >
                            <FaFilePdf />
                          </Link> */}
                        </div>
                        <div className="d-flex ">
                          <button
                            title="Roster"
                            style={{ height: "39px" }}
                            type="button"
                            onClick={() => sendnotification()}
                            className="btn btn-success"
                          >
                            Send Notification
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="right-form-box bgg bg-white shadow p-2 mt-4">
                    <div className="d-flex justify-content-between">
                      <h5 className="align-items-center">
                        Total Documents ({Userdocs.length})
                      </h5>
                      <div
                        className="position-relative"
                        to="javascript:void(0)"
                      >
                        <div
                          className="d-flex align-items-center gap-2 cursor-pointer"
                          onClick={UpfileDialog}
                        >
                          <p>Upload File</p>
                          <img
                            src={require("./../assets/images/1091885.png")}
                            width="35"
                            alt="Image"
                          />{" "}
                        </div>
                        {uploadfiledialog && (
                          <ul>
                            <li>
                              <Link
                                to="javascript:void(0)"
                                onClick={openadminDoc}
                              >
                                Upload File
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="javascript:void(0)"
                                onClick={openadminlic}
                              >
                                Upload licence
                              </Link>
                            </li>
                          </ul>
                        )}
                      </div>
                    </div>

                    <div className="d-flex gap-4 mt-2">
                      <Link to="javascript:void(0)" onClick={docdownload}>
                        Download
                      </Link>
                      <Link to="javascript:void(0)" onClick={docdelete}>
                        Delete
                      </Link>
                    </div>
                    {Docdelete && (
                      <p className="text-danger">
                        Document has been deleted successfully
                      </p>
                    )}
                    {Docdown1 && (
                      <p className="text-danger">
                        Please choose at least one file
                      </p>
                    )}
                    <div
                      className="row mt-4 p-2 scroll-text-box "
                      style={{ height: "150px" }}
                    >
                      {Array.isArray(Userdocs) && Userdocs.length > 0 ? (
                        Userdocs.map((item) => (
                          <div
                            className="d-flex align-items-start"
                            key={item.id}
                          >
                            <div className="w-100 form-check gap-2 d-flex align-items-center border-bottom p-2">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                checked={
                                  (selectedItems.find(
                                    (checkbox) => checkbox.id === item.id
                                  ) &&
                                    selectedItems.find(
                                      (checkbox) => checkbox.id === item.id
                                    ).isChecked) ||
                                  false
                                }
                                onChange={(event) =>
                                  handleCheckboxChange(
                                    item.name,
                                    item.id,
                                    event,
                                    selectedItems.findIndex(
                                      (checkbox) => checkbox.id === item.id
                                    ),
                                    `${appUrl}/userdoc/${item.name}`
                                  )
                                }
                              />
                              <Link
                                to="javascript:void(0)"
                                onClick={() =>
                                  downloadimage(
                                    item.name,
                                    item.id,
                                    `${appUrl}/userdoc/${item.name}`
                                  )
                                }
                              >
                                <img
                                  src={require("./../assets/images/4347587.png")}
                                  width="30"
                                  alt="Image"
                                />{" "}
                                {item.name}
                              </Link>
                            </div>
                          </div>
                        ))
                      ) : (
                        <div>
                          <p>No data available</p>
                        </div>
                      )}
                    </div>

                    <h5 className="align-items-center">
                      Licence Document Total ({Userlic.length})
                    </h5>
                    <div className="d-flex gap-4 mt-2">
                      <Link to="javascript:void(0)" onClick={licdownload}>
                        Download
                      </Link>
                      <Link to="javascript:void(0)" onClick={licdelete}>
                        Delete
                      </Link>
                    </div>
                    {Docdelete1 && (
                      <p className="text-danger">
                        Licence has been deleted successfully
                      </p>
                    )}
                    {Docdown2 && (
                      <p className="text-danger">
                        Please choose at least one file
                      </p>
                    )}
                    <div
                      className="row mt-4 p-2 scroll-text-box "
                      style={{ height: "150px" }}
                    >
                      {Array.isArray(Userlic) && Userlic.length > 0 ? (
                        Userlic.map((item, index) => {
                          const expiryDate = new Date(item.expirydate);
                          const currentDate = new Date();
                          const isExpired = expiryDate < currentDate;

                          return (
                            <div
                              className="d-flex align-items-start"
                              key={index}
                            >
                              <div
                                className={`w-100 form-check gap-2 d-flex  align-items-center border-bottom  p-2 ${
                                  isExpired ? "text-danger" : ""
                                }`}
                              >
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={
                                    (selectedItemsLic.find(
                                      (checkbox) => checkbox.id === item.id
                                    ) &&
                                      selectedItemsLic.find(
                                        (checkbox) => checkbox.id === item.id
                                      ).isChecked) ||
                                    false
                                  }
                                  onChange={(event) =>
                                    handleCheckboxChangeLic(
                                      item.file_name,
                                      item.id,
                                      event,
                                      selectedItemsLic.findIndex(
                                        (checkbox) => checkbox.id === item.id
                                      ),
                                      `${appUrl}/userlicence/${item.file_name}`
                                    )
                                  }
                                />
                                <Link
                                  to="javascript:void(0)"
                                  onClick={() =>
                                    downloadimageLic(
                                      item.file_name,
                                      item.id,
                                      `${appUrl}/userlicence/${item.file_name}`
                                    )
                                  }
                                >
                                  <img
                                    src={require("./../assets/images/4347587.png")}
                                    width="30"
                                    alt="Image"
                                  />{" "}
                                  {item.name} Ex.{" "}
                                  <span
                                    className={isExpired ? "text-danger" : ""}
                                  >
                                    {formatDate_notes(item.expirydate)}
                                  </span>
                                </Link>
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <div>
                          <p>No data available</p>
                        </div>
                      )}
                    </div>
                  </div>

                  {admindoc1 && (
                    <ReactDialogBox
                      closeBox={closeBox}
                      modalWidth="40%"
                      headerBackgroundColor="red"
                      headerTextColor="white"
                      headerHeight="50"
                      closeButtonColor="white"
                      bodyBackgroundColor="white"
                      bodyTextColor="black"
                      bodyHeight="380px"
                      headerText="Upload"
                    >
                      <div style={{ height: "280px" }}>
                        <div className="d-flex flex-column gap-3 bg-white   p-4 inperson">
                          <p className="mt-4"></p>
                          <form method="post" onSubmit={handlesubmitDoc}>
                            <div className="d-flex flex-column text-center gap-3 rel p-4 drop-box-pop mx-auto ">
                              {doca1 && (
                                <p className="text-success">
                                  Dcoument has been uploaded successfully
                                </p>
                              )}
                              <i className="fal fa-arrow-alt-up"></i>
                              <h1>Drag and drop your files here to upload</h1>
                              <h6>Browse files...</h6>
                              <input
                                type="file"
                                required
                                onChange={handleFileChange_doc}
                                className="file-name-block"
                              />
                            </div>
                            <div className="d-flex justify-content-center ">
                              <div className="position-relative  Document-nm1">
                                <button
                                  type="submit"
                                  className={`${
                                    docUp1 === true ? "docup" : ""
                                  }`}
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </ReactDialogBox>
                  )}
                  {adminlic1 && (
                    <ReactDialogBox
                      closeBox={closeBox}
                      modalWidth="40%"
                      headerBackgroundColor="red"
                      headerTextColor="white"
                      headerHeight="50"
                      closeButtonColor="white"
                      bodyBackgroundColor="white"
                      bodyTextColor="black"
                      bodyHeight="450px"
                      headerText="Upload"
                    >
                      <div style={{ height: "400px" }}>
                        <div className="d-flex flex-column gap-3 bg-white   p-4 inperson">
                          <p className="mt-4"></p>
                          <form method="post" onSubmit={handlesubmitlic}>
                            <div className="d-flex flex-column text-center gap-3 rel p-4 drop-box-pop mx-auto ">
                              {doca2 && (
                                <p className="text-success">
                                  Licence has been uploaded successfully
                                </p>
                              )}
                              <i className="fal fa-arrow-alt-up"></i>
                              <h1>Drag and drop your files here to upload</h1>
                              <h6>Browse files...</h6>
                              <input
                                type="file"
                                required
                                onChange={handleFileChange_lic}
                                className="file-name-block"
                              />
                            </div>

                            <label>Expiry Date</label>
                            <div className="d-flex align-items-center gap-3 date">
                              <input
                                type="text"
                                placeholder="MM"
                                name="expiryMonth"
                                required
                                pattern="[0-9]*"
                                min="1"
                                className="form-control"
                                maxLength="2"
                              />
                              <input
                                type="text"
                                placeholder="YYYY"
                                pattern="[0-9]*"
                                required
                                className="form-control"
                                min="1"
                                maxLength="4"
                                name="expiryYear"
                              />
                            </div>

                            <div className="d-flex justify-content-center mt-4">
                              <div className="position-relative  Document-nm1">
                                <button
                                  type="submit"
                                  className={`${
                                    docUp2 === true ? "docup" : ""
                                  }`}
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </ReactDialogBox>
                  )}
                </div>
              )}
            </div>
            {opennotification && (
              <ReactDialogBox
                closeBox={closeBox}
                modalWidth="40%"
                headerBackgroundColor="red"
                headerTextColor="white"
                headerHeight="50"
                closeButtonColor="white"
                bodyBackgroundColor="white"
                bodyTextColor="black"
                bodyHeight="280px"
                headerText="Send Notification"
              >
                <div style={{ height: "280px", overflowY: "auto" }}>
                  <div className="d-flex flex-column gap-3 bg-white   p-4 inperson">
                    <p className="mt-4"></p>
                    <form method="post" onSubmit={handlenotification}>
                      <div className="d-flex flex-column gap-3 py-3">
                        {succ_n && (
                          <p className="text-success ">
                            Notification has been send successfully
                          </p>
                        )}
                        <div className="d-flex flex-row align-items-center gap-3 justify-content-center">
                          <div className="d-flex">
                            <label className="">
                              Enter here <span className="text-danger">*</span>
                            </label>
                            <textarea
                              className=" form-control"
                              type="text"
                              placeholder="Enter here..."
                              required
                              name="notification"
                            />
                          </div>
                        </div>

                        <div className="d-flex justify-content-center ">
                          <div className="position-relative  Document-nm1">
                            <button type="submit">Submit</button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </ReactDialogBox>
            )}
            {editprofile && (
              <>
                <ReactDialogBox
                  closeBox={closeBox}
                  modalWidth="70%"
                  headerBackgroundColor="red"
                  headerTextColor="white"
                  closeButtonColor="white"
                  bodyBackgroundColor="white"
                  bodyTextColor="black"
                  headerText="Edit Profile"
                  style={{ marginTop: "50px" }}
                >
                  <div
                    className="profile_mainyh"
                    style={{
                      height: "560px",
                      overflowY: "scroll",
                      overflowX: "hidden",
                      paddingTop: "58px",
                    }}
                  >
                    <div className="d-flex flex-column bg-white shadow  inperson mt-4 ">
                      <ul className="nav nav-tabs" id="myTab" role="tablist">
                        <li className="nav-item" role="presentation">
                          <button
                            disabled
                            className={`nav-link  ${
                              tab1 === "tab2" ? "active " : ""
                            } `}
                            id="profile-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#profile-tab-pane"
                            type="button"
                            role="tab"
                            aria-controls="profile-tab-pane"
                            aria-selected="false"
                          >
                            <span>01</span>
                            <span>.</span>
                            <p className="d-flex">
                              <span>Basic</span>
                              <span>Details</span>
                            </p>
                          </button>
                        </li>
                        <li className="nav-item " role="presentation">
                          <button
                            disabled
                            className={`nav-link  ${
                              tab1 === "tab3" ? "active " : ""
                            } `}
                            id="contact-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#contact-tab-pane"
                            type="button"
                            role="tab"
                            aria-controls="contact-tab-pane"
                            aria-selected="false"
                          >
                            <span>02</span>
                            <span>.</span>
                            <p className="d-flex">
                              <span>Emergency</span>
                              <span>con..</span>
                            </p>
                          </button>
                        </li>
                        <li className="nav-item" role="presentation">
                          <button
                            disabled
                            className={`nav-link  ${
                              tab1 === "tab4" ? "active " : ""
                            } `}
                            id="work-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#work-tab-pane"
                            type="button"
                            role="tab"
                            aria-controls="work-tab-pane"
                            aria-selected="false"
                          >
                            <span>03</span>
                            <span>.</span>
                            <p className="d-flex">
                              <span>Work</span>
                              <span> History</span>
                            </p>
                          </button>
                        </li>
                        <li className="nav-item" role="presentation">
                          <button
                            disabled
                            className={`nav-link  ${
                              tab1 === "tab5" ? "active " : ""
                            } `}
                            id="qualifications-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#qualifications-tab-pane"
                            type="button"
                            role="tab"
                            aria-controls="qualifications-tab-pane"
                            aria-selected="false"
                          >
                            <span>04</span>
                            <span>.</span>
                            <p class="d-flex">
                              <span>Qualifications</span>
                            </p>
                          </button>
                        </li>
                      </ul>
                      <div
                        className="tab-content py-5 profile-form"
                        id="myTabContent"
                      >
                        <div
                          className={`tab-pane fade  ${
                            tab1 === "tab2" ? "show active " : ""
                          } `}
                          id="profile-tab-pane"
                          role="tabpanel"
                          aria-labelledby="profile-tab"
                          tabindex="0"
                        >
                          <div className="d-block text-center">
                            <h4>Application Form</h4>
                          </div>
                          <form
                            action="javascript:void(0)"
                            onSubmit={handlesubmitStep2}
                            method="post"
                          >
                            <div className="row gy-3 align-items-end">
                              <div className="col-md-3">
                                <div className="d-flex flex-column">
                                  <label>
                                    Name
                                    <span className="text-danger">*</span>
                                  </label>

                                  <select
                                    className="p-1"
                                    onChange={handlestep2_title}
                                    name="step2_title"
                                    value={issstep2_title}
                                    required
                                  >
                                    <option value="">Title</option>
                                    <option value="Ms">Ms</option>
                                    <option value="Miss">Miss</option>
                                    <option value="Mr">Mr</option>
                                    <option value="Mrs">Mrs</option>
                                  </select>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="d-flex flex-column">
                                  <input
                                    onChange={handleInputChangeStep2}
                                    className="px-2"
                                    placeholder="First"
                                    type="text"
                                    id="name"
                                    defaultValue={allrecord.first_name}
                                    name="first_name"
                                  />
                                </div>
                              </div>
                              <div className="col-md-5">
                                <div className="d-flex flex-column">
                                  <input
                                    onChange={handleInputChangeStep2}
                                    className="px-2"
                                    placeholder="Last"
                                    type="text"
                                    defaultValue={allrecord.last_name}
                                    id="name"
                                    name="last_name"
                                  />
                                </div>
                              </div>
                              <div className="col-md-5">
                                <div className="d-flex flex-column">
                                  <label>
                                    Mobile Number
                                    <span className="text-danger">*</span>
                                  </label>

                                  <input
                                    onInput={handleInputmobiletsep2}
                                    onKeyDown={handleKeyDownstep2}
                                    className="px-2"
                                    type="text"
                                    name="contact"
                                    value={contactstep2}
                                    required
                                  />
                                  <span className="text-danger">
                                    {invalidnumberstep2}
                                  </span>
                                </div>
                              </div>
                              <div className="col-md-2">
                                <div className="d-flex flex-column">
                                  <label>Gender</label>

                                  <select
                                    onChange={handlegenderOptions}
                                    className="p-1"
                                    name="step2_gender"
                                    id="step2_gender"
                                    value={genderOptions} // Bind value to state for controlled behavior
                                  >
                                    <option value="">Please select</option>
                                    <option value="Male">Male</option>
                                    <option value="Female">Female</option>
                                    <option value="Non Binary">
                                      Non Binary
                                    </option>
                                    <option value="Others">Others</option>
                                  </select>
                                </div>
                              </div>
                              <div className="col-md-5">
                                <div className="d-flex flex-column">
                                  <label>
                                    Are you of Aboriginal or Torres Strait
                                    Island origin?
                                  </label>

                                  <select
                                    onChange={handleoptionorigin}
                                    className="p-1"
                                    name="step2_origin"
                                    id="step2_origin"
                                    value={optionorigin}
                                  >
                                    <option value="">Please select</option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                    <option value="Prefer Not To Say">
                                      Prefer Not To Say
                                    </option>
                                  </select>
                                </div>
                              </div>
                              <div className="col-md-6" ref={emailerr}>
                                <div className="d-flex flex-column">
                                  <label>
                                    Email
                                    <span className="text-danger">*</span>
                                  </label>
                                  <input
                                    onChange={handleInputChangeStep2}
                                    className="px-2"
                                    type="email"
                                    name="email"
                                    defaultValue={allrecord.email}
                                    required
                                  />
                                  {erroremail && (
                                    <span
                                      class="text-danger"
                                      style={{ marginTop: "5px" }}
                                    >
                                      {isemail}
                                    </span>
                                  )}
                                </div>
                              </div>

                              <div className="col-12">
                                <div className="d-flex flex-column">
                                  <label>
                                    Home Address:
                                    <span className="text-danger">*</span>
                                  </label>

                                  <input
                                    onChange={handleInputChangeStep2}
                                    className="px-2"
                                    type="text"
                                    defaultValue={allrecord.address}
                                    placeholder="Street Address"
                                    name="address"
                                    required
                                  />
                                </div>
                              </div>
                              <div className="col-12">
                                <div className="d-flex flex-column">
                                  <input
                                    onChange={handleInputChangeStep2}
                                    className="px-2"
                                    type="text"
                                    placeholder="Street Address Line 2"
                                    name="step2_address"
                                    defaultValue={allrecord.step2_address}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="d-flex flex-column">
                                  <input
                                    onChange={handleInputChangeStep2}
                                    className="px-2"
                                    type="text"
                                    defaultValue={allrecord.step2_city}
                                    placeholder="City"
                                    name="step2_city"
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="d-flex flex-column">
                                  <select
                                    className="p-1"
                                    onChange={handleoptionstatefirst}
                                    name="step2_state"
                                    value={optionstatefirst}
                                  >
                                    <option value="">State</option>
                                    <option value="New South Wales">
                                      New South Wales
                                    </option>
                                    <option value="Northern Territory">
                                      Northern Territory
                                    </option>
                                    <option value="Queensland">
                                      Queensland
                                    </option>
                                    <option value="South Australia">
                                      South Australia
                                    </option>
                                    <option value="Tasmania">Tasmania</option>
                                    <option value="Victoria">Victoria</option>
                                    <option value="Western Australia">
                                      Western Australia
                                    </option>
                                  </select>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="d-flex flex-column">
                                  <input
                                    onChange={handleInputChangeStep2}
                                    className="px-2"
                                    type="text"
                                    placeholder="Postal Code"
                                    name="step2_Postal"
                                    defaultValue={allrecord.step2_Postal}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="d-flex flex-column">
                                  <select
                                    className="p-1"
                                    onChange={handleoptioncountryfirst}
                                    name="step2_country"
                                    value={optioncountryfirst}
                                  >
                                    <option value="">Country</option>
                                    <option value="Australia">Australia</option>
                                  </select>
                                </div>
                              </div>
                              <div className="col-12">
                                <div className="d-flex flex-column gap-2">
                                  <label>
                                    Is your postal address the same as your
                                    residential address?
                                    <span className="text-danger">*</span>
                                  </label>

                                  <div className="d-flex align-items-center gap-3">
                                    <div className="d-flex align-items-center gap-2">
                                      <input
                                        type="radio"
                                        id="step2_residential_address1"
                                        name="step2_residential_address"
                                        value="Yes"
                                        checked={isresidentalAddress === "Yes"}
                                        onClick={() => residentalAddress("Yes")}
                                        required
                                      />
                                      <label for="step2_residential_address1">
                                        Yes
                                      </label>
                                    </div>
                                    <div className="d-flex align-items-center gap-2">
                                      <input
                                        type="radio"
                                        id="step2_residential_address2"
                                        onClick={() => residentalAddress("No")}
                                        name="step2_residential_address"
                                        value="No"
                                        checked={isresidentalAddress === "No"}
                                        required
                                      />
                                      <label for="step2_residential_address2">
                                        No
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {step2_Postaladdress && (
                                <div className="col-12">
                                  <div className="d-flex flex-column">
                                    <label>
                                      Postal Address:
                                      <span className="text-danger">*</span>
                                    </label>

                                    <input
                                      className="px-2"
                                      type="text"
                                      onChange={handleInputChangeStep2}
                                      placeholder="Street Address"
                                      name="step2_postal_address"
                                      required
                                      defaultValue={
                                        allrecord.step2_postal_address
                                      }
                                    />
                                  </div>
                                </div>
                              )}
                              {step2_Postaladdress && (
                                <div className="col-12">
                                  <div className="d-flex flex-column">
                                    <input
                                      className="px-2"
                                      type="text"
                                      onChange={handleInputChangeStep2}
                                      placeholder="Street Address Line 2"
                                      name="step2_postal_address2"
                                      defaultValue={
                                        allrecord.step2_postal_address2
                                      }
                                    />
                                  </div>
                                </div>
                              )}
                              {step2_Postaladdress && (
                                <div className="col-md-6">
                                  <div className="d-flex flex-column">
                                    <input
                                      className="px-2"
                                      type="text"
                                      onChange={handleInputChangeStep2}
                                      placeholder="City"
                                      name="step2_postal_city"
                                      defaultValue={allrecord.step2_postal_city}
                                    />
                                  </div>
                                </div>
                              )}
                              {step2_Postaladdress && (
                                <div className="col-md-6">
                                  <div className="d-flex flex-column">
                                    <select
                                      className="p-1"
                                      value={isstep2_postal_state}
                                      onChange={handlestep2_postal_state}
                                      name="step2_postal_state"
                                    >
                                      <option value="">State</option>
                                      <option value="New South Wales">
                                        New South Wales
                                      </option>
                                      <option value="Northern Territory">
                                        Northern Territory
                                      </option>
                                      <option value="Queensland">
                                        Queensland
                                      </option>
                                      <option value="South Australia">
                                        South Australia
                                      </option>
                                      <option value="Tasmania">Tasmania</option>
                                      <option value="Victoria">Victoria</option>
                                      <option value="Western Australia">
                                        Western Australia
                                      </option>
                                    </select>
                                  </div>
                                </div>
                              )}
                              {step2_Postaladdress && (
                                <div className="col-md-6">
                                  <div className="d-flex flex-column">
                                    <input
                                      onChange={handleInputChangeStep2}
                                      className="px-2"
                                      type="text"
                                      placeholder="Postal Code"
                                      defaultValue={allrecord.step2_postal_code}
                                      name="step2_postal_code"
                                    />
                                  </div>
                                </div>
                              )}
                              {step2_Postaladdress && (
                                <div className="col-md-6">
                                  <div className="d-flex flex-column">
                                    <select
                                      className="p-1"
                                      value={isstep2_postal_country}
                                      onChange={handlestep2_postal_country}
                                      name="step2_postal_country"
                                    >
                                      <option value="">Country</option>
                                      <option value="Australia">
                                        Australia
                                      </option>
                                    </select>
                                  </div>
                                </div>
                              )}
                              <div className="col-md-4">
                                <div className="d-flex flex-column gap-2">
                                  <label>
                                    Employee Photo
                                    <span className="text-danger">*</span>
                                  </label>
                                  <p className="photo-drag">
                                    <i>
                                      Please upload an employee photo, this
                                      photo must be taken within 30 days and be
                                      of Passport standard – white background,
                                      from the shoulders upwards
                                    </i>
                                  </p>
                                  <div className="d-flex align-items-center gap-3">
                                    <div className="d-flex align-items-center profile-chosie w-100 gap-2 position-relative">
                                      <input
                                        className="chose-file"
                                        type="file"
                                        name="image"
                                        onChange={handleEmployeephoto}
                                        required={
                                          allrecord.image === null ||
                                          allrecord.image === ""
                                        }
                                      />
                                      <button className="chose-btn">
                                        {allrecord.image === null ||
                                        allrecord.image === ""
                                          ? "Choose files or drag here"
                                          : // Render something else when allrecord.image is not empty
                                            allrecord.image}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-8"></div>
                              <div className="col-md-5">
                                <div className="d-flex flex-column">
                                  <label>
                                    Date of Birth{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <input
                                    onChange={handleInputChangeStep2}
                                    className="px-2"
                                    type="date"
                                    name="step2_dob"
                                    required
                                    defaultValue={formatDateForState(
                                      allrecord.step2_dob
                                    )}
                                  />
                                </div>
                              </div>
                              <div className="col-md-7">
                                <div className="d-flex flex-column">
                                  <label>
                                    Country of Birth
                                    <span className="text-danger">*</span>
                                  </label>
                                  <Select
                                    onChange={handleSelectCountryBirth}
                                    placeholder="Select a country"
                                    options={allcountry.map((country) => ({
                                      value: country.country_name,
                                      label: country.country_name,
                                    }))}
                                    value={isselectcountrybirth} // Example: setting the default value to the first country
                                    name="step2_country_birth"
                                    required
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="d-flex flex-column gap-2">
                                  <label>
                                    Are you a permanent Resident/Citizen of
                                    Australia?
                                    <span className="text-danger">*</span>
                                  </label>

                                  <div className="d-flex align-items-center gap-3">
                                    <div className="d-flex align-items-center gap-2">
                                      <input
                                        type="radio"
                                        name="step2_permanent_address"
                                        required
                                        onClick={() => citizenaus("Yes")}
                                        checked={
                                          isSelectedpermanentAddress === "Yes"
                                        }
                                        value="Yes"
                                      />
                                      <label>Yes</label>
                                    </div>
                                    <div className="d-flex align-items-center gap-2">
                                      <input
                                        type="radio"
                                        onClick={() => citizenaus("No")}
                                        name="step2_permanent_address"
                                        required
                                        checked={
                                          isSelectedpermanentAddress === "No"
                                        }
                                        value="No"
                                      />
                                      <label>No</label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {auscitizenNo && (
                                <div className="col-md-6">
                                  <div className="d-flex flex-column gap-2">
                                    <label>
                                      Passport
                                      <span className="text-danger">*</span>
                                    </label>

                                    <div className="d-flex align-items-center gap-3">
                                      <div className="d-flex align-items-center profile-chosie w-100 gap-2 position-relative">
                                        <input
                                          className="chose-file"
                                          type="file"
                                          name="step2_passport"
                                          onChange={handlePermanentpassport}
                                          required={
                                            allrecord.step2_passport === null ||
                                            allrecord.step2_passport ===
                                              undefined ||
                                            allrecord.step2_passport === ""
                                          }
                                        />
                                        <button className="chose-btn">
                                          {allrecord.step2_passport === "" ||
                                          allrecord.step2_passport === null ||
                                          allrecord.step2_passport === undefined
                                            ? "Choose files or drag here"
                                            : // Render something else when allrecord.image is not empty
                                              allrecord.step2_passport}
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                              {auscitizenYes && (
                                <div className="col-md-12">
                                  <div className="d-flex flex-column gap-2">
                                    <label>Please select proof to upload</label>

                                    <div className="d-flex align-items-center gap-3">
                                      <div className="d-flex align-items-center gap-2">
                                        <input
                                          type="checkbox"
                                          name="step2_proof_upload"
                                          onChange={birthcheck}
                                          checked={selectedProofs.includes(
                                            "Birth Certificate"
                                          )}
                                          value="Birth Certificate"
                                        />
                                        <label>Birth Certificate</label>
                                      </div>
                                      <div className="d-flex align-items-center gap-2">
                                        <input
                                          type="checkbox"
                                          name="step2_proof_upload"
                                          value="Passport"
                                          checked={selectedProofs.includes(
                                            "Passport"
                                          )}
                                          onChange={passportcheck}
                                        />
                                        <label>Passport</label>
                                      </div>
                                      <div className="d-flex align-items-center gap-2">
                                        <input
                                          type="checkbox"
                                          name="step2_proof_upload"
                                          onChange={certificatecheck}
                                          checked={selectedProofs.includes(
                                            "Citizenship Certificate"
                                          )}
                                          value="Citizenship Certificate"
                                        />

                                        <label>Citizenship Certificate</label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                              {auscitizenYes && (
                                <div className="col-md-12">
                                  <div className="d-flex justify-content-between align-items-center">
                                    {auscitizenYesBirth && (
                                      <div
                                        className="d-flex flex-column gap-2"
                                        style={{
                                          width: "200px",
                                          pointerEvents: isImageValid(
                                            allrecord.step2_birthcertificate_file
                                          )
                                            ? "none"
                                            : "auto",
                                        }}
                                      >
                                        <label>
                                          Birth Certificate
                                          <span className="text-danger">*</span>
                                        </label>

                                        <div className="d-flex align-items-center gap-3">
                                          <div className="d-flex align-items-center gap-2 profile-chosie w-100 position-relative">
                                            <input
                                              className="chose-file"
                                              type="file"
                                              name="step2_birthcertificate_file"
                                              required={
                                                allrecord.step2_birthcertificate_file ===
                                                  null ||
                                                allrecord.step2_birthcertificate_file ===
                                                  undefined ||
                                                allrecord.step2_birthcertificate_file ===
                                                  ""
                                              }
                                              onChange={handlebirthcertificate}
                                            />
                                            <button className="chose-btn">
                                              {allrecord.step2_birthcertificate_file ===
                                                null ||
                                              allrecord.step2_birthcertificate_file ===
                                                undefined ||
                                              allrecord.step2_birthcertificate_file ===
                                                ""
                                                ? "Choose files or drag here"
                                                : // Render something else when allrecord.image is not empty
                                                  allrecord.step2_birthcertificate_file}
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                    {auscitizenYesPassport && (
                                      <div
                                        style={{
                                          width: "200px",
                                        }}
                                        className="d-flex flex-column gap-2"
                                      >
                                        <label>
                                          Passport
                                          <span className="text-danger">*</span>
                                        </label>

                                        <div
                                          className="d-flex align-items-center gap-3"
                                          style={{
                                            pointerEvents: isImageValid(
                                              allrecord.step2_passportcertificate_file
                                            )
                                              ? "none"
                                              : "auto",
                                          }}
                                        >
                                          <div className="d-flex align-items-center  profile-chosie w-100 gap-2 position-relative">
                                            <input
                                              className="chose-file"
                                              type="file"
                                              onChange={
                                                handlepassportcertificate
                                              }
                                              name="step2_passportcertificate_file"
                                              required={
                                                allrecord.step2_passportcertificate_file ===
                                                  null ||
                                                allrecord.step2_passportcertificate_file ===
                                                  undefined ||
                                                allrecord.step2_passportcertificate_file ===
                                                  ""
                                              }
                                            />
                                            <button className="chose-btn">
                                              {allrecord.step2_passportcertificate_file ===
                                                null ||
                                              allrecord.step2_passportcertificate_file ===
                                                undefined ||
                                              allrecord.step2_passportcertificate_file ===
                                                ""
                                                ? "Choose files or drag here"
                                                : // Render something else when allrecord.image is not empty
                                                  allrecord.step2_passportcertificate_file}
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                    {auscitizenYesCert && (
                                      <div
                                        style={{
                                          width: "200px",
                                        }}
                                        className="d-flex flex-column gap-2"
                                      >
                                        <label>
                                          Australian Citizenship Certificate
                                          Upload
                                          <span className="text-danger">*</span>
                                        </label>

                                        <div
                                          className="d-flex align-items-center gap-3"
                                          style={{
                                            pointerEvents: isImageValid(
                                              allrecord.step2_auscitizencertificate_file
                                            )
                                              ? "none"
                                              : "auto",
                                          }}
                                        >
                                          <div className="d-flex align-items-center profile-chosie w-100 gap-2 position-relative">
                                            <input
                                              className="chose-file"
                                              onChange={handlecertificatefile}
                                              type="file"
                                              name="step2_auscitizencertificate_file"
                                              required={
                                                allrecord.step2_auscitizencertificate_file ===
                                                  null ||
                                                allrecord.step2_auscitizencertificate_file ===
                                                  undefined ||
                                                allrecord.step2_auscitizencertificate_file ===
                                                  ""
                                              }
                                            />
                                            <button className="chose-btn">
                                              {allrecord.step2_auscitizencertificate_file ===
                                                null ||
                                              allrecord.step2_auscitizencertificate_file ===
                                                undefined ||
                                              allrecord.step2_auscitizencertificate_file ===
                                                ""
                                                ? "Choose files or drag here"
                                                : // Render something else when allrecord.image is not empty
                                                  allrecord.step2_auscitizencertificate_file}
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              )}
                              <div className="col-md-6">
                                <div className="d-flex flex-column gap-2">
                                  <label>
                                    Do you have the legal right to live and work
                                    in Australia?
                                  </label>

                                  <div className="d-flex align-items-center gap-3">
                                    <div className="d-flex align-items-center gap-2">
                                      <input
                                        type="radio"
                                        id="y1"
                                        name="step2_legal_work"
                                        onChange={legalwork}
                                        checked={islegalwork === "Yes"}
                                        value="Yes"
                                      />
                                      <label for="y1">Yes</label>
                                    </div>
                                    <div className="d-flex align-items-center gap-2">
                                      <input
                                        type="radio"
                                        name="step2_legal_work"
                                        onChange={legalwork}
                                        checked={islegalwork === "No"}
                                        value="No"
                                        id="n1"
                                      />
                                      <label for="n1">No</label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {islegalworkdesc && (
                                <div className="col-md-9">
                                  <div className="d-flex flex-column gap-2">
                                    <span className="text-white bgred">
                                      If you do not have the right to work in
                                      Australia, please do not proceed with this
                                      application.
                                    </span>
                                  </div>
                                </div>
                              )}
                              <div className="col-md-6">
                                <div className="d-flex flex-column">
                                  <label>
                                    What date do you become available?
                                    <span className="text-danger">*</span>
                                  </label>
                                  <input
                                    className="px-2"
                                    type="date"
                                    onChange={handleInputChangeStep2}
                                    name="step2_available_date"
                                    required
                                    defaultValue={formatDateForState(
                                      allrecord.step2_available_date
                                    )}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="d-flex flex-column gap-2">
                                  <label>
                                    Have you ever been convicted of any criminal
                                    offenses?
                                  </label>

                                  <div className="d-flex align-items-center gap-3">
                                    <div className="d-flex align-items-center gap-2">
                                      <input
                                        type="radio"
                                        name="step2_criminal_offenses"
                                        onChange={() =>
                                          criminaloffensenses("Yes")
                                        }
                                        checked={
                                          iscriminaloffensenses === "Yes"
                                        }
                                        value="Yes"
                                        id="yy"
                                      />
                                      <label for="yy">Yes</label>
                                    </div>
                                    <div className="d-flex align-items-center gap-2">
                                      <input
                                        type="radio"
                                        name="step2_criminal_offenses"
                                        value="No"
                                        onChange={() =>
                                          criminaloffensenses("No")
                                        }
                                        checked={iscriminaloffensenses === "No"}
                                        id="yy1"
                                      />
                                      <label for="yy1">No</label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="d-flex flex-column gap-2">
                                  <label>Have you served time in prison?</label>

                                  <div className="d-flex align-items-center gap-3">
                                    <div className="d-flex align-items-center gap-2">
                                      <input
                                        type="radio"
                                        name="step2_served_time"
                                        value="Yes"
                                        onChange={() => servedtime("Yes")}
                                        checked={isservedtime === "Yes"}
                                        id="yy_1"
                                      />
                                      <label for="yy_1">Yes</label>
                                    </div>
                                    <div className="d-flex align-items-center gap-2">
                                      <input
                                        type="radio"
                                        name="step2_served_time"
                                        value="No"
                                        onChange={() => servedtime("No")}
                                        checked={isservedtime === "No"}
                                        id="yu"
                                      />
                                      <label for="yu">No</label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="d-flex flex-column gap-2">
                                  <label>
                                    Are you an ex serving member of the
                                    Australian Defence Force?
                                  </label>

                                  <div className="d-flex align-items-center gap-3">
                                    <div className="d-flex align-items-center gap-2">
                                      <input
                                        type="radio"
                                        name="step2_defence_forced"
                                        value="Yes"
                                        id="uu"
                                        onChange={() => defenceforced("Yes")}
                                        checked={isdefenceforced === "Yes"}
                                      />
                                      <label for="uu">Yes</label>
                                    </div>
                                    <div className="d-flex align-items-center gap-2">
                                      <input
                                        type="radio"
                                        name="step2_defence_forced"
                                        value="No"
                                        id="u_u"
                                        onChange={() => defenceforced("No")}
                                        checked={isdefenceforced === "No"}
                                      />
                                      <label for="u_u">No</label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="d-flex flex-column">
                                  <label>
                                    Shirt Size
                                    <span className="text-danger">*</span>
                                  </label>
                                  <select
                                    className="p-1"
                                    onChange={handlestep2_shirt_size}
                                    name="step2_shirt_size"
                                    value={isstep2_shirt_size}
                                    required
                                  >
                                    <option value="">Please select</option>
                                    <option value="Shirt Size S" data-index="0">
                                      Shirt Size S
                                    </option>
                                    <option value="Shirt Size M" data-index="1">
                                      Shirt Size M
                                    </option>
                                    <option value="Shirt Size L" data-index="2">
                                      Shirt Size L
                                    </option>
                                    <option
                                      value="Shirt Size XL"
                                      data-index="3"
                                    >
                                      Shirt Size XL
                                    </option>
                                    <option
                                      value="Shirt Size XXL"
                                      data-index="4"
                                    >
                                      Shirt Size XXL
                                    </option>
                                    <option
                                      value="Shirt Size XXXL"
                                      data-index="5"
                                    >
                                      Shirt Size XXXL
                                    </option>
                                    <option
                                      value="Shirt Size XXXXL"
                                      data-index="6"
                                    >
                                      Shirt Size XXXXL
                                    </option>
                                  </select>
                                </div>
                              </div>
                              <div className="col-12">
                                <div className="d-flex flex-column gap-2">
                                  <label>
                                    Which shifts are you willing to work? Please
                                    select all that apply
                                  </label>
                                  <div className="d-flex gap-5">
                                    <div className="d-flex flex-column align-items-start gap-2">
                                      <label>
                                        Day Shift
                                        <span className="text-danger">*</span>
                                      </label>
                                      <div className="d-flex align-items-center gap-3">
                                        <div className="d-flex align-items-center gap-2">
                                          <input
                                            type="radio"
                                            name="step2_which_dayshift"
                                            value="Yes"
                                            onChange={() => dayshift("Yes")}
                                            checked={isdayshift === "Yes"}
                                          />
                                          <label>Yes</label>
                                        </div>
                                        <div className="d-flex align-items-center gap-2">
                                          <input
                                            type="radio"
                                            name="step2_which_dayshift"
                                            value="No"
                                            onChange={() => dayshift("No")}
                                            checked={isdayshift === "No"}
                                          />
                                          <label>No</label>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="d-flex flex-column align-items-start gap-2">
                                      <label>
                                        Night Shift
                                        <span className="text-danger">*</span>
                                      </label>
                                      <div className="d-flex align-items-center gap-3">
                                        <div className="d-flex align-items-center gap-2">
                                          <input
                                            type="radio"
                                            name="step2_which_nightshift"
                                            value="Yes"
                                            onChange={() => nightshift("Yes")}
                                            checked={isnightshift === "Yes"}
                                          />
                                          <label>Yes</label>
                                        </div>
                                        <div className="d-flex align-items-center gap-2">
                                          <input
                                            type="radio"
                                            name="step2_which_nightshift"
                                            value="No"
                                            onChange={() => nightshift("No")}
                                            checked={isnightshift === "No"}
                                          />
                                          <label>No</label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-12">
                                <div className="d-flex flex-column gap-2">
                                  <label>
                                    Employment Type - Please select all that
                                    apply
                                  </label>
                                  <div className="d-flex flex-column align-items-start  gap-2">
                                    <div className="d-flex flex-md-row flex-column gy-3 align-items-start  w-100 justify-content-between">
                                      <div className="d-flex align-items-center gap-2">
                                        <input
                                          type="checkbox"
                                          id="fifo"
                                          name="step2_employment_type"
                                          checked={selectedEmployetype.includes(
                                            "FIFO"
                                          )}
                                          onChange={() => employetype("FIFO")}
                                        />
                                        <label for="fifo">FIFO</label>
                                      </div>
                                      <div className="d-flex align-items-center gap-2">
                                        <input
                                          type="checkbox"
                                          id="Shutdowns"
                                          name="step2_employment_type"
                                          value="Shutdowns"
                                          checked={selectedEmployetype.includes(
                                            "Shutdowns"
                                          )}
                                          onChange={() =>
                                            employetype("Shutdowns")
                                          }
                                        />
                                        <label for="Shutdowns">Shutdowns</label>
                                      </div>
                                      <div className="d-flex align-items-center gap-2">
                                        <input
                                          type="checkbox"
                                          value="Local Work"
                                          id="Local"
                                          name="step2_employment_type"
                                          checked={selectedEmployetype.includes(
                                            "Local Work"
                                          )}
                                          onChange={() =>
                                            employetype("Local Work")
                                          }
                                        />
                                        <label for="Local">Local Work</label>
                                      </div>
                                      <div className="d-flex align-items-center gap-2">
                                        <input
                                          type="checkbox"
                                          name="step2_employment_type"
                                          value="Casual"
                                          id="Casual"
                                          checked={selectedEmployetype.includes(
                                            "Casual"
                                          )}
                                          onChange={() => employetype("Casual")}
                                        />
                                        <label for="Casual">Casual</label>
                                      </div>
                                      <div className="d-flex align-items-center gap-2">
                                        <input
                                          type="checkbox"
                                          name="step2_employment_type"
                                          value="Full Time"
                                          id="Full"
                                          checked={selectedEmployetype.includes(
                                            "Full Time"
                                          )}
                                          onChange={() =>
                                            employetype("Full Time")
                                          }
                                        />
                                        <label for="Full">Full Time</label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-12">
                                <div className="d-flex flex-md-row flex-column profile-btns align-items-center justify-content-center gap-2 pt-4">
                                  <button
                                    className="save-btn"
                                    type="button"
                                    onClick={() => previousstep("tab1")}
                                  >
                                    Previous
                                  </button>

                                  <button className="next-btn" type="submit">
                                    Next
                                  </button>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                        <div
                          className={`tab-pane fade  ${
                            tab1 === "tab3" ? "show active " : ""
                          } `}
                          id="contact-tab-pane"
                          role="tabpanel"
                          aria-labelledby="contact-tab"
                          tabindex="0"
                        >
                          <div className="d-block text-center">
                            <h4>Application Form</h4>
                          </div>
                          <div className="d-block py-3">
                            <h3>Emergency Contact Details</h3>
                          </div>
                          <form
                            action="javascript:void(0)"
                            method="post"
                            onSubmit={handlesubmitStep3}
                          >
                            <div className="row gy-3 align-items-end">
                              <div className="col-md-3">
                                <div className="d-flex flex-column">
                                  <label>
                                    Name
                                    <span className="text-danger">*</span>
                                  </label>

                                  <select
                                    className="p-1"
                                    name="step3_title"
                                    id="step3_title"
                                    onChange={handlestep3_title}
                                    value={issstep3_title}
                                    required
                                  >
                                    <option value="">Title</option>
                                    <option value="Ms">Ms</option>
                                    <option value="Miss">Miss</option>
                                    <option value="Mr">Mr</option>
                                    <option value="Mrs">Mrs</option>
                                  </select>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="d-flex flex-column">
                                  <input
                                    className="px-2"
                                    placeholder="First"
                                    type="text"
                                    id="name"
                                    name="step3_first_name"
                                    defaultValue={allrecord.step3_first_name}
                                    onChange={handleInputChangeStep3}
                                  />
                                </div>
                              </div>
                              <div className="col-md-5">
                                <div className="d-flex flex-column">
                                  <input
                                    className="px-2"
                                    placeholder="Last"
                                    type="text"
                                    defaultValue={allrecord.step3_last_name}
                                    onChange={handleInputChangeStep3}
                                    name="step3_last_name"
                                  />
                                </div>
                              </div>
                              <div className="col-12">
                                <div className="d-flex flex-column">
                                  <label>
                                    Relationship to you (eg. spouse, parent,
                                    friend)
                                    <span className="text-danger">*</span>
                                  </label>

                                  <input
                                    className="px-2"
                                    required
                                    defaultValue={allrecord.step3_relationship}
                                    type="text"
                                    onChange={handleInputChangeStep3}
                                    name="step3_relationship"
                                  />
                                </div>
                              </div>
                              <div className="col-12">
                                <div className="d-flex flex-column gap-2">
                                  <label>
                                    Is your Emergency Contact located in
                                    Australia?
                                    <span className="text-danger">*</span>
                                  </label>

                                  <div className="d-flex align-items-center gap-3">
                                    <div className="d-flex align-items-center gap-2">
                                      <input
                                        type="radio"
                                        onChange={handlestep3_contact}
                                        value="Yes"
                                        checked={isstep3_contact === "Yes"}
                                        name="step3_contact"
                                        required
                                      />
                                      <label>Yes</label>
                                    </div>
                                    <div className="d-flex align-items-center gap-2">
                                      <input
                                        type="radio"
                                        onChange={handlestep3_contact}
                                        value="No"
                                        checked={isstep3_contact === "No"}
                                        name="step3_contact"
                                        required
                                      />
                                      <label>No</label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="d-flex flex-column gap-2">
                                  <label>
                                    Mobile Phone Number
                                    <span className="text-danger">*</span>
                                  </label>
                                  <span className="dark-text">
                                    eg 0422 222 222
                                  </span>
                                  <input
                                    className="px-2"
                                    type="text"
                                    max="12"
                                    value={contactstep3}
                                    onKeyDown={handleKeyDownstep3}
                                    onChange={handleInputMobilephone_step3}
                                    required
                                    name="step3_mobile_number"
                                  />
                                  <span className="text-danger">
                                    {invalidnumberstep3}
                                  </span>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="d-flex flex-column gap-2">
                                  <label>
                                    Alternative Phone Number
                                    <span className="text-danger">*</span>
                                  </label>
                                  <span className="dark-text">
                                    eg 0422 222 222
                                  </span>
                                  <input
                                    className="px-2"
                                    type="text"
                                    max="12"
                                    value={contactstep3_alter}
                                    onKeyDown={handleKeyDownstep3_alter}
                                    onChange={
                                      handleInputMobilephone_step3__alter
                                    }
                                    name="step3_phone_number"
                                    required
                                  />
                                  <span className="text-danger">
                                    {invalidnumberstep3_alter}
                                  </span>
                                </div>
                              </div>
                              <div className="col-12">
                                <div className="d-flex flex-column">
                                  <label>
                                    Address
                                    <span className="text-danger">*</span>
                                  </label>

                                  <input
                                    className="px-2"
                                    onChange={handleInputChangeStep3}
                                    type="text"
                                    required
                                    defaultValue={allrecord.step3_address}
                                    placeholder="Street Address"
                                    name="step3_address"
                                  />
                                </div>
                              </div>
                              <div className="col-12">
                                <div className="d-flex flex-column">
                                  <input
                                    className="px-2"
                                    onChange={handleInputChangeStep3}
                                    type="text"
                                    defaultValue={allrecord.step3_address2}
                                    placeholder="Street Address Line 2"
                                    name="step3_address2"
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="d-flex flex-column">
                                  <input
                                    className="px-2"
                                    onChange={handleInputChangeStep3}
                                    type="text"
                                    defaultValue={allrecord.step3_city}
                                    placeholder="City"
                                    name="step3_city"
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="d-flex flex-column">
                                  <select
                                    className="p-1"
                                    name="step3_state"
                                    value={isstep3_postal_state}
                                    onChange={handlestep3_postal_state}
                                  >
                                    <option value="">State</option>
                                    <option value="New South Wales">
                                      New South Wales
                                    </option>
                                    <option value="Northern Territory">
                                      Northern Territory
                                    </option>
                                    <option value="Queensland">
                                      Queensland
                                    </option>
                                    <option value="South Australia">
                                      South Australia
                                    </option>
                                    <option value="Tasmania">Tasmania</option>
                                    <option value="Victoria">Victoria</option>
                                    <option value="Western Australia">
                                      Western Australia
                                    </option>
                                  </select>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="d-flex flex-column">
                                  <input
                                    className="px-2"
                                    type="text"
                                    onChange={handleInputChangeStep3}
                                    defaultValue={allrecord.step3_postal}
                                    placeholder="Postal Code"
                                    name="step3_postal"
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="d-flex flex-column">
                                  <select
                                    className="p-1"
                                    onChange={handleoptioncountryfirstthird}
                                    value={optioncountrythird}
                                    name="step3_country"
                                  >
                                    <option value="Country">Country</option>
                                    <option value="Australia">Australia</option>
                                  </select>
                                </div>
                              </div>
                              <div className="col-12">
                                <div className="d-flex flex-md-row flex-column profile-btns align-items-center justify-content-center gap-2 pt-4">
                                  <button
                                    className="save-btn"
                                    onClick={() => previousstep("tab2")}
                                    type="button"
                                  >
                                    Previous
                                  </button>

                                  <button className="next-btn" type="submit">
                                    Next
                                  </button>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                        <div
                          className={`tab-pane fade  ${
                            tab1 === "tab4" ? "show active " : ""
                          } `}
                          id="work-tab-pane"
                          role="tabpanel"
                          aria-labelledby="work-tab"
                          tabindex="0"
                        >
                          <div className="d-block text-center">
                            <h4>Application Form</h4>
                          </div>
                          <div className="d-block py-3">
                            <h3>Employement History</h3>
                          </div>
                          <form
                            action="javascript:void(0)"
                            method="post"
                            onSubmit={handleSubmitstep4}
                          >
                            <div className="d-block">
                              {employmentHistorySections.map(
                                (section, index) => (
                                  <div
                                    className="row mt-2 gy-3 align-items-end"
                                    key={index}
                                  >
                                    {index !== 0 && (
                                      <div className="d-flex align-items-center justify-content-end">
                                        <Link
                                          to="javascript:void(0)"
                                          className="text-white bg-danger py-2 px-3 rounded-1 float-end"
                                          onClick={() => trashdiv(index)}
                                        >
                                          Remove
                                        </Link>
                                      </div>
                                    )}
                                    <div className="col-md-6">
                                      <div className="d-flex flex-column">
                                        <label>Start date </label>
                                        <input
                                          className="px-2"
                                          name={`start_date_${index}`}
                                          value={section.start_date}
                                          onChange={(e) =>
                                            handleInputChange(
                                              index,
                                              "start_date",
                                              e.target.value
                                            )
                                          }
                                          type="date"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="d-flex flex-column">
                                        <label>End date </label>
                                        <input
                                          className="px-2"
                                          type="date"
                                          name={`end_date_${index}`}
                                          value={section.end_date}
                                          onChange={(e) =>
                                            handleInputChange(
                                              index,
                                              "end_date",
                                              e.target.value
                                            )
                                          }
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-12">
                                      <div className="d-flex flex-column">
                                        <input
                                          className="px-2"
                                          type="text"
                                          name={`company_${index}`}
                                          placeholder="Enter Company"
                                          value={section.company}
                                          onChange={(e) =>
                                            handleInputChange(
                                              index,
                                              "company",
                                              e.target.value
                                            )
                                          }
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-12">
                                      <div className="d-flex flex-column">
                                        <input
                                          className="px-2"
                                          type="text"
                                          name={`role_${index}`}
                                          value={section.role}
                                          onChange={(e) =>
                                            handleInputChange(
                                              index,
                                              "role",
                                              e.target.value
                                            )
                                          }
                                          placeholder="Enter Your Roll"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-12">
                                      <div className="d-flex flex-column">
                                        <label>
                                          Enter What you done in this company
                                        </label>
                                        <textarea
                                          name={`company_done_${index}`}
                                          placeholder="type here.."
                                          id=""
                                          rows="5"
                                          value={section.company_done}
                                          onChange={(e) =>
                                            handleInputChange(
                                              index,
                                              "company_done",
                                              e.target.value
                                            )
                                          }
                                        ></textarea>
                                      </div>
                                    </div>
                                  </div>
                                )
                              )}
                              <div className="col-12 mt-2">
                                <div className=" add-btn">
                                  <button
                                    type="button"
                                    className="next-btn"
                                    onClick={addMoreDiv}
                                  >
                                    <span>Add More</span>{" "}
                                    <span>
                                      <i className="fal fa-plus"></i>
                                    </span>
                                  </button>
                                </div>
                              </div>
                              <div className="d-block">
                                <div className="d-flex flex-md-row flex-column profile-btns align-items-center justify-content-center gap-2 pt-4">
                                  <button
                                    className="save-btn"
                                    onClick={() => previousstep("tab3")}
                                    type="button"
                                  >
                                    Previous
                                  </button>

                                  <button className="next-btn" type="submit">
                                    Next
                                  </button>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                        <div
                          className={`tab-pane fade  ${
                            tab1 === "tab5" ? "show active " : ""
                          } `}
                          id="qualifications-tab-pane"
                          role="tabpanel"
                          aria-labelledby="qualifications-tab"
                          tabindex="0"
                        >
                          <div className="d-block text-center">
                            <h4>Application Form</h4>
                          </div>
                          <div className="d-block py-3">
                            <h3>Education</h3>
                          </div>
                          <form
                            action="javascript:void(0)"
                            method="post"
                            onSubmit={handleFormFinalsubmit}
                          >
                            <div className="row gy-3 align-items-end">
                              {employmenteducation.map((section, index) => (
                                <div
                                  key={index}
                                  className="row gy-3 align-items-end"
                                >
                                  {index !== 0 && (
                                    <div className="d-flex align-items-center justify-content-end ">
                                      <Link
                                        to="javascript:void(0)"
                                        className="bg-danger text-white float-end py-2 px-3 rounded-1"
                                        onClick={() => trashdiveducation(index)}
                                      >
                                        Remove
                                      </Link>
                                    </div>
                                  )}
                                  <div className="col-md-6">
                                    <div className="d-flex flex-column">
                                      <label>
                                        Year of Completion
                                        <span className="text-danger">*</span>
                                      </label>
                                      <input
                                        name={`year_${index}`}
                                        style={{ paddingLeft: "10px" }}
                                        type="text"
                                        placeholder="Year of completion"
                                        value={section.year}
                                        onChange={(e) =>
                                          handleInputChange_edication(
                                            index,
                                            "year",
                                            e.target.value
                                          )
                                        }
                                        required
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="d-flex flex-column">
                                      <label>
                                        Course
                                        <span className="text-danger">*</span>
                                      </label>
                                      <input
                                        name={`course_${index}`}
                                        style={{ paddingLeft: "10px" }}
                                        type="text"
                                        placeholder="Course"
                                        value={section.course}
                                        onChange={(e) =>
                                          handleInputChange_edication(
                                            index,
                                            "course",
                                            e.target.value
                                          )
                                        }
                                        required
                                      />
                                    </div>
                                  </div>
                                  <div className="col-12">
                                    <div className="d-flex flex-column">
                                      <label>
                                        Institution
                                        <span className="text-danger">*</span>
                                      </label>
                                      <input
                                        name={`institution_${index}`}
                                        style={{ paddingLeft: "10px" }}
                                        type="text"
                                        placeholder="institution"
                                        value={section.institution}
                                        onChange={(e) =>
                                          handleInputChange_edication(
                                            index,
                                            "institution",
                                            e.target.value
                                          )
                                        }
                                        required
                                      />
                                    </div>
                                  </div>
                                </div>
                              ))}

                              <div className="col-12">
                                <div className=" add-btn">
                                  <button
                                    type="button"
                                    onClick={addMoreDivEducation}
                                  >
                                    <span>Add More</span>{" "}
                                    <span>
                                      <i className="fal fa-plus"></i>
                                    </span>
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div className="d-block py-3">
                              <h3>Skill and Extra Informations</h3>
                            </div>
                            <div className="row gy-3 align-items-end">
                              <div className="col-md-6">
                                <div className="d-flex flex-column">
                                  <label>
                                    Mention All Skills{" "}
                                    <span className="text-danger">*</span>
                                  </label>

                                  <CreatableSelect
                                    isMulti
                                    formatCreateLabel={formatCreateLabel}
                                    placeholder={placeholderText}
                                    options={options}
                                    value={selectvalue_skill}
                                    name="skills"
                                    required
                                    onChange={handleSelectChange}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="d-flex flex-column">
                                  <label>Years of Experience</label>
                                  <input
                                    className="px-2"
                                    type="number"
                                    pattern="[0-9]*"
                                    defaultValue={allrecord.years}
                                    onChange={handleInputChangeStep5}
                                    name="years"
                                    required
                                  />
                                </div>
                              </div>
                              <div className="col-12">
                                <div className="d-flex flex-column">
                                  <label>Mention Licences</label>
                                  <CreatableSelect
                                    isMulti
                                    formatCreateLabel={formatCreateLabel}
                                    placeholder={placeholderText}
                                    options={MentionLic}
                                    value={MentionLic_get}
                                    name="licence"
                                    onChange={handleSelectChange_mentionl}
                                  />
                                  <input
                                    className="px-0 pt-2"
                                    type="file"
                                    multiple
                                    accept="image/*"
                                    onChange={handlelicense_File}
                                    name="licence_file"
                                  />
                                  <br />
                                  <span className="text-danger">
                                    {setselect_l_msg}
                                  </span>
                                  {licenseArray !== null ? (
                                    <div className="row">
                                      {Array.isArray(licenseArray) &&
                                        licenseArray.map((item, index) => (
                                          <div key={index} className="col-md-2">
                                            <img
                                              style={{
                                                width: "80px",
                                                height: "80px",
                                                objectFit: "contain",
                                              }}
                                              src={`${apiurl}${item}`} // Assuming each item has an imageUrl property
                                              alt="Image"
                                            />
                                            <br />
                                            <button
                                              className="removeskill text-danger  mt-2"
                                              type="button"
                                              onClick={() =>
                                                removemention(
                                                  item,
                                                  userdetail.id
                                                )
                                              }
                                            >
                                              <FaTrash />
                                            </button>
                                          </div>
                                        ))}
                                    </div>
                                  ) : (
                                    <p>No result found</p>
                                  )}
                                </div>
                              </div>
                              <div className="col-12">
                                <div className="d-flex flex-column">
                                  <label>Mention Other Certification</label>
                                  <CreatableSelect
                                    isMulti
                                    formatCreateLabel={formatCreateLabel}
                                    placeholder={placeholderText}
                                    options={MentionLCert}
                                    value={MentionLCert_m}
                                    name="certificate"
                                    onChange={handleSelectChange_mentionc}
                                  />
                                  <input
                                    type="file"
                                    onChange={handlecertificate_File}
                                    multiple
                                    accept="image/*"
                                    name="certificate_file"
                                    style={{ color: "#fff" }}
                                  />
                                  <br />
                                  <span className="text-danger">
                                    {selectcertmsg}
                                  </span>
                                  {edfile !== null ? (
                                    <div className="row">
                                      {Array.isArray(edfile) &&
                                        edfile.map((item, index) => (
                                          <div key={index} className="col-md-3">
                                            <img
                                              style={{
                                                width: "80px",
                                                height: "80px",
                                                objectFit: "contain",
                                              }}
                                              src={`${apiurl}${item}`} // Assuming each item has an imageUrl property
                                              alt="Image"
                                            />
                                            <br />
                                            <button
                                              className="removeskill text-danger  mt-2"
                                              type="button"
                                              onClick={() =>
                                                removecertificate(
                                                  item,
                                                  userdetail.id
                                                )
                                              }
                                            >
                                              <FaTrash />
                                            </button>
                                          </div>
                                        ))}
                                    </div>
                                  ) : (
                                    <p>No result found</p>
                                  )}
                                </div>
                              </div>
                              <div className="col-12">
                                <div className="d-flex flex-column">
                                  <label>Trade Certifications</label>
                                  <CreatableSelect
                                    isMulti
                                    formatCreateLabel={formatCreateLabel}
                                    placeholder={placeholderText}
                                    name="trade"
                                    options={TradeM}
                                    value={selectvalue_trade}
                                    onChange={handleSelectChange_trade}
                                  />
                                  <input
                                    type="file"
                                    multiple
                                    accept="image/*"
                                    onChange={handletrade_File}
                                    name="trade_file"
                                    style={{ color: "#fff" }}
                                  />
                                  <br />
                                  <span className="text-danger">
                                    {setselect_t_msg}
                                  </span>
                                  {tradetextArray !== null ? (
                                    <div className="row">
                                      {Array.isArray(tradetextArray) &&
                                        tradetextArray.map((item, index) => (
                                          <div key={index} className="col-md-3">
                                            <img
                                              style={{
                                                width: "80px",
                                                height: "80px",
                                                objectFit: "contain",
                                              }}
                                              src={`${apiurl}${item}`} // Assuming each item has an imageUrl property
                                              alt="Image"
                                            />
                                            <br />
                                            <button
                                              className="removeskill text-danger  mt-2"
                                              type="button"
                                              onClick={() =>
                                                removetrade(item, userdetail.id)
                                              }
                                            >
                                              <FaTrash />
                                            </button>
                                          </div>
                                        ))}
                                    </div>
                                  ) : (
                                    <p>No result found</p>
                                  )}
                                </div>
                              </div>
                              <div className="col-12">
                                <div className="d-flex flex-column">
                                  <label>Machinery</label>
                                  <CreatableSelect
                                    isMulti
                                    formatCreateLabel={formatCreateLabel}
                                    placeholder={placeholderText}
                                    options={machinerygett}
                                    value={selectvalue_machinery}
                                    name="machinery"
                                    onChange={handleSelectChange_machinery}
                                  />
                                  <input
                                    type="file"
                                    multiple
                                    accept="image/*"
                                    name="machinery_file"
                                    onChange={handlemachinery_File}
                                    style={{ color: "#fff" }}
                                  />
                                  <br />
                                  <span className="text-danger">
                                    {setselect_m_msg}
                                  </span>
                                  {machinetextArrayy !== null ? (
                                    <div className="row">
                                      {Array.isArray(machinetextArrayy) &&
                                        machinetextArrayy.map((item, index) => (
                                          <div key={index} className="col-md-3">
                                            <img
                                              style={{
                                                width: "80px",
                                                height: "80px",
                                                objectFit: "contain",
                                              }}
                                              src={`${apiurl}${item}`} // Assuming each item has an imageUrl property
                                              alt="Image"
                                            />
                                            <br />
                                            <button
                                              className="removeskill text-danger  mt-2"
                                              type="button"
                                              onClick={() =>
                                                removemachine(
                                                  item,
                                                  userdetail.id
                                                )
                                              }
                                            >
                                              <FaTrash />
                                            </button>
                                          </div>
                                        ))}
                                    </div>
                                  ) : (
                                    <p>No result found</p>
                                  )}
                                </div>
                              </div>
                              <div className="col-12">
                                <div className="d-flex flex-column">
                                  <label>Vocational training</label>
                                  <CreatableSelect
                                    isMulti
                                    formatCreateLabel={formatCreateLabel}
                                    placeholder={placeholderText}
                                    options={Vocationtraa}
                                    value={selectvalue_vocational_training}
                                    name="vocational_training"
                                    onChange={
                                      handleSelectChange_vocational_training
                                    }
                                  />
                                </div>
                              </div>
                              <div className="col-12">
                                <div className="d-flex flex-column">
                                  <label>Equipment worked</label>
                                  <CreatableSelect
                                    isMulti
                                    formatCreateLabel={formatCreateLabel}
                                    placeholder={placeholderText}
                                    options={Equipmentworkk}
                                    value={selectvalue_equipment_work}
                                    name="equipment_work"
                                    onChange={handleSelectChange_equipment_work}
                                  />
                                </div>
                              </div>
                              <div className="col-12">
                                <div className="d-flex flex-md-row flex-column profile-btns align-items-center justify-content-center gap-2 pt-4">
                                  <button
                                    className="save-btn"
                                    type="button"
                                    onClick={() => previousstep("tab4")}
                                  >
                                    Previous
                                  </button>
                                  <button
                                    disabled={isButtonDisabled}
                                    className="next-btn"
                                    type="submit"
                                  >
                                    Submit
                                  </button>
                                  {textmessage && (
                                    <div className="textmessage text-center mt-2 ml-2">
                                      <div class="spinner-border text-success"></div>
                                    </div>
                                  )}
                                  <br />
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </ReactDialogBox>
              </>
            )}
            {/******Edit Profile */}

            {rosterdialog && (
              <>
                <ReactDialogBox
                  closeBox={closeBox}
                  modalWidth="60%"
                  headerBackgroundColor="red"
                  headerTextColor="white"
                  headerHeight="50"
                  closeButtonColor="white"
                  bodyBackgroundColor="white"
                  bodyTextColor="black"
                  bodyHeight="500px"
                  headerText="User Roster"
                >
                  <div style={{ height: "460px", overflowY: "scroll" }}>
                    <h1 className="text-dark">User Roster</h1>
                    {opencloseroster && (
                      <div className="d-flex flex-column bg-white shadow  inperson">
                        <div className="d-flex justify-content-between gap-2">
                          <div className="mb-2">
                            {clientreq && (
                              <Alert
                                type="error"
                                description="This field is required"
                                closable
                              />
                            )}
                            <h5 className="mb-3">Select Client</h5>
                            <div className="over-admin">
                              <div className="d-flex flex-column h-100 clientselectmain">
                                {Array.isArray(rosterclient) ? (
                                  rosterclient.map((item, index) => (
                                    <Link
                                      to="javascript:void(0)"
                                      key={index}
                                      className={`item ${
                                        activeIndex_index === index
                                          ? "active"
                                          : ""
                                      }`}
                                      onClick={() =>
                                        handlegetmnesites(item.id, index)
                                      }
                                    >
                                      {item.name}
                                    </Link>
                                  ))
                                ) : (
                                  <Link>No data available</Link>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="">
                            {locatreq && (
                              <Alert
                                type="error"
                                description="This field is required"
                                closable
                              />
                            )}
                            <h5 className="mb-3">Mine Sites</h5>
                            <div className="over-admin">
                              <div className="d-flex flex-column h-100 clientselectmain">
                                {Array.isArray(gldata) ? (
                                  gldata.map((item, index) => (
                                    <Link
                                      to="javascript:void(0)"
                                      key={index}
                                      className={`item ${
                                        activesIndex === index ? "active" : ""
                                      }`}
                                      onClick={() =>
                                        handlegetmoinesites(item.id, index)
                                      }
                                    >
                                      {item.location_name}
                                    </Link>
                                  ))
                                ) : (
                                  <Link>No data available</Link>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="">
                            <div className="right-form-box bg-white shadow p-2">
                              <div className="row gap-2">
                                <div className="col-12 col-lg-8">
                                  <div className="d-flex justify-content-between p-2">
                                    <label for="">Client Name:-</label>
                                    <span className="fs-14">
                                      {clientinfo.name}
                                    </span>
                                  </div>

                                  <div className="d-flex justify-content-between p-2">
                                    <label for="">Position:-</label>
                                    <span className="fs-14">
                                      {clientinfo.position}
                                    </span>
                                  </div>
                                  <div className="d-flex justify-content-between p-2">
                                    <label for="">Department:-</label>
                                    <span className="fs-14">
                                      {clientinfo.department}
                                    </span>
                                  </div>
                                  <div className="d-flex justify-content-between p-2">
                                    <label for="">Work Phone Number:-</label>
                                    <span className="fs-14">
                                      {clientinfo.phone_number}
                                    </span>
                                  </div>
                                  <div className="d-flex justify-content-between p-2">
                                    <label for="">Mobile Phone Number:-</label>
                                    <span className="fs-14">
                                      {clientinfo.mobile_number}
                                    </span>
                                  </div>
                                  <div className="d-flex justify-content-between p-2">
                                    <label for="">Home Phone Number:-</label>
                                    <span className="fs-14">
                                      {clientinfo.home_phone_number}
                                    </span>
                                  </div>
                                  <div className="d-flex justify-content-between p-2">
                                    <label for="">Fax Number:-</label>
                                    <span className="fs-14">
                                      {clientinfo.fax_number}
                                    </span>
                                  </div>
                                  <div className="d-flex justify-content-between p-2">
                                    <label for="">Email-Id:-</label>
                                    <span className="fs-14">
                                      {clientinfo.email}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex flex-column">
                          <div className="">
                            <div className="d-flex flex-column h-100 clientselectmain">
                              <div className="d-flex flex-column h-100 ">
                                <form
                                  className="mt-auto"
                                  action="javascript:void(0)"
                                  method="post"
                                  onSubmit={handlechooseRostersubmit}
                                >
                                  {showAlert?.type === "error" && (
                                    <Alert
                                      type="error"
                                      description="You have no permission to add this roster!"
                                      closable
                                    />
                                  )}
                                  {showAlert?.type === "errorroster" && (
                                    <Alert
                                      type="error"
                                      description="You have no permission to add this roster,because your location duration date is expire!."
                                      closable
                                    />
                                  )}
                                  {showAlert?.type === "success" && (
                                    <Alert
                                      type="success"
                                      description="Roster has been successfully created!"
                                      closable
                                    />
                                  )}
                                  <div className="d-block red-heading bg-white shadow py-4">
                                    <h4 className="mb-3">Remove Rosters</h4>
                                    <div className="d-block num-block-box">
                                      <div className="d-flex flex-row h-100 gap-3 roast-sel justify-content-center">
                                        <div className="d-flex flex-column gap-3">
                                          {getallroster.length === 0 ? (
                                            <div className="d-flex px-3 gap-4 align-items-center">
                                              No data available
                                            </div>
                                          ) : (
                                            getallroster.map((item, index) => (
                                              <div
                                                className="d-flex px-3 gap-4 align-items-center"
                                                key={item.id}
                                              >
                                                <div>
                                                  <h6>({item.type})</h6>
                                                </div>
                                                <div className="">
                                                  <button
                                                    className="border-0 bg-danger text-white py-1 px-2 fs-14"
                                                    type="button"
                                                    onClick={() =>
                                                      deleterotser(item.id)
                                                    }
                                                  >
                                                    <FaTrashAlt />
                                                  </button>
                                                </div>
                                              </div>
                                            ))
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    {rosterreq && (
                                      <Alert
                                        type="error"
                                        description="This field is required"
                                        closable
                                      />
                                    )}
                                    <h4 className="mb-3 mt-3">
                                      Select Rosters
                                    </h4>
                                    <div className="d-block num-block-box">
                                      <div className="d-flex flex-row h-100 gap-3 roast-sel justify-content-center">
                                        <div className="innerradio">
                                          <label for="1">
                                            <input
                                              id="1"
                                              required
                                              name="selroaster"
                                              checked={selectedOption === "2/2"}
                                              onChange={handleOptionChange}
                                              type="radio"
                                              value="2/2"
                                            />
                                            <p> 2/2</p>
                                            <span class="checkmark"></span>
                                          </label>
                                        </div>
                                        <div className="innerradio">
                                          <label for="2">
                                            <input
                                              id="2"
                                              checked={selectedOption === "3/1"}
                                              onChange={handleOptionChange}
                                              name="selroaster"
                                              type="radio"
                                              value="3/1"
                                            />
                                            <p> 3/1</p>
                                            <span class="checkmark"></span>
                                          </label>
                                        </div>
                                        <div className="innerradio">
                                          <label for="3">
                                            <input
                                              id="3"
                                              checked={selectedOption === "8/6"}
                                              onChange={handleOptionChange}
                                              name="selroaster"
                                              type="radio"
                                              value="8/6"
                                            />
                                            <p> 8/6 </p>
                                            <span class="checkmark"></span>
                                          </label>
                                        </div>
                                        <div className="innerradio">
                                          <label for="4">
                                            <input
                                              id="4"
                                              checked={selectedOption === "2/1"}
                                              onChange={handleOptionChange}
                                              name="selroaster"
                                              type="radio"
                                              value="2/1"
                                            />
                                            <p> 2/1 </p>
                                            <span class="checkmark"></span>{" "}
                                          </label>
                                        </div>
                                        <div className="innerradio">
                                          <label for="5">
                                            <input
                                              id="5"
                                              checked={
                                                selectedOption === "15/13"
                                              }
                                              onChange={handleOptionChange}
                                              name="selroaster"
                                              type="radio"
                                              value="15/13"
                                            />
                                            <p> 15/13</p>
                                            <span class="checkmark"></span>{" "}
                                          </label>
                                        </div>
                                        <div className="innerradio">
                                          <label for="6">
                                            <input
                                              id="6"
                                              checked={selectedOption === "7/7"}
                                              onChange={handleOptionChange}
                                              name="selroaster"
                                              type="radio"
                                              value="7/7"
                                            />
                                            <p> 7/7</p>
                                            <span class="checkmark"></span>{" "}
                                          </label>
                                        </div>
                                        <div className="innerradio">
                                          <label for="7">
                                            <input
                                              id="7"
                                              checked={selectedOption === "5/7"}
                                              onChange={handleOptionChange}
                                              name="selroaster"
                                              type="radio"
                                              value="5/7"
                                            />
                                            <p> 5/7 </p>
                                            <span class="checkmark"></span>
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div class="d-flex justify-content-between mt-4">
                                    <div class="form-box formbox">
                                      <label className="text-left mb-2">
                                        Choose Date
                                      </label>
                                      <input
                                        type="date"
                                        name="date"
                                        // min={getCurrentDate()}
                                        required
                                      />
                                    </div>
                                    <div className="mt-4">
                                      <button
                                        type="submit"
                                        disabled={isConditionMet}
                                        onClick={() => checkcondition()}
                                        className="submit-btn"
                                      >
                                        {isConditionMetbtn}
                                      </button>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {openclosecalender && (
                      <div className="d-flex flex-column bg-white shadow  inperson">
                        <Modal
                          isOpen={isModalOpen_hrs}
                          onRequestClose={() => setIsModalOpen_hrs(false)}
                          contentLabel="Add Text Modal"
                          className="d-flex flex-column gap-3 bg-light mt-5 p-3 align-item-center justify-content-center"
                        >
                          {" "}
                          <h3 className="text-success text-center fs-5">
                            Your hour has been submited
                          </h3>
                        </Modal>
                        <div className="d-block pt-5">
                          <div className="container-lg">
                            <div className="row justify-content-center">
                              <div className="col-md-9 col-lg-6 num-block-box">
                                <div className="d-flex flex-column flex-md-row h-100 gap-3 justify-content-md-center">
                                  <div className="flex-grow-1">
                                    <select
                                      className="form-control w-100 float-start text-center text-md-start"
                                      onClick={(e) => handleclientcheck(e)}
                                    >
                                      <option value="">Select Client</option>
                                      {Array.isArray(emDataclient) ? (
                                        emDataclient.map((item, index) => (
                                          <option value={item.id}>
                                            {item.name}
                                          </option>
                                        ))
                                      ) : (
                                        <option>No data available</option>
                                      )}
                                    </select>
                                  </div>
                                  <div className="flex-grow-1">
                                    <input
                                      type="text"
                                      className="form-control text-center text-md-start"
                                      value={locatedname}
                                      disabled
                                      placeholder="Mine Site"
                                    />
                                  </div>

                                  <div className="flex-shirnk-0">
                                    <Link
                                      href="javascript:void(0)"
                                      onClick={deletecurrentroster}
                                      className="logout d-inline-block"
                                      title="Deleted Roster"
                                    >
                                      Deleted Roster
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row mt-3 justify-content-center">
                              <div className="col-md-9 col-lg-6">
                                <div className="d-block red-heading bg-white shadow py-4">
                                  <h3 className="mb-3" ref={targetDivRef}>
                                    Select Rosters
                                  </h3>
                                  <div className="d-block num-block-box">
                                    <div className="d-flex flex-row h-100 gap-3 roast-sel justify-content-center mb-2">
                                      <div className="innerradio">
                                        <label for="1">
                                          <input
                                            id="1"
                                            disabled={
                                              !valuegetroster.includes("2/2")
                                            }
                                            checked={valuegetroster.includes(
                                              "2/2"
                                            )}
                                            name="selroaster"
                                            type="checkbox"
                                            value="2/2"
                                          />
                                          <p> 2/2</p>
                                          <span className="checkmark"></span>
                                        </label>
                                      </div>
                                      <div className="innerradio">
                                        <label for="2">
                                          <input
                                            id="2"
                                            disabled={
                                              !valuegetroster.includes("3/1")
                                            }
                                            checked={valuegetroster.includes(
                                              "3/1"
                                            )}
                                            name="selroaster"
                                            type="checkbox"
                                            value="3/1"
                                          />
                                          <p> 3/1</p>
                                          <span className="checkmark"></span>
                                        </label>
                                      </div>
                                      <div className="innerradio">
                                        <label for="3">
                                          <input
                                            id="3"
                                            disabled={
                                              !valuegetroster.includes("8/6")
                                            }
                                            checked={valuegetroster.includes(
                                              "8/6"
                                            )}
                                            name="selroaster"
                                            type="checkbox"
                                            value="8/6"
                                          />
                                          <p> 8/6 </p>
                                          <span className="checkmark"></span>
                                        </label>
                                      </div>
                                      <div className="innerradio">
                                        <label for="4">
                                          <input
                                            id="4"
                                            disabled={
                                              !valuegetroster.includes("2/1")
                                            }
                                            checked={valuegetroster.includes(
                                              "2/1"
                                            )}
                                            name="selroaster"
                                            type="checkbox"
                                            value="2/1"
                                          />
                                          <p> 2/1 </p>
                                          <span className="checkmark"></span>{" "}
                                        </label>
                                      </div>
                                      <div className="innerradio">
                                        <label for="5">
                                          <input
                                            id="5"
                                            disabled={
                                              !valuegetroster.includes("15/13")
                                            }
                                            checked={valuegetroster.includes(
                                              "15/13"
                                            )}
                                            name="selroaster"
                                            type="checkbox"
                                            value="15/13"
                                          />
                                          <p> 15/13</p>
                                          <span className="checkmark"></span>{" "}
                                        </label>
                                      </div>
                                      <div className="innerradio">
                                        <label for="6">
                                          <input
                                            id="6"
                                            disabled={
                                              !valuegetroster.includes("7/7")
                                            }
                                            checked={valuegetroster.includes(
                                              "7/7"
                                            )}
                                            name="selroaster"
                                            type="radio"
                                            value="7/7"
                                          />
                                          <p> 7/7</p>
                                          <span className="checkmark"></span>{" "}
                                        </label>
                                      </div>
                                      <div className="innerradio">
                                        <label for="7">
                                          <input
                                            id="7"
                                            disabled={
                                              !valuegetroster.includes("5/7")
                                            }
                                            checked={valuegetroster.includes(
                                              "5/7"
                                            )}
                                            name="selroaster"
                                            type="radio"
                                            value="5/7"
                                          />
                                          <p> 5/7</p>
                                          <span className="checkmark"></span>{" "}
                                        </label>
                                      </div>
                                    </div>
                                    {/* weather icon top */}
                                    {isClickedopen && (
                                      <div className="d-flex gap-2 justify-content-center my-3">
                                        <Link
                                          to=""
                                          className={iconClassName}
                                          style={{ backgroundColor }}
                                        >
                                          <i className={iconset}></i>
                                        </Link>
                                      </div>
                                    )}
                                    {isClickedopen_roster && (
                                      <div className="d-flex gap-2 justify-content-center my-3">
                                        <b>Your Current roster is</b>{" "}
                                        <span className="currentroster">
                                          {currentroster.type}
                                        </span>
                                      </div>
                                    )}
                                    {remember_roster && (
                                      <div className="d-flex gap-2 justify-content-center my-3">
                                        <b>Your roster start from</b>{" "}
                                        <span className="currentrosters">
                                          {moment(
                                            currentroster.startdate
                                          ).format("YYYY-MM-DD")}
                                        </span>
                                      </div>
                                    )}
                                    {/* weather icons bottom */}

                                    <div className="d-block px-4">
                                      <div className="row gy-3 list-weather align-items-center">
                                        <div className="col-6 col-md-3">
                                          <Link
                                            className="d-flex align-items-center gap-2"
                                            to="javascript:void(0)"
                                            onClick={() =>
                                              handle_getIcon(
                                                "fas fa-umbrella-beach",
                                                "#F71718",
                                                "AL"
                                              )
                                            }
                                          >
                                            <div className="flex-shrink-0 icon-user-we">
                                              <i className="fas fa-umbrella-beach"></i>
                                            </div>
                                            <div className="list-we-name">
                                              {" "}
                                              <h5>Annual Leave </h5>
                                            </div>
                                          </Link>
                                        </div>
                                        <div className="col-6 col-md-3">
                                          <Link
                                            to="javascript:void(0)"
                                            onClick={() =>
                                              handle_getIcon(
                                                "far fa-treasure-chest",
                                                "#F8B70D",
                                                "Work Camp"
                                              )
                                            }
                                            className="d-flex align-items-center gap-2"
                                          >
                                            <div className="flex-shrink-0 icon-user-we bg-warning">
                                              <i className="far fa-treasure-chest"></i>
                                            </div>
                                            <div className="list-we-name">
                                              {" "}
                                              <h5>Workers Comp </h5>
                                            </div>
                                          </Link>
                                        </div>
                                        <div className="col-6 col-md-3">
                                          <Link
                                            to="javascript:void(0)"
                                            onClick={() =>
                                              handle_getIcon(
                                                "fas fa-plane-arrival",
                                                "#7DBFFC",
                                                "FLIPM"
                                              )
                                            }
                                            className="d-flex align-items-center gap-2"
                                          >
                                            <div className="flex-shrink-0 icon-user-we bgfip">
                                              <i className="fas fa-plane-arrival"></i>
                                            </div>
                                            <div className="list-we-name">
                                              {" "}
                                              <h5>Fly in PM </h5>
                                            </div>
                                          </Link>
                                        </div>
                                        <div className="col-6 col-md-3">
                                          <Link
                                            to="javascript:void(0)"
                                            onClick={() =>
                                              handle_getIcon(
                                                "far fa-plane-departure",
                                                "#9F20AA",
                                                "FLOAM"
                                              )
                                            }
                                            className="d-flex align-items-center gap-2"
                                          >
                                            <div className="flex-shrink-0 icon-user-we foa">
                                              <i className="far fa-plane-departure"></i>
                                            </div>
                                            <div className="list-we-name">
                                              {" "}
                                              <h5>Fly out AM </h5>
                                            </div>
                                          </Link>
                                        </div>
                                      </div>
                                      <div className="row gy-3 list-weather align-items-center mt-4">
                                        <div className="col-6 col-md-3">
                                          <Link
                                            to="javascript:void(0)"
                                            onClick={() =>
                                              handle_getIcon(
                                                "far fa-thermometer",
                                                "#395AEF",
                                                "Sick Leave"
                                              )
                                            }
                                            className="d-flex align-items-center gap-2"
                                          >
                                            <div className="flex-shrink-0 icon-user-we sl">
                                              <i className="far fa-thermometer"></i>
                                            </div>
                                            <div className="list-we-name">
                                              {" "}
                                              <h5>Sick Leave </h5>
                                            </div>
                                          </Link>
                                        </div>
                                        <div className="col-6 col-md-3">
                                          <Link
                                            to="javascript:void(0)"
                                            onClick={() =>
                                              handle_getIcon(
                                                "far fa-sun-cloud",
                                                "#B2DAF2",
                                                "Day"
                                              )
                                            }
                                            className="d-flex align-items-center gap-2"
                                          >
                                            <div className="flex-shrink-0 icon-user-we ds">
                                              <i className="far fa-sun-cloud text-dark"></i>
                                            </div>
                                            <div className="list-we-name">
                                              {" "}
                                              <h5>Day's Worked, dayshift </h5>
                                            </div>
                                          </Link>
                                        </div>
                                        <div className="col-6 col-md-3">
                                          <Link
                                            to="javascript:void(0)"
                                            onClick={() =>
                                              handle_getIcon(
                                                "far fa-plane-arrival",
                                                "#B9723D",
                                                "FLIAM"
                                              )
                                            }
                                            className="d-flex align-items-center gap-2"
                                          >
                                            <div className="flex-shrink-0 icon-user-we fia">
                                              <i className="far fa-plane-arrival"></i>
                                            </div>
                                            <div className="list-we-name">
                                              {" "}
                                              <h5>Fly in AM </h5>
                                            </div>
                                          </Link>
                                        </div>
                                        <div className="col-6 col-md-3">
                                          <Link
                                            to="javascript:void(0)"
                                            onClick={() =>
                                              handle_getIcon(
                                                "far fa-chimney",
                                                "#FB02F5",
                                                "In Isolation on site"
                                              )
                                            }
                                            className="d-flex align-items-center gap-2"
                                          >
                                            <div className="flex-shrink-0 icon-user-we iso">
                                              <i className="far fa-chimney"></i>
                                            </div>
                                            <div className="list-we-name">
                                              {" "}
                                              <h5>In Isolation on site </h5>
                                            </div>
                                          </Link>
                                        </div>
                                      </div>
                                      <div className="row gy-3 list-weather align-items-center mt-4">
                                        <div className="col-6 col-md-3">
                                          <Link
                                            to="javascript:void(0)"
                                            onClick={() =>
                                              handle_getIcon(
                                                "fas fa-map-marker-times",
                                                "#F3DC0C",
                                                "Leave Without Pay"
                                              )
                                            }
                                            className="d-flex align-items-center gap-2"
                                          >
                                            <div className="flex-shrink-0 icon-user-we  lwp">
                                              <i className="fas fa-map-marker-times"></i>
                                            </div>
                                            <div className="list-we-name">
                                              {" "}
                                              <h5>Leave Without Pay </h5>
                                            </div>
                                          </Link>
                                        </div>
                                        <div className="col-6 col-md-3">
                                          <Link
                                            to="javascript:void(0)"
                                            onClick={() =>
                                              handle_getIcon(
                                                "far fa-moon-cloud",
                                                "#1D0303",
                                                "Night"
                                              )
                                            }
                                            className="d-flex align-items-center gap-2"
                                          >
                                            <div className="flex-shrink-0 icon-user-we ns">
                                              <i className="far fa-moon-cloud"></i>
                                            </div>
                                            <div className="list-we-name">
                                              {" "}
                                              <h5>Day's Worked, NightShift </h5>
                                            </div>
                                          </Link>
                                        </div>
                                        <div className="col-6 col-md-3">
                                          <Link
                                            to="javascript:void(0)"
                                            onClick={() =>
                                              handle_getIcon(
                                                "fas fa-plane-departure",
                                                "#77D928",
                                                "FLOPM"
                                              )
                                            }
                                            className="d-flex align-items-center gap-2"
                                          >
                                            <div className="flex-shrink-0 icon-user-we fop">
                                              <i className="fas fa-plane-departure"></i>
                                            </div>
                                            <div className="list-we-name">
                                              {" "}
                                              <h5>Fly out PM </h5>
                                            </div>
                                          </Link>
                                        </div>
                                        <div className="col-6 col-md-3">
                                          <Link
                                            to="javascript:void(0)"
                                            onClick={() =>
                                              handle_getIcon(
                                                "fal fa-digging",
                                                "#490909",
                                                "Work Offsite"
                                              )
                                            }
                                            className="d-flex align-items-center gap-2"
                                          >
                                            <div className="flex-shrink-0 icon-user-we wo">
                                              <i className="fal fa-digging"></i>
                                            </div>
                                            <div className="list-we-name">
                                              {" "}
                                              <h5>Work Offsite </h5>
                                            </div>
                                          </Link>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="d-flex gap-2 justify-content-center my-3 text-danger">
                                    <b>{shiftmessagevalue}</b>{" "}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* section */}
                        <div className="d-block mt-5">
                          <div className="row justify-content-center">
                            <div className="col-md-9">
                              <div className="mb-3 mb-md-0 float-md-end">
                                {isClickedloader && (
                                  <div
                                    class="spinner-border imgsrc"
                                    role="status"
                                  >
                                    <span class="visually-hidden">
                                      Loading...
                                    </span>
                                  </div>
                                )}

                                <button
                                  type="submit"
                                  className="refreshbutton"
                                  onClick={() => handleget_refresh()}
                                  title="Refresh Calendar"
                                >
                                  <FaSync />
                                </button>
                              </div>
                              <div
                                className="hide-day-week-agenda-buttons"
                                style={{
                                  color: "black",
                                }}
                              >
                                <Calendar
                                  localizer={localizer}
                                  events={events}
                                  startAccessor="start"
                                  endAccessor="end"
                                  selectable
                                  views={["month"]}
                                  components={{
                                    event: EventComponent,
                                  }}
                                  style={{ height: 500 }}
                                />

                                <Modal
                                  isOpen={isModalOpen}
                                  onRequestClose={() => setIsModalOpen(false)}
                                  contentLabel="Add Text Modal"
                                  className="d-flex flex-column gap-3 bg-light mt-5 p-3 align-item-center justify-content-center"
                                >
                                  {" "}
                                  <form
                                    action="javascript:void(0)"
                                    onSubmit={handleAttend}
                                    method="post"
                                  >
                                    {showAlert?.type === "error" && (
                                      <Alert
                                        type="error"
                                        description="Already Exist"
                                        closable
                                      />
                                    )}
                                    {showAlert?.type === "success" && (
                                      <Alert
                                        type="success"
                                        description="Successfully created!"
                                        closable
                                      />
                                    )}
                                    <h4 className="mb-2">
                                      {" "}
                                      Choose Roster For Attendance{" "}
                                    </h4>
                                    {/* <textarea value={popupText} onChange={handlePopupTextChange} /> */}
                                    <div class="row">
                                      <div class="col-md-6">
                                        <label className="mb-2">
                                          Client Name
                                        </label>
                                        {isClickededit && (
                                          <input
                                            type="text"
                                            name="clientId"
                                            required
                                            disabled
                                            value={valueclient}
                                            className="form-control mb-2"
                                          />
                                        )}
                                        {isClickedadd && (
                                          <select
                                            className="form-control mb-2"
                                            required
                                            onChange={handle_getClient}
                                            name="client_Id"
                                          >
                                            <option value={""}>
                                              Select value
                                            </option>
                                            {Array.isArray(valueroster) ? (
                                              valueroster.map((item, index) => (
                                                <option value={item.id}>
                                                  {item.name}
                                                </option>
                                              ))
                                            ) : (
                                              <option value=""></option>
                                            )}
                                          </select>
                                        )}
                                      </div>
                                      <div class="col-md-6">
                                        <label className="mb-2">
                                          Mine Site
                                        </label>
                                        {isClickededit && (
                                          <input
                                            type="text"
                                            name="location"
                                            required
                                            disabled
                                            value={valuelocations}
                                            className="form-control mb-2"
                                          />
                                        )}
                                        {isClickedadd && (
                                          <select
                                            className="form-control mb-2"
                                            required
                                            onChange={handle_getClientlocate}
                                            name="location_id"
                                          >
                                            <option value={""}>
                                              Select value
                                            </option>
                                            {Array.isArray(locationname) ? (
                                              locationname.map(
                                                (item, index) => (
                                                  <option value={item.id}>
                                                    {item.location_name}
                                                  </option>
                                                )
                                              )
                                            ) : (
                                              <option value=""></option>
                                            )}
                                          </select>
                                        )}
                                      </div>
                                      <div class="col-md-6">
                                        <label className="mb-2">Roster</label>

                                        <input
                                          type="text"
                                          name="roster"
                                          required
                                          disabled
                                          value={valueget}
                                          className="form-control mb-2"
                                        />
                                      </div>
                                      <div class="col-md-6">
                                        <label className="mb-2">Shift</label>
                                        <select
                                          name="shift"
                                          required
                                          className="form-control mb-2"
                                        >
                                          <option value="">--Select--</option>
                                          <option value="AL">
                                            Annual Leave
                                          </option>
                                          <option value="Work Camp">
                                            Work Camp
                                          </option>
                                          <option value="FLIPM">
                                            Fly in PM
                                          </option>
                                          <option value="FLOAM">
                                            Fly out AM
                                          </option>
                                          <option value="Sick Leave">
                                            Sick Leave
                                          </option>
                                          <option value="Day">
                                            Day's Worked, dayshift
                                          </option>
                                          <option value="FLIAM">
                                            Fly in AM{" "}
                                          </option>
                                          <option value="In Isolation on site">
                                            In Isolation on site
                                          </option>
                                          <option value="Leave Without Pay">
                                            Leave Without Pay{" "}
                                          </option>
                                          <option value="Night">
                                            Day's Worked, NightShift
                                          </option>
                                          <option value="FLOPM">
                                            Fly out PM{" "}
                                          </option>
                                          <option value="Work Offsite">
                                            Work Offsite
                                          </option>
                                        </select>
                                      </div>
                                    </div>
                                    <button
                                      className="btn btn-success"
                                      type="submit"
                                    >
                                      Save
                                    </button>
                                  </form>
                                </Modal>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* section */}
                      </div>
                    )}
                  </div>
                </ReactDialogBox>
              </>
            )}
            {/** */}
            <div className="row gy-4 mt-4">
              <div className="col-md-4"></div>
              {isClickedclient && (
                <div className="col-md-8">
                  <Link to="javascript:void(0)" className="addclient">
                    Client
                  </Link>
                  <div className="row mt-4">
                    <div className="col-md-6">
                      <select
                        className="form-control"
                        onChange={handle_getallattendance}
                      >
                        <option value="">--Select Client--</option>
                        {valueclients.map((valueclients) => (
                          <option
                            key={valueclients.client_id}
                            value={valueclients.client_id}
                          >
                            {valueclients.cname}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-md-6">
                      {isClickedyourroster && (
                        <span className="p-2 mt-4 ">
                          Your Roster is <b>{rostertype}</b>
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="right-form-box bg-white shadow p-2 mt-4">
                    <div className="d-flex flex-column gap-4 heightprofile">
                      <div className="row">
                        <div className="col-3">
                          <h4 className="text-black fs-16">Working Days:</h4>
                        </div>
                        <div className="col-9">
                          {isObjectEmptyworkingdays ? (
                            <p>No results found.</p>
                          ) : (
                            <div>
                              {Object.keys(Valueworkingdays).map((month) => (
                                <div className="d-flex flex-column" key={month}>
                                  <div className="d-flex align-items-center gap-2 border-bottom border-secondary border-opacity-50">
                                    <div className="flex-grow-1">
                                      <div className="scroll-date-block">
                                        <div className="d-flex gap-1">
                                          {Valueworkingdays[month].map(
                                            (row) => (
                                              <h5 key={row.id}>{row.nd}</h5>
                                            )
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="flex-shrink-0">
                                      <h5 className="text-black fs-14">
                                        {month}/
                                        {Valueworkingdays[month][0].year % 100}
                                      </h5>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-3">
                          <h4 className="text-black fs-16">Day Off:</h4>
                        </div>
                        <div className="col-9">
                          {isObjectEmptyalldaysOff ? (
                            <p>No results found.</p>
                          ) : (
                            <div>
                              {Object.keys(Valuealloffdays).map((month) => (
                                <div className="d-flex flex-column" key={month}>
                                  <div className="d-flex align-items-center gap-2 border-bottom border-secondary border-opacity-50">
                                    <div className="flex-grow-1">
                                      <div className="scroll-date-block">
                                        <div className="d-flex gap-1">
                                          {Valuealloffdays[month].map((row) => (
                                            <h5 key={row.id}>{row.nd}</h5>
                                          ))}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="flex-shrink-0">
                                      <h5 className="text-black fs-14">
                                        {month}/
                                        {Valuealloffdays[month][0].year % 100}
                                      </h5>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    {isClickedyourroster && (
                      <div>
                        {
                          Valuemainroster ? (
                            <p>
                              <b>
                                {" "}
                                Current Roster: {gdata.first_name}{" "}
                                {gdata.last_name}{" "}
                              </b>
                              Roster from <b>{Valuemainroster.cname} </b> and{" "}
                              <b> {Valuemainroster.location_name}</b>
                            </p>
                          ) : null // or any fallback content you want to render when Valuemainroster is falsy
                        }
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <AdminFooter />
      </div>
    </>
  );
};
