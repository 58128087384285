import React, { useState, useEffect } from "react";
import axios from "axios";
import { message, Form, Input, Button, Alert } from "antd";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Header } from "../Header";
import { Footer } from "../Footer";
import moment from "moment-timezone";
import Select from "react-select";
import Modal from "react-modal";
import { Documentsnotes } from "./Documentsnotes";
import { Documents } from "./Documents";
import "../assets/ohs/newstyle.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
export const Aboutmiss = () => {
  const currentYear = new Date().getFullYear();
  const navigate = useNavigate();
  const [timezones, setTimezones] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [witn_id, setwitn_id] = useState("");
  const [timezonesus, setausTimezones] = useState("");
  const [activeTab, setActiveTab] = useState("home-tab");
  const [activeTabMenu, setactiveTabMenu] = useState("details");
  const [activetab1, setactivetab1] = useState(true);
  const [activetab2, setactivetab2] = useState(false);
  const [isDisabledstep2, setisDisabledstep2] = useState(false);
  const [isDisabledtomenu, setisDisabledtomenu] = useState(false);
  const [activetab3, setactivetab3] = useState(false);
  const [activetab4, setactivetab4] = useState(false);
  const [activecounrty, setactivecounrty] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);
  const [perdetailstep2, setperdetailstep2] = useState(false);
  const [iscountryChecked, setIscountryChecked] = useState("au");
  const [isyesNoChecked, setyesNoChecked] = useState("Yes");
  const [isStateChecked, setStateChecked] = useState("");
  const [isSet3employee, setisSet3employee] = useState("");
  const [isstep2employee, setisstep2employee] = useState("Employee");
  const [persondetail, setpersondetail] = useState("");
  const [additional_information, setadditional_information] = useState("");
  const [addressabout, setaddressabout] = useState("");
  const [isreferences, setreferences] = useState("");
  const [isstep2_persondetail, setisstep2_persondetail] = useState("Yes");
  const [iswasChecked, setiswasChecked] = useState("No");
  const [iswasfirst, setwasfirst] = useState(false);
  const [iswhereotherChecked, setiswhereotherChecked] = useState("No");
  const [iswhereother, setwhereother] = useState(false);
  const [uniquecode, setuniquecode] = useState("");
  const [isselectedtimezone, selectedtimezone] = useState(null);
  const [iswitnessChecked, setiswitnessChecked] = useState("No");
  const [iswitness, setwitness] = useState(false);
  const [countries, setCountries] = useState([]);
  const [isetcounty, setisetcounty] = useState("");
  const [isetcountystep3, setisetcountystep3] = useState("");
  const [getincidentData, setgetincidentData] = useState([]);
  const [isincident_safeChecked, setisincident_safeChecked] = useState("No");
  const [isincidentafe, setisincidentafe] = useState(false);
  const [issetgetLocations, setgetLocations] = useState([]);
  const [issetSelectedsite, setSelectedsite] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [istimezone, setistimezone] = useState(false);
  const [issite, setissite] = useState(false);
  const [iswirnessall, setwitnessall] = useState([]);
  const [openDropdownIndex, setOpenDropdownIndex] = useState(null);
  const [openDropdownIndexedit, setOpenDropdownIndexedit] = useState(null);
  const changeFavicon = (url) => {
    const favicon = document.querySelector('link[rel="icon"]');
    if (favicon) {
      favicon.href = url;
    }
  };
  const { id } = useParams();

  useEffect(() => {
    const userStr = JSON.parse(localStorage.getItem("users"));
    if (userStr === null) {
      window.location.href = "/login";
    }
    document.title = "About Page";
    if (id === undefined) {
      setuniquecode("");
    } else {
      setuniquecode(id);
    }

    axios
      .get("https://jlmining.app/country", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        const options = res.data.results.map((country) => ({
          value: country.country_name,
          label: country.country_name,
        }));
        setCountries(options);
      })
      .catch((err) => {});
    const australianTimezones = [
      "Australia/Lord_Howe",
      "Antarctica/Macquarie",
      "Australia/Hobart",
      "Australia/Melbourne",
      "Australia/Sydney",
      "Australia/Broken_Hill",
      "Australia/Brisbane",
      "Australia/Lindeman",
      "Australia/Adelaide",
      "Australia/Darwin",
      "Australia/Perth",
      "Australia/Eucla",
    ].map((zone) => ({ value: zone, label: zone }));
    setTimezones(australianTimezones);

    let formdata = {
      user_id: userStr[0].id,
      code: id,
    };
    axios
      .post("https://jlmining.app/getlocationohs", formdata, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log("locations");
        console.log(res.data.results);
        if (res.data.results.length > 0) {
          const options = res.data.results.map((item) => ({
            value: item.location_name,
            label: item.location_name,
          }));
          setgetLocations(options);
        }
      })
      .catch((err) => {});
    axios
      .post("https://jlmining.app/getincidentdatamiss", formdata, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res.data.row.length > 0) {
          var indata = res.data.row[0];
          if (indata.status === "Closed") {
            const newUrl = "/incident/";
            navigate(newUrl);
          }
          setgetincidentData(indata);
          if (indata.was_first === "Yes") {
            setwasfirst(true);
          }
          setisstep2employee(indata.employee_status);
          setiswhereotherChecked(indata.other_person_involved);
          if (indata.other_person_involved === "Yes") {
            setwhereother(true);
          }
          setiswitnessChecked(indata.witness);
          if (indata.witness === "Yes") {
            setwitness(true);
          }
          setisincident_safeChecked(indata.incident_safe);
          if (indata.incident_safe === "Yes") {
            setisincidentafe(true);
          }
          setiswasChecked(indata.was_first);
          selectedtimezone({
            value: indata.time_zone,
            label: indata.time_zone,
          });
          setSelectedsite({
            value: indata.site_step4,
            label: indata.site_step4,
          });
        }
      })
      .catch((err) => {});
    axios
      .post("https://jlmining.app/getnearmiss_witness", formdata, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setwitnessall(res.data.results);
      })
      .catch((err) => {});
  }, [id]);
  //console.log(uniquecode);
  const step2Country = (event) => {
    if (event !== "") {
      setisetcounty(event.value);
    }
  };
  const step3Country = (event) => {
    if (event !== "") {
      setisetcountystep3(event.value);
    }
  };
  const step3site = (event) => {
    console.log(event.value);
    if (event !== "") {
      setSelectedsite({
        value: event.value,
        label: event.value,
      });
    }
  };
  const handinjured_check = (event) => {
    if (event === "Yes") {
      setyesNoChecked("Yes");
      setIsDisabled(false);
    } else {
      setyesNoChecked("No");
      setIsDisabled(true);
    }
    console.log(event);
  };
  const handcountry = (event) => {
    if (event === "au") {
      setIscountryChecked("au");
      setactivecounrty(true);
    } else {
      setIscountryChecked("nz");
      setactivecounrty(false);
    }
  };
  const handstate = (event) => {
    setStateChecked(event);
  };
  const handstep2employee = (event) => {
    setisstep2employee(event);
    setisDisabledstep2(false);
    if (event !== "Employee") {
      setperdetailstep2(true);
    } else {
      setpersondetail("");
      setisstep2_persondetail("Yes");
      setperdetailstep2(false);
    }
  };
  const handstep2persondetail = (event) => {
    setisstep2_persondetail(event);
    setpersondetail("");
    if (event === "Yes") {
      setisDisabledstep2(false);
      setpersondetail("");
    } else {
      setisDisabledstep2(true);
      setpersondetail(event);
    }
  };
  const backbuttonStep2 = (event) => {
    setactivetab1(true);
    setactivetab2(false);
    setactiveTabMenu("details");
  };
  const backbuttonStep3 = (event) => {
    setactivetab1(false);
    setactivetab3(false);
    setactivetab2(true);
    setactiveTabMenu("injuredperson");
  };
  const backbuttonStep4 = (event) => {
    setactivetab4(false);
    setactivetab3(true);
    setactiveTabMenu("reporter");
  };
  const step2fullname = (e) => {
    setpersondetail(e.target.value);
  };
  const handmenu_check = (e) => {
    if (uniquecode !== undefined || uniquecode !== "") {
      if (e === "details") {
        const newUrl = "/near-miss/detail/" + uniquecode;
        navigate(newUrl);
      }

      if (e === "reporter") {
        const newUrl = "/near-miss/reporter/" + uniquecode;
        navigate(newUrl);
      }
      if (e === "about") {
        const newUrl = "/near-miss/about/" + uniquecode;
        navigate(newUrl);
      }
    }
  };
  const handincident_safe = (event) => {
    setisincident_safeChecked(event);
    if (event === "Yes") {
      setisincidentafe(true);
    } else {
      setisincidentafe(false);
    }
  };
  const handwasfirst = (event) => {
    setiswasChecked(event);
    if (event === "Yes") {
      setwasfirst(true);
    } else {
      setwasfirst(false);
    }
  };
  const handwhereother = (event) => {
    setiswhereotherChecked(event);
    if (event === "Yes") {
      setwhereother(true);
    } else {
      setwhereother(false);
    }
  };
  const handwitness = (event) => {
    setiswitnessChecked(event);
    if (event === "Yes") {
      setwitness(true);
    } else {
      setwitness(false);
    }
  };
  const references = (event) => {
    setreferences(event.target.value);
  };

  const handbuttonStep4 = (event) => {
    var vll = event.target;
    const userStr = JSON.parse(localStorage.getItem("users"));
    if (iswasChecked === "Yes") {
      var treat_ment = vll.treatment.value;
    } else {
      var treat_ment = "";
    }
    if (iswhereotherChecked === "Yes") {
      var detperson = vll.detail_person_involved.value;
    } else {
      var detperson = "";
    }
    if (iswitnessChecked === "Yes") {
      var detwit = vll.detail_witness.value;
    } else {
      var detwit = "";
    }
    if (isincident_safeChecked === "Yes") {
      var acto = vll.action_token.value;
    } else {
      var acto = "";
    }
    let formdata = {
      check_code: uniquecode,
      site_step4: issetSelectedsite.value,
      site_reference: vll.site_reference.value,
      locations: vll.locations.value,
      step4_date: vll.step4_date.value,
      step4_time: vll.step4_time.value,
      chain_event: vll.chain_event.value,
      was_first: iswasChecked,
      treatment: treat_ment,
      other_person_involved: iswhereotherChecked,
      detail_person_involved: detperson,
      witness: iswitnessChecked,
      detail_witness: detwit,
      incident_safe: isincident_safeChecked,
      action_token: acto,
      time_zone: isselectedtimezone.value,
    };
    if (isselectedtimezone.value === null) {
      setistimezone(true);
      return false;
    } else {
      setistimezone(false);
    }
    if (issetSelectedsite.value === null) {
      setissite(true);
      return false;
    } else {
      setissite(false);
    }

    axios
      .post("https://jlmining.app/nearmiss_step33", formdata, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res.data.code !== "") {
          const newUrl = "/near-miss/summary/" + uniquecode;
          navigate(newUrl);
        }
      })
      .catch((err) => {});
  };
  const step3_employeename = (e) => {
    setisSet3employee(e.target.value);
  };
  const handleincident = (event) => {};
  const handletimezone = (event) => {
    selectedtimezone({
      value: event.value,
      label: event.value,
    });
  };
  function getFormattedDate(dateString) {
    if (!dateString) return ""; // Return empty string if dateString is empty or undefined
    const date = new Date(dateString);
    const year = date.getFullYear();
    let month = (date.getMonth() + 1).toString().padStart(2, "0"); // Add leading zero if needed
    let day = date.getDate().toString().padStart(2, "0"); // Add leading zero if needed
    return `${year}-${month}-${day}`;
  }
  const witnessbutton = () => {
    setIsModalOpen(true);
  };
  const docclose = () => {
    setIsModalOpen(false);
  };
  const witnessform = (event) => {
    const userStr = JSON.parse(localStorage.getItem("users"));
    let formdata = {
      user_id: userStr[0].id,
      witn_id: event.target.witn_id.value,
      code: uniquecode,
      employee_status: isstep2employee,
      full_name: event.target.full_name.value,
      additional_information: event.target.additional_information.value,
      addressabout: event.target.addressabout.value,
    };

    axios
      .post("https://jlmining.app/witnessmiss", formdata, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setwitnessall(res.data.results);
        setIsModalOpen(false);
        setwitn_id("");
      })
      .catch((err) => {});
  };
  const notesedit = (index) => {
    setOpenDropdownIndex(index === openDropdownIndex ? null : index);
  };
  const editnotes = (
    id,
    index,
    full_name,
    employee_status,
    addressabout,
    additional_information
  ) => {
    setwitn_id(id);
    setpersondetail(full_name);
    setisstep2employee(employee_status);
    setaddressabout(addressabout);
    setadditional_information(additional_information);
    setIsModalOpen(true);
    setOpenDropdownIndexedit(index === openDropdownIndexedit ? null : index);
  };
  const removewitnes = (index, id) => {
    const userStr = JSON.parse(localStorage.getItem("users"));
    let formdata = {
      id: id,
      user_id: userStr[0].id,
      code: uniquecode,
    };
    axios
      .post("https://jlmining.app/removewitnes", formdata, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST,PUT",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setwitn_id("");
        setisstep2employee("Employee");
        setpersondetail("");
        setaddressabout("");
        setadditional_information("");
        setwitnessall(res.data.results);
        setIsModalOpen(false);
      })
      .catch((err) => {});
  };
  return (
    <>
      <Header />
      <section className="d-block py-5">
        <div className="container-lg">
          <div className="row gy-4">
            <div className="col-md-8">
              {/* <Documents /> */}
              <div className="d-block">
                <div className="row gy-3 mb-4">
                  <div className="col-12 hazard-form-block">
                    <ul className="brow-nav">
                      <li>
                        <Link
                          to="javascript:void(0)"
                          onClick={() => handmenu_check("details")}
                        >
                          Details
                        </Link>
                      </li>

                      <li>
                        <Link
                          to="javascript:void(0)"
                          onClick={() => handmenu_check("reporter")}
                        >
                          Reporter
                        </Link>
                      </li>
                      <li className="active">
                        <Link
                          to="javascript:void(0)"
                          onClick={() => handmenu_check("about")}
                        >
                          About
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <form
                action="javascript:void(0)"
                onSubmit={handbuttonStep4}
                method="post"
              >
                <div className="row gy-3">
                  <div className="col-12 hazard-form-block">
                    <div className="row gy-4">
                      <div className="col-12">
                        <div className="d-flex flex-column gap-2">
                          <h1 className="h-title">About the near miss</h1>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="row gy-4">
                          <div className="col-12">
                            <h5 className="in-title">Where did it happen?</h5>
                          </div>

                          <div className="col-md-6">
                            <div className="d-flex flex-column gap-2">
                              <label for="">
                                Site
                                <span className="text-danger">*</span>
                              </label>
                              {/* <input
                                type="text"
                                required
                                defaultValue={getincidentData.site_step4}
                                name="site_step4"
                                placeholder=""
                              /> */}
                              <Select
                                required
                                options={issetgetLocations}
                                value={issetSelectedsite}
                                onChange={step3site}
                                name="site_step4"
                              />
                              {issite && (
                                <span className="text-danger">
                                  This field is required
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="d-flex flex-column gap-2">
                              <label for="">Site reference </label>
                              <input
                                type="text"
                                name="site_reference"
                                placeholder=""
                                defaultValue={getincidentData.site_reference}
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="d-flex flex-column gap-2">
                              <label for="">Location </label>
                              <input
                                type="text"
                                name="locations"
                                placeholder=""
                                defaultValue={getincidentData.locations}
                              />
                            </div>
                          </div>
                          <div className="col-12">
                            <h5 className="in-title">When did it happen?</h5>
                          </div>
                          <div className="col-md-6">
                            <div className="d-flex flex-column gap-2">
                              <label for="">
                                Date <span className="text-danger">*</span>
                              </label>
                              <input
                                type="date"
                                name="step4_date"
                                required
                                defaultValue={getFormattedDate(
                                  getincidentData.step4_date
                                )}
                                placeholder=""
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="d-flex flex-column gap-2">
                              <label for="">
                                Time <span className="text-danger">*</span>
                              </label>
                              <input
                                type="time"
                                required
                                defaultValue={getincidentData.step4_time}
                                name="step4_time"
                                placeholder=""
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="d-flex flex-column gap-2">
                              <label for="">
                                Time Zone <span className="text-danger">*</span>
                              </label>
                              <Select
                                required
                                options={timezones}
                                value={isselectedtimezone}
                                onChange={handletimezone}
                                placeholder="Select an Australian time zone"
                              />
                              {istimezone && (
                                <span className="text-danger">
                                  This field is required
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-12">
                            <h5 className="in-title">What happened?</h5>
                          </div>
                          <div className="col-12">
                            <div className="d-flex flex-column gap-2">
                              <label for="">
                                Description of chain of events
                                <span className="text-danger">*</span>
                              </label>
                              <textarea
                                name="chain_event"
                                required
                                id=""
                                cols="30"
                                defaultValue={getincidentData.chain_event}
                                rows="4"
                              ></textarea>
                            </div>
                          </div>

                          <div className="col-12">
                            <div className="d-flex flex-column gap-2 mt-2">
                              <label for="">
                                Has the area, structure or plant been made safe?
                                <span className="text-danger">*</span>
                              </label>
                              <div className="d-flex gap-4 align-items-center">
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    required
                                    name="was_first"
                                    id="the1"
                                    checked={iswasChecked === "Yes"}
                                    onClick={() => handwasfirst("Yes")}
                                  />
                                  <label
                                    className="form-check-label rabutton"
                                    for="the1"
                                  >
                                    Yes
                                  </label>
                                </div>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="was_first"
                                    id="the2"
                                    checked={iswasChecked === "No"}
                                    onClick={() => handwasfirst("No")}
                                  />
                                  <label
                                    className="form-check-label rabutton"
                                    for="the2"
                                  >
                                    No
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>

                          {iswasfirst && (
                            <div className="col-12">
                              <div className="d-flex flex-column gap-2">
                                <label for="">
                                  Details of treatment
                                  <span className="text-danger">*</span>
                                </label>
                                <textarea
                                  name="treatment"
                                  required
                                  id=""
                                  cols="30"
                                  defaultValue={getincidentData.treatment}
                                  rows="4"
                                ></textarea>
                              </div>
                            </div>
                          )}
                          <div className="col-12">
                            <h3>Witnesses</h3>

                            <div className="d-flex flex-column gap-2 mt-2">
                              <label for="">
                                Add the details to any witnesses
                                <span className="text-danger">*</span>
                              </label>
                            </div>

                            <div className="col-12 mt-4">
                              <div className="d-flex gap-2 align-items-center buttons-two">
                                <button
                                  type="button"
                                  onClick={witnessbutton}
                                  className="rep-btn"
                                >
                                  Add a witness
                                </button>
                              </div>
                            </div>

                            {Array.isArray(iswirnessall) ? (
                              iswirnessall.map((item, index) => (
                                <div className="row editopt">
                                  <div className="col-8 ">
                                    <div className="d-flex flex-column gap-2">
                                      <span>
                                        <strong> {item.full_name} </strong>
                                        {item.employee_status}
                                      </span>
                                      <span>Address:- {item.addressabout}</span>
                                      <span>
                                        Additional information:-{" "}
                                        {item.additional_information}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="col-4">
                                    <div className="dropdown">
                                      <button
                                        className="btn dropdown-toggle"
                                        type="button"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                        onClick={() => notesedit(index)}
                                      >
                                        <i className="fal fa-ellipsis-v"></i>
                                      </button>

                                      <ul
                                        className={`dropdown-menu ${
                                          openDropdownIndex === index
                                            ? "show"
                                            : ""
                                        } dropdown-menu-end p-0`}
                                      >
                                        <li>
                                          <Link
                                            className="dropdown-item"
                                            style={{ fontSize: "12px" }}
                                            to="javascript:void(0)"
                                            onClick={() =>
                                              editnotes(
                                                item.id,
                                                index,
                                                item.full_name,
                                                item.employee_status,
                                                item.addressabout,
                                                item.additional_information
                                              )
                                            }
                                          >
                                            <i className="fal fa-edit"></i> Edit
                                          </Link>
                                        </li>
                                        <li>
                                          <Link
                                            className="dropdown-item text-danger"
                                            style={{ fontSize: "13px" }}
                                            to="javascript:void(0)"
                                            onClick={() =>
                                              removewitnes(index, item.id)
                                            }
                                          >
                                            <i className="fal fa-trash-alt"></i>{" "}
                                            Delete
                                          </Link>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              ))
                            ) : (
                              <Link> </Link>
                            )}
                          </div>
                          <div className="col-12">
                            <div className="d-flex gap-2 align-items-center buttons-two">
                              <Link
                                to={`/near-miss/reporter/${uniquecode}`}
                                className="cel-btn"
                              >
                                Back
                              </Link>
                              <button type="submit" className="rep-btn">
                                Review Report
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <Modal
              isOpen={isModalOpen}
              onRequestClose={() => setIsModalOpen(false)}
              contentLabel="Add Text Modal"
              className="docs d-flex flex-column  bg-light mt-5 p-3 align-item-center justify-content-center"
            >
              <div style={{ width: "500px" }}>
                <div className="d-flex flex-column file-pop">
                  <div className="d-flex justify-content-between align-items-center top-head">
                    <div className="flex-grow-1">
                      <h2>Witness</h2>
                    </div>
                    <div className="flex-shirnk-0">
                      <button
                        type="button"
                        onClick={() => docclose()}
                        className="close-btn"
                      >
                        <i className="fal fa-times" aria-hidden="true"></i>
                      </button>
                    </div>
                  </div>
                  <div className="d-flex flex-column px-1 py-2">
                    <form
                      action="javascript:void(0)"
                      method="post"
                      onSubmit={witnessform}
                    >
                      <input type="hidden" name="witn_id" value={witn_id} />
                      <div className="col-12">
                        <div className="d-flex flex-column gap-2 rel ">
                          <label for="">
                            Who witnessed the near miss{" "}
                            <span class="text-danger">*</span>
                          </label>
                          <div className="d-flex flex-wrap gap-4 align-items-center">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                required
                                name="employee_status"
                                id="non1"
                                onClick={() => handstep2employee("Employee")}
                                checked={isstep2employee === "Employee"}
                              />
                              <label
                                className="form-check-label rabutton"
                                for="non1"
                              >
                                Employee
                              </label>
                            </div>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="employee_status"
                                onClick={() =>
                                  handstep2employee("Non-Employee")
                                }
                                checked={isstep2employee === "Non-Employee"}
                                id="non2"
                              />
                              <label
                                className="form-check-label rabutton"
                                for="non2"
                              >
                                Non-Employee
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="d-flex flex-column gap-3 drag-box mt-4">
                        <div class="d-flex flex-column gap-1">
                          <label for="">
                            Full name
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            required
                            className="form-control"
                            name="full_name"
                            defaultValue={persondetail}
                            placeholder=""
                          />
                        </div>
                      </div>
                      <div class="d-flex flex-column gap-3 drag-box mt-4">
                        <div class="d-flex flex-column gap-1">
                          <label for="">Address</label>
                          <input
                            type="text"
                            className="form-control"
                            name="addressabout"
                            defaultValue={addressabout}
                            placeholder=""
                          />
                        </div>
                      </div>
                      <div className="d-flex flex-column gap-3 drag-box mt-4">
                        <div className="d-flex flex-column gap-2">
                          <label for="">Additional information</label>
                          <textarea
                            name="additional_information"
                            id=""
                            cols="30"
                            className="form-control"
                            defaultValue={additional_information}
                            rows="3"
                          />
                        </div>
                      </div>
                      <div className="col-12 mt-4">
                        <div className="d-flex gap-2 align-items-center buttons-two">
                          <button type="submit" className="rep-btn">
                            Save Witness
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </Modal>
            <div className="col-md-4">
              <div className="d-flex flex-column gap-3">
                <div className="d-flex flex-column need-block border border-secondary border-opacity-25 rounded-2 overflow-hidden">
                  <div className="d-block fulw">
                    <img
                      src={require("../assets/ohs/images/need.png")}
                      alt="image"
                    />
                  </div>
                  <div className="d-block px-3 pb-3">
                    <p>
                      Need help with the hazard report process? Call the Health
                      & Safety Advice Line on
                      <Link to="tel:1300 029 198">
                        0431 619 015,0406 400 994
                      </Link>
                    </p>
                  </div>
                </div>
                {/* <Documentsnotes /> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};
