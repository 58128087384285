import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../assets/admin/css/custom-style.css";
import "../assets/admin/css/main.css";
const AdminFooter = () => {
  return (
    <footer>
      {/* Your footer content */}
      <script src="https://code.jquery.com/jquery-3.6.0.min.js" />
      <script src="https://cdn.jsdelivr.net/npm/@popperjs/core@2.11.8/dist/umd/popper.min.js" />
      <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/js/bootstrap.min.js" />
    </footer>
  );
};

export default AdminFooter;
