import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "../assets/workinformation/css/custom-style.css";
import "../assets/workinformation/style.css";

import { FaSignOutAlt } from "react-icons/fa";
const WorkHeader = () => {
  const location = useLocation();

  const handleLogout = () => {
    sessionStorage.removeItem("admin");
    window.location.href = "/admin/login";
  };
  const changeFavicon = (url) => {
    const favicon = document.querySelector('link[rel="icon"]');
    if (favicon) {
      favicon.href = url;
    }
  };
  const isMobileScreen = window.innerWidth <= 767.98;
  const [isDivVisible, setDivVisibility] = useState(!isMobileScreen);
  useEffect(() => {
    changeFavicon(require("../assets/admin/images/f-icon.png"));
    const handleResize = () => {
      const isMobile = window.innerWidth <= 767.98;
      if (isMobile !== isDivVisible) {
        setDivVisibility(!isMobile);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isDivVisible]);

  const toggleDivVisibility = () => {
    setDivVisibility(!isDivVisible);
  };
  return (
    <>
      <div className="main-red-block">
        <div className="container-fluid">
          <div className="row gy-3 justify-content-between align-items-center">
            <div className="col-6 col-md-5">
              <div className="d-flex gap-3 logo-block align-items-center">
                <Link to="index.html" className="fulw">
                  <img
                    src={require("../assets/workinformation/images/logo1.png")}
                    alt="logo"
                  />
                </Link>
                <Link to="index.html" className="fulw">
                  <img
                    src={require("../assets/workinformation/images/logo2.png")}
                    alt="logo"
                  />
                </Link>
              </div>
            </div>
            <div className="col-6 col-md-4">
              <div className="d-flex justify-content-end">
                <div className="d-flex gap-2 align-items-center user-login-btn">
                  <div className="drop-block rel">
                    <Link to="#" className="reg-btn">
                      Sign in
                    </Link>
                  </div>
                  <div className="drop-block rel">
                    <Link to="#" className="reg-btn">
                      Join
                    </Link>
                    <ul className="drop-menu-block">
                      <li>
                        <Link to="#">Blue Collar</Link>
                      </li>
                      <li>
                        <Link
                          to="javascript:void(0)"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                        >
                          White Collar
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WorkHeader;
